import { Form } from "react-bootstrap"
import { PaymentServiceType } from "../constants/PaymentServiceType"
import { useTranslation } from "react-i18next"

interface IPaymentServiceSelectProps {
    value: string
    required?: boolean
    onChange: (e: any) => void
}

function PaymentServiceSelect(props: IPaymentServiceSelectProps) {
    const { t } = useTranslation("global")

    return (
        <Form.Group className="mb-1" controlId="paymentService">
            <Form.Label className="mb-0">{t("eventTicketsPage.paymentService")}</Form.Label>
            <Form.Select name="paymentService" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                <option value={PaymentServiceType.CASH}>{t(`paymentService.${PaymentServiceType.CASH}`)}</option>
                <option value={PaymentServiceType.BANK_TRANSFER}>{t(`paymentService.${PaymentServiceType.BANK_TRANSFER}`)}</option>
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredPaymentService")}</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default PaymentServiceSelect