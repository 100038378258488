import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Button, Modal, Form, Table, InputGroup, Card, Container, Row, Col, Alert, Tabs, Tab } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import Pagination from "../../components/Pagination"
import Configs from "../../Configs"
import ApiKeyService, { IApiKey, IApiKeyUpdate } from "../../services/ApiKeyService"
import PlaceService, { IPlace } from "../../services/PlaceService"
import useAppContext from "../../hooks/useAppContext"
import { SelectOptionType } from "../../constants/SelectOptionType"
import { SelectInstance } from "react-select"
import AsyncSelect from "react-select/async"
import LocalizationsModal from "./LocalizationsModal"
import ClipboardCopy from "../../components/ClipboardCopy"
import AppsModal from "./AppsModal"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"
import EventService, { IEvent } from "../../services/EventService"

function ApiKeys() {
    const { state } = useAppContext()
    const { hasPermission } = useAuthContext()

    const [tableData, setTableData] = useState({
        data: new Array<IApiKey>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IApiKey = {
        id: "",
        name: "",
        company: "",
        address: "",
        email: "",
        country: "",
        accessKey: "",
        isActive: false,
        providerId: state.currentProviderId || 0,
        places: [],
        events: []
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [validatedFormModal, setValidatedFormModal] = useState(false)
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [selectedItem, setSelectedItem] = useState({
        id: "",
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await ApiKeyService.filter(state.currentProviderId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentProviderId])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const handleEdit = async (id: string) => {
        if (state.currentProviderId && id) {
            const res = await ApiKeyService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setFormData(res.data)

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (state.currentProviderId) {
            if (formRef.current?.checkValidity()) {
                const apiKeyUpdate: IApiKeyUpdate = {
                    name: formData.name,
                    company: formData.company,
                    address: formData.address,
                    email: formData.email,
                    country: formData.country,
                    places: formData.places.map(x => { return { placeId: x.id || 0 } }),
                    events: formData.events.map(x => { return { eventId: x.id || 0 } })
                }

                if (formData.id) {
                    const res = await ApiKeyService.update(state.currentProviderId, formData.id, apiKeyUpdate)

                    if (res?.isSuccess) {
                        FilterFn(currentPageIndex)
                        handleCloseModal()
                        setMessage(res.message)
                    } else {
                        setMessageFormModal(res?.message)
                    }
                } else {
                    const res = await ApiKeyService.create(state.currentProviderId, apiKeyUpdate)

                    if (res?.isSuccess) {
                        FilterFn(1)
                        handleCloseModal()
                        setMessage(res.message)
                    } else {
                        setMessageFormModal(res?.message)
                    }
                }
            } else {
                setValidatedFormModal(true)
            }
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedFormModal(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = formData.id ? "Edit Api Key" : "New Api Key"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: "",
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: string, name: string) => {
        setSelectedItem({
            id: id,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentProviderId && selectedItem.id) {
            const res = await ApiKeyService.delete(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handleActiveSwitch = async (id: string) => {
        if (state.currentProviderId) {
            const res = await ApiKeyService.changeIsActive(state.currentProviderId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    //Places Async Select
    const [selectedPlaceOption, setSelectedPlaceOption] = useState(null as SelectOptionType | null)
    const placeAsyncRef = useRef<SelectInstance<SelectOptionType> | null>(null)

    const handlePlaceSelectChange = async (option: SelectOptionType | null) => {
        setSelectedPlaceOption(option)

        if (option?.value) {
            let value = option?.value as number

            if (formData.places.filter(x => x.id === value).length === 0) {
                if (state.currentProviderId) {
                    var res = await PlaceService.get(state.currentProviderId, value)

                    if (res.isSuccess) {
                        const { places } = formData
                        places.push(res.data)

                        setFormData({ ...formData, places: places })

                        setSelectedPlaceOption(null)
                        placeAsyncRef.current?.focus()
                    }
                }
            }
        }
    }

    const filterPlaces = async (inputValue: string) => {
        var options: SelectOptionType[] = []

        if (state.currentProviderId) {
            var res = await PlaceService.search(state.currentProviderId, inputValue)

            if (res.isSuccess && res.data) {
                options = res.data.map((item: IPlace) => {
                    return { value: item.id, label: item.name }
                })
            }
        }

        return options
    }

    const loadPlaceOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterPlaces(inputValue))
    })

    const handleRemovePlace = (placeId: number | null) => {
        setFormData({ ...formData, places: formData.places.filter(x => x.id !== placeId) })
    }

    //Events Async Select
    const [selectedEventOption, setSelectedEventOption] = useState(null as SelectOptionType | null)
    const eventAsyncRef = useRef<SelectInstance<SelectOptionType> | null>(null)

    const handleEventSelectChange = async (option: SelectOptionType | null) => {
        setSelectedEventOption(option)

        if (option?.value) {
            let value = option?.value as number

            if (formData.events.filter(x => x.id === value).length === 0) {
                if (state.currentProviderId) {
                    var res = await EventService.get(state.currentProviderId, value)

                    if (res.isSuccess) {
                        const { events } = formData
                        events.push(res.data)

                        setFormData({ ...formData, events: events })

                        setSelectedEventOption(null)
                        eventAsyncRef.current?.focus()
                    }
                }
            }
        }
    }

    const filterEvents = async (inputValue: string) => {
        var options: SelectOptionType[] = []

        if (state.currentProviderId) {
            var res = await EventService.search(state.currentProviderId, inputValue)

            if (res.isSuccess && res.data) {
                options = res.data.map((item: IEvent) => {
                    return { value: item.id, label: item.name }
                })
            }
        }

        return options
    }

    const loadEventOptions = (inputValue: string) => new Promise<SelectOptionType[]>((resolve) => {
        resolve(filterEvents(inputValue))
    })

    const handleRemoveEvent = (eventId: number | null) => {
        setFormData({ ...formData, events: formData.events.filter(x => x.id !== eventId) })
    }

    // Localizations
    const [showLocalizationsModal, setShowLocalizationsModal] = useState(false)
    const handleCloseLocalizationsModal = () => {
        setShowLocalizationsModal(false)
        setSelectedItem({
            id: "",
            name: ""
        })
    }

    const handleShowLocalizationsModal = (id: string, name: string) => {
        if (id) {
            setSelectedItem({
                id: id,
                name: name
            })
            setShowLocalizationsModal(true)
        }
    }

    // Apps
    const [showAppsModal, setShowAppsModal] = useState(false)
    const handleCloseAppsModal = () => {
        setShowAppsModal(false)
        setSelectedItem({
            id: "",
            name: ""
        })
    }

    const handleShowAppsModal = (id: string, name: string) => {
        if (id) {
            setSelectedItem({
                id: id,
                name: name
            })
            setShowAppsModal(true)
        }
    }

    const defaultActiveKey = () => {
        if (hasPermission(Permissions.Places.Search)) {
            return "places-tab"
        } else if (hasPermission(Permissions.Events.Search)) {
            return "events-tab"
        } else {
            return "privacy-policy-tab"
        }
    }
    return (
        <>
            <ContentHeader title="Api Keys" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.ApiKeys.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Add new
                                        </Button>
                                    }
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "300px" }}>App Name</th>
                                                <th>Access Key</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Active</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            {item.name}
                                                            <p>
                                                                {hasPermission(Permissions.Localizations.Custom) &&
                                                                    <Button variant="default" size="sm" onClick={() => handleShowLocalizationsModal(item.id, item.name)}>
                                                                        <i className="fas fa-language text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}> Localizations</span>
                                                                    </Button>
                                                                }
                                                                {hasPermission(Permissions.Apps.View) &&
                                                                    <Button variant="default" size="sm" onClick={() => handleShowAppsModal(item.id, item.name)}>
                                                                        <i className="fab fa-app-store-ios text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}> Apps</span>
                                                                    </Button>
                                                                }
                                                                <a className="btn btn-sm btn-default" href={`${Configs.PRIVACY_POLICY_URL}${item.id}`} target="_blank" rel="noreferrer"><i className="fas fa-shield-alt text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}> Privacy Policy</span></a>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <ClipboardCopy text={item.accessKey}></ClipboardCopy>
                                                        </td>
                                                        {/* <td className="text-truncate rtl">{item.accessKey}</td> */}
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" id={"apikey_isactive_" + item.id} checked={item.isActive} onChange={() => handleActiveSwitch(item.id)} disabled={!hasPermission(Permissions.ApiKeys.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.ApiKeys.Edit) &&
                                                                <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.ApiKeys.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        {formData.id &&
                            <Form.Group className="mb-3" controlId="accessKey">
                                <Form.Label>Access Key</Form.Label>
                                <Form.Control as="textarea" rows={5} readOnly name="accessKey" value={formData.accessKey} />
                            </Form.Group>
                        }
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>App Name</Form.Label>
                            <Form.Control type="text" name="name" required value={formData.name} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">App Name is required.</Form.Control.Feedback>
                        </Form.Group>

                        <Tabs defaultActiveKey={defaultActiveKey()}>
                            {hasPermission(Permissions.Places.Search) &&
                                <Tab eventKey="places-tab" title="Places" className="p-3">
                                    <AsyncSelect className="mb-3" isClearable value={selectedPlaceOption} loadOptions={loadPlaceOptions} onChange={handlePlaceSelectChange} ref={placeAsyncRef} placeholder="Search" noOptionsMessage={({ inputValue }) => !inputValue ? "Enter your place to search." : "Can't find your place!"} />
                                    <Table hover bordered size="sm" className="mb-0">
                                        <thead>
                                            <tr>
                                                <th>Place Name</th>
                                                <th style={{ width: "40px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formData.places.map((item: IPlace) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        #{item.id}. {item.name || ""}
                                                    </td>
                                                    <td>
                                                        <Button variant="light" size="sm" onClick={() => handleRemovePlace(item.id)}><i className="fas fa-times"></i></Button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Tab>}
                            {hasPermission(Permissions.Events.Search) &&
                                <Tab eventKey="events-tab" title="Events" className="p-3">
                                    <AsyncSelect className="mb-3" isClearable value={selectedEventOption} loadOptions={loadEventOptions} onChange={handleEventSelectChange} ref={eventAsyncRef} placeholder="Search" noOptionsMessage={({ inputValue }) => !inputValue ? "Enter your event to search." : "Can't find your event!"} />
                                    <Table hover bordered size="sm" className="mb-0">
                                        <thead>
                                            <tr>
                                                <th>Event Name</th>
                                                <th style={{ width: "40px" }}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formData.events.map((item: IEvent) =>
                                                <tr key={item.id}>
                                                    <td>
                                                        #{item.id}. {item.name || ""}
                                                    </td>
                                                    <td>
                                                        <Button variant="light" size="sm" onClick={() => handleRemoveEvent(item.id)}><i className="fas fa-times"></i></Button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Tab>}
                            <Tab eventKey="privacy-policy-tab" title="Privacy Policy Info" className="p-3">
                                <legend>Privacy Policy Info</legend>
                                <Form.Group className="mb-3" controlId="company">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control type="text" name="company" value={formData.company || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="address">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control type="text" name="address" value={formData.address || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" name="email" value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>Country</Form.Label>
                                    <Form.Control type="text" name="country" value={formData.country || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                </Form.Group>
                            </Tab>
                        </Tabs>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={selectedItem.name} />

            <LocalizationsModal providerId={state.currentProviderId || 0} apiKeyId={selectedItem.id} title={selectedItem.name} show={showLocalizationsModal} onClose={handleCloseLocalizationsModal}></LocalizationsModal>
            <AppsModal providerId={state.currentProviderId || 0} apiKeyId={selectedItem.id} title={selectedItem.name} show={showAppsModal} onClose={handleCloseAppsModal}></AppsModal>
        </>
    )
}

export default ApiKeys