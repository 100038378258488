import { Alert, Button, ButtonGroup, Card, Col, Container, Dropdown, DropdownButton, Form, InputGroup, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import Permissions from "../../constants/Permissions"
import useAppContext from "../../hooks/useAppContext"
import useAuthContext from "../../hooks/useAuthContext"
import { KeyboardEvent, RefObject, useCallback, useEffect, useRef, useState } from "react"
import MyEventTicketCreateModal from "./MyEventTicketCreateModal"
import Configs from "../../Configs"
import EventTicketService, { IEventTicket, GetEventTicketSortFields, IEventTicketPrint } from "../../services/EventTicketService"
import SortBy from "../../components/SortBy"
import Utils from "../../utils/Utils"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import MyEventTicketEditModal from "./MyEventTicketEditModal"
import TicketTemplateService, { ITicketTemplate } from "../../services/TicketTemplateService"
import HtmlToPrint from "../../components/HtmlToPrint"
import { useReactToPrint } from "react-to-print"
import Confirmation from "../../components/Confirmation"
import MyEventTicketDashboardModal from "./MyEventTicketDashboardModal"
import QRCodeModal from "../../components/QRCodeModal"
import PaymentStatusBadge from "../../components/PaymentStatusBadge"
import { PaymentStatusType } from "../../constants/PaymentStatusType"
// import PayGateService from "../../services/PayGateService"
// import RoutePath from "../../constants/RoutePath"
import MyEventTicketDetailsModal from "./MyEventTicketDetailsModal"
import { InvoiceStatusType } from "../../constants/InvoiceStatusType"
import RoutePath from "../../constants/RoutePath"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"
import MyEventTicketCustomerEditModal from "./MyEventTicketCustomerEditModal"

function MyEventTickets() {
    const { t } = useTranslation("global")
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()

    const [processing, setProcessing] = useState<boolean>(false)
    const [tableData, setTableData] = useState({
        data: new Array<IEventTicket>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })
    const [message, setMessage] = useState("")
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [sortBy, setSortBy] = useState("createdOn")
    const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC")
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await EventTicketService.filterByUser(state.currentProviderId, pageIndex, pageSize, keyword, sortBy, sortDirection)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentProviderId, sortBy, sortDirection])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    //AddNew
    const [showCreateModal, setShowCreateModal] = useState(false)

    const handleCreateSuccess = (id: number) => {
        handlePrint(id)
        FilterFn(1)
    }

    //Delete
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentProviderId && selectedItem.id) {
            const res = await EventTicketService.deleteByUser(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    //Issue Invoice
    const [displayIssueInvoiceConfirmationModal, setDisplayIssueInvoiceConfirmationModal] = useState(false)

    const hideIssueInvoiceConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })
        setDisplayIssueInvoiceConfirmationModal(false)
    }

    const showIssueInvoiceConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })
        setDisplayIssueInvoiceConfirmationModal(true)
        setMessage("")
    }

    const handleIssueInvoice = async () => {
        if (state.currentProviderId && selectedItem.id) {
            const res = await EventTicketService.issueInvoiceByUser(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideIssueInvoiceConfirmationModal()
            setMessage(res.message)
        }
    }

    //Cancel Invoice
    const [displayCancelInvoiceConfirmationModal, setDisplayCancelInvoiceConfirmationModal] = useState(false)

    const hideCancelInvoiceConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })
        setDisplayCancelInvoiceConfirmationModal(false)
    }

    const showCancelInvoiceConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })
        setDisplayCancelInvoiceConfirmationModal(true)
        setMessage("")
    }

    const handleCancelInvoice = async () => {
        if (state.currentProviderId && selectedItem.id) {
            const res = await EventTicketService.cancelInvoiceByUser(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideCancelInvoiceConfirmationModal()
            setMessage(res.message)
        }
    }

    //Edit
    const [showEditModal, setShowEditModal] = useState(false)

    const handleShowEditModal = (id: number | null) => {
        if (id) {
            setSelectedItem({
                id: id,
                name: ""
            })

            setShowEditModal(true)
        }
    }

    const handleCloseEditModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setShowEditModal(false)
    }

    const handleEditSuccess = () => {
        FilterFn(1)
    }

    //Edit Customer
    const [showCustomerEditModal, setShowCustomerEditModal] = useState(false)

    const handleShowCustomerEditModal = (id: number | null) => {
        if (id) {
            setSelectedItem({
                id: id,
                name: ""
            })

            setShowCustomerEditModal(true)
        }
    }

    const handleCloseCustomerEditModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setShowCustomerEditModal(false)
    }

    const handleCustomerEditSuccess = () => {
        FilterFn(1)
    }

    //Details
    const [showDetailsModal, setShowDetailsModal] = useState(false)

    const handleShowDetailsModal = (id: number | null) => {
        if (id) {
            setSelectedItem({
                id: id,
                name: ""
            })

            setShowDetailsModal(true)
        }
    }

    const handleCloseDetailsModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setShowDetailsModal(false)
    }

    //Print
    const [isPrinting, setIsPrinting] = useState(false)
    const printRef = useRef(null)
    const onBeforeGetContentResolve = useRef<(() => void) | null>(null)
    const [eventTicketId, setEventTicketId] = useState<number | null>(null)
    const [ticketContentToPrint, setTicketContentToPrint] = useState<ITicketTemplate | null>(null)

    const handlePrint = (id: number | null) => {
        if (state.currentProviderId && id) {
            if (Configs.PrintMethod.get() === "HTML") {
                setEventTicketId(id)
            } else {
                EventTicketService.generatePDFByUser(state.currentProviderId, id)
            }
        }
    }

    useEffect(() => {
        loadTicketContent()
    }, [eventTicketId])

    const loadTicketContent = async () => {
        if (state.currentProviderId && eventTicketId) {
            const template = await TicketTemplateService.getEventTicketHTMLTemplate(state.currentProviderId)

            if (template?.isSuccess && template.data) {
                const ticketTemplate: ITicketTemplate = template.data

                const res = await EventTicketService.getForPrintByUser(state.currentProviderId, eventTicketId)

                if (res?.isSuccess) {
                    var html = ""
                    res.data.forEach((ticket: IEventTicketPrint, index: number) => {
                        var contentTemplate = ticketTemplate.htmlContent
                        var logo = (ticketTemplate.logo || "") !== "" ? `${Configs.API_BASE_URL}${ticketTemplate.logo}` : ""
                        var bgImage = (ticketTemplate.bgImage || "") !== "" ? `${Configs.API_BASE_URL}${ticketTemplate.bgImage}` : ""

                        if (index > 0) {
                            html += '<div class="page-break" />'
                        }

                        html += contentTemplate
                            .replaceAll("{eventId}", ticket.eventId.toString())
                            .replaceAll("{eventName}", ticket.eventName || "")
                            .replaceAll("{thumbnail}", ticket.thumbnail || "")
                            .replaceAll("{eventDate}", Utils.dateToString(ticket.eventDate, Configs.DATE_FORMAT))
                            .replaceAll("{eventStartTime}", ticket.eventStartTime?.substring(0, 5) || "")
                            .replaceAll("{eventEndTime}", ticket.eventEndTime?.substring(0, 5) || "")
                            .replaceAll("{tel}", ticket.tel || "")
                            .replaceAll("{email}", ticket.email || "")
                            .replaceAll("{website}", ticket.website || "")
                            .replaceAll("{websiteQRCode}", ticket.websiteQRCode || "")
                            .replaceAll("{address}", ticket.address || "")
                            .replaceAll("{code}", ticket.code || "")
                            .replaceAll("{qrcode}", ticket.qrcodeImage)
                            .replaceAll("{price}", Utils.formatNumber(ticket.price))
                            .replaceAll("{currency}", ticket.currency)
                            .replaceAll("{seat}", ticket.seat)
                            .replaceAll("{ticketClass}", ticket.ticketClass)
                            .replaceAll("{eventTicketId}", ticket.eventTicketId.toString())
                            .replaceAll("{logo}", logo)
                            .replaceAll("{bgImage}", bgImage)
                            .replaceAll("{createdOn}", Utils.dateToString(ticket.createdOn, `${Configs.TIME_FORMAT} ${Configs.DATE_FORMAT}`))
                    })

                    setTicketContentToPrint({ ...ticketTemplate, htmlContent: html })
                } else {
                    setTicketContentToPrint(null)
                }
            } else {
                setTicketContentToPrint(null)
            }
        } else {
            setTicketContentToPrint(null)
        }
    }

    const handleOnBeforeGetContent = useCallback(() => {
        setIsPrinting(true)

        return new Promise<void>((resolve) => {
            onBeforeGetContentResolve.current = resolve
            setIsPrinting(false)
            setEventTicketId(null)
            resolve()
        })
    }, [isPrinting, ticketContentToPrint])

    const handlePrintHTML = useReactToPrint({
        content: () => printRef?.current,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: () => {
            onBeforeGetContentResolve.current = null
            setTicketContentToPrint(null)
        },
        removeAfterPrint: true
    })

    useEffect(() => {
        if (ticketContentToPrint) {
            handlePrintHTML()
        }
    }, [ticketContentToPrint])

    useEffect(() => {
        if (ticketContentToPrint && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current()
        }
    }, [onBeforeGetContentResolve.current, ticketContentToPrint])

    //Email
    const [showEmailConfirmationModal, setShowEmailConfirmationModal] = useState(false)
    const [emailConfirmationMessage, setEmailConfirmationMessage] = useState("")

    const handleShowEmailConfirmationModal = (id: number | null, email: string) => {
        if (state.currentProviderId && id && email) {
            setSelectedItem({ id: id, name: email })
            setEmailConfirmationMessage(`${t("eventTicketsPage.confirmSendEmail")} "${email}"?`)
            setShowEmailConfirmationModal(true)
        }
    }

    const handleSendETicket = async () => {
        if (state.currentProviderId && selectedItem.id && !processing) {
            setProcessing(true)
            setEmailConfirmationMessage(t("eventTicketsPage.pleaseWait"))

            const res = await EventTicketService.sendETicketByUser(state.currentProviderId, selectedItem.id)

            setShowEmailConfirmationModal(false)
            setMessage(res.message)
            setProcessing(false)
        }
    }

    //Payment QR Code
    const [qrCode, setQRCode] = useState<{ image: string, code: string, caption?: Array<string> }>({ image: "", code: "", caption: [] })
    const [showQRCodeModal, setShowQRCodeModal] = useState(false)
    const handleCloseQRCodeModal = () => {
        setShowQRCodeModal(false)
        setQRCode({ image: "", code: "", caption: [] })
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowPaymentQRCodeModal = (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = EventTicketService.generatePaymentQRCodeByUser(state.currentProviderId, id)

            res.then(data => {
                if (data?.isSuccess) {
                    setQRCode({ image: data.data, code: id.toString(), caption: [] })
                    setSelectedItem({
                        id: id || 0,
                        name: ""
                    })
                    setShowQRCodeModal(true)
                } else {
                    console.log(data?.message)
                }
            })
        }
    }

    const handleDownloadQRCode = () => {
        Utils.downloadBase64File(qrCode.image, `PaymentQRCode-${qrCode.code}.png`)
    }

    //PaymentUrl
    // const [paymentUrl, setPaymentUrl] = useState("")
    // const [showPaymentUrlModal, setShowPaymentUrlModal] = useState(false)
    // const handleClosePaymentUrlModal = () => {
    //     setShowPaymentUrlModal(false)
    //     setPaymentUrl("")
    //     setSelectedItem({
    //         id: 0,
    //         name: ""
    //     })
    // }

    // const handleShowPaymentUrlModal = (id: number | null) => {
    //     if (state.currentProviderId && id) {
    //         const returnUrl = `${Utils.getBaseUrl()}${RoutePath.PROVIDER.CHECKOUT}`

    //         const res = PayGateService.getQRPaymentUrl(state.currentProviderId, id, returnUrl)

    //         res.then(data => {
    //             if (data?.isSuccess) {
    //                 setPaymentUrl(data?.data || "")
    //                 setSelectedItem({
    //                     id: id || 0,
    //                     name: ""
    //                 })
    //                 setShowPaymentUrlModal(true)
    //             } else {
    //                 console.log(data?.message)
    //                 FilterFn(currentPageIndex)
    //             }
    //         })
    //     }
    // }

    const [showMyEventTicketDashboardModal, setShowMyEventTicketDashboardModal] = useState(false)

    const disableEditButton = (paymentService: string, paymentStatus: string) => {
        return (paymentService || "") === "" || paymentStatus === PaymentStatusType.PAID
    }

    return (
        <>
            <ContentHeader title={t("myEventTicketsPage.title")} />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.MyEventTickets.Create) &&
                                        <Button variant="info" size="sm" onClick={() => setShowCreateModal(true)}>
                                            <i className="fas fa-plus"></i> {t("eventTicketsPage.createNewEventTicket")}
                                        </Button>
                                    }
                                    {hasPermission(Permissions.MyEventTickets.Report) &&
                                        <Button variant="secondary" size="sm" className="ml-1" onClick={() => setShowMyEventTicketDashboardModal(true)}>
                                            <i className="fas fa-chart-line"></i> {t("myEventTicketsPage.dashboard")}
                                        </Button>
                                    }

                                    <div className="card-tools" style={{ display: "flex" }}>
                                        <NavLink to={RoutePath.PROVIDER.MY_EVENT_TICKETS} style={{ whiteSpace: "nowrap" }} className="btn btn-light btn-sm mr-1"><i className="fas fa-square"></i> {t("eventTicketsPage.overview")}</NavLink>
                                        <NavLink to={RoutePath.PROVIDER.MY_EVENT_TICKETS_SOLD} style={{ whiteSpace: "nowrap" }} className="btn btn-light btn-sm mr-1"><i className="fas fa-list"></i> {t("eventTicketsPage.details")}</NavLink>
                                        <SortBy fields={GetEventTicketSortFields()} sortBy={sortBy} sortDirection={sortDirection} onFieldChange={name => setSortBy(name)} onDirectionChange={name => setSortDirection(name)}></SortBy>

                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder={t("common.search")}
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>{t("eventTicketsPage.event")}</th>
                                                <th>{t("eventTicketsPage.customer")}</th>
                                                <th>{t("eventTicketsPage.ticket")}</th>
                                                <th>{t("eventTicketsPage.payment")}</th>
                                                <th>{t("eventTicketsPage.note")}</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>{t("common.actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            <ul className="list-in-table">
                                                                <li>#{item.id}. {item.event?.name}</li>
                                                                <li><i className="fas fa-calendar-day"></i> {Utils.dateToString(item.eventDate, Configs.DATE_FORMAT)}</li>
                                                                <li><i className="fas fa-clock"></i> {item.eventSchedule && <>{item.eventSchedule.startTime.substring(0, 5)} - {item.eventSchedule.endTime.substring(0, 5)}</>}</li>
                                                                {item.event?.timeZoneInfo?.displayName && <li style={{ fontSize: "10px" }}><i className="fas fa-globe"></i> {item.event?.timeZoneInfo?.displayName}</li>}
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul className="list-in-table">
                                                                <li><i className="fas fa-user"></i> {item.customer.name}</li>
                                                                <li><i className="fas fa-phone"></i> {item.customer.formattedTel}</li>
                                                                <li><i className="fas fa-envelope"></i>
                                                                    {item.paymentStatus === PaymentStatusType.PAID ?
                                                                        <a onClick={() => handleShowEmailConfirmationModal(item.id, item.customer.email)} style={{ cursor: "pointer" }} className="text-primary">{item.customer.email}</a>
                                                                        : item.customer.email}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul className="list-in-table">
                                                                <li><span>{t("eventTicketsPage.quantity")}:</span> {item.quantity} {item.bookedSeats && <>({item.bookedSeats})</>}</li>
                                                                <li><span>{t("eventTicketsPage.amount")}:</span> <span className="text-danger">{Utils.formatNumber(item.totalPrice)}</span></li>
                                                            </ul>
                                                        </td>
                                                        <td>
                                                            <ul className="list-in-table">
                                                                <li><span>{t("eventTicketsPage.createdOn")}:</span> {Utils.dateToString(item.createdOn, Configs.DATE_TIME_FORMAT)}</li>
                                                                <li><span>{t("eventTicketsPage.paymentService")}:</span> {item.paymentService && t(`paymentService.${item.paymentService}`)}</li>
                                                                <li><span>{t("eventTicketsPage.paymentStatus")}:</span> <PaymentStatusBadge value={item.paymentStatus}></PaymentStatusBadge></li>
                                                                <li><span>{t("eventTicketsPage.invoiceStatus")}:</span> <span className="text-green">{item.invoiceStatus && t(`invoiceStatus.${item.invoiceStatus}`)}</span></li>
                                                            </ul>
                                                        </td>
                                                        <td style={{ whiteSpace: "pre-wrap" }}>{item.note}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <DropdownButton
                                                                as={ButtonGroup}
                                                                size="sm"
                                                                variant="outline-secondary"
                                                                className="btn-actions-dropdown"
                                                                align={{ lg: 'end' }}
                                                                title={t("common.actions")}
                                                            >
                                                                <Dropdown.Item onClick={() => handleShowDetailsModal(item.id)}><i className="fas fa-eye text-info"></i> {t("eventTicketsPage.viewDetails")}</Dropdown.Item>
                                                                {hasPermission(Permissions.MyEventTickets.Print) && item.paymentStatus === PaymentStatusType.PAID &&
                                                                    <Dropdown.Item onClick={() => handlePrint(item.id)}><i className="fas fa-print text-info"></i> {t("eventTicketsPage.printTicket")}</Dropdown.Item>
                                                                }
                                                                {!Utils.isEmpty(item.customer.email) && item.paymentStatus === PaymentStatusType.PAID &&
                                                                    <Dropdown.Item onClick={() => handleShowEmailConfirmationModal(item.id, item.customer.email)}><i className="fas fa-envelope text-info"></i> {t("eventTicketsPage.sendTickets")}</Dropdown.Item>
                                                                }
                                                                {item.event?.bankAccountEnabled && item.paymentStatus === PaymentStatusType.PENDING &&
                                                                    <Dropdown.Item onClick={() => handleShowPaymentQRCodeModal(item.id)}><i className="fas fa-qrcode text-info"></i> {t("eventTicketsPage.printPaymentQRCode")}</Dropdown.Item>
                                                                }
                                                                {/* {item.event?.onlinePaymentEnabled && item.paymentStatus === PaymentStatusType.PENDING &&
                                                                    <Dropdown.Item onClick={() => handleShowPaymentUrlModal(item.id)}><i className="fas fa-qrcode text-info"></i> Online payment QR code</Dropdown.Item>
                                                                } */}
                                                                {hasPermission(Permissions.MyEventTickets.EditCustomer) &&
                                                                    <Dropdown.Item onClick={() => handleShowCustomerEditModal(item.id)}><i className="fas fa-user-edit text-info"></i> {t("eventTicketsPage.editCustomer")}</Dropdown.Item>
                                                                }
                                                                {hasPermission(Permissions.MyEventTickets.Edit) && !disableEditButton(item.paymentService, item.paymentStatus) &&
                                                                    <Dropdown.Item onClick={() => handleShowEditModal(item.id)}><i className="fas fa-pencil-alt text-info"></i> {t("common.edit")}</Dropdown.Item>
                                                                }
                                                                {item.event?.eInvoiceEnabled && item.paymentStatus === PaymentStatusType.PAID && <>
                                                                    {item.invoiceStatus !== InvoiceStatusType.ISSUED && item.invoiceStatus !== InvoiceStatusType.REPLACED && hasPermission(Permissions.MyEventTickets.IssueInvoice) && <>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item onClick={() => showIssueInvoiceConfirmation(item.id, item.customer.name)}><i className="fas fa-file-invoice text-success"></i> {t("eventTicketsPage.issue")}</Dropdown.Item></>
                                                                    }
                                                                    {(item.invoiceStatus === InvoiceStatusType.ISSUED || item.invoiceStatus === InvoiceStatusType.REPLACED) && hasPermission(Permissions.MyEventTickets.CancelInvoice) && <>
                                                                        <Dropdown.Divider />
                                                                        <Dropdown.Item onClick={() => showCancelInvoiceConfirmation(item.id, item.customer.name)}><i className="fas fa-file-invoice text-danger"></i> {t("eventTicketsPage.cancel")}</Dropdown.Item></>
                                                                    }
                                                                </>}
                                                                {hasPermission(Permissions.MyEventTickets.Delete) && !disableEditButton(item.paymentService, item.paymentStatus) && <>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item onClick={() => showDeleteConfirmation(item.id, item.customer.name)}><i className="fas fa-trash-alt text-danger"></i> {t("common.delete")}</Dropdown.Item>
                                                                </>}
                                                            </DropdownButton>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <MyEventTicketCreateModal show={showCreateModal} providerId={state.currentProviderId} onClose={() => setShowCreateModal(false)} onSuccess={handleCreateSuccess} />
            <MyEventTicketEditModal show={showEditModal} providerId={state.currentProviderId} eventTicketId={selectedItem.id} onClose={handleCloseEditModal} onSuccess={handleEditSuccess} />
            <MyEventTicketCustomerEditModal show={showCustomerEditModal} providerId={state.currentProviderId} eventTicketId={selectedItem.id} onClose={handleCloseCustomerEditModal} onSuccess={handleCustomerEditSuccess} />
            <MyEventTicketDetailsModal show={showDetailsModal} providerId={state.currentProviderId} eventTicketId={selectedItem.id} onClose={handleCloseDetailsModal} />

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={`#${selectedItem.id}`} />
            <Confirmation show={showEmailConfirmationModal} message={emailConfirmationMessage} onHide={() => setShowEmailConfirmationModal(false)} onConfirm={handleSendETicket} />
            <QRCodeModal image={qrCode.image} code={qrCode.code} caption={qrCode.caption} show={showQRCodeModal} onClose={handleCloseQRCodeModal} onDownload={handleDownloadQRCode}></QRCodeModal>
            {/* <Modal show={showPaymentUrlModal} onHide={handleClosePaymentUrlModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Online Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {paymentUrl !== "" && <iframe src={paymentUrl} style={{ width: "100%", height: "600px" }}></iframe>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClosePaymentUrlModal}><i className="fas fa-times"></i> Cancel</Button>
                </Modal.Footer>
            </Modal> */}
            <Confirmation show={displayIssueInvoiceConfirmationModal} onConfirm={handleIssueInvoice} onHide={hideIssueInvoiceConfirmationModal} message={`${t("eventTicketsPage.confirmIssueInvoice")} #${selectedItem.id}?`} />
            <Confirmation show={displayCancelInvoiceConfirmationModal} onConfirm={handleCancelInvoice} onHide={hideCancelInvoiceConfirmationModal} message={`${t("eventTicketsPage.confirmCancelInvoice")} #${selectedItem.id}?`} />

            <HtmlToPrint
                htmlContent={ticketContentToPrint?.htmlContent || ""}
                styleSheet={ticketContentToPrint?.styleSheet || ""}
                paperSizeWidth={ticketContentToPrint?.paperSizeWidth || ""}
                paperSizeHeight={ticketContentToPrint?.paperSizeHeight || ""}
                ref={printRef}
            />

            <MyEventTicketDashboardModal show={showMyEventTicketDashboardModal} onClose={() => setShowMyEventTicketDashboardModal(false)} />
        </>
    )
}

export default MyEventTickets