import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface IEventPhotoTranslation {
    eventPhotoId: number | null
    languageId: number
    title: string
    description: string
    language: ILanguage
}

export interface IEventPhoto {
    id: number | null
    title: string
    photo: string
    orderNumber: number | null
    published: boolean
    eventId: number
    translations: Array<IEventPhotoTranslation>
}

export default class EventPhotoService {
    static async listAll(eventId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventPhotos/${eventId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(eventId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventPhotos/${eventId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventPhotos/${eventId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/EventPhotos/${eventId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(eventId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventPhotos/${eventId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(eventId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventPhotos/${eventId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePublished(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventPhotos/${eventId}/ChangePublished/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}