import { ChangeEvent, RefObject, SyntheticEvent, useRef, useState } from "react"
import { Link } from "react-router-dom"
import AccountService from "../services/AccountService"
import RoutePath from "../constants/RoutePath"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"

function Register() {
    const { t } = useTranslation("global")
    const [formData, setFormData] = useState({
        userName: "",
        password: "",
        confirmPassword: "",
        email: "",
        fullName: ""
    })

    const [message, setMessage] = useState("")
    const [validatedForm, setValidatedForm] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isHideForm, setIsHideForm] = useState(false)
    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault()
        if (!isLoading) {
            setIsLoading(true)
            try {
                if (formRef.current?.checkValidity()) {
                    const res = await AccountService.register(formData.userName, formData.password, formData.confirmPassword, formData.email, formData.fullName)

                    if (res?.isSuccess) {
                        setFormData({
                            userName: "",
                            password: "",
                            confirmPassword: "",
                            email: "",
                            fullName: ""
                        })

                        setMessage(res.message)
                        setIsHideForm(true)
                    } else {
                        setMessage(res?.message)
                    }
                } else {
                    setValidatedForm(true)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <div className="page-center">
            <div className="card max-w-400">
                <div className="card-body">
                    <p className="login-box-msg">
                        {t("registerPage.title")}
                        <small style={{ display: "block", marginTop: "20px" }}>
                            {t("registerPage.subTitle")}
                        </small>
                    </p>
                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                    {!isHideForm &&
                        <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>} className="mb-4">
                            <Form.Group className="mb-3" controlId="fullName">
                                <Form.Label>{t("registerPage.fullName")}</Form.Label>
                                <Form.Control type="text" name="fullName" autoComplete="off" required value={formData.fullName || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("registerPage.requiredFullName")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" autoComplete="off" required value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("registerPage.requiredEmail")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="userName">
                                <Form.Label>{t("registerPage.userName")}</Form.Label>
                                <Form.Control type="text" name="userName" autoComplete="off" required value={formData.userName || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("registerPage.requiredUserName")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="password">
                                <Form.Label>{t("registerPage.password")}</Form.Label>
                                <Form.Control type="password" name="password" autoComplete="off" required value={formData.password || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("registerPage.requiredPassword")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirmPassword">
                                <Form.Label>{t("registerPage.retypePassword")}</Form.Label>
                                <Form.Control type="password" name="confirmPassword" autoComplete="off" required value={formData.confirmPassword || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("registerPage.requiredRetypePassword")}</Form.Control.Feedback>
                            </Form.Group>
                            <div className="text-center">
                                <Button variant="primary" onClick={handleSubmit}>
                                    {isLoading ? <><Spinner animation="border" size="sm" /> {t("registerPage.sending")}</> : <>{t("registerPage.register")}</>}
                                </Button>
                            </div>
                        </Form>
                    }
                    <Link to={RoutePath.AUTH.LOGIN} className="text-center">
                        {isHideForm ? <>{t("loginPage.signIn")}</> : <>{t("registerPage.iAlreadyHaveAMembership")}</>}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Register