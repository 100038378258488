import { ChangeEvent, RefObject, SyntheticEvent, useRef, useState } from "react"
import AccountService from "../services/AccountService"
import { Alert, Button, Form } from "react-bootstrap"
import { useTranslation } from "react-i18next"

function ChangePassword() {
    const { t } = useTranslation("global")
    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    })

    const [message, setMessage] = useState("")
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [isHideForm, setIsHideForm] = useState(false)

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        try {
            if (formRef.current?.checkValidity()) {
                const res = await AccountService.changePassword(formData.currentPassword, formData.newPassword, formData.confirmNewPassword)

                if (res?.isSuccess) {
                    setFormData({
                        currentPassword: "",
                        newPassword: "",
                        confirmNewPassword: ""
                    })
                    setValidatedForm(false)
                    setIsHideForm(true)

                    setMessage(res.message)
                } else {
                    setMessage(res?.message)
                }
            } else {
                setValidatedForm(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <div className="page-center">
            <div className="card max-w-400">
                <div className="card-body">
                    <p className="login-box-msg">{t("changepasswordPage.title")}</p>
                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                    {!isHideForm &&
                        <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                            <Form.Group className="mb-3" controlId="currentPassword">
                                <Form.Label>{t("changepasswordPage.currentPassword")}</Form.Label>
                                <Form.Control type="password" name="currentPassword" autoComplete="off" required value={formData.currentPassword || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("changepasswordPage.requiredCurrentPassword")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="newPassword">
                                <Form.Label>{t("changepasswordPage.newPassword")}</Form.Label>
                                <Form.Control type="password" name="newPassword" autoComplete="off" required value={formData.newPassword || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("changepasswordPage.requiredNewPassword")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirmNewPassword">
                                <Form.Label>{t("changepasswordPage.confirmNewPassword")}</Form.Label>
                                <Form.Control type="password" name="confirmNewPassword" autoComplete="off" required value={formData.confirmNewPassword || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("changepasswordPage.requiredConfirmNewPassword")}</Form.Control.Feedback>
                            </Form.Group>
                            <div className="text-center">
                                <Button variant="primary" onClick={handleSave}><i className="fas fa-save"></i> {t("changepasswordPage.changePassword")}</Button>
                            </div>
                        </Form>
                    }
                </div>
            </div>
        </div>
    )
}

export default ChangePassword