import { Button, Modal } from "react-bootstrap"

interface IQRCodeModalProps {
    image: string
    code: string
    caption?: Array<string>
    show: boolean
    onClose: () => void
    onDownload: (e: any) => void
}

function QRCodeModal(props: IQRCodeModalProps) {
    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton className="p-2">
                <Modal.Title><i className="fas fa-qrcode"></i> QR Code</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
                {props.image && <img src={props.image} style={{ maxWidth: "100%" }} alt={props.code} />}
                <h1>{props.code}</h1>
                {props.caption && props.caption.map((x, i) => { return <p key={i}>{x}</p> })}
                {props.image && <Button variant="default" className="mb-3 text-info" onClick={props.onDownload}><i className="fas fa-download"></i> Download</Button>}
            </Modal.Body>
        </Modal>
    )
}

export default QRCodeModal