import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient";
import { IUser } from "./UserService";

export interface IProvider {
    id: number | null;
    name: string;
    address: string;
    email: string;
    description: string;
    about: string;
    logo: string;
    banner: string;
    isUsed: boolean;
    users: Array<IUser>;
}

export interface IProviderProfile {
    id: number | null;
    name: string;
    address: string;
    email: string;
    description: string;
    about: string;
    logo: string;
    banner: string;
}

export default class ProviderService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Providers?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Providers/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Providers/${id}`, {

            });

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async update(id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Providers/${id}`, formData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async create(formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Providers/Create`, formData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async changeIsUsed(id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Providers/ChangeIsUsed/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async listByUser() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Providers/ListByUser");

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async getByUser(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Providers/GetByUser/${id}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async updateByUser(id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Providers/UpdateByUser/${id}`, formData);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }

    static async search(keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Providers/Search?keyword=${keyword}`);

            return data;
        } catch (error) {
            return axiosCustomError(error);
        }
    }
}