import fileDownload from "js-file-download"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import axiosClientBlob from "./axiosClientBlob"
import Utils from "../utils/Utils"

export default class QRCodeService {
    static async generate(text: string) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/QRCode/Generate`, JSON.stringify(text))

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static download(text: string) {
        try {
            axiosClientBlob.post(`/api/QRCode/Download`, JSON.stringify(text)).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}