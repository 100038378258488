import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface ILanguage {
    id: number | null
    code: string
    name: string
    flag: string
    orderNumber: number | null
    isUsed: boolean
}

export default class LanguageService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Languages/All")

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Languages?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Languages/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Languages/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Languages/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Languages/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Languages/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}