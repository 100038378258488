import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import ContentHeader from "../../components/ContentHeader"
import useAppContext from "../../hooks/useAppContext"
import useAuthContext from "../../hooks/useAuthContext"
import useGlobalContext from "../../hooks/useGlobalContext"
import EventService, { ITicketEmailTemplate, IEvent, IOnlinePaymentSettings, IBankAccountSettings, IEInvoiceSettings } from "../../services/EventService"
import Configs from "../../Configs"
import AddOrRemoveLanguages, { LanguageActionTypes } from "../../components/AddOrRemoveLanguages"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Spinner, Tab, Table, Tabs } from "react-bootstrap"
import Permissions from "../../constants/Permissions"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import DatePicker from "../../components/DatePicker"
import DaysOfWeekCheckboxes from "../../components/DaysOfWeekCheckboxes"
import TicketClassesModal from "./TicketClassesModal"
import SeatsModal from "./SeatsModal"
import Utils from "../../utils/Utils"
import TimeZoneSelect from "../../components/TimeZoneSelect"
import TextEditor from "../../components/TextEditor"
import EventPhotosModal from "./EventPhotosModal"
import EventCategoriesCheckboxes from "../../components/EventCategoriesCheckboxes"
import OnlinePaymentProviderSelect from "../../components/OnlinePaymentProviderSelect"
import BankSelect from "../../components/BankSelect"
import EInvoiceProviderSelect from "../../components/EInvoiceProviderSelect"
import EventInvitationCodesModal from "./EventInvitationCodesModal"

function Events() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()
    const { languages } = useGlobalContext()

    const [tableData, setTableData] = useState({
        data: new Array<IEvent>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IEvent = {
        id: null,
        name: "",
        thumbnail: "",
        floorPlan: "",
        tel: "",
        email: "",
        website: "",
        orderNumber: null,
        timeZoneId: "",
        startDate: null,
        endDate: null,
        openingTime: null,
        closingTime: null,
        published: false,
        featured: false,
        timeZoneInfo: null,
        translations: languages.filter(x => x.code === Configs.DEFAULT_LANGUAGE_CODE).map(x => {
            return {
                eventId: null,
                languageId: x.id || 0,
                name: "",
                description: "",
                organizer: "",
                venue: "",
                address: "",
                isActive: false,
                language: x
            }
        }),
        schedules: [
            {
                id: null,
                startTime: "",
                endTime: "",
                byDays: []
            }
        ],
        ticketClasses: [],
        seats: [],
        categoryIds: []
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [activeTabKey, setActiveTabKey] = useState(languages.find(x => x.code === Configs.DEFAULT_LANGUAGE_CODE)?.id?.toString())
    const [thumbnail, setThumbnail] = useState(null as any)
    const [floorPlan, setFloorPlan] = useState(null as any)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await EventService.filter(state.currentProviderId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentProviderId])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "orderNumber" || e.target.name === "openingTime" || e.target.name === "closingTime") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleTranslateFormControlChange = (e: ChangeEvent<HTMLInputElement>, languageId: number | null) => {
        if (e.target.name === "isActive") {
            setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.checked } : x) })
        } else {
            setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.value } : x) })
        }
    }

    const handleEventDescriptionChange = (description: string, languageId: number | null) => {
        setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, description: description } : x) })
    }

    const handleScheduleFormControlChange = (e: ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        if (e.target.name === "startTime" || e.target.name === "endTime") {
            setFormData({ ...formData, schedules: formData.schedules.map((x, index) => index === currentIndex ? { ...x, [e.target.name]: e.target.value } : x) })
        } else {
        }
    }

    const handleRemoveSchedule = (currentIndex: number) => {
        setFormData({ ...formData, schedules: formData.schedules.filter((x, index) => index !== currentIndex) })
    }

    const handleAddNewSchedule = () => {
        let newSchedules = formData.schedules
        newSchedules.push({
            id: null,
            startTime: "",
            endTime: "",
            byDays: []
        })

        setFormData({ ...formData, schedules: newSchedules })
    }

    const handleDaysChange = (e: ChangeEvent<HTMLInputElement>, currentIndex: number) => {
        const checked = e.target.checked
        const value = e.target.value

        let newByDays = formData.schedules[currentIndex]?.byDays || []
        if (checked) {
            newByDays.push(value)
        } else {
            newByDays = newByDays.filter(x => x !== value)
        }

        setFormData({ ...formData, schedules: formData.schedules.map((x, index) => index === currentIndex ? { ...x, byDays: newByDays } : x) })
    }

    const handleCategoriesChange = (e: ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked
        const value = parseInt(e.target.value)

        let newCategories = formData.categoryIds || []
        if (checked) {
            newCategories.push(value)
        } else {
            newCategories = newCategories.filter(x => x !== value)
        }

        setFormData({ ...formData, categoryIds: newCategories })
    }

    const handleAddNew = () => {
        setFormData(initFormData)
        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await EventService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setFormData(res.data)
                setActiveTabKey(res.data.translations[0]?.languageId?.toString())

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        setMessageFormModal("")
        if (!formData.translations?.length) {
            setMessageFormModal("Bạn phải nhập ít nhất 1 ngôn ngữ.")
            return
        }

        if (!formData.schedules?.length) {
            setMessageFormModal("Schedule is required.")
            return
        }

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId) {
                if (formRef.current?.checkValidity()) {
                    var frmData = new FormData()
                    if (thumbnail !== null) {
                        frmData.append("Thumbnail", thumbnail)
                    }
                    if (floorPlan !== null) {
                        frmData.append("FloorPlan", floorPlan)
                    }
                    frmData.append("Tel", formData.tel || "")
                    frmData.append("Email", formData.email || "")
                    frmData.append("Website", formData.website || "")
                    frmData.append("OrderNumber", formData.orderNumber ? formData.orderNumber.toString() : "")
                    frmData.append("TimeZoneId", formData.timeZoneId || "")
                    frmData.append("StartDate", formData.startDate ? formData.startDate.toString() : "")
                    frmData.append("EndDate", formData.endDate ? formData.endDate.toString() : "")
                    frmData.append("OpeningTime", formData.openingTime ? formData.openingTime.toString() : "")
                    frmData.append("ClosingTime", formData.closingTime ? formData.closingTime.toString() : "")

                    formData.translations.forEach((item, index) => {
                        if (item.eventId) {
                            frmData.append(`Translations[${index}].EventId`, item.eventId.toString())
                        }
                        frmData.append(`Translations[${index}].LanguageId`, item.languageId.toString())
                        frmData.append(`Translations[${index}].Name`, item.name.toString() || "")
                        frmData.append(`Translations[${index}].Description`, item.description?.toString() || "")
                        frmData.append(`Translations[${index}].Organizer`, item.organizer?.toString() || "")
                        frmData.append(`Translations[${index}].Venue`, item.venue?.toString() || "")
                        frmData.append(`Translations[${index}].Address`, item.address?.toString() || "")
                        frmData.append(`Translations[${index}].IsActive`, item.isActive ? "True" : "False")
                    })

                    formData.schedules.forEach((item, index) => {
                        if (item.id) {
                            frmData.append(`Schedules[${index}].Id`, item.id.toString())
                        }
                        frmData.append(`Schedules[${index}].StartTime`, item.startTime?.toString() || "")
                        frmData.append(`Schedules[${index}].EndTime`, item.endTime?.toString() || "")
                        item.byDays.forEach((day, subIndex) => {
                            frmData.append(`Schedules[${index}].ByDays[${subIndex}]`, day?.toString() || "")
                        })
                    })

                    formData.categoryIds.forEach((value, index) => {
                        frmData.append(`CategoryIds[${index}]`, value.toString())
                    })

                    try {
                        if (formData.id && formData.id > 0) {
                            const res = await EventService.update(state.currentProviderId, formData.id, frmData)

                            if (res?.isSuccess) {
                                FilterFn(currentPageIndex)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        } else {
                            const res = await EventService.create(state.currentProviderId, frmData)

                            if (res?.isSuccess) {
                                FilterFn(1)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setThumbnail(null)
        setMessage("")
        setShowModal(true)
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Event" : "New Event"


    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentProviderId && selectedItem.id > 0) {
            const res = await EventService.delete(state.currentProviderId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handlePublishedSwitch = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await EventService.changePublished(state.currentProviderId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleFeaturedSwitch = async (id: number | null) => {
        if (state.currentProviderId && id) {
            const res = await EventService.changeFeatured(state.currentProviderId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setThumbnail(e.target.files[0])
        } else {
            setThumbnail(null)
        }
    }

    const handlefloorPlanChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setFloorPlan(e.target.files[0])
        } else {
            setFloorPlan(null)
        }
    }

    const handleLanguagesChange = async (languageId: number, action: LanguageActionTypes) => {
        if (action === "Add") {
            const newLang = languages.find(x => x.id === languageId)
            if (newLang) {
                if (formData.translations.filter(x => x.languageId === newLang.id).length === 0) {
                    const newFormData = { ...formData }

                    newFormData.translations.push({
                        eventId: null,
                        languageId: newLang.id || 0,
                        name: "",
                        description: "",
                        organizer: "",
                        venue: "",
                        address: "",
                        isActive: false,
                        language: newLang
                    })

                    setFormData(newFormData)
                    setActiveTabKey(newLang.id?.toString())
                }
            }
        } else {
            const newTranslations = formData.translations.filter(x => x.languageId !== languageId)

            setFormData({ ...formData, translations: newTranslations })
            setActiveTabKey(newTranslations[0]?.languageId.toString())
        }
    }

    // TicketClassesModal
    const [showTicketClassesModal, setShowTicketClassesModal] = useState(false)
    const handleCloseTicketClassesModal = () => {
        setShowTicketClassesModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowTicketClassesModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowTicketClassesModal(true)
        }
    }

    // SeatsModal
    const [showSeatsModal, setShowSeatsModal] = useState(false)
    const handleCloseSeatsModal = () => {
        setShowSeatsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowSeatsModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowSeatsModal(true)
        }
    }

    // EventPhotos
    const [showEventPhotosModal, setShowEventPhotosModal] = useState(false)
    const handleCloseEventPhotosModal = () => {
        setShowEventPhotosModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowEventPhotosModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowEventPhotosModal(true)
        }
    }

    // EventInvitationCodes
    const [showEventInvitationCodesModal, setShowEventInvitationCodesModal] = useState(false)
    const handleCloseEventInvitationCodesModal = () => {
        setShowEventInvitationCodesModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowEventInvitationCodesModal = (id: number | null, name: string) => {
        if (id) {
            setSelectedItem({
                id: id || 0,
                name: name
            })
            setShowEventInvitationCodesModal(true)
        }
    }

    //TicketEmailTemplate
    const formTicketEmailTemplateRef = useRef<HTMLFormElement>(null)
    const [validatedTicketEmailTemplateForm, setValidatedTicketEmailTemplateForm] = useState(false)
    const [messageTicketEmailTemplateFormModal, setMessageTicketEmailTemplateFormModal] = useState("")
    const initTicketEmailTemplateFormData: ITicketEmailTemplate = {
        ticketEmailSubject: "",
        ticketEmailBody: ""
    }
    const [ticketEmailTemplateFormData, setTicketEmailTemplateFormData] = useState(initTicketEmailTemplateFormData)

    const [showTicketEmailTemplateModal, setShowTicketEmailTemplateModal] = useState(false)
    const handleCloseTicketEmailTemplateModal = () => {
        setShowTicketEmailTemplateModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
        setTicketEmailTemplateFormData(initTicketEmailTemplateFormData)
    }

    const handleShowTicketEmailTemplateModal = async (id: number | null, name: string) => {
        if (state.currentProviderId && id) {
            const res = await EventService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setTicketEmailTemplateFormData({ ...ticketEmailTemplateFormData, ticketEmailSubject: res.data.ticketEmailSubject, ticketEmailBody: res.data.ticketEmailBody })
                setSelectedItem({
                    id: id || 0,
                    name: name
                })
                setShowTicketEmailTemplateModal(true)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleTicketEmailTemplateFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setTicketEmailTemplateFormData({ ...ticketEmailTemplateFormData, [e.target.name]: e.target.value })
    }

    const handleSaveTicketEmailTemplate = async (e: SyntheticEvent) => {
        e.preventDefault()

        setMessageTicketEmailTemplateFormModal("")

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId && selectedItem.id) {
                if (formTicketEmailTemplateRef.current?.checkValidity()) {
                    if (!ticketEmailTemplateFormData.ticketEmailBody) {
                        setMessageTicketEmailTemplateFormModal("Body is required.")
                    } else {
                        try {
                            const res = await EventService.updateTicketEmailTemplate(state.currentProviderId, selectedItem.id, ticketEmailTemplateFormData)

                            if (res?.isSuccess) {
                                handleCloseTicketEmailTemplateModal()
                                setMessage(res.message)
                            } else {
                                setMessageTicketEmailTemplateFormModal(res?.message)
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }
                } else {
                    setValidatedTicketEmailTemplateForm(true)
                }
            }
            setProcessing(false)
        }
    }

    //OnlinePaymentSettings
    const formOnlinePaymentSettingsRef = useRef<HTMLFormElement>(null)
    const [validatedOnlinePaymentSettingsForm, setValidatedOnlinePaymentSettingsForm] = useState(false)
    const [messageOnlinePaymentSettingsFormModal, setMessageOnlinePaymentSettingsFormModal] = useState("")
    const initOnlinePaymentSettingsFormData: IOnlinePaymentSettings = {
        onlinePaymentProviderName: "",
        onlinePaymentConfigurations: "",
        onlinePaymentEnabled: false
    }
    const [onlinePaymentSettingsFormData, setOnlinePaymentSettingsFormData] = useState(initOnlinePaymentSettingsFormData)

    const [showOnlinePaymentSettingsModal, setShowOnlinePaymentSettingsModal] = useState(false)
    const handleCloseOnlinePaymentSettingsModal = () => {
        setShowOnlinePaymentSettingsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
        setOnlinePaymentSettingsFormData(initOnlinePaymentSettingsFormData)
    }

    const handleShowOnlinePaymentSettingsModal = async (id: number | null, name: string) => {
        if (state.currentProviderId && id) {
            const res = await EventService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setOnlinePaymentSettingsFormData({ ...onlinePaymentSettingsFormData, onlinePaymentProviderName: res.data.onlinePaymentProviderName, onlinePaymentConfigurations: res.data.onlinePaymentConfigurations, onlinePaymentEnabled: res.data.onlinePaymentEnabled })
                setSelectedItem({
                    id: id || 0,
                    name: name
                })
                setShowOnlinePaymentSettingsModal(true)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleOnlinePaymentSettingsFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "onlinePaymentEnabled") {
            setOnlinePaymentSettingsFormData({ ...onlinePaymentSettingsFormData, [e.target.name]: e.target.checked })
        } else {
            setOnlinePaymentSettingsFormData({ ...onlinePaymentSettingsFormData, [e.target.name]: e.target.value })
        }
    }

    const handleSaveOnlinePaymentSettings = async (e: SyntheticEvent) => {
        e.preventDefault()

        setMessageOnlinePaymentSettingsFormModal("")

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId && selectedItem.id) {
                if (formOnlinePaymentSettingsRef.current?.checkValidity()) {
                    try {
                        const res = await EventService.updateOnlinePaymentSettings(state.currentProviderId, selectedItem.id, onlinePaymentSettingsFormData)

                        if (res?.isSuccess) {
                            handleCloseOnlinePaymentSettingsModal()
                            setMessage(res.message)
                        } else {
                            setMessageOnlinePaymentSettingsFormModal(res?.message)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    setValidatedOnlinePaymentSettingsForm(true)
                }
            }
            setProcessing(false)
        }
    }

    //EInvoiceSettings
    const formEInvoiceSettingsRef = useRef<HTMLFormElement>(null)
    const [validatedEInvoiceSettingsForm, setValidatedEInvoiceSettingsForm] = useState(false)
    const [messageEInvoiceSettingsFormModal, setMessageEInvoiceSettingsFormModal] = useState("")
    const initEInvoiceSettingsFormData: IEInvoiceSettings = {
        eInvoiceProviderName: "",
        eInvoiceConfigurations: "",
        eInvoiceAutoPublish: false,
        eInvoiceEnabled: false
    }
    const [eInvoiceSettingsFormData, setEInvoiceSettingsFormData] = useState(initEInvoiceSettingsFormData)

    const [showEInvoiceSettingsModal, setShowEInvoiceSettingsModal] = useState(false)
    const handleCloseEInvoiceSettingsModal = () => {
        setShowEInvoiceSettingsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
        setEInvoiceSettingsFormData(initEInvoiceSettingsFormData)
    }

    const handleShowEInvoiceSettingsModal = async (id: number | null, name: string) => {
        if (state.currentProviderId && id) {
            const res = await EventService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setEInvoiceSettingsFormData({ ...eInvoiceSettingsFormData, eInvoiceProviderName: res.data.eInvoiceProviderName, eInvoiceConfigurations: res.data.eInvoiceConfigurations, eInvoiceEnabled: res.data.eInvoiceEnabled })
                setSelectedItem({
                    id: id || 0,
                    name: name
                })
                setShowEInvoiceSettingsModal(true)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleEInvoiceSettingsFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "eInvoiceEnabled" || e.target.name === "eInvoiceAutoPublish") {
            setEInvoiceSettingsFormData({ ...eInvoiceSettingsFormData, [e.target.name]: e.target.checked })
        } else {
            setEInvoiceSettingsFormData({ ...eInvoiceSettingsFormData, [e.target.name]: e.target.value })
        }
    }

    const handleSaveEInvoiceSettings = async (e: SyntheticEvent) => {
        e.preventDefault()

        setMessageEInvoiceSettingsFormModal("")

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId && selectedItem.id) {
                if (formEInvoiceSettingsRef.current?.checkValidity()) {
                    try {
                        const res = await EventService.updateEInvoiceSettings(state.currentProviderId, selectedItem.id, eInvoiceSettingsFormData)

                        if (res?.isSuccess) {
                            handleCloseEInvoiceSettingsModal()
                            setMessage(res.message)
                        } else {
                            setMessageEInvoiceSettingsFormModal(res?.message)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    setValidatedEInvoiceSettingsForm(true)
                }
            }
            setProcessing(false)
        }
    }

    //BankAccountSettings
    const formBankAccountSettingsRef = useRef<HTMLFormElement>(null)
    const [validatedBankAccountSettingsForm, setValidatedBankAccountSettingsForm] = useState(false)
    const [messageBankAccountSettingsFormModal, setMessageBankAccountSettingsFormModal] = useState("")
    const initBankAccountSettingsFormData: IBankAccountSettings = {
        bankAccountName: "",
        bankAccountNumber: "",
        bankAccountEnabled: false
    }
    const [bankAccountSettingsFormData, setBankAccountSettingsFormData] = useState(initBankAccountSettingsFormData)

    const [showBankAccountSettingsModal, setShowBankAccountSettingsModal] = useState(false)
    const handleCloseBankAccountSettingsModal = () => {
        setShowBankAccountSettingsModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
        setBankAccountSettingsFormData(initBankAccountSettingsFormData)
    }

    const handleShowBankAccountSettingsModal = async (id: number | null, name: string) => {
        if (state.currentProviderId && id) {
            const res = await EventService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setBankAccountSettingsFormData({ ...bankAccountSettingsFormData, bankAccountName: res.data.bankAccountName, bankAccountNumber: res.data.bankAccountNumber, bankAccountEnabled: res.data.bankAccountEnabled })
                setSelectedItem({
                    id: id || 0,
                    name: name
                })
                setShowBankAccountSettingsModal(true)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleBankAccountSettingsFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "bankAccountEnabled") {
            setBankAccountSettingsFormData({ ...bankAccountSettingsFormData, [e.target.name]: e.target.checked })
        } else {
            setBankAccountSettingsFormData({ ...bankAccountSettingsFormData, [e.target.name]: e.target.value })
        }
    }

    const handleSaveBankAccountSettings = async (e: SyntheticEvent) => {
        e.preventDefault()

        setMessageBankAccountSettingsFormModal("")

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId && selectedItem.id) {
                if (formBankAccountSettingsRef.current?.checkValidity()) {
                    try {
                        const res = await EventService.updateBankAccountSettings(state.currentProviderId, selectedItem.id, bankAccountSettingsFormData)

                        if (res?.isSuccess) {
                            handleCloseBankAccountSettingsModal()
                            setMessage(res.message)
                        } else {
                            setMessageBankAccountSettingsFormModal(res?.message)
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    setValidatedBankAccountSettingsForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const onlinePaymentConfigurationExamples: Record<string, string> = {
        "OnePay": `{
    "AgainLink": "http://mywebsite.com/checkout",
    "Title": "OnePay PayGate",
    "PaymentLink": "https://mtf.onepay.vn/vpcpay/vpcpay.op",
    "HashCode": "6D0870CDE5F24F34F3915FB0045120DB",
    "Merchant": "TESTONEPAY",
    "AccessCode": "6BEB2546",
    "Version": "2",
    "Command": "pay",
    "Currency": "VND"
}`
    }

    const eInvoiceConfigurationExamples: Record<string, string> = {
        "VNPT": `{
    "PublishServiceUrl": "https://bvhuedemo-tt78admindemo.vnpt-invoice.com.vn/PublishService.asmx",
    "BusinessServiceUrl": "https://bvhuedemo-tt78admindemo.vnpt-invoice.com.vn/BusinessService.asmx",
    "PortalServiceUrl": "https://bvhuedemo-tt78admindemo.vnpt-invoice.com.vn/PortalService.asmx",
    "Account": "bvhuedemoadmin",
    "ACpass": "Bvhue@123",
    "UserName": "bvtphue78sv",
    "Password": "123456aA@",
    "Pattern": "1/001",
    "Serial": "C24TPN",
    "Convert": 0,
    "VATRate": 8,
    "IsSum": 0,
    "ProdUnit": "Vé",
    "ProdName": "Vé {eventName} ({ticketClassName})"
}`
    }

    return (
        <>
            <ContentHeader title="Event Management" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.Events.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Create new event
                                        </Button>
                                    }
                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "160px" }}>Thumbnail</th>
                                                <th>Name</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Order Number</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Status</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.thumbnail && <img src={`${Configs.API_BASE_URL}${item.thumbnail}`} style={{ maxWidth: "100%" }} alt={item.name} />}</td>
                                                        <td>
                                                            <h6>#{item.id}. {item.name}</h6>
                                                            {item.timeZoneInfo && <p className="text-muted" style={{ fontSize: "11px", marginBottom: "0.5rem" }}>
                                                                <i className="fas fa-globe"></i> {item.timeZoneInfo.displayName}
                                                            </p>}
                                                            {hasPermission(Permissions.TicketClasses.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowTicketClassesModal(item.id, item.name)}>
                                                                    <i className="fas fa-tags text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Ticket Classes</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Seats.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowSeatsModal(item.id, item.name)}>
                                                                    <i className="fas fa-chair text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Seats</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.EventPhotos.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowEventPhotosModal(item.id, item.name)}>
                                                                    <i className="far fa-images text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Photos</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.EventInvitationCodes.View) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowEventInvitationCodesModal(item.id, item.name)}>
                                                                    <i className="fas fa-envelope-open-text text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Invitation Codes</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Events.TicketEmailTemplate) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowTicketEmailTemplateModal(item.id, item.name)}>
                                                                    <i className="fas fa-envelope text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Ticket Email Template</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Events.OnlinePaymentSettings) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowOnlinePaymentSettingsModal(item.id, item.name)}>
                                                                    <i className="fas fa-credit-card text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Online Payment Settings</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Events.BankAccountSettings) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowBankAccountSettingsModal(item.id, item.name)}>
                                                                    <i className="fas fa-university text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Bank Account Settings</span>
                                                                </Button>
                                                            }
                                                            {hasPermission(Permissions.Events.EInvoiceSettings) &&
                                                                <Button variant="default" size="sm" onClick={() => handleShowEInvoiceSettingsModal(item.id, item.name)}>
                                                                    <i className="fas fa-file-invoice-dollar text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>E-Invoice Settings</span>
                                                                </Button>
                                                            }
                                                        </td>
                                                        <td>{Utils.dateToString(item.startDate)}</td>
                                                        <td>{Utils.dateToString(item.endDate)}</td>
                                                        <td>{item.orderNumber}</td>
                                                        <td>
                                                            <Form.Check type="switch" id={"event_published_" + item.id} checked={item.published} onChange={() => handlePublishedSwitch(item.id)} disabled={!hasPermission(Permissions.Events.Edit)} label="Published" />
                                                            <Form.Check type="switch" id={"event_featured_" + item.id} checked={item.featured} onChange={() => handleFeaturedSwitch(item.id)} disabled={!hasPermission(Permissions.Events.Edit)} label="Featured" />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.Events.Edit) &&
                                                                <Button variant="warning" size="sm" className="mr-1" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.Events.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddOrRemoveLanguages selectedLanguages={formData.translations.map(x => x.language)} onChange={handleLanguagesChange} />
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Tabs defaultActiveKey="thumbnailFile">
                            <Tab eventKey="thumbnailFile" title="Thumbnail">
                                <Form.Group controlId="thumbnailFile">
                                    <Card className="m-0 border-0 shadow-0">
                                        {formData.thumbnail && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.thumbnail}`} style={{ width: "inherit", maxWidth: "300px", margin: "auto" }} />}
                                        <Card.Body className="p-2">
                                            <Form.Control type="file" onChange={handleThumbnailChange} accept={Configs.IMAGE_EXTENSION} />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="floorPlanFile" title="Floor Plan">
                                <Form.Group controlId="floorPlanFile">
                                    <Card className="m-0 border-0 shadow-0">
                                        {formData.floorPlan && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.floorPlan}`} style={{ width: "inherit", maxWidth: "300px", margin: "auto" }} />}
                                        <Card.Body className="p-2">
                                            <Form.Control type="file" onChange={handlefloorPlanChange} accept={Configs.IMAGE_EXTENSION} />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Tab>
                        </Tabs>
                        <Tabs activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k || undefined)}>
                            {
                                formData.translations.sort((a, b) => { return (a.language.orderNumber || 0) - (b.language.orderNumber || 0) }).map(translate => {
                                    return (
                                        <Tab
                                            key={translate.languageId}
                                            eventKey={(translate.languageId).toString()}
                                            title={translate.language.name}
                                            className="p-3"
                                        >
                                            <Form.Group className="mb-3" controlId={"name_" + translate.languageId}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control type="text" name="name" required value={translate?.name || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId={"description_" + translate.languageId}>
                                                <Form.Label>Description</Form.Label>
                                                {/* <Form.Control as="textarea" rows={7} type="text" name="description" value={translate?.description || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} /> */}
                                                <TextEditor contentHtml={translate?.description || ""} onChange={contentHtml => { handleEventDescriptionChange(contentHtml, translate.languageId) }} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId={"venue_" + translate.languageId}>
                                                <Form.Label>Organizer</Form.Label>
                                                <Form.Control type="text" name="organizer" value={translate?.organizer || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId={"venue_" + translate.languageId}>
                                                <Form.Label>Venue</Form.Label>
                                                <Form.Control type="text" name="venue" value={translate?.venue || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId={"address_" + translate.languageId}>
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control type="text" name="address" value={translate?.address || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                            <Form.Check
                                                type="checkbox"
                                                id={`event_isactive_${translate.languageId}`}
                                                name="isActive"
                                                label="Active"
                                                checked={translate.isActive || false}
                                                onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)}
                                            />
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="startDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker name="startDate" value={formData.startDate} onChange={handleFormControlChange}></DatePicker>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="endDate">
                                    <Form.Label>End Date</Form.Label>
                                    <DatePicker name="endDate" value={formData.endDate} onChange={handleFormControlChange}></DatePicker>
                                </Form.Group>
                            </Col>
                        </Row>
                        <TimeZoneSelect timeZoneId={formData.timeZoneId} onChange={handleFormControlChange}></TimeZoneSelect>
                        <fieldset className="mb-3">
                            <legend>Schedules</legend>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <td style={{ width: "90px", borderTop: "none" }}>Start Time</td>
                                        <td style={{ width: "90px", borderTop: "none" }}>End Time</td>
                                        <td style={{ borderTop: "none" }}>Days</td>
                                        <td style={{ width: "48px", borderTop: "none" }}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.schedules.map((schedule, index) =>
                                        <tr key={index}>
                                            <td>
                                                <Form.Control type="time" name="startTime" required value={schedule.startTime || ""} onChange={(e) => handleScheduleFormControlChange(e as any, index)} />
                                            </td>
                                            <td>
                                                <Form.Control type="time" name="endTime" required value={schedule.endTime || ""} onChange={(e) => handleScheduleFormControlChange(e as any, index)} />
                                            </td>
                                            <td>
                                                <DaysOfWeekCheckboxes index={index} value={schedule.byDays} onChange={e => handleDaysChange(e, index)} />
                                            </td>
                                            <td>
                                                <Button variant="outline-danger" onClick={() => handleRemoveSchedule(index)} size="sm">
                                                    <i className="fas fa-minus"></i>
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                            <Button variant="default" onClick={handleAddNewSchedule} size="sm" className="text-info" style={{ float: "right", padding: 0 }}>
                                <i className="fas fa-plus"></i> Add new Schedule
                            </Button>
                        </fieldset>
                        <Form.Group className="mb-1">
                            <Form.Label>Opening/Closing Time of Check-in Counters</Form.Label>
                            <Row>
                                <Col md={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="openingTime">Opening Time</InputGroup.Text>
                                        <Form.Control type="number" min={0} name="openingTime" value={formData.openingTime || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                        <InputGroup.Text>Minutes</InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text>{formData.openingTime} Minutes Before Scheduled Start Time</Form.Text>
                                </Col>
                                <Col md={6}>
                                    <InputGroup>
                                        <InputGroup.Text id="closingTime">Closing Time</InputGroup.Text>
                                        <Form.Control type="number" max={formData.openingTime || undefined} name="closingTime" value={formData.closingTime || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                        <InputGroup.Text>Minutes</InputGroup.Text>
                                    </InputGroup>
                                    <Form.Text>{Math.abs(formData.closingTime || 0)} Minutes {(formData.closingTime || 0) >= 0 ? <>Before</> : <>After</>} Scheduled Start Time</Form.Text>
                                </Col>
                            </Row>
                        </Form.Group>

                        <fieldset className="mb-3">
                            <legend>Categories</legend>
                            <EventCategoriesCheckboxes value={formData.categoryIds} onChange={e => handleCategoriesChange(e)} />
                        </fieldset>

                        <fieldset className="mb-3">
                            <legend>Contact Info</legend>
                            <Form.Group controlId="tel">
                                <Form.Label>Tel</Form.Label>
                                <Form.Control type="text" name="tel" value={formData.tel || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mt-3" controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mt-3" controlId="website">
                                <Form.Label>Website</Form.Label>
                                <Form.Control type="text" name="website" value={formData.website || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            </Form.Group>
                        </fieldset>
                        <Form.Group controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={selectedItem.name} />
            <EventInvitationCodesModal eventId={selectedItem.id} title={selectedItem.name} show={showEventInvitationCodesModal} onClose={handleCloseEventInvitationCodesModal}></EventInvitationCodesModal>
            <EventPhotosModal eventId={selectedItem.id} title={selectedItem.name} show={showEventPhotosModal} onClose={handleCloseEventPhotosModal}></EventPhotosModal>
            <TicketClassesModal eventId={selectedItem.id} title={selectedItem.name} show={showTicketClassesModal} onClose={handleCloseTicketClassesModal} />
            <SeatsModal eventId={selectedItem.id} title={selectedItem.name} show={showSeatsModal} onClose={handleCloseSeatsModal} />
            <Modal show={showTicketEmailTemplateModal} onHide={handleCloseTicketEmailTemplateModal} backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-envelope text-info"></i> Ticket Email Template <span>|</span> {selectedItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <Form noValidate validated={validatedTicketEmailTemplateForm} ref={formTicketEmailTemplateRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="ticketEmailSubject">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control type="text" name="ticketEmailSubject" required value={ticketEmailTemplateFormData?.ticketEmailSubject || ""} onChange={handleTicketEmailTemplateFormControlChange} />
                            <Form.Control.Feedback type="invalid">Subject is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Body</Form.Label>
                            <TextEditor contentHtml={ticketEmailTemplateFormData.ticketEmailBody || ""} onChange={contentHtml => { setTicketEmailTemplateFormData({ ...ticketEmailTemplateFormData, ticketEmailBody: contentHtml }) }} />
                        </Form.Group>
                        <Alert variant="secondary">
                            Template Variables: <br />{`{customerName}, {eventName}, {eventDate}, {eventStartTime}, {eventEndTime}, {tel}, {email}, {website}, {address}, {eventTicketId}, {createdOn}, {quantity}`}
                        </Alert>
                    </Form>
                    {messageTicketEmailTemplateFormModal !== "" && <Alert variant="danger" onClose={() => setMessageTicketEmailTemplateFormModal("")} dismissible>{messageTicketEmailTemplateFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseTicketEmailTemplateModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSaveTicketEmailTemplate} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showOnlinePaymentSettingsModal} onHide={handleCloseOnlinePaymentSettingsModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-credit-card text-info"></i> Online Payment Settings <span>|</span> {selectedItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <OnlinePaymentProviderSelect required={false} value={onlinePaymentSettingsFormData?.onlinePaymentProviderName || ""} onChange={handleOnlinePaymentSettingsFormControlChange}></OnlinePaymentProviderSelect>
                    <Form noValidate validated={validatedOnlinePaymentSettingsForm} ref={formOnlinePaymentSettingsRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="onlinePaymentConfigurations">
                            <Form.Label>Configurations</Form.Label>
                            <Form.Control as="textarea"
                                placeholder={onlinePaymentConfigurationExamples[onlinePaymentSettingsFormData?.onlinePaymentProviderName || ""]}
                                rows={15}
                                name="onlinePaymentConfigurations"
                                value={onlinePaymentSettingsFormData?.onlinePaymentConfigurations || ""}
                                onChange={handleOnlinePaymentSettingsFormControlChange} />
                        </Form.Group>
                    </Form>
                    <Form.Check type="checkbox" id="onlinePaymentEnabled" name="onlinePaymentEnabled" label="Enabled" checked={onlinePaymentSettingsFormData?.onlinePaymentEnabled || false} onChange={handleOnlinePaymentSettingsFormControlChange} />
                    {messageOnlinePaymentSettingsFormModal !== "" && <Alert variant="danger" onClose={() => setMessageOnlinePaymentSettingsFormModal("")} dismissible>{messageOnlinePaymentSettingsFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseOnlinePaymentSettingsModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSaveOnlinePaymentSettings} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showBankAccountSettingsModal} onHide={handleCloseBankAccountSettingsModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-university text-info"></i> Bank Account Settings <span>|</span> {selectedItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <BankSelect required={false} value={bankAccountSettingsFormData?.bankAccountName || ""} onChange={handleBankAccountSettingsFormControlChange}></BankSelect>
                    <Form noValidate validated={validatedBankAccountSettingsForm} ref={formBankAccountSettingsRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="bankAccountNumber">
                            <Form.Label>Bank Account Number</Form.Label>
                            <Form.Control type="number" min={0} name="bankAccountNumber" value={bankAccountSettingsFormData?.bankAccountNumber || ""} onChange={handleBankAccountSettingsFormControlChange} />
                        </Form.Group>
                    </Form>
                    <Form.Check type="checkbox" id="bankAccountEnabled" name="bankAccountEnabled" label="Enabled" checked={bankAccountSettingsFormData?.bankAccountEnabled || false} onChange={handleBankAccountSettingsFormControlChange} />
                    {messageBankAccountSettingsFormModal !== "" && <Alert variant="danger" onClose={() => setMessageBankAccountSettingsFormModal("")} dismissible>{messageBankAccountSettingsFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseBankAccountSettingsModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSaveBankAccountSettings} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEInvoiceSettingsModal} onHide={handleCloseEInvoiceSettingsModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><i className="fas fa-file-invoice-dollar text-info"></i> E-Invoice Settings <span>|</span> {selectedItem.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <EInvoiceProviderSelect required={false} value={eInvoiceSettingsFormData?.eInvoiceProviderName || ""} onChange={handleEInvoiceSettingsFormControlChange}></EInvoiceProviderSelect>
                    <Form noValidate validated={validatedEInvoiceSettingsForm} ref={formEInvoiceSettingsRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="eInvoiceConfigurations">
                            <Form.Label>Configurations</Form.Label>
                            <Form.Control as="textarea"
                                placeholder={eInvoiceConfigurationExamples[eInvoiceSettingsFormData?.eInvoiceProviderName || ""]}
                                rows={15}
                                name="eInvoiceConfigurations"
                                value={eInvoiceSettingsFormData?.eInvoiceConfigurations || ""}
                                onChange={handleEInvoiceSettingsFormControlChange} />
                        </Form.Group>
                    </Form>
                    <Form.Check type="checkbox" id="eInvoiceAutoPublish" name="eInvoiceAutoPublish" label="Auto Publish" checked={eInvoiceSettingsFormData?.eInvoiceAutoPublish || false} onChange={handleEInvoiceSettingsFormControlChange} />
                    <Form.Check type="checkbox" id="eInvoiceEnabled" name="eInvoiceEnabled" label="Enabled" checked={eInvoiceSettingsFormData?.eInvoiceEnabled || false} onChange={handleEInvoiceSettingsFormControlChange} />
                    {messageEInvoiceSettingsFormModal !== "" && <Alert variant="danger" onClose={() => setMessageEInvoiceSettingsFormModal("")} dismissible>{messageEInvoiceSettingsFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseEInvoiceSettingsModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSaveEInvoiceSettings} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Events