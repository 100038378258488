import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IEventInvitationCode {
    id: number | null
    code: string
    description: string
    startDate: string | null
    endDate: string | null
    maxQuantity: number | null
    isActive: boolean
    eventId: number
}

export default class EventInvitationCodeService {
    static async filter(eventId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventInvitationCodes/${eventId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventInvitationCodes/${eventId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/EventInvitationCodes/${eventId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(eventId: number, id: number, itemData: IEventInvitationCode) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventInvitationCodes/${eventId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(eventId: number, itemData: IEventInvitationCode) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventInvitationCodes/${eventId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsActive(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventInvitationCodes/${eventId}/ChangeIsActive/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}