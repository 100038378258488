import { IAudioStory } from "./AudioStoryService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface ITourTranslation {
    tourId: number | null
    languageId: number
    name: string
    description: string
    language: ILanguage
}

export interface ITour {
    id: number | null
    name: string
    orderNumber: number | null
    isUsed: boolean
    placeId: number
    translations: Array<ITourTranslation>
    audioStories: Array<IAudioStory>
}

export default class TourService {
    static async listAll(placeId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Tours/${placeId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(placeId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Tours/${placeId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Tours/${placeId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Tours/${placeId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(placeId: number, id: number, itemData: ITour) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Tours/${placeId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(placeId: number, itemData: ITour) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Tours/${placeId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Tours/${placeId}/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}