import { IUser } from "./UserService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IAuditLog {
    id: number
    userId: number | null
    auditType: string
    tableName: string
    affectedColumns: string
    primaryKey: string
    oldValues: string
    newValues: string
    changedOn: Date
    user: IUser | null
}

export default class AuditLogService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AuditLogs?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AuditLogs/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AuditLogs/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async clear() {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AuditLogs/Clear`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}