import fileDownload from "js-file-download"
import Utils from "../utils/Utils"
import { IEvent, IEventSchedule } from "./EventService"
import { ISeat } from "./SeatService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import axiosClientBlob from "./axiosClientBlob"
import { format } from "date-fns/esm"
import { ITicketClass } from "./TicketClassService"
import { IUser } from "./UserService"
import { useTranslation } from "react-i18next"

export function GetEventTicketSortFields() {
    const { t } = useTranslation("global")

    return [
        {
            name: "createdOn",
            description: t("eventTicketsPage.createdOn")
        },
        {
            name: "eventDate",
            description: t("eventTicketsPage.eventDate")
        },
        {
            name: "paymentService",
            description: t("eventTicketsPage.paymentService")
        },
        {
            name: "paymentStatus",
            description: t("eventTicketsPage.paymentStatus")
        }
    ]
}

export function GetEventTicketDetailsSortFields() {
    const { t } = useTranslation("global")

    return [
        {
            name: "createdOn",
            description: t("eventTicketsPage.createdOn")
        },
        {
            name: "usedOn",
            description: t("eventTicketsPage.usedOn")
        },
        {
            name: "eventDate",
            description: t("eventTicketsPage.eventDate")
        },
        {
            name: "paymentService",
            description: t("eventTicketsPage.paymentService")
        }
    ]
}

export interface IEventTicket {
    id: number | null
    eventId: number | null
    event: IEvent | null
    eventDate: string | null
    eventScheduleId: number | null
    eventSchedule: IEventSchedule | null
    note: string
    paymentService: string
    paymentStatus: string
    customer: ICustomer
    quantity: number | null
    seatIds: number[]
    details: IEventTicketDetails[] | null
    createdOn: Date | null
    bookedSeats: string
    totalPrice: number | null
    currency: string
    invoiceStatus: string
    creator: IUser | null
    invitationCode: string
}

export interface IEventTicketDetails {
    id: number | null
    code: string
    ticketClassId: number | null
    ticketClass: ITicketClass | null
    seatId: number | null
    seat: ISeat | null
    price: number | null
    currency: string
    isUsed: boolean
    createdOn: Date | null
    usedOn: Date | null
    checker: IUser | null
    creator: IUser | null
    eventTicket: IEventTicket
}

export interface ICustomer {
    id: number | null
    name: string
    tel: string
    formattedTel: string
    email: string
    address: string
    personalId: string
}

export interface IEventTicketCreate {
    eventId: number | null
    eventDate: string | null
    eventScheduleId: number | null
    note: string
    paymentService: string
    paymentStatus: string
    customer: ICustomer
    ticketClasses: IBookedTicketClass[]
    seatIds: number[]
}

export interface IBookedTicketClass {
    ticketClassId: number
    quantity: number
}

export interface IEventTicketUpdate {
    note: string
    paymentService: string
    paymentStatus: string
    customer: ICustomer
}

export interface IEventTicketCustomerUpdate {
    note: string
    customer: ICustomer
}

export interface IBookedSeatFilter {
    eventId: number
    eventDate: string
    eventScheduleId: number
}

export interface IEventTicketPrint {
    eventTicketId: number
    createdOn: Date | null
    eventId: number
    eventName: string
    eventDate: string
    eventStartTime: string
    eventEndTime: string
    thumbnail: string
    logo: string
    bgImage: string
    tel: string
    email: string
    website: string
    websiteQRCode: string
    address: string
    price: number | null
    currency: string
    seat: string
    ticketClass: string
    code: string
    qrcodeImage: string
}

export default class EventTicketService {
    //Provider
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listOfSold(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/ListOfSold?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async cancel(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/Cancel/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/EventTickets/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, itemData: IEventTicketCreate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, itemData: IEventTicketUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventTickets/${providerId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateCustomer(providerId: number, id: number, itemData: IEventTicketCustomerUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/UpdateCustomer/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getBookedSeats(providerId: number, filter: IBookedSeatFilter) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GetBookedSeats`, filter)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDF(providerId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/EventTickets/${providerId}/GeneratePDF/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getForPrint(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GetForPrint/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async sendETicket(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/SendETicket/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async generatePaymentQRCode(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GeneratePaymentQRCode/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async issueInvoice(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/IssueInvoice/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async cancelInvoice(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/CancelInvoice/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(providerId: number, code: string) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/ChangeIsUsed/${code}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    //Users
    static async filterByUser(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/ListByUser?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listOfSoldByUser(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/ListOfSoldByUser?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventTickets/${providerId}/GetByIdForUser/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async deleteByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/EventTickets/${providerId}/DeleteByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async createByUser(providerId: number, itemData: IEventTicketCreate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/CreateByUser`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateByUser(providerId: number, id: number, itemData: IEventTicketUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventTickets/${providerId}/UpdateByUser/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateCustomerByUser(providerId: number, id: number, itemData: IEventTicketCustomerUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/UpdateCustomerByUser/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsedByUser(providerId: number, code: string) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/ChangeIsUsedByUser/${code}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDFByUser(providerId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/EventTickets/${providerId}/GeneratePDFByUser/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getForPrintByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GetForPrintByUser/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async sendETicketByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/SendETicketByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async generatePaymentQRCodeByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/GeneratePaymentQRCodeByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async issueInvoiceByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/IssueInvoiceByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async cancelInvoiceByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/CancelInvoiceByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    //Report
    static async dailyMyEventTicketsReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/DailyMyEventTicketsReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async dailyEventTicketsReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/DailyEventTicketsReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async monthlyEventTicketsReport(providerId: number, startYear: number, startMonth: number, endYear: number, endMonth: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/MonthlyEventTicketsReport`, {
                startYear: startYear,
                startMonth: startMonth,
                endYear: endYear,
                endMonth: endMonth
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async ticketSellersReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/TicketSellersReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async ticketSellersDetailsReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/TicketSellersDetailsReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async dailySalesBySellerReport(providerId: number, sellerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/DailySalesBySellerReport/${sellerId}`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async invitationCodesReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/InvitationCodesReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async ticketClassesReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventTickets/${providerId}/TicketClassesReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}