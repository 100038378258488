import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface IAreaTranslation {
    areaId: number | null
    languageId: number
    name: string
    language: ILanguage
}

export interface IArea {
    id: number | null
    name: string
    thumbnail: string
    parentId: number | null
    orderNumber: number | null
    isUsed: boolean
    placeId: number
    translations: Array<IAreaTranslation>
}

export default class AreaService {
    static async list(placeId: number, excludeBranchId: number | null) {
        try {
            var query = ""
            if (excludeBranchId) {
                query = `?excludeBranchId=${excludeBranchId}`
            }
            const { data } = await axiosClient.get<ApiResponse>(`/api/Areas/${placeId}/List${query}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(placeId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Areas/${placeId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Areas/${placeId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Areas/${placeId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(placeId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Areas/${placeId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(placeId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Areas/${placeId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Areas/${placeId}/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}