import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import useAppContext from "../../hooks/useAppContext"
import DateRangePicker from "../../components/DateRangePicker"
import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import EventTicketService from "../../services/EventTicketService"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import { getReportItemText } from "../../constants/ReportItems"

function TicketClassesDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    const [reportData, setReportData] = useState<{
        eventId: number,
        eventName: string,
        ticketClassId: number,
        ticketClassName: string,
        quantity: number,
        amount: number
    }[] | null>(null)

    const GetTicketClassesReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await EventTicketService.ticketClassesReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetTicketClassesReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [currentEventId, setCurrentEventId] = useState<number | null>(null)
    const [events, setEvents] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentEventId(null)
            setTicketClassesSummary([])

            setEvents(
                reportData
                    .map((x) => { return { id: x.eventId, name: x.eventName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )

            generateTicketClassesSummary(null)
        } else {
            setCurrentEventId(null)
            setEvents(null)
            setTicketClassesSummary([])
        }
    }, [reportData])

    const handleEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentEventId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateTicketClassesSummary(currentEventId)
    }, [currentEventId])

    const [ticketClassesSummary, setTicketClassesSummary] = useState<any[]>([])

    const generateTicketClassesSummary = (eventId: number | null) => {
        const list = [] as any[]

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            var events = data
                .map((x) => { return { eventId: x.eventId, eventName: x.eventName } })
                .filter((value, index, self) => self.findIndex(x => x.eventId === value.eventId) === index)

            // events
            events.forEach((event, index) => {
                var ticketClasses = data.filter(x => x.eventId === event.eventId)

                if (ticketClasses.length === 1) {
                    let ticketClass = ticketClasses[0]

                    list.push(<tr key={index}>
                        <td>{index + 1}</td>
                        <td>{ticketClass.eventName}</td>
                        <td>{ticketClass.ticketClassName}</td>
                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(ticketClass.quantity)}</td>
                        <td style={{ textAlign: "right" }}>{Utils.formatNumber(ticketClass.amount)}</td>
                    </tr>)
                } else {
                    const rowSpan = ticketClasses.length + 1

                    // ticketClasses
                    ticketClasses.forEach((ticketClass, subindex) => {
                        if (subindex === 0) {
                            list.push(<tr key={index}>
                                <td rowSpan={rowSpan}>{index + 1}</td>
                                <td rowSpan={rowSpan}>{ticketClass.eventName}</td>
                                <td>{ticketClass.ticketClassName}</td>
                                <td style={{ textAlign: "right" }}>{Utils.formatNumber(ticketClass.quantity)}</td>
                                <td style={{ textAlign: "right" }}>{Utils.formatNumber(ticketClass.amount)}</td>
                            </tr>)
                        } else {
                            list.push(<tr key={`${index}.${subindex}`}>
                                <td>{ticketClass.ticketClassName}</td>
                                <td style={{ textAlign: "right" }}>{Utils.formatNumber(ticketClass.quantity)}</td>
                                <td style={{ textAlign: "right" }}>{Utils.formatNumber(ticketClass.amount)}</td>
                            </tr>)
                        }
                    })

                    let quantity = ticketClasses.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    let amount = ticketClasses.map(x => x.amount).reduce((a, b) => a + b, 0)

                    list.push(<tr key={`Subtotal.${index}`}>
                        <th>Subtotal</th>
                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(quantity)}</th>
                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(amount)}</th>
                    </tr>)
                }
            })

            //Total

            let quantity = data.map(x => x.quantity).reduce((a, b) => a + b, 0)
            let amount = data.map(x => x.amount).reduce((a, b) => a + b, 0)

            list.push(<tr key={`Total`}>
                <th style={{ textAlign: "center" }} colSpan={3}>Total</th>
                <th style={{ textAlign: "right" }}>{Utils.formatNumber(quantity)}</th>
                <th style={{ textAlign: "right" }}>{Utils.formatNumber(amount)}</th>
            </tr>)

            setTicketClassesSummary(list)
        } else {
            setTicketClassesSummary([])
        }
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>{getReportItemText(Permissions.AllEventTickets.TicketClassesReport)}</h1>
                            <DashboardDropdown reportName={Permissions.AllEventTickets.TicketClassesReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentEventId || ""} onChange={handleEventChange}>
                                        <option value="">All events</option>
                                        {events && events.map(event => {
                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                        })}
                                    </Form.Select>
                                </Card.Header>
                                <Card.Body>
                                    <Table responsive bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>#</th>
                                                <th>Event</th>
                                                <th>Ticket Class</th>
                                                <th style={{ textAlign: "right" }}>Volume</th>
                                                <th style={{ textAlign: "right" }}>Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketClassesSummary}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default TicketClassesDashboard