import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IVoucherType {
    id: number | null
    name: string
    orderNumber: number | null
    price: number | null
    currency: string
    timeLimit: number | null
    isUsed: boolean
    placeId: number
}

export default class VoucherTypeService {
    static async listAll(placeId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/VoucherTypes/${placeId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(placeId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/VoucherTypes/${placeId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/VoucherTypes/${placeId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/VoucherTypes/${placeId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(placeId: number, id: number, itemData: IVoucherType) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/VoucherTypes/${placeId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(placeId: number, itemData: IVoucherType) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/VoucherTypes/${placeId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/VoucherTypes/${placeId}/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}