import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"

export interface ISortField {
    name: string,
    description: string
}

function SortBy(props: {
    fields: ISortField[],
    sortBy: string,
    sortDirection: "ASC" | "DESC",
    onFieldChange: (name: string) => void,
    onDirectionChange: (direction: "ASC" | "DESC") => void
}) {
    const { t } = useTranslation("global")

    const SortDirections: Array<{
        name: "ASC" | "DESC",
        description: string
    }> = [
            {
                name: "ASC",
                description: `${t("sort.asc")}`
            },
            {
                name: "DESC",
                description: `${t("sort.desc")}`
            }
        ]

    return (
        <Dropdown className="sort-by">
            <Dropdown.Toggle variant="default" size="sm">
                <i className="fas fa-sort-amount-up"></i> {t("sort.title")}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {
                    props.fields.map(x => <Dropdown.Item key={x.name} className={x.name === props.sortBy ? "active" : ""} onClick={() => props.onFieldChange(x.name)}><i className="fas fa-circle"></i> {x.description}</Dropdown.Item>)
                }
                <Dropdown.Divider />
                {
                    SortDirections.map(x => <Dropdown.Item key={x.name} className={x.name === props.sortDirection ? "active" : ""} onClick={() => props.onDirectionChange(x.name)}><i className="fas fa-circle"></i> {x.description}</Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default SortBy