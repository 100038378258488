import moment from "moment"
import { useEffect, useState } from "react"


function Clock(props: { value: string }) {
    const [seconds, setSeconds] = useState<number>(0)

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(seconds => seconds + 1)
        }, 1000)

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    return (
        <div className="text-primary">
            {moment(props.value).add(seconds, "seconds").format("MMMM DD YYYY, HH:mm:ss")}
        </div>
    )
}

export default Clock