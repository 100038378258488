import { forwardRef } from "react"

interface IHtmlToPrintProps {
    paperSizeWidth: string
    paperSizeHeight: string
    htmlContent: string
    styleSheet: string
}

const HtmlToPrint = forwardRef((props: IHtmlToPrintProps, ref: any) => {
    const getPaperStyles = () => {
        return `
        @page {
            size: ${props.paperSizeWidth} ${props.paperSizeHeight} !important; 
        }

        .page-break {
            margin-top: 1rem;
            display: block;
            page-break-before: auto;
        }
        
        ${props.styleSheet}
        `
    }

    return (
        <div style={{ display: "none" }}>
            <div className="print-wrap" ref={ref}>
                <style type="text/css" media="print">{getPaperStyles()}</style>

                <div dangerouslySetInnerHTML={{ __html: props.htmlContent }}></div>
            </div>
        </div>
    )
})

export default HtmlToPrint