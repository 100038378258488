import { SyntheticEvent } from "react"
import { Link, useNavigate } from "react-router-dom"
import RoutePath from "../constants/RoutePath"
import useAuthContext from "../hooks/useAuthContext"
import AuthService from "../services/AuthService"
import TokenService from "../services/TokenService"
import { useTranslation } from "react-i18next"
import Configs from "../Configs"

function UserNavbar(props: { showControlSidebarButtonToggle?: boolean }) {
    const { user } = useAuthContext()
    const { t, i18n } = useTranslation("global")
    const navigate = useNavigate()

    const handleLogout = async (e: SyntheticEvent) => {
        e.preventDefault()

        await AuthService.logout()
        TokenService.removeUser()
        navigate(RoutePath.AUTH.LOGIN)
    }

    const languages: Array<{ code: string, name: string }> = [
        { code: "en", name: "English" },
        { code: "vi", name: "Tiếng Việt" }
    ]

    const handleChangeLanguage = (code: string) => {
        i18n.changeLanguage(code)
        Configs.Language.set(code)
    }

    return (
        <ul className="navbar-nav ml-auto">
            <li className="nav-item">
                <Link to={RoutePath.DOWNLOAD} className="nav-link"><i className="fas fa-cloud-download-alt"></i> {t("userNavbar.download")}</Link>
            </li>
            <li className="nav-item dropdown">
                <a id="languageMenu" data-toggle="dropdown" aria-expanded="false" className="nav-link dropdown-toggle">
                    <i className="fas fa-globe"></i> {languages.find(x => x.code === Configs.Language.get())?.name || t("languageSwitcher.chooseLanguage")}
                </a>
                <ul aria-labelledby="languageMenu" className="dropdown-menu dropdown-menu-right border-0 shadow">
                    {languages.map((lang, index) => <li key={index}>
                        <a className="dropdown-item py-2" onClick={() => handleChangeLanguage(lang.code)}>
                            {lang.name}
                            {lang.code === Configs.Language.get() && <i className="fas fa-check text-primary float-right"></i>}
                        </a>
                    </li>)}
                </ul>
            </li>
            {!user ? (
                <>
                    <li className="nav-item">
                        <Link to={RoutePath.AUTH.LOGIN} className="nav-link"><i className="fas fa-sign-in-alt"></i> {t("userNavbar.login")}</Link>
                    </li>
                    <li className="nav-item">
                        <Link to={RoutePath.AUTH.REGISTER} className="nav-link"><i className="fas fa-user-edit"></i> {t("userNavbar.register")}</Link>
                    </li>
                </>
            ) : (
                <>
                    <li className="nav-item dropdown">
                        <a id="userMenu" href={RoutePath.AUTH.PROFILE} data-toggle="dropdown" aria-expanded="false" className="nav-link dropdown-toggle">
                            <i className="fas fa-user-circle"></i> {user.userInfo.username}
                        </a>
                        <ul aria-labelledby="userMenu" className="dropdown-menu dropdown-menu-right border-0 shadow">
                            <li><Link to={RoutePath.AUTH.PROFILE} className="dropdown-item"><i className="fas fa-user"></i> {t("userNavbar.myProfile")}</Link></li>
                            <li className="dropdown-divider" />
                            <li><Link to={RoutePath.AUTH.CHANGE_PASSWORD} className="dropdown-item"><i className="fas fa-key"></i> {t("userNavbar.changePassword")}</Link></li>
                            <li className="dropdown-divider" />
                            <li><Link to="/" onClick={handleLogout} className="dropdown-item text-red"><i className="fas fa-sign-out-alt"></i> {t("userNavbar.logout")}</Link></li>
                        </ul>
                    </li>
                </>
            )
            }
            {props.showControlSidebarButtonToggle && <li className="nav-item">
                <a className="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
                    <i className="fas fa-th-large"></i>
                </a>
            </li>
            }
        </ul>
    )
}

export default UserNavbar