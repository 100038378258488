import { useTranslation } from "react-i18next"

function Unauthorized() {
    const { t } = useTranslation("global")

    return (
        <div className="page-center">
            <h1 className="text-white w3-animate-top">{t("unauthorizedPage.title")}</h1>
            <p className="text-white w3-animate-bottom" style={{ fontSize: "26px" }}>{t("unauthorizedPage.subTitle")}</p>
        </div>
    )
}

export default Unauthorized