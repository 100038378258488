import { Form } from "react-bootstrap"
import { PaymentStatusType } from "../constants/PaymentStatusType"
import { useTranslation } from "react-i18next"

interface IPaymentStatusSelectProps {
    value: string
    required?: boolean
    onChange: (e: any) => void
}

function PaymentStatusSelect(props: IPaymentStatusSelectProps) {
    const { t } = useTranslation("global")

    return (
        <Form.Group className="mb-1" controlId="paymentStatus">
            <Form.Label className="mb-0">{t("eventTicketsPage.paymentStatus")}</Form.Label>
            <Form.Select name="paymentStatus" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                <option value={PaymentStatusType.PENDING}>{t(`paymentStatus.${PaymentStatusType.PENDING}`)}</option>
                <option value={PaymentStatusType.PAID}>{t(`paymentStatus.${PaymentStatusType.PAID}`)}</option>
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredPaymentStatus")}</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default PaymentStatusSelect