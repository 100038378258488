import { useEffect, useState } from "react"
import EventCategoryService, { IEventCategory } from "../services/EventCategoryService"
import { Form } from "react-bootstrap"

interface IEventCategoriesCheckboxesProps {
    value: Array<number> | null
    onChange: (e: any) => void
}

function EventCategoriesCheckboxes(props: IEventCategoriesCheckboxesProps) {
    const [categories, setCategories] = useState([] as any[])

    useEffect(() => {
        EventCategoryService.listAll().then(res => {
            if (res?.isSuccess) {
                setCategories(res.data)
            } else {
                setCategories([])
                console.log(res?.message)
            }
        })
    }, [])

    return (
        <>
            {categories.map((category: IEventCategory, index) => {
                const checked = (props.value && props.value.includes(category.id || 0)) || false

                return <Form.Check
                    key={index}
                    id={`event_category_${category.id}`}
                    type="checkbox"
                    label={category.name}
                    checked={checked}
                    value={category.id || ""}
                    className="text-dark"
                    onChange={e => props.onChange(e)}
                />
            })}
        </>
    )
}

export default EventCategoriesCheckboxes