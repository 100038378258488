import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ITicketClass } from "./TicketClassService"

export interface ISeat {
    id: number | null
    number: number
    letter: string
    ticketClassId: number | null
    ticketClass: ITicketClass
}

export interface ISeatCreate {
    beginLetter: string
    endLetter: string
    beginNumber: number | null
    endNumber: number | null
    ticketClassId: number | null
}

export interface ISeatBulkDelete {
    beginLetter: string
    endLetter: string
    beginNumber: number | null
    endNumber: number | null
}

export interface ISeatUpdate {
    id: number | null
    letter: string
    number: number | null
    ticketClassId: number | null
}

export default class SeatService {
    static async listAll(eventId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Seats/${eventId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(eventId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Seats/${eventId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Seats/${eventId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(eventId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Seats/${eventId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async bulkDelete(eventId: number, itemData: ISeatBulkDelete) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Seats/${eventId}/BulkDelete`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(eventId: number, id: number, itemData: ISeatUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Seats/${eventId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(eventId: number, itemData: ISeatUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Seats/${eventId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async createOrUpdate(eventId: number, itemData: ISeatCreate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Seats/${eventId}/CreateOrUpdate`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}