import { Form } from "react-bootstrap"

interface ILetterSelectProps {
    name?: string
    value?: string | null
    required?: boolean
    onChange?: (e: any) => void
}

function LetterSelect(props: ILetterSelectProps) {
    const Alphabets = () => {
        let options: any[] = []
        for (let i = 65; i <= 90; i++) {
            let c = String.fromCharCode(i)

            options.push(<option key={i} value={c}>{c}</option>)
        }
        return options
    }

    return (
        <>
            <Form.Select name={props.name} required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {Alphabets()}
            </Form.Select>
        </>
    )
}

export default LetterSelect