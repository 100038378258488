import Configs from "../Configs"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

class AccountService {
    static async register(username: string, password: string, confirmPassword: string, email: string, fullname: string) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Account/Register", {
                userName: username,
                password: password,
                confirmPassword: confirmPassword,
                email: email,
                fullName: fullname
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateProfile(email: string, fullname: string) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Account/UpdateProfile", {
                email: email,
                fullName: fullname
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeAvatar(formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Account/ChangeAvatar", formData)

            data.data = `${Configs.API_BASE_URL}${data.data}`

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async profile() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Account/Profile")

            if (data.data.avatar) {
                data.data.avatar = `${Configs.API_BASE_URL}${data.data.avatar}`
            }

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePassword(currentPassword: string, newPassword: string, confirmNewPassword: string) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Account/ChangePassword", {
                currentPassword,
                newPassword,
                confirmNewPassword
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async forgotPassword(email: string) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Account/ForgotPassword", {
                email: email,
                resetPasswordUrl: Configs.RECOVER_PASSWORD_URL
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async resetPassword(password: string, confirmPassword: string, email: string, resetPasswordToken: string) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Account/ResetPassword", {
                password,
                confirmPassword,
                email,
                resetPasswordToken
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}

export default AccountService