import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export default class PermissionService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Permissions/All")

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async Initialize() {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Permissions/Initialize")

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}