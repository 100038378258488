import { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import TimeZoneService, { ITimeZoneInfo } from "../services/TimeZoneService"

interface ITimeZoneSelectProps {
    timeZoneId: string
    required?: boolean
    onChange: (e: any) => void
}

function TimeZoneSelect(props: ITimeZoneSelectProps) {
    const [timeZoneOptions, setTimeZoneOptions] = useState([] as any[])

    const TimeZoneOptions = async () => {
        const res = await TimeZoneService.list()
        let options: any[] = []

        if (res?.isSuccess) {
            options = res.data.map((item: ITimeZoneInfo) => <option key={item.id} value={item.id || 0}>{item.displayName}</option>)
        } else {
            console.log(res?.message)
        }

        return options
    }

    useEffect(() => {
        TimeZoneOptions().then(options => {
            setTimeZoneOptions(options)
        })
    }, [])

    return (
        <Form.Group className="mb-3" controlId="timeZoneId">
            <Form.Label>Time Zone</Form.Label>
            <Form.Select name="timeZoneId" required={props.required} value={props.timeZoneId || ""} onChange={props.onChange}>
                <option value=""></option>
                {timeZoneOptions}
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Select a Time Zone.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default memo(TimeZoneSelect)