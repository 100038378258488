import Permissions from "./Permissions"
import RoutePath from "./RoutePath"

export type ReportItem = {
    name: string,
    text: string,
    link: string
}

export const ReportItems: ReportItem[] = [
    {
        name: Permissions.AllVouchers.DailyVouchersReport,
        text: "Daily Voucher Sales Report",
        link: RoutePath.PROVIDER.DAILY_VOUCHER_DASHBOARD
    },
    {
        name: Permissions.AllVouchers.MonthlyVouchersReport,
        text: "Monthly Voucher Sales Report",
        link: RoutePath.PROVIDER.MONTHLY_VOUCHER_DASHBOARD
    },
    {
        name: Permissions.AllVouchers.VoucherSellersReport,
        text: "Voucher Sales Report by Seller",
        link: RoutePath.PROVIDER.VOUCHER_SELLERS_DASHBOARD
    },
    {
        name: Permissions.AllVouchers.VoucherOverTimeReport,
        text: "Voucher Sales Report by Time",
        link: RoutePath.PROVIDER.VOUCHER_OVER_TIME_DASHBOARD
    },
    {
        name: Permissions.AllVouchers.VoucherProvidersReport,
        text: "Voucher Sales Summary Report by Provider",
        link: RoutePath.PROVIDER.VOUCHER_PROVIDERS_DASHBOARD
    },
    {
        name: Permissions.AllVouchers.MonthlyVoucherProvidersReport,
        text: "Monthly Voucher Sales Report by Provider",
        link: RoutePath.PROVIDER.MONTHLY_VOUCHER_PROVIDERS_DASHBOARD
    },
    {
        name: Permissions.AgencyPartners.Report,
        text: "Voucher Sales Report by Agency",
        link: RoutePath.PROVIDER.AGENCY_DASHBOARD
    },
    {
        name: Permissions.AllEventTickets.DailyEventTicketsReport,
        text: "Daily Event Ticket Sales Report",
        link: RoutePath.PROVIDER.DAILY_EVENT_TICKET_DASHBOARD
    },
    {
        name: Permissions.AllEventTickets.MonthlyEventTicketsReport,
        text: "Monthly Event Ticket Sales Report",
        link: RoutePath.PROVIDER.MONTHLY_EVENT_TICKET_DASHBOARD
    },
    {
        name: Permissions.AllEventTickets.TicketSellersReport,
        text: "Event Ticket Sales Report by Seller",
        link: RoutePath.PROVIDER.TICKET_SELLERS_DASHBOARD
    },
    {
        name: Permissions.AllEventTickets.TicketClassesReport,
        text: "Event Ticket Sales Report by Ticket Class",
        link: RoutePath.PROVIDER.TICKET_CLASSES_DASHBOARD
    },
    {
        name: Permissions.AllEventTickets.InvitationCodesReport,
        text: "Event Ticket Sales Report by Invitation Code",
        link: RoutePath.PROVIDER.INVITATION_CODES_DASHBOARD
    }
]

export function getReportItemText(reportItemName: string) {
    return ReportItems.find(x => x.name === reportItemName)?.text || ""
}