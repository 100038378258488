import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export default class ProviderPermissionService {
    static async listAll(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderPermissions/${providerId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}