import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import useGlobalContext from "../../hooks/useGlobalContext"
import AudioStoryService, { IAudioStory } from "../../services/AudioStoryService"
import Configs from "../../Configs"
import AddOrRemoveLanguages, { LanguageActionTypes } from "../../components/AddOrRemoveLanguages"
import { Alert, Button, Card, Form, InputGroup, Modal, Spinner, Tab, Table, Tabs } from "react-bootstrap"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import CollectionSelect from "../../components/CollectionSelect"
import AreaSelect from "../../components/AreaSelect"
import { ILocation } from "../../constants/Common"
import GoogleMapPickerModal from "../../components/GoogleMapPickerModal"
import AudioStoryPhotosModal from "./AudioStoryPhotosModal"
import QRCodeModal from "../../components/QRCodeModal"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

interface IAudioStoriesModalProps {
    placeId: number | null
    show: boolean
    title: string
    onClose: () => void
}

function AudioStoriesModal(props: IAudioStoriesModalProps) {
    const { hasPermission } = useAuthContext()
    const { languages } = useGlobalContext()

    const [tableData, setTableData] = useState({
        data: new Array<IAudioStory>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IAudioStory = {
        id: null,
        name: "",
        code: "",
        type: "",
        thumbnail: "",
        largeThumbnail: "",
        locationId: null,
        lat: null,
        lng: null,
        zoom: null,
        collectionId: null,
        areaId: null,
        areaFullname: null,
        orderNumber: null,
        published: false,
        free: false,
        scanQRCode: false,
        placeId: props.placeId || 0,
        collection: null,
        area: null,
        likes: null,
        views: null,
        translations: languages.filter(x => x.code === Configs.DEFAULT_LANGUAGE_CODE).map(x => {
            return {
                audioStoryId: null,
                languageId: x.id || 0,
                name: "",
                audio: "",
                audioType: "",
                duration: null,
                description: "",
                language: x
            }
        })
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [activeTabKey, setActiveTabKey] = useState(languages.find(x => x.code === Configs.DEFAULT_LANGUAGE_CODE)?.id?.toString())

    const FilterFn = async (pageIndex: number) => {
        if (props.placeId && props.show) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await AudioStoryService.filter(props.placeId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        } else {
            setTableData({
                data: new Array<IAudioStory>(),
                hasNext: false,
                hasPrevious: false,
                pageIndex: 1,
                pageSize: Configs.DEFAULT_PAGE_SIZE,
                totalPages: 0,
                totalRecords: 0
            })
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()
        FilterFn(1)
    }, [props.placeId, props.show])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>) => {
        if (e.target.name === "orderNumber" || e.target.name === "collectionId" || e.target.name === "areaId") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleTranslateFormControlChange = (e: ChangeEvent<HTMLInputElement>, languageId: number | null) => {
        setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.value } : x) })
    }

    const handleAddNew = () => {
        setFormData(initFormData)
        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await AudioStoryService.get(props.placeId, id)

            if (res?.isSuccess) {
                setFormData(res.data)
                setActiveTabKey(res.data.translations[0]?.languageId?.toString())

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.placeId) {
                if (formRef.current?.checkValidity()) {
                    var frmData = new FormData()
                    if (thumbnail !== null) {
                        frmData.append("Thumbnail", thumbnail)
                    }
                    if (largeThumbnail !== null) {
                        frmData.append("LargeThumbnail", largeThumbnail)
                    }
                    frmData.append("Code", formData.code)
                    frmData.append("Type", formData.type || "")
                    frmData.append("OrderNumber", formData.orderNumber ? formData.orderNumber.toString() : "")
                    frmData.append("CollectionId", formData.collectionId ? formData.collectionId.toString() : "")
                    frmData.append("AreaId", formData.areaId ? formData.areaId.toString() : "")

                    formData.translations.forEach((item, index) => {
                        if (item.audioStoryId) {
                            frmData.append(`Translations[${index}].AudioStoryId`, item.audioStoryId.toString())
                        }
                        frmData.append(`Translations[${index}].LanguageId`, item.languageId.toString())
                        frmData.append(`Translations[${index}].Name`, item.name.toString())
                        frmData.append(`Translations[${index}].Description`, item.description?.toString() || "")

                        const audio = audios.find(x => x.languageId === item.languageId)
                        if (audio && audio.file) {
                            frmData.append(`Translations[${index}].Audio`, audio.file)
                            frmData.append(`Translations[${index}].AudioType`, audio.type)
                            frmData.append(`Translations[${index}].Duration`, audio.duration.toString())
                        }
                    })

                    try {
                        if (formData.id && formData.id > 0) {
                            const res = await AudioStoryService.update(props.placeId, formData.id, frmData)

                            if (res?.isSuccess) {
                                FilterFn(currentPageIndex)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        } else {
                            const res = await AudioStoryService.create(props.placeId, frmData)

                            if (res?.isSuccess) {
                                FilterFn(1)
                                handleCloseModal()
                                setMessage(res.message)
                            } else {
                                setMessageFormModal(res?.message)
                            }
                        }
                    } catch (error) {
                        console.log(error)
                    }
                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setThumbnail(null)
        setLargeThumbnail(null)
        setAudios([])
        setMessage("")
        setShowModal(true)
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Audio Story" : "New Audio Story"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setSelectedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setSelectedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (props.placeId && selectedItem.id > 0) {
            const res = await AudioStoryService.delete(props.placeId, selectedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handlePublishedSwitch = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await AudioStoryService.changePublished(props.placeId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleFreeSwitch = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await AudioStoryService.changeFree(props.placeId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleScanQRCodeSwitch = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await AudioStoryService.changeScanQRCode(props.placeId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleLanguagesChange = async (languageId: number, action: LanguageActionTypes) => {
        if (action === "Add") {
            const newLang = languages.find(x => x.id === languageId)
            if (newLang) {
                if (formData.translations.filter(x => x.languageId === newLang.id).length === 0) {
                    const newFormData = { ...formData }

                    newFormData.translations.push({
                        audioStoryId: null,
                        languageId: newLang.id || 0,
                        name: "",
                        audio: "",
                        audioType: "",
                        duration: null,
                        description: "",
                        language: newLang
                    })

                    setFormData(newFormData)
                    setActiveTabKey(newLang.id?.toString())
                }
            }
        } else {
            const newTranslations = formData.translations.filter(x => x.languageId !== languageId)

            setFormData({ ...formData, translations: newTranslations })
            setActiveTabKey(newTranslations[0]?.languageId.toString())
        }
    }

    const [thumbnail, setThumbnail] = useState<File | null>(null)
    const [largeThumbnail, setLargeThumbnail] = useState<File | null>(null)

    const handleThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setThumbnail(e.target.files[0])
        } else {
            setThumbnail(null)
        }
    }

    const handleLargeThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setLargeThumbnail(e.target.files[0])
        } else {
            setLargeThumbnail(null)
        }
    }

    const [audios, setAudios] = useState<Array<{
        languageId: number,
        file: File | null,
        type: string,
        duration: number
    }>>([])

    const handleAudioChange = (e: ChangeEvent<HTMLInputElement>, languageId: number) => {
        const list = audios.filter(x => x.languageId !== languageId)
        if (e.target.files?.length) {
            var file = e.target.files[0]
            var audio = new Audio()
            audio.addEventListener("loadedmetadata", () => {
                list.push({ languageId: languageId, file: file, type: file.type, duration: audio.duration })
            })
            audio.src = URL.createObjectURL(e.target.files[0])
        }
        setAudios(list)
    }

    // Location
    const [location, setLocation] = useState<ILocation>({ locationId: null, zoom: null, lat: null, lng: null })
    const [showLocationModal, setShowLocationModal] = useState(false)
    const handleCloseLocationModal = () => {
        setShowLocationModal(false)
    }
    const handleShowLocationModal = () => {
        setShowLocationModal(true)
    }

    const handleShowLocationPicker = async (id: number | null, name: string) => {
        if (props.placeId && id) {
            const res = await AudioStoryService.get(props.placeId, id)

            if (res?.isSuccess) {
                setLocation({ ...location, locationId: res.data.id, lat: res.data.lat, lng: res.data.lng, zoom: res.data.zoom })
                setSelectedItem({
                    id: id || 0,
                    name: name
                })
                handleShowLocationModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleZoomChanged = (zoom: number | null) => {
        setLocation({ ...location, zoom: zoom })
    }

    const handleLocationChanged = (lat: number | null, lng: number | null) => {
        setLocation({ ...location, lat: lat, lng: lng })
    }

    const handleLatLngChange = (e: ChangeEvent<HTMLInputElement>) => {
        setLocation({ ...location, [e.target.name]: e.target.value ? parseFloat(e.target.value) : null })
    }

    const handleLocationSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (props.placeId && location.locationId && location.locationId > 0) {
            try {
                const res = await AudioStoryService.setLocation(props.placeId, location.locationId, location)

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex)
                    handleCloseLocationModal()
                } else {
                    console.log(res?.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    // AudioStoryPhotos
    const [showAudioStoryPhotosModal, setShowAudioStoryPhotosModal] = useState(false)
    const handleCloseAudioStoryPhotosModal = () => {
        setShowAudioStoryPhotosModal(false)
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowAudioStoryPhotosModal = (audioStoryId: number | null, name: string) => {
        if (audioStoryId) {
            setSelectedItem({
                id: audioStoryId || 0,
                name: name
            })
            setShowAudioStoryPhotosModal(true)
        }
    }

    // QRCode
    const [qrCode, setQRCode] = useState<{ image: string, code: string, caption?: Array<string> }>({ image: "", code: "", caption: [] })
    const [showQRCodeModal, setShowQRCodeModal] = useState(false)
    const handleCloseQRCodeModal = () => {
        setShowQRCodeModal(false)
        setQRCode({ image: "", code: "", caption: [] })
        setSelectedItem({
            id: 0,
            name: ""
        })
    }

    const handleShowQRCodeModal = (audioStoryId: number | null, name: string) => {
        if (props.placeId && audioStoryId) {
            const res = AudioStoryService.generateQRCode(props.placeId, audioStoryId)

            res.then(data => {
                if (data?.isSuccess) {
                    setQRCode({ image: data.data.image, code: audioStoryId.toString(), caption: data.data.caption })
                    setSelectedItem({
                        id: audioStoryId || 0,
                        name: name
                    })
                    setShowQRCodeModal(true)
                } else {
                    console.log(data?.message)
                }
            })
        }
    }

    const handleDownloadQRCode = () => {
        if (props.placeId && selectedItem.id) {
            AudioStoryService.downloadQRCode(props.placeId, selectedItem.id)
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} dialogClassName="modal-90w">
                <Modal.Header closeButton className="p-2">
                    <Modal.Title><i className="fas fa-headphones-alt"></i> Audio Stories <span>|</span> {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Card className="m-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Card.Header>
                            {hasPermission(Permissions.AudioStories.Create) &&
                                <Button variant="info" size="sm" onClick={handleAddNew}>
                                    <i className="fas fa-plus"></i> Add new
                                </Button>
                            }

                            <div className="card-tools">
                                <InputGroup size="sm">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        ref={keywordRef as RefObject<HTMLInputElement>}
                                        onKeyUp={handleSearchInputKeyPress}
                                    />
                                    <Button variant="info" size="sm" onClick={handleSearch}>
                                        <i className="fas fa-search"></i>
                                    </Button>
                                </InputGroup>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", width: "160px" }}>Thumbnail</th>
                                        <th>Name</th>
                                        <th>Code</th>
                                        <th>Type</th>
                                        <th>Order Number</th>
                                        <th style={{ textAlign: "center", width: "210px" }}>Status</th>
                                        <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.data.map(item =>
                                            <tr key={item.id}>
                                                <td>{item.thumbnail && <img src={`${Configs.API_BASE_URL}${item.thumbnail}`} style={{ maxWidth: "100%" }} alt={item.name} />}</td>
                                                <td>
                                                    <h6 style={{ display: "flex" }}><span className="text-muted">#{item.id}.</span> <span style={{ whiteSpace: "pre-wrap", paddingLeft: "5px" }}>{item.name}</span></h6>
                                                    <p className="text-muted" style={{ fontSize: "11px", marginBottom: "0.5rem" }}>
                                                        {item.collection && <span style={{ whiteSpace: "nowrap" }}><i className="fas fa-tags ml-2"></i> {item.collection.name} </span>}
                                                        {/* {item.area && <span style={{ whiteSpace: "nowrap" }}><i className="fas fa-map ml-2"></i> {item.area.name}</span>} */}
                                                        {item.areaFullname && <span style={{ whiteSpace: "nowrap" }}><i className="fas fa-kaaba ml-2"></i> {item.areaFullname}</span>}
                                                        {item.likes && <span style={{ whiteSpace: "nowrap" }}><i className="fas fa-thumbs-up ml-2"></i> {item.likes}</span>}
                                                        {item.views && <span style={{ whiteSpace: "nowrap" }}><i className="fas fa-eye ml-2"></i> {item.views}</span>}
                                                    </p>
                                                    {hasPermission(Permissions.AudioStories.Edit) && <>
                                                        <hr />
                                                        <Button variant="default" size="sm" onClick={() => handleShowLocationPicker(item.id, item.name)}>
                                                            <i className="fas fa-map-marked-alt text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>{item.lat && item.lng ? <>{item.lat}, {item.lng}</> : <>Location</>}</span>
                                                        </Button>
                                                    </>
                                                    }
                                                    <hr />
                                                    {hasPermission(Permissions.AudioStoryPhotos.View) &&
                                                        <Button variant="default" size="sm" onClick={() => handleShowAudioStoryPhotosModal(item.id, item.name)}>
                                                            <i className="far fa-images text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>Photos</span>
                                                        </Button>
                                                    }
                                                    {hasPermission(Permissions.AudioStories.View) &&
                                                        <Button variant="default" size="sm" onClick={() => handleShowQRCodeModal(item.id, item.name)}>
                                                            <i className="fas fa-qrcode text-info"></i> <span className="text-muted" style={{ fontSize: "11px" }}>QR Code</span>
                                                        </Button>
                                                    }
                                                </td>
                                                <td>{item.code}</td>
                                                <td>{item.type}</td>
                                                <td>{item.orderNumber}</td>
                                                <td>
                                                    <Form.Check type="switch" id={"audio_story_published_" + item.id} checked={item.published} onChange={() => handlePublishedSwitch(item.id)} label="Published" disabled={!hasPermission(Permissions.AudioStories.Edit)} />
                                                    <Form.Check type="switch" id={"audio_story_free_" + item.id} checked={item.free} onChange={() => handleFreeSwitch(item.id)} label="Free in apps" disabled={!hasPermission(Permissions.AudioStories.Edit)} />
                                                    <Form.Check type="switch" id={"audio_story_scanQRCode_" + item.id} checked={item.scanQRCode} onChange={() => handleScanQRCodeSwitch(item.id)} label="Free on web" disabled={!hasPermission(Permissions.AudioStories.Edit)} />
                                                    <a href={`${Configs.AUDIO_PLAYER_URL}/audio/${item.placeId}/${item.id}`} className="text-muted" style={{ fontSize: "11px" }} target="_blank">Preview on web</a>
                                                </td>
                                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                    {hasPermission(Permissions.AudioStories.Edit) &&
                                                        <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                    }
                                                    {hasPermission(Permissions.AudioStories.Delete) &&
                                                        <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddOrRemoveLanguages selectedLanguages={formData.translations.map(x => x.language)} onChange={handleLanguagesChange}></AddOrRemoveLanguages>
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Tabs defaultActiveKey="thumbnail">
                            <Tab eventKey="thumbnail" title="Thumbnail">
                                <Form.Group controlId="thumbnailFile">
                                    <Card className="m-0 border-0 shadow-0">
                                        {formData.thumbnail && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.thumbnail}`} style={{ width: "inherit", maxWidth: "300px", margin: "auto" }} />}
                                        <Card.Body className="p-2">
                                            <Form.Control type="file" onChange={handleThumbnailChange} accept={Configs.IMAGE_EXTENSION} />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Tab>
                            <Tab eventKey="largeThumbnail" title="Large Thumbnail">
                                <Form.Group controlId="largeThumbnailFile">
                                    <Card className="m-0 border-0 shadow-0">
                                        {formData.largeThumbnail && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.largeThumbnail}`} style={{ width: "inherit", maxHeight: "300px", margin: "auto" }} />}
                                        <Card.Body className="p-2">
                                            <Form.Control type="file" onChange={handleLargeThumbnailChange} accept={Configs.IMAGE_EXTENSION} />
                                        </Card.Body>
                                    </Card>
                                </Form.Group>
                            </Tab>
                        </Tabs>
                        <Tabs activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k || undefined)}>
                            {
                                formData.translations.sort((a, b) => { return (a.language.orderNumber || 0) - (b.language.orderNumber || 0) }).map(translate => {
                                    return (
                                        <Tab
                                            key={translate.languageId}
                                            eventKey={(translate.languageId).toString()}
                                            title={translate.language.name}
                                            className="p-3"
                                        >
                                            <Form.Group controlId="audioFile" className="mb-3">
                                                <Form.Label>Audio</Form.Label>
                                                <Card className="shadow-0">
                                                    {translate.audio && <audio controls style={{ margin: "auto" }}>
                                                        <source src={`${Configs.API_BASE_URL}${translate.audio}`} type={translate.audioType} />
                                                        Your browser does not support the audio tag.
                                                    </audio>}
                                                    <Card.Body className="p-2">
                                                        <Form.Control type="file" accept={Configs.AUDIO_EXTENSION} onChange={(e: ChangeEvent<HTMLInputElement>) => handleAudioChange(e, translate.languageId)} />
                                                    </Card.Body>
                                                </Card>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId={"name_" + translate.languageId}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control as="textarea" rows={2} name="name" required value={translate?.name || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group controlId={"description_" + translate.languageId}>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" rows={7} name="description" value={translate?.description || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                            </Form.Group>
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>
                        <Form.Group className="mt-3" controlId="code">
                            <Form.Label>Code</Form.Label>
                            <Form.Control type="text" name="code" required value={formData.code || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Code is required.</Form.Control.Feedback>
                        </Form.Group>
                        <CollectionSelect placeId={props.placeId} value={formData.collectionId} onChange={handleFormControlChange}></CollectionSelect>
                        <AreaSelect name="areaId" placeId={props.placeId} excludeBranchId={null} value={formData.areaId} onChange={handleFormControlChange}></AreaSelect>
                        <Form.Group className="mt-3" controlId="type">
                            <Form.Label>Type</Form.Label>
                            <Form.Select name="type" value={formData.type || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                <option value=""></option>
                                <option value="reference">Reference</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mt-3" controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={selectedItem.id} name={selectedItem.name} />

            <GoogleMapPickerModal defaultZoom={location.zoom} defaultCenter={{ lat: location.lat, lng: location.lng }} title={selectedItem.name} show={showLocationModal} onClose={handleCloseLocationModal} onSave={handleLocationSave} onLocationChanged={handleLocationChanged} onLatLngChange={handleLatLngChange} onZoomChanged={handleZoomChanged}></GoogleMapPickerModal>
            <AudioStoryPhotosModal audioStoryId={selectedItem.id} title={selectedItem.name} show={showAudioStoryPhotosModal} onClose={handleCloseAudioStoryPhotosModal}></AudioStoryPhotosModal>
            <QRCodeModal image={qrCode.image} code={qrCode.code} caption={qrCode.caption} show={showQRCodeModal} onClose={handleCloseQRCodeModal} onDownload={handleDownloadQRCode}></QRCodeModal>
        </>
    )
}

export default AudioStoriesModal