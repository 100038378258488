import { Link } from "react-router-dom"
import RoutePath from "../constants/RoutePath"
import { UserTypes } from "../constants/UserTypes"
import useAuthContext from "../hooks/useAuthContext"
import logo from "../logo-3x1.png"
import logoMini from "../logo-mini.png"

function Logo({ className = "navbar-brand" }) {
    const { user } = useAuthContext()

    const homeUrl = Object.values(UserTypes).includes(user?.userInfo.type || "") && user?.userInfo.type !== UserTypes.GUEST ? RoutePath.WELCOME : "/"

    return (
        <Link to={homeUrl} className={className} replace={true}>
            <img src={logo} alt="BonBonGuide" className="brand-image" />
            <img src={logoMini} alt="BonBonGuide" className="brand-image mini" />
        </Link>
    )
}

export default Logo