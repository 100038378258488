import { CSSProperties, useState } from "react"
import { Autocomplete, GoogleMap, LoadScriptProps, MarkerF, useLoadScript } from "@react-google-maps/api"
import { Button, Form, InputGroup, Modal } from "react-bootstrap"
import Configs from "../Configs"

const mapContainerStyle: CSSProperties = {
    height: "500px",
    width: "100%"
}

const mapSearchBoxStyle: CSSProperties = {
    position: "absolute",
    width: "100%",
    maxWidth: "300px",
    left: "50%",
    top: "10px",
    transform: "translateX(-50%)",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
    borderRadius: "2px"
}

const mapsLibraries: LoadScriptProps['libraries'] = ["places"]

interface GoogleMapPickerModalProps {
    defaultZoom?: number | null
    defaultCenter?: {
        lat?: number | null
        lng?: number | null
    }
    onZoomChanged: (zoom: number | null) => void
    onLocationChanged: (lat: number | null, lng: number | null) => void
    onLatLngChange: (e: any) => void
    title: string
    show: boolean
    onClose: () => void
    onSave: (e: any) => void
}

function GoogleMapPickerModal(props: GoogleMapPickerModalProps) {
    const { isLoaded } = useLoadScript({ googleMapsApiKey: Configs.GOOGLE_MAPS_API_KEY, libraries: mapsLibraries })
    const [map, setMap] = useState<google.maps.Map>()

    const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>()

    if (!isLoaded) return <div>Google Maps Loading...</div>

    const getPlaceResultLocation = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace()
            const lat = place?.geometry?.location?.lat() || null
            const lng = place?.geometry?.location?.lng() || null

            if (map && lat && lng) {
                map.setZoom(12)
                map.setCenter({ lat, lng })
                return { lat, lng }
            }
        }
    }

    const handleZoomChanged = () => {
        if (map) {
            props.onZoomChanged(map.getZoom() || null)
        }
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton className="p-2">
                <Modal.Title><i className="fas fa-map-marked-alt"></i> Location <span>|</span> {props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="p-0">
                <GoogleMap
                    options={{
                        streetViewControl: false,
                        mapTypeControlOptions: {
                            position: google.maps.ControlPosition.TOP_LEFT,
                            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
                        },
                        mapTypeId: google.maps.MapTypeId.ROADMAP
                    }}
                    zoom={props.defaultZoom || 5}
                    center={{
                        lat: props.defaultCenter?.lat || 16.463713,
                        lng: props.defaultCenter?.lng || 107.590866
                    }}
                    mapContainerStyle={mapContainerStyle}
                    onLoad={map => setMap(map)}
                    onZoomChanged={handleZoomChanged}
                >
                    <Autocomplete
                        onLoad={(autocomplete) => { setAutocomplete(autocomplete) }}
                        onPlaceChanged={() => {
                            const location = getPlaceResultLocation()
                            if (location) props.onLocationChanged(location.lat, location.lng)
                        }}
                    >
                        <Form.Control type="search" autoFocus placeholder="Enter address..." style={mapSearchBoxStyle} />
                    </Autocomplete>

                    <MarkerF
                        position={{
                            lat: props.defaultCenter?.lat || 16.463713,
                            lng: props.defaultCenter?.lng || 107.590866
                        }}
                        draggable
                        onDragEnd={e => { props.onLocationChanged(e.latLng?.lat() || null, e.latLng?.lng() || null) }}
                        animation={google.maps.Animation.DROP}
                    />
                </GoogleMap >
                <div className="row mx-1">
                    <div className="col-md-6">
                        <InputGroup className="m-1">
                            <InputGroup.Text id="lat" className="bg-danger">Lat</InputGroup.Text>
                            <Form.Control type="number" aria-describedby="lat" name="lat" value={props.defaultCenter?.lat || undefined} onChange={(e) => props.onLatLngChange(e as any)} />
                        </InputGroup>
                    </div>
                    <div className="col-md-6">
                        <InputGroup className="m-1">
                            <InputGroup.Text id="lng" className="bg-danger">Lng</InputGroup.Text>
                            <Form.Control type="number" aria-describedby="lng" name="lng" value={props.defaultCenter?.lng || undefined} onChange={(e) => props.onLatLngChange(e as any)} />
                        </InputGroup>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="p-2">
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> Cancel</Button>
                <Button variant="info" onClick={props.onSave}><i className="fas fa-save"></i> Save</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default GoogleMapPickerModal