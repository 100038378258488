import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import Pagination from "../../components/Pagination"
import Configs from "../../Configs"
import LanguageService, { ILanguage } from "../../services/LanguageService"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

function Languages() {
    const { hasPermission } = useAuthContext()

    const [tableData, setTableData] = useState({
        data: new Array<ILanguage>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData = {
        id: null,
        name: "",
        code: "",
        flag: "",
        orderNumber: null
    } as ILanguage

    const [file, setFile] = useState(null as any)
    const [formData, setFormData] = useState(initFormData)
    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [validatedFormModal, setValidatedFormModal] = useState(false)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE
        let keyword = keywordRef.current?.value || ""

        const res = await LanguageService.filter(pageIndex, pageSize, keyword)

        if (res?.isSuccess) {
            setTableData(res.data)
        } else {
            console.log(res?.message)
        }

        setCurrentPageIndex(pageIndex)
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "orderNumber") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleFileControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setFile(e.target.files[0])
        } else {
            setFile(null)
        }
    }

    const handleIsUsedSwitch = async (id: number | null) => {
        if (id) {
            const res = await LanguageService.changeIsUsed(id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        if (id) {
            setDeletedItem({
                id: id,
                name: name
            })

            setDisplayConfirmationModal(true)
            setMessage("")
        }
    }

    const handleDelete = async () => {
        const res = await LanguageService.delete(deletedItem.id)

        if (res?.isSuccess) {
            FilterFn(currentPageIndex)
        }

        hideConfirmationModal()
        setMessage(res.message)
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedFormModal(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setFile(null)
        setMessage("")
    }

    const modalTitle = formData.id !== null ? "Edit Language" : "New Language"

    const handleEdit = async (id: number | null) => {
        if (id) {
            const res = await LanguageService.get(id)

            if (res?.isSuccess) {
                setFormData({
                    ...initFormData,
                    id: res.data.id,
                    code: res.data.code,
                    name: res.data.name,
                    flag: res.data.flag,
                    orderNumber: res.data.orderNumber
                })

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formRef.current?.checkValidity()) {
            var frmData = new FormData()
            if (file !== null) {
                frmData.append("Flag", file)
            }

            frmData.append("Name", formData.name)
            frmData.append("Code", formData.code)
            frmData.append("OrderNumber", formData.orderNumber ? formData.orderNumber.toString() : "")

            if (formData.id) {
                const res = await LanguageService.update(formData.id, frmData)

                if (res?.isSuccess) {
                    FilterFn(currentPageIndex)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            } else {
                const res = await LanguageService.create(frmData)

                if (res?.isSuccess) {
                    FilterFn(1)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            }
        } else {
            setValidatedFormModal(true)
        }
    }

    return (
        <>
            <ContentHeader title="Languages" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.Languages.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Add New
                                        </Button>
                                    }

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "80px" }}>Flag</th>
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Order Number</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Is Used</th>
                                                <th style={{ textAlign: "center", width: "90px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.flag && <img src={`${Configs.API_BASE_URL}${item.flag}`} style={{ maxWidth: "100%" }} alt={item.code} />}</td>
                                                        <td>{item.code}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.orderNumber || ""}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" id={"language_isused_" + item.id} checked={item.isUsed} onChange={(e) => handleIsUsedSwitch(item.id)} disabled={!hasPermission(Permissions.Languages.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.Languages.Edit) &&
                                                                <Button variant="warning" size="sm" className="mr-1" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.Languages.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Flag</Form.Label>
                            <Card>
                                {formData.flag && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.flag}`} style={{ width: "inherit", maxWidth: "300px", margin: "auto" }} />}
                                <Card.Body className="p-2">
                                    <Form.Control type="file" onChange={handleFileControlChange} />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>Code</Form.Label>
                            <Form.Control type="text" name="code" required value={formData.code} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Code is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" required value={formData.name} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Languages