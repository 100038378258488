import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IRegionUpdate {
    name: string
    code: string
    orderNumber: number | null
    isUsed: boolean
    parentId: number | null
}

export interface IRegion extends IRegionUpdate {
    id: number
}

export default class RegionService {
    static async getChildren(parentId: number | null) {
        try {
            let url = "/api/Regions/GetChildren"
            if (parentId != null) {
                url = `${url}?parentId=${parentId}`
            }

            const { data } = await axiosClient.get<ApiResponse>(url)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getPath(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Regions/GetPath/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(parentId: number | null, pageIndex: number, pageSize: number, keyword: string) {
        try {
            let url = "/api/Regions"
            if (parentId != null) {
                url = `${url}?parentId=${parentId}&keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`
            } else {
                url = `${url}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`
            }

            const { data } = await axiosClient.get<ApiResponse>(url)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Regions/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Regions/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: number, itemData: IRegion) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Regions/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(itemData: IRegion) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Regions/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Regions/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async refreshTreeDataCache() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Regions/RefreshTreeDataCache`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getTreeData() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Regions/GetTreeData`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}