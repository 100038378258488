import { Form } from "react-bootstrap"
import { EInvoiceProvider } from "../constants/EInvoiceProvider"

interface IEInvoiceProviderSelectProps {
    value: string
    required?: boolean
    onChange: (e: any) => void
}

function EInvoiceProviderSelect(props: IEInvoiceProviderSelectProps) {
    return (
        <Form.Group className="mb-3" controlId="eInvoiceProviderName">
            <Form.Label className="mb-0">Provider</Form.Label>
            <Form.Select name="eInvoiceProviderName" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                <option value={EInvoiceProvider.VNPT}>{EInvoiceProvider.VNPT}</option>
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Select a Provider.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default EInvoiceProviderSelect