import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IErrorLog {
    id: number
    logLevel: string
    threadId: number
    eventId: number
    eventName: string | null
    exceptionDetails: string | null
    createdOn: Date
}

export default class ErrorLogService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ErrorLogs?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ErrorLogs/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ErrorLogs/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async clear() {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ErrorLogs/Clear`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}