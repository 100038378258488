import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IPermission {
    id: number
    name: string
    checked: boolean
}

export interface IRoleType {
    name: string
    checked: boolean
}

export interface IRoleUpdate {
    name: string
    permissions: Array<IPermission>
    roleTypes: Array<IRoleType>
}

export interface IRole extends IRoleUpdate {
    id: number
}

export default class RoleService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Roles/All")

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Roles?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Roles/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Roles/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: number, roleData: IRoleUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Roles/${id}`, roleData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(roleData: IRoleUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Roles/Create`, roleData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}