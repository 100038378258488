import { ILocation } from "../constants/Common"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { IArea } from "./AreaService"
import { ICollection } from "./CollectionService"
import { ILanguage } from "./LanguageService"
import fileDownload from "js-file-download"
import axiosClientBlob from "./axiosClientBlob"
import Utils from "../utils/Utils"

export interface IAudioStoryTranslation {
    audioStoryId: number | null
    languageId: number
    name: string
    audio: string
    audioType: string
    duration: number | null
    description: string
    language: ILanguage
}

export interface IAudioStory extends ILocation {
    id: number | null
    name: string
    thumbnail: string
    largeThumbnail: string
    type: string
    code: string
    collectionId: number | null
    areaId: number | null
    orderNumber: number | null
    published: boolean
    free: boolean
    scanQRCode: boolean
    placeId: number
    collection: ICollection | null
    area: IArea | null
    translations: Array<IAudioStoryTranslation>
    likes: number | null
    views: number | null
    areaFullname: string | null
}

export default class AudioStoryService {
    static async filter(placeId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AudioStories/${placeId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async search(placeId: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AudioStories/${placeId}/Search?keyword=${keyword}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AudioStories/${placeId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AudioStories/${placeId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(placeId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AudioStories/${placeId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(placeId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/AudioStories/${placeId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePublished(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AudioStories/${placeId}/ChangePublished/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeFree(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AudioStories/${placeId}/ChangeFree/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeScanQRCode(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AudioStories/${placeId}/ChangeScanQRCode/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async setLocation(placeId: number, id: number, location: ILocation) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AudioStories/${placeId}/SetLocation/${id}`, location)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async generateQRCode(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AudioStories/${placeId}/GenerateQRCode/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static downloadQRCode(placeId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/AudioStories/${placeId}/DownloadQRCode/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}