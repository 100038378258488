import { IPlace } from "./PlaceService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface ICombo {
    id: number | null
    name: string
    description: string
    percentageDiscount: number | null
    isUsed: boolean
    places: Array<IPlace>
}

export default class ComboService {
    static async listForSale(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Combos/${providerId}/ListForSale`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Combos/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Combos/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Combos/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, itemData: ICombo) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Combos/${providerId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, itemData: ICombo) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Combos/${providerId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Combos/${providerId}/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}