import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import EventService, { IEvent, IEventSchedule } from "../../services/EventService"
import { Alert, Button, ButtonGroup, Dropdown, Form, InputGroup, Modal, Spinner, ToggleButton, ToggleButtonGroup } from "react-bootstrap"
import { DayNames, MonthNames } from "../../components/DaysOfWeekCheckboxes"
import moment from "moment"
import EventTicketService, { IBookedSeatFilter, IEventTicketCreate } from "../../services/EventTicketService"
import Configs from "../../Configs"
import Utils from "../../utils/Utils"
import { ISeat } from "../../services/SeatService"
import TimeZoneService from "../../services/TimeZoneService"
import Clock from "../../components/Clock"
import { PaymentServiceType } from "../../constants/PaymentServiceType"
import uuid from "react-uuid"
import PaymentStatusSelect from "../../components/PaymentStatusSelect"
import PaymentServiceSelect from "../../components/PaymentServiceSelect"
import { ITicketClass } from "../../services/TicketClassService"
import { useTranslation } from "react-i18next"
import PhoneInput, { CountryData } from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { PaymentStatusType } from "../../constants/PaymentStatusType"

interface IEventTicketCreateModalProps {
    providerId: number | null
    show: boolean
    onClose: () => void
    onSuccess: (eventTicketId: number) => void
}

function EventTicketCreateModal(props: IEventTicketCreateModalProps) {
    const { t } = useTranslation("global")
    const SeatSizes = ["Small", "Medium", "Large"]
    const [currentSeatSize, setCurrentSeatSize] = useState("Medium")

    const initEventTicketCreate: IEventTicketCreate = {
        eventId: null,
        eventDate: null,
        eventScheduleId: null,
        ticketClasses: [],
        seatIds: [],
        note: "",
        paymentService: PaymentServiceType.CASH,
        paymentStatus: "", //PaymentStatusType.PAID
        customer: {
            id: null,
            name: "",
            tel: "",
            formattedTel: "",
            address: "",
            email: "",
            personalId: ""
        }
    }

    const [eventTicketCreate, setEventTicketCreate] = useState<IEventTicketCreate>(initEventTicketCreate)

    const handleSeatChange = (e: ChangeEvent<HTMLInputElement>) => {
        var seatId = parseInt(e.target.value)
        var seatIds = eventTicketCreate.seatIds.map(x => x)

        if (e.target.checked) {
            if (!seatIds.includes(seatId)) {
                seatIds.push(seatId)
                setEventTicketCreate({
                    ...eventTicketCreate,
                    seatIds: seatIds
                })
            }
        } else {
            setEventTicketCreate({
                ...eventTicketCreate,
                seatIds: seatIds.filter(x => x !== seatId)
            })
        }
    }

    const handleTicketClassInputChange = (e: ChangeEvent<HTMLInputElement>, ticketClassId: number | null) => {
        const quantity = e.target.value ? parseInt(e.target.value) : null
        var ticketClasses = eventTicketCreate.ticketClasses.map(x => x)

        if (ticketClassId && ticketClassId > 0 && quantity && quantity > 0) {
            if (!ticketClasses.some(x => x.ticketClassId === ticketClassId)) {
                ticketClasses.push({
                    ticketClassId: ticketClassId,
                    quantity: quantity
                })

                setEventTicketCreate({
                    ...eventTicketCreate,
                    ticketClasses: ticketClasses
                })
            } else {
                setEventTicketCreate({
                    ...eventTicketCreate,
                    ticketClasses: ticketClasses.map(x => x.ticketClassId !== ticketClassId ? x : { ...x, quantity: quantity })
                })
            }
        } else {
            setEventTicketCreate({
                ...eventTicketCreate,
                ticketClasses: ticketClasses.filter(x => x.ticketClassId !== ticketClassId)
            })
        }
    }

    const [events, setEvents] = useState<Array<IEvent>>([])
    var datePaginator: {
        data: Array<Date>
        hasNext: boolean
        hasPrevious: boolean
        pageIndex: number
    } = {
        data: new Array<Date>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1
    }
    const [dates, setDates] = useState(datePaginator)
    const [schedules, setSchedules] = useState<Array<IEventSchedule>>([])
    const [seatMatrix, setSeatMatrix] = useState<Array<Array<any>>>([])
    const [ticketClassInput, setTicketClassInput] = useState<Array<any>>([])
    const [orderedSeats, setOrderedSeats] = useState<{
        ticketClasses: Array<ITicketClass>,
        seats: Array<ISeat>,
        availableQuantity: number | null
    }>({
        ticketClasses: [],
        seats: [],
        availableQuantity: null
    })
    const [timeZoneDisplayName, setTimeZoneDisplayName] = useState<string>()
    const [zonedDateTime, setZonedDateTime] = useState<string>()

    const LoadEvents = async () => {
        if (props.providerId && props.show) {
            const res = await EventService.listAllPublished(props.providerId)
            if (res?.isSuccess) {
                setEvents(res.data)
            } else {
                console.log(res?.message)
                setEvents([])
            }
        } else {
            setEvents([])
        }
    }

    const [isLoadDates, setIsLoadDates] = useState<string>("")
    const [pageOfDates, setPageOfDates] = useState<number>(1)
    const LoadDates = async (pageId: number) => {
        if (props.providerId && eventTicketCreate.eventId && pageId > 0) {
            setPageOfDates(pageId)
            const res = await EventService.getEventDates(props.providerId, eventTicketCreate.eventId, pageId)
            if (res?.isSuccess) {
                datePaginator = {
                    data: res.data.data.map((x: string) => Utils.stringToDate(x)),
                    hasNext: res.data.hasNext,
                    hasPrevious: res.data.hasPrevious,
                    pageIndex: res.data.pageIndex
                }

                //set default date
                setEventTicketCreate({ ...eventTicketCreate, eventDate: datePaginator.data.length ? moment(datePaginator.data[0]).format("YYYY-MM-DD") : null })

                setDates(datePaginator)
                setIsLoadDates(uuid())
            } else {
                console.log(res?.message)
                setDates(datePaginator)
                setSchedules([])
                setSeatMatrix([])
                setEventTicketCreate(initEventTicketCreate)
            }
        } else {
            setDates(datePaginator)
            setSchedules([])
            setSeatMatrix([])
            setEventTicketCreate(initEventTicketCreate)
        }
    }

    const handleNextDates = () => {
        LoadDates(pageOfDates + 1)
    }

    const handlePrevDates = () => {
        if (pageOfDates > 1)
            LoadDates(pageOfDates - 1)
    }

    const handleCurrentDates = () => {
        LoadDates(1)
    }

    const showClock = async () => {
        if (eventTicketCreate.eventId) {
            const event = events.find(x => x.id === eventTicketCreate.eventId)
            if (event?.timeZoneInfo?.id) {
                setTimeZoneDisplayName(event?.timeZoneInfo?.displayName || "")
            } else {
                setTimeZoneDisplayName("")
            }

            const res = await TimeZoneService.getCurrentDateTimeById(event?.timeZoneInfo?.id || "")
            if (res?.isSuccess) {
                setZonedDateTime(res.data)
            } else {
                console.log(res?.message)
            }
        } else {
            setTimeZoneDisplayName("")
            setZonedDateTime("")
        }
    }

    const LoadSeats = () => {
        if (eventTicketCreate.eventId) {
            const event = events.find(x => x.id === eventTicketCreate.eventId)
            if (event) {
                const seats = event.seats || []
                const ticketClasses = event.ticketClasses || []

                if (seats.length > 0) {
                    const letters = seats.map((x) => x.letter).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a > b ? 1 : -1)
                    const minNumber = Math.min(...seats.map(x => x.number))
                    const maxNumber = Math.max(...seats.map(x => x.number))

                    const matrix: Array<any> = []
                    letters.forEach(letter => {
                        const row: Array<any> = []
                        for (let num = minNumber; num <= maxNumber; num++) {
                            const seat = seats.find(x => x.letter === letter && x.number === num)
                            if (seat) {
                                let disabled = orderedSeats.seats.some(x => x.id === seat.id)

                                row.push(<li className={`seat ${disabled ? "ordered" : ""}`} key={seat.id}>
                                    <input type="checkbox" name="seat" id={`seat-${seat.id || 0}`} value={seat.id || 0} disabled={disabled} checked={eventTicketCreate.seatIds.includes(seat.id || 0)} onChange={handleSeatChange} />
                                    <label htmlFor={`seat-${seat.id || 0}`} style={{ "--color": seat.ticketClass?.color || "#ced4da" } as React.CSSProperties}>{seat.letter}{seat.number}</label>
                                </li>)
                            } else {
                                row.push(<li className="seat unserved" key={`${letter}${num}`}><label style={{ "--color": "#ced4da" } as React.CSSProperties}></label></li>)
                            }
                        }

                        matrix.push(<ul key={letter} className="seat-row">{row}</ul>)
                    })

                    setSeatMatrix(matrix)
                    setTicketClassInput([])
                } else {
                    const inputs: Array<any> = []
                    ticketClasses.forEach(ticketClass => {
                        let quantity = eventTicketCreate.ticketClasses.find(x => x.ticketClassId === ticketClass.id)?.quantity || 0
                        let availableQuantity;
                        if (ticketClass.quantity && ticketClass.quantity > 0) {
                            let orderedQuantity = orderedSeats.ticketClasses.find(x => x.id === ticketClass.id)?.quantity || 0

                            availableQuantity = ticketClass.quantity - orderedQuantity - quantity

                            if (availableQuantity < 0) {
                                availableQuantity = 0
                                quantity = ticketClass.quantity
                            }
                        }

                        let description = ticketClass.translations ? ticketClass.translations[0]?.description || "" : ""

                        inputs.push(<Form.Group className="mb-3" controlId={`ticketClass${ticketClass.id}`} key={ticketClass.id}>
                            <Form.Label>
                                {ticketClass.name}
                                {availableQuantity !== undefined && <span className="text-muted"> ({availableQuantity} {t("eventTicketCreateModal.available")})</span>}
                                : <span className="text-danger">{Utils.formatNumber(ticketClass.price)}</span> <span className="text-muted">{ticketClass.currency}</span>
                            </Form.Label>
                            <InputGroup>
                                <InputGroup.Text style={{ backgroundColor: ticketClass.color }}><i className="fas fa-ticket-alt text-white"></i></InputGroup.Text>
                                <Form.Control type="number" min={0} name="quantity" value={quantity || ""} onChange={(e: ChangeEvent<HTMLInputElement>) => handleTicketClassInputChange(e, ticketClass.id)} />
                            </InputGroup>
                            {description && <Form.Text muted>{description}</Form.Text>}
                        </Form.Group>)
                    })

                    setTicketClassInput(inputs)
                    setSeatMatrix([])
                }
            } else {
                setSeatMatrix([])
                setTicketClassInput([])
            }
        } else {
            setSeatMatrix([])
            setTicketClassInput([])
        }
    }

    const LoadSchedules = async () => {
        if (props.providerId && eventTicketCreate.eventId && eventTicketCreate.eventDate) {
            const res = await EventService.getEventSchedules(props.providerId, eventTicketCreate.eventId, eventTicketCreate.eventDate)

            if (res?.isSuccess) {
                const list: IEventSchedule[] = res.data;

                setSchedules(list)

                //set default time
                if (list.filter(x => x.id === eventTicketCreate.eventScheduleId).length === 0) {
                    setEventTicketCreate({ ...eventTicketCreate, eventScheduleId: list.length ? list[0].id : null, seatIds: [] })
                } else {
                    setEventTicketCreate({ ...eventTicketCreate, seatIds: [] })
                }
            } else {
                console.log(res?.message)
                setSchedules([])
            }
        } else {
            setSchedules([])
        }
    }

    useEffect(() => {
        setMessage("")
        setValidatedForm(false)
        setEventTicketCreate(initEventTicketCreate)
        LoadEvents()
    }, [props.providerId, props.show])

    useEffect(() => {
        LoadDates(1)
        showClock()
    }, [eventTicketCreate.eventId])

    useEffect(() => {
        LoadSchedules()
    }, [isLoadDates])

    useEffect(() => {
        setEventTicketCreate({ ...eventTicketCreate, seatIds: [] })
    }, [eventTicketCreate.eventScheduleId])

    useEffect(() => {
        LoadSeats()
    }, [eventTicketCreate.seatIds, eventTicketCreate.ticketClasses, orderedSeats])

    const [isCheckSeats, setIsCheckSeats] = useState<boolean>(false)
    useEffect(() => {
        setIsCheckSeats(true)
    }, [eventTicketCreate.eventId, eventTicketCreate.eventDate, eventTicketCreate.eventScheduleId])

    const GetBookedSeats = async () => {
        if (props.providerId && isCheckSeats) {
            setIsCheckSeats(false)

            if (eventTicketCreate.eventId && eventTicketCreate.eventDate && eventTicketCreate.eventScheduleId) {
                const eventSchedule = (events.find(x => x.id === eventTicketCreate.eventId)?.schedules || []).find(x => x.id === eventTicketCreate.eventScheduleId)
                if (eventSchedule) {
                    var filter: IBookedSeatFilter = {
                        eventId: eventTicketCreate.eventId,
                        eventDate: eventTicketCreate.eventDate,
                        eventScheduleId: eventTicketCreate.eventScheduleId
                    }

                    const res = await EventTicketService.getBookedSeats(props.providerId, filter)
                    if (res?.isSuccess) {
                        setOrderedSeats(res.data)
                    } else {
                        console.log(res?.message)
                        setOrderedSeats({
                            ticketClasses: [],
                            seats: [],
                            availableQuantity: null
                        })
                    }
                }
            }
        }
    }

    useEffect(() => {
        GetBookedSeats()
    }, [isCheckSeats])

    const [message, setMessage] = useState("")
    const [validatedForm, setValidatedForm] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)
    const [processing, setProcessing] = useState<boolean>(false)

    const handleCreate = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.providerId) {
                if (formRef.current?.checkValidity()) {
                    let createData = { ...eventTicketCreate }
                    createData.customer.tel = (dialCode === createData.customer.tel) ? "" : createData.customer.tel

                    const res = await EventTicketService.create(props.providerId, createData)

                    if (res?.isSuccess) {
                        props.onSuccess(createData.paymentStatus === PaymentStatusType.PAID ? res.data.id : null)
                        props.onClose()
                        setMessage(res.message)
                    } else {
                        setMessage(res?.message)
                    }

                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const handleCustomerChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEventTicketCreate({ ...eventTicketCreate, customer: { ...eventTicketCreate.customer, [e.target.name]: e.target.value } })
    }

    const displayTicketDetails = () => {
        var event = events.find(x => x.id === eventTicketCreate.eventId)
        var date = eventTicketCreate.eventDate ? moment(eventTicketCreate.eventDate).format(Configs.DATE_FORMAT) : ""
        var schedule = schedules.find(x => x.id === eventTicketCreate.eventScheduleId)
        var time = `${schedule?.startTime.substring(0, 5) || ""} - ${schedule?.endTime.substring(0, 5) || ""}`

        var seats = event?.seats || []
        var ticketClasses = event?.ticketClasses || []

        var quantity = 0
        var amount = 0
        var seatsText = ""
        if (seats.length > 0) {
            var selectedSeats = seats.filter(x => eventTicketCreate.seatIds.includes(x.id || 0))
            quantity = selectedSeats.length

            seatsText = selectedSeats.map(x => `${x.letter}${x.number}`).join(", ")
            amount = selectedSeats.filter(x => x.ticketClass != null).map(x => x.ticketClass).map(x => x.price || 0).reduce((a, b) => a + b, 0)
        } else if (ticketClasses.length > 0) {
            var selectedTicketClasses = ticketClasses.filter(x => eventTicketCreate.ticketClasses.some(y => y.ticketClassId === x.id)).map(x => { return { name: x.name, price: x.price || 0, quantity: eventTicketCreate.ticketClasses.find(y => y.ticketClassId === x.id)?.quantity || 0 } })

            quantity = selectedTicketClasses.filter(x => x.quantity > 0).map(x => x.quantity).reduce((a, b) => a + b, 0)
            seatsText = selectedTicketClasses.map(x => `${x.quantity} ${x.name}`).join(", ")
            amount = selectedTicketClasses.map(x => x.price * x.quantity).reduce((a, b) => a + b, 0)
        }

        return (
            <dl className="ticket-details">
                <dt>{t("eventTicketsPage.event")}:</dt><dd>{event?.name || ""}</dd>
                <dt>{t("eventTicketsPage.date")}:</dt><dd>{date}</dd>
                <dt>{t("eventTicketsPage.time")}:</dt><dd>{time}</dd>
                <dt>{t("eventTicketsPage.quantity")}:</dt><dd><span className="text-danger">{quantity}</span> {seatsText && <>({seatsText})</>}</dd>
                <dt>{t("eventTicketsPage.amount")}:</dt><dd><span className="text-danger">{Utils.formatNumber(amount)}</span></dd>
            </dl>
        )
    }

    //PhoneInput
    const [dialCode, setDialCode] = useState("")
    const handlePhoneInputChange = (value: string, data: {} | CountryData) => {
        let dialCode = (data as CountryData).dialCode
        setDialCode(dialCode)
        setEventTicketCreate({ ...eventTicketCreate, customer: { ...eventTicketCreate.customer, tel: value } })
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} fullscreen>
            <Modal.Header closeButton>
                <Modal.Title>{t("eventTicketCreateModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>} style={{ display: "flex", minHeight: "100%" }}>
                    <div style={{ flex: "1 1 auto", minHeight: "100%", width: "calc(100% - 330px)", paddingRight: "1rem" }}>
                        <Form.Group className="mb-3" controlId="eventId">
                            <Form.Label>{t("eventTicketsPage.event")}</Form.Label>
                            <Form.Select name="eventId" required value={eventTicketCreate.eventId || ""} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, eventId: e.target.value ? parseInt(e.target.value) : null })}>
                                <option value=""></option>
                                {events.map((item: IEvent) => <option key={item.id} value={item.id || 0}>{item.name}</option>)}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredEvent")}</Form.Control.Feedback>
                            <Form.Text>{timeZoneDisplayName}</Form.Text>
                            {zonedDateTime && <Clock value={zonedDateTime}></Clock>}
                        </Form.Group>
                        {dates.data.length > 0 &&
                            <Form.Group className="mb-3" controlId="date">
                                <Form.Label style={{ display: "flex", alignItems: "center" }}>
                                    <span style={{ flex: 1 }}>{t("eventTicketsPage.date")}</span>
                                    <ButtonGroup size="sm">
                                        {dates.hasPrevious && <Button variant="default" className="text-muted" onClick={handlePrevDates}><i className="fas fa-chevron-left"></i> {t("eventTicketCreateModal.prev")}</Button>}
                                        <Button variant="outline-info" onClick={handleCurrentDates}><i className="fas fa-dot-circle"></i></Button>
                                        {dates.hasNext && <Button variant="default" className="text-muted" onClick={handleNextDates}>{t("eventTicketCreateModal.next")} <i className="fas fa-chevron-right"></i></Button>}
                                    </ButtonGroup>
                                </Form.Label>
                                <div style={{ overflow: "auto", padding: "0px 1px 5px 1px" }}>
                                    <ToggleButtonGroup type="radio" name="date" value={eventTicketCreate.eventDate}>
                                        {dates.data.map(date => {
                                            let dayOfMonth = date.getDate()
                                            let dayName = DayNames[date.getDay()] || ""
                                            let monthName = MonthNames[date.getMonth()] || ""

                                            let value = moment(date).format("YYYY-MM-DD")
                                            return <ToggleButton
                                                key={dayOfMonth}
                                                variant="default"
                                                className="date-button btn-flat"
                                                id={`date-${dayOfMonth}`}
                                                value={value}
                                                onChange={() => setEventTicketCreate({ ...eventTicketCreate, eventDate: value })}
                                            >
                                                <span>{monthName}</span>
                                                <span>{dayOfMonth < 10 ? "0" : ""}{dayOfMonth}</span>
                                                <span>{dayName}</span>
                                            </ToggleButton>
                                        })}
                                    </ToggleButtonGroup>
                                </div>
                            </Form.Group>}
                        {schedules.length > 0 &&
                            <Form.Group className="mb-3" controlId="eventScheduleId">
                                <Form.Label style={{ width: "100%" }}>{t("eventTicketsPage.time")}</Form.Label>
                                <div style={{ overflow: "auto", padding: "0px 1px 5px 1px" }}>
                                    <ToggleButtonGroup type="radio" name="eventScheduleId" value={eventTicketCreate.eventScheduleId}>
                                        {schedules.map(schedule => {
                                            return <ToggleButton
                                                key={schedule.id}
                                                variant="default"
                                                className="time-button btn-flat"
                                                id={`time-${schedule.id}`}
                                                value={schedule.id || 0}
                                                onChange={() => setEventTicketCreate({ ...eventTicketCreate, eventScheduleId: schedule.id })}
                                            >
                                                {schedule.startTime.substring(0, 5)} - {schedule.endTime.substring(0, 5)}
                                            </ToggleButton>
                                        })}
                                    </ToggleButtonGroup>
                                </div>
                            </Form.Group>}
                        {seatMatrix.length > 0 ?
                            <Form.Group controlId="seats">
                                <Form.Label style={{ display: "flex", alignItems: "center", marginBottom: 0 }}>
                                    <span style={{ flex: 1 }}>
                                        {t("eventTicketsPage.seats")}
                                        {(orderedSeats.availableQuantity || 0) > 0 && <span className="text-muted"> ({(orderedSeats.availableQuantity || 0) - eventTicketCreate.seatIds.length} {t("eventTicketCreateModal.available")})</span>}
                                    </span>
                                    <span>{t("eventTicketCreateModal.size")}: </span>
                                    <Dropdown align="end">
                                        <Dropdown.Toggle size="sm" variant="default" className="text-muted">
                                            {currentSeatSize}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {SeatSizes.map((size, index) => <Dropdown.Item key={index} active={size === currentSeatSize} onClick={() => setCurrentSeatSize(size)}>{size}</Dropdown.Item>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Label>
                                <div className={`event-seats ${currentSeatSize.toLowerCase()}`}>
                                    <div className="event-seats-inner">
                                        {seatMatrix}
                                    </div>
                                </div>
                            </Form.Group> :
                            <>{eventTicketCreate.eventId && ticketClassInput}</>
                        }
                    </div>
                    <div style={{ flex: "0 0 330px", borderLeft: "dashed 1px #ced4da", minHeight: "100%", paddingLeft: "1rem" }}>
                        <fieldset>
                            <legend>{t("eventTicketDetailsModal.ticketDetails")}</legend>
                            {displayTicketDetails()}
                        </fieldset>
                        <fieldset>
                            <legend>{t("eventTicketDetailsModal.paymentDetails")}</legend>
                            <PaymentServiceSelect required value={eventTicketCreate.paymentService} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, paymentService: e.target.value })}></PaymentServiceSelect>
                            <PaymentStatusSelect required value={eventTicketCreate.paymentStatus} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, paymentStatus: e.target.value })}></PaymentStatusSelect>
                            <Form.Group controlId="note">
                                <Form.Label className="mb-0">{t("eventTicketsPage.note")}</Form.Label>
                                <Form.Control as="textarea" rows={2} type="text" name="note" value={eventTicketCreate.note || ""} onChange={(e) => setEventTicketCreate({ ...eventTicketCreate, note: e.target.value })} />
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <legend>{t("eventTicketDetailsModal.customerDetails")}</legend>
                            <Form.Group className="mb-1" controlId="name">
                                <Form.Label className="mb-0">{t("eventTicketsPage.name")}</Form.Label>
                                <Form.Control type="text" name="name" required={false} value={eventTicketCreate.customer.name || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredName")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="tel">
                                <Form.Label className="mb-0">{t("eventTicketsPage.tel")}</Form.Label>
                                <PhoneInput
                                    country={'vn'}
                                    countryCodeEditable={false}
                                    placeholder=""
                                    searchPlaceholder={t("phoneInput.searchPlaceholder")}
                                    searchNotFound={t("phoneInput.searchNotFound")}
                                    enableSearch={true}
                                    value={eventTicketCreate.customer.tel}
                                    onChange={handlePhoneInputChange}
                                />
                                {/* <Form.Control type="text" name="tel" required={false} value={eventTicketCreate.customer.tel || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredTel")}</Form.Control.Feedback> */}
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="email">
                                <Form.Label className="mb-0">Email</Form.Label>
                                <Form.Control type="email" name="email" required={false} value={eventTicketCreate.customer.email || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredEmail")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="address">
                                <Form.Label className="mb-0">{t("eventTicketsPage.address")}</Form.Label>
                                <Form.Control type="text" name="address" value={eventTicketCreate.customer.address || ""} onChange={(e) => handleCustomerChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="personalId">
                                <Form.Label className="mb-0">{t("eventTicketsPage.personalId")}</Form.Label>
                                <Form.Control type="text" name="personalId" value={eventTicketCreate.customer.personalId || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                {/* <Form.Text>Priority custormer</Form.Text> */}
                            </Form.Group>
                        </fieldset>
                    </div>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {message !== "" && <Alert variant="danger" onClose={() => setMessage("")} dismissible style={{ flex: "1" }}>{message}</Alert>}
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> {t("common.cancel")}</Button>
                <Button variant="info" onClick={handleCreate} disabled={processing}>
                    {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-save"></i>} {t("common.create")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EventTicketCreateModal