import fileDownload from "js-file-download"
import Utils from "../utils/Utils"
import { IPlace } from "./PlaceService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import axiosClientBlob from "./axiosClientBlob"
import { format } from "date-fns/esm"
import { ISortField } from "../components/SortBy"
import { IVoucherType } from "./VoucherTypeService"
import { IUser } from "./UserService"

export const VoucherSortFields: ISortField[] = [
    {
        name: "createdOn",
        description: "Created On"
    },
    {
        name: "activedOn",
        description: "Actived On"
    }
]

export interface IVoucher {
    id: number | null
    code: string
    qrcodeImage: string
    secretKey: string
    placeId: number | null
    voucherTypeId: number | null
    price: number | null
    currency: string
    timeLimit: number | null
    paymentService: string
    note: string
    createdOn: Date | null
    activedOn: Date | null
    expiredOn: Date | null
    isExpired: boolean
    isTest: boolean
    userId: string
    place: IPlace | null
    voucherType: IVoucherType | null
    placeName?: string
    providerId?: number
    creator: IUser | null
}

export interface IVoucherUpdate {
    id: number | null
    code: string
    placeId: number | null
    voucherTypeId: number | null
    paymentService: string
    note: string
    quantity: number | null
    isSameCode: boolean
    isTest: boolean | null
}

export default class VoucherService {
    //Provider
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string, hideTestData: boolean) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Vouchers/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}&sortBy=${sortBy}&sortDirection=${sortDirection}&hideTestData=${hideTestData}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Vouchers/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Vouchers/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, itemData: IVoucherUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, itemData: IVoucherUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Vouchers/${providerId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDF(providerId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${providerId}/GeneratePDF/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDFs(providerId: number, ids: number[]) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${providerId}/GeneratePDF`, ids).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getForPrint(providerId: number, ids: number[]) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/GetForPrint`, ids)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    //Users
    static async filterByUser(providerId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string, hideTestData: boolean) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Vouchers/${providerId}/ListByUser?keyword=${keyword}&sortBy=${sortBy}&sortDirection=${sortDirection}&pageSize=${pageSize}&pageIndex=${pageIndex}&hideTestData=${hideTestData}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Vouchers/${providerId}/GetByIdForUser/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async deleteByUser(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Vouchers/${providerId}/DeleteByUser/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async createByUser(providerId: number, itemData: IVoucherUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/CreateByUser`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateByUser(providerId: number, id: number, itemData: IVoucherUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Vouchers/${providerId}/UpdateByUser/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDFByUser(providerId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${providerId}/GeneratePDFByUser/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDFsByUser(providerId: number, ids: number[]) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${providerId}/GeneratePDFByUser`, ids).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generateInactivePDFByDateForUser(providerId: number, date: string) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${providerId}/GenerateInactivePDFByDateForUser/${date}`, {}).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getForPrintByUser(providerId: number, ids: number[]) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/GetForPrintByUser`, ids)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    //Agency
    static async filterByAgency(agencyId: number, pageIndex: number, pageSize: number, keyword: string, sortBy: string, sortDirection: string, hideTestData: boolean) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Vouchers/${agencyId}/ListByAgency?keyword=${keyword}&sortBy=${sortBy}&sortDirection=${sortDirection}&pageSize=${pageSize}&pageIndex=${pageIndex}&hideTestData=${hideTestData}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getByAgency(agencyId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Vouchers/${agencyId}/GetByIdForAgency/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async deleteByAgency(agencyId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Vouchers/${agencyId}/DeleteByAgency/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async createByAgency(agencyId: number, itemData: IVoucherUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${agencyId}/CreateByAgency`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateByAgency(agencyId: number, id: number, itemData: IVoucherUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Vouchers/${agencyId}/UpdateByAgency/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDFByAgency(agencyId: number, id: number) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${agencyId}/GeneratePDFByAgency/${id}`).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static generatePDFsByAgency(agencyId: number, ids: number[]) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${agencyId}/GeneratePDFByAgency`, ids).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getForPrintByAgency(agencyId: number, ids: number[]) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${agencyId}/GetForPrintByAgency`, ids)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    //Report
    static async dailyActiveMyVouchersReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/DailyActiveMyVouchersReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async dailyActiveVouchersReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/DailyActiveVouchersReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static exportDailyActiveVouchersReport2Excel(providerId: number, startDate: Date, endDate: Date) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${providerId}/ExportDailyActiveVouchersReport2Excel`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            }).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async monthlyActiveVouchersReport(providerId: number, startYear: number, startMonth: number, endYear: number, endMonth: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/MonthlyActiveVouchersReport`, {
                startYear: startYear,
                startMonth: startMonth,
                endYear: endYear,
                endMonth: endMonth
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static exportMonthlyActiveVouchersReport2Excel(providerId: number, startYear: number, startMonth: number, endYear: number, endMonth: number) {
        try {
            axiosClientBlob.post(`/api/Vouchers/${providerId}/ExportMonthlyActiveVouchersReport2Excel`, {
                startYear: startYear,
                startMonth: startMonth,
                endYear: endYear,
                endMonth: endMonth
            }).then(response => {
                const contentDisposition = response.headers["content-disposition"]
                var filename = Utils.getFileNameUTF8FromContentDisposition(contentDisposition)
                fileDownload(response.data, filename)
            }).catch(error => {
                console.error(error)
            })
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async activeVouchersPerHourReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/ActiveVouchersPerHourReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async activeVouchersByDayOfWeekReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/ActiveVouchersByDayOfWeekReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async activeVoucherSellersReport(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/ActiveVoucherSellersReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async activeVoucherProvidersReport(startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/ActiveVoucherProvidersReport`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async monthlyActiveVoucherProvidersReport(startYear: number, startMonth: number, endYear: number, endMonth: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/MonthlyActiveVoucherProvidersReport`, {
                startYear: startYear,
                startMonth: startMonth,
                endYear: endYear,
                endMonth: endMonth
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async dailyActiveVouchersReportByAgency(providerId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${providerId}/DailyActiveVouchersReportByAgency`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async dailyActiveVouchersReportForAgency(agencyId: number, startDate: Date, endDate: Date) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Vouchers/${agencyId}/DailyActiveVouchersReportForAgency`, {
                startDate: format(startDate, "yyyy-MM-dd"),
                endDate: format(endDate, "yyyy-MM-dd")
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}