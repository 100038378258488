import { useState } from "react"
import { Button, Form } from "react-bootstrap"

type ClipboardCopyProps = {
    text: string
}

function ClipboardCopy(props: ClipboardCopyProps) {
    const [isCopied, setIsCopied] = useState(false)

    async function copyTextToClipboard(text: string) {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text)
        } else {
            return document.execCommand("copy", true, text)
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(props.text)
            .then(() => {
                setIsCopied(true)
                setTimeout(() => {
                    setIsCopied(false)
                }, 1500)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            <Form.Control type="text" readOnly value={props.text || ""} />
            <Button size="sm" variant="outline" onClick={handleCopyClick}>
                {isCopied ? <><i className="fas fa-check text-success"></i> Copied!</> : <><i className="far fa-copy"></i> Copy</>}
            </Button>
        </>
    )
}

export default ClipboardCopy