import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface ILocalizationTranslation {
    id: number | null
    localizationId: number | null
    languageId: number
    value: string
    language: ILanguage
}

export interface ILocalization {
    id: number | null
    name: string
    description: string
    inputType: string
    translations: Array<ILocalizationTranslation>
}

export interface ILocalizationTranslationUpdate {
    localizationId: number
    languageId: number
    value: string
}

export default class LocalizationService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Localizations?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Localizations/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Localizations/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: number, itemData: ILocalization) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Localizations/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(itemData: ILocalization) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Localizations/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listByApiKeyId(providerId: number, apiKeyId: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Localizations/${providerId}/ListByApiKeyId/${apiKeyId}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listTranslationByApiKeyId(apiKeyId: string, languageId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Localizations/ListTranslationByApiKeyId/${apiKeyId}/${languageId}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateByApiKeyId(providerId: number, apiKeyId: string, listData: Array<ILocalizationTranslationUpdate>) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Localizations/${providerId}/UpdateByApiKeyId/${apiKeyId}`, listData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}