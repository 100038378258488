import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import useAppContext from "../../hooks/useAppContext"
import DateRangePicker from "../../components/DateRangePicker"
import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import EventTicketService from "../../services/EventTicketService"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import { NavLink } from "react-router-dom"
import RoutePath from "../../constants/RoutePath"
import uuid from "react-uuid"
import { getReportItemText } from "../../constants/ReportItems"

function TicketSellersDetailsDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    const [reportData, setReportData] = useState<{
        createdBy: number,
        userName: string
        fullName: string,
        eventId: number,
        eventName: string,
        ticketClassId: number,
        ticketClassName: string,
        paymentService: string,
        quantity: number,
        amount: number
    }[] | null>(null)

    const GetTicketSellersReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await EventTicketService.ticketSellersDetailsReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetTicketSellersReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [currentEventId, setCurrentEventId] = useState<number | null>(null)
    const [events, setEvents] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentEventId(null)
            setTicketSellersSummary([])

            setEvents(
                reportData
                    .map((x) => { return { id: x.eventId, name: x.eventName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )

            generateTicketSellersSummary(null)
        } else {
            setCurrentEventId(null)
            setEvents(null)
            setTicketSellersSummary([])
        }
    }, [reportData])

    const handleEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentEventId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateTicketSellersSummary(currentEventId)
    }, [currentEventId])

    const [ticketSellersSummary, setTicketSellersSummary] = useState<any[]>([])

    const generateTicketSellersSummary = (eventId: number | null) => {
        const list = [] as any[]

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            var sellers = data
                .map(x => { return { createdBy: x.createdBy, userName: x.userName, fullName: x.fullName } })
                .filter((value, index, self) => self.findIndex(x => x.createdBy === value.createdBy) === index)

            // sellers
            var sellerIndex = 0
            sellers.forEach(seller => {
                var showSeller = true
                var sellerItems = data.filter(x => x.createdBy === seller.createdBy)

                var events = sellerItems
                    .map(x => { return { eventId: x.eventId, eventName: x.eventName } })
                    .filter((value, index, self) => self.findIndex(x => x.eventId === value.eventId) === index)

                // events
                // var eventIndex = 0
                events.forEach(event => {
                    var showEvent = true
                    var eventItems = sellerItems.filter(x => x.eventId === event.eventId)

                    var ticketClasses = eventItems
                        .map(x => { return { ticketClassId: x.ticketClassId, ticketClassName: x.ticketClassName } })
                        .filter((value, index, self) => self.findIndex(x => x.ticketClassId === value.ticketClassId) === index)

                    // ticketClasses
                    // var ticketClassIndex = 0
                    ticketClasses.forEach(ticketClass => {
                        var showTicketClass = true
                        var ticketClassItems = eventItems.filter(x => x.ticketClassId === ticketClass.ticketClassId)

                        var paymentServices = ticketClassItems.map((x) => x.paymentService).filter((value, index, self) => self.indexOf(value) === index).sort()

                        // paymentServices
                        paymentServices.forEach(paymentService => {
                            var paymentServiceItems = ticketClassItems.filter(x => x.paymentService === paymentService)

                            var quantity = paymentServiceItems.map(x => x.quantity).reduce((a, b) => a + b, 0)
                            var amount = paymentServiceItems.map(x => x.amount).reduce((a, b) => a + b, 0)

                            list.push(<tr key={uuid()} style={{ backgroundColor: sellerIndex % 2 === 0 ? "rgba(0,0,0,.05)" : "#fff" }}>
                                <td className={showSeller ? "border-top" : ""}>{showSeller && sellerIndex + 1}</td>
                                <td className={showSeller ? "border-top" : ""}>{showSeller && seller.fullName}</td>
                                <td className={showEvent ? "border-top" : ""}>{showEvent && event.eventName}</td>
                                <td className={showTicketClass ? "border-top" : ""} style={{ textAlign: "center" }}>{showTicketClass && ticketClass.ticketClassName}</td>
                                <td className="border-top" style={{ textAlign: "center" }}>{paymentService}</td>
                                <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(quantity)}</td>
                                <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(amount)}</td>
                            </tr>)

                            showTicketClass = false
                            showEvent = false
                            showSeller = false
                        })
                        // ticketClassIndex++
                    })
                    // eventIndex++
                })
                sellerIndex++
            })

            //Total
            // list.push({
            //     createdBy: null,
            //     userName: "",
            //     fullName: "Total",
            //     quantity: data.map(x => x.quantity).reduce((a, b) => a + b, 0),
            //     amount: data.map(x => x.amount).reduce((a, b) => a + b, 0)
            // })

            setTicketSellersSummary(list)
        } else {
            setTicketSellersSummary([])
        }
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>{getReportItemText(Permissions.AllEventTickets.TicketSellersReport)}</h1>
                            <DashboardDropdown reportName={Permissions.AllEventTickets.TicketSellersReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentEventId || ""} onChange={handleEventChange}>
                                        <option value="">All events</option>
                                        {events && events.map(event => {
                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                        })}
                                    </Form.Select>

                                    <div className="card-tools">
                                        <NavLink to={RoutePath.PROVIDER.TICKET_SELLERS_DASHBOARD} style={{ whiteSpace: "nowrap" }} className="btn btn-light btn-sm"><i className="fas fa-square"></i> Summary</NavLink>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Table responsive hover className="table-ticket-sellers-details">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>#</th>
                                                <th>Seller</th>
                                                <th>Event</th>
                                                <th style={{ textAlign: "center" }}>Ticket Class</th>
                                                <th style={{ textAlign: "center" }}>Payment</th>
                                                <th style={{ textAlign: "right" }}>Volume</th>
                                                <th style={{ textAlign: "right" }}>Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketSellersSummary}
                                        </tbody>
                                        {/* <tfoot>
                                            {ticketSellersSummary.filter(x => x.createdBy === null).map((item, index) => <tr key={index}>
                                                <th></th>
                                                <th>{item.fullName}</th>
                                                <th style={{ textAlign: "right" }}>{Utils.formatNumber(item.quantity)}</th>
                                                <th style={{ textAlign: "right" }}>{Utils.formatNumber(item.amount)}</th>
                                            </tr>)}
                                        </tfoot> */}
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default TicketSellersDetailsDashboard