export type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
    }
    : {
        type: Key
        payload: M[Key]
    }
}

export enum ActionTypes {
    CHANGE_PROVIDER = "CHANGE_PROVIDER",
    CHANGE_AGENCY = "CHANGE_AGENCY"
}