import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import AccountService from "../services/AccountService"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"

function Profile() {
    const { t } = useTranslation("global")
    const [formData, setFormData] = useState({
        email: "",
        fullName: "",
        avatar: ""
    })

    const [message, setMessage] = useState("")
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const fileRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        loadProfile()
    }, [])

    const loadProfile = async () => {
        const res = await AccountService.profile()
        if (res?.isSuccess) {
            setFormData(res.data)
        } else {
            setMessage(res?.message)
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!isLoading) {
            setIsLoading(true)
            try {
                if (formRef.current?.checkValidity()) {
                    const res = await AccountService.updateProfile(formData.email, formData.fullName)

                    if (res?.isSuccess) {
                        setMessage(res.message)
                    } else {
                        setMessage(res?.message)
                    }
                } else {
                    setValidatedForm(true)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
    }

    const handleImageChange = async (e: SyntheticEvent) => {
        if (fileRef.current?.files && fileRef.current.files[0]) {
            const frmData = new FormData()
            frmData.append("avatar", fileRef.current.files[0])

            const res = await AccountService.changeAvatar(frmData)

            if (res?.isSuccess) {
                setFormData({ ...formData, avatar: res.data })
            } else {
                setMessage(res?.message)
            }
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return (
        <div className="page-center">
            <div className="avatar-wrapper">
                <img className="profile-pic" src={formData.avatar} alt={formData.fullName} />
                <div className="upload-button" onClick={() => { fileRef.current?.click() }}>
                    <i className="fas fa-cloud-upload-alt" aria-hidden="true"></i>
                </div>
                <input type="file" className="file-upload" accept="image/*" onChange={handleImageChange} ref={fileRef} />
            </div>
            <div className="card max-w-400">
                <div className="card-body">
                    <p className="login-box-msg">{t("profilePage.title")}</p>
                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="fullName">
                            <Form.Label>{t("registerPage.fullName")}</Form.Label>
                            <Form.Control type="text" name="fullName" required value={formData.fullName || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">{t("registerPage.requiredFullName")}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" name="email" required value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">{t("registerPage.requiredEmail")}</Form.Control.Feedback>
                        </Form.Group>
                        <div className="text-center">
                            <Button variant="primary" onClick={handleSave}>
                                {isLoading ? <><Spinner animation="border" size="sm" /> {t("profilePage.save")}</> : <>{t("profilePage.save")}</>}
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Profile