import { ChangeEvent, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import Dropdown from "react-bootstrap/Dropdown"
import useGlobalContext from "../hooks/useGlobalContext"
import { ILanguage } from "../services/LanguageService"
import Configs from "../Configs"

export type LanguageActionTypes = "Add" | "Remove"

interface IAddOrRemoveLanguagesProps {
    selectedLanguages: Array<ILanguage>,
    onChange: (id: number, action: LanguageActionTypes) => void
}

function AddOrRemoveLanguages(props: IAddOrRemoveLanguagesProps) {
    const { languages } = useGlobalContext()
    const [selectedItem, setSelectedItem] = useState({
        id: 0,
        action: "Add" as LanguageActionTypes
    })

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const id = parseInt(e.target.value)
        const action = e.target.checked ? "Add" : "Remove"

        if (action === "Add") {
            props.onChange(id, action)
        } else {
            setSelectedItem({
                id,
                action
            })
            setDisplayConfirmationModal(true)
        }
    }

    const handleRemove = () => {
        if (selectedItem.id > 0) {
            props.onChange(selectedItem.id, selectedItem.action)
            setDisplayConfirmationModal(false)
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <Dropdown className="float-right" align="end">
                        <Dropdown.Toggle size="sm" variant="default" style={{ display: "flex", alignItems: "center", color: "#17a2b8" }}>
                            <svg width="20" height="20" fill="#17a2b8" viewBox="0 0 24 24" focusable="false" style={{ marginRight: "3px" }}>
                                <path d="M12.87 15.07l-2.54-2.51.03-.03A17.52 17.52 0 0 0 14.07 6H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"></path>
                            </svg>
                            Add/Remove Languages
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {
                                languages.map(x => {
                                    const checked = props.selectedLanguages.find(d => d.id === x.id) !== undefined

                                    return (
                                        <Form.Check
                                            key={x.id}
                                            type="checkbox"
                                            label={<><img src={`${Configs.API_BASE_URL}${x.flag}`} style={{ height: "20px" }} alt={x.name} /> {x.name}</>}
                                            name={`global-lang-${x.id}`}
                                            id={`global-lang-${x.id}`}
                                            value={x.id || 0}
                                            className="mx-2"
                                            checked={checked}
                                            onChange={handleChange}
                                        />
                                    )
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>

            <Modal show={displayConfirmationModal} onHide={() => setDisplayConfirmationModal(false)} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Remove {languages.find(x => x.id === selectedItem.id)?.name || ""}!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to remove this language?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setDisplayConfirmationModal(false)}>Cancel</Button>
                    <Button variant="danger" onClick={handleRemove}>Remove</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddOrRemoveLanguages