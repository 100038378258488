import { addDays } from "date-fns/esm";
import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap"
import DateRangePicker from "../../components/DateRangePicker";
import EventTicketService from "../../services/EventTicketService";
import useAppContext from "../../hooks/useAppContext";
import { DatasetChart, LineChart } from "../../components/ChartJS";
import Utils from "../../utils/Utils";
import Configs from "../../Configs";
import { useTranslation } from "react-i18next";

function MyEventTicketDashboardModal(props: {
    show: boolean,
    onClose: () => void
}) {
    const { t } = useTranslation("global")
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        if (props.show) {
            DailyMyEventTicketsReport(dateReport.startDate, dateReport.endDate)
        }
    }, [props.show, dateReport])

    const DailyMyEventTicketsReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await EventTicketService.dailyMyEventTicketsReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                generateEventTicketQuantityChartData(res.data)
                generateEventTicketAmountChartData(res.data)
            } else {
                console.log(res.message);
            }
        }
    }

    const [eventTicketQuantityChartData, setEventTicketQuantityChartData] = useState({
        title: `${t("myEventTicketDashboardModal.ticketSalesVolume")} (${t("myEventTicketDashboardModal.total")})`,
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateEventTicketQuantityChartData = (source: any) => {
        const data = source as {
            date: Date,
            quantity: number,
            amount: number
        }[]

        const labels = [] as string[]
        const dataset = {
            label: `${t("myEventTicketDashboardModal.volume")}`,
            data: []
        } as DatasetChart

        data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).forEach(item => {
            labels.push(Utils.dateToString(item.date, Configs.DATE_FORMAT))
            dataset.data.push(item.quantity)
        })

        var sum = dataset.data.reduce((a, b) => a + b, 0)
        var title = `${t("myEventTicketDashboardModal.ticketSalesVolume")} (${t("myEventTicketDashboardModal.total")}: ${Utils.formatNumber(sum)})`

        setEventTicketQuantityChartData({ ...eventTicketQuantityChartData, title: title, labels: labels, datasets: [dataset] })
    }

    const [eventTicketAmountChartData, setEventTicketAmountChartData] = useState({
        title: `${t("myEventTicketDashboardModal.ticketSalesRevenue")} (${t("myEventTicketDashboardModal.total")})`,
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateEventTicketAmountChartData = (source: any) => {
        const data = source as {
            date: Date,
            quantity: number,
            amount: number
        }[]

        const labels = [] as string[]
        const dataset = {
            label: `${t("myEventTicketDashboardModal.revenue")}`,
            data: []
        } as DatasetChart

        data.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).forEach(item => {
            labels.push(Utils.dateToString(item.date, Configs.DATE_FORMAT))
            dataset.data.push(item.amount)
        })

        var sum = dataset.data.reduce((a, b) => a + b, 0)
        var title = `${t("myEventTicketDashboardModal.ticketSalesRevenue")} (${t("myEventTicketDashboardModal.total")}: ${Utils.formatNumber(sum)})`

        setEventTicketAmountChartData({ ...eventTicketAmountChartData, title: title, labels: labels, datasets: [dataset] })
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="xl">
            <Modal.Header closeButton className="p-2">
                <Modal.Title><i className="fas fa-chart-line"></i> {t("myEventTicketDashboardModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Header className="p-2">
                <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
            </Modal.Header>
            <Modal.Body className="p-2">
                <Row>
                    <Col lg={12}>
                        <LineChart title={eventTicketAmountChartData.title} labels={eventTicketAmountChartData.labels} datasets={eventTicketAmountChartData.datasets}></LineChart>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <LineChart title={eventTicketQuantityChartData.title} labels={eventTicketQuantityChartData.labels} datasets={eventTicketQuantityChartData.datasets}></LineChart>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default MyEventTicketDashboardModal