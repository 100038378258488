import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export default class PayGateService {
    static async getQRPaymentUrl(providerId: number, id: number, returnUrl: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PayGate/${providerId}/GetQRPaymentUrl/${id}?returnUrl=${returnUrl}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async checkPaymentResponse(providerId: number, id: number, searchParams: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PayGate/${providerId}/CheckPaymentResponse/${id}${searchParams}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}
