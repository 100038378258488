import { IProvider } from "./ProviderService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IProviderPartner {
    providerId: number,
    agencyId: number,
    status: number,
    requestedTime: Date,
    provider: IProvider
}

export default class ProviderPartnerService {
    static async filter(agencyId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderPartners/${agencyId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(agencyId: number, providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderPartners/${agencyId}/${providerId}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async add(agencyId: number, providerId: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/ProviderPartners/${agencyId}/Add/${providerId}`, {})

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async cancel(agencyId: number, providerId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ProviderPartners/${agencyId}/Cancel/${providerId}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async remove(agencyId: number, providerId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ProviderPartners/${agencyId}/Remove/${providerId}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async accept(agencyId: number, providerId: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/ProviderPartners/${agencyId}/Accept/${providerId}`, {})

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async reject(agencyId: number, providerId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ProviderPartners/${agencyId}/Reject/${providerId}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}