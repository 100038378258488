import { IUser } from "./UserService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IAgency {
    id: number | null
    name: string
    address: string
    tel: string
    email: string
    isUsed: boolean
    users: Array<IUser>
}

export interface IAgencyProfile {
    id: number | null
    name: string
    address: string
    tel: string
    email: string
}

export default class AgencyService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Agencies?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Agencies/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Agencies/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: number, itemData: IAgency) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Agencies/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(itemData: IAgency) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Agencies/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Agencies/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listByUser() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Agencies/ListByUser")

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getByUser(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Agencies/GetByUser/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateByUser(id: number, itemData: IAgencyProfile) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Agencies/UpdateByUser/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async search(keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Agencies/Search?keyword=${keyword}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}