import { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import EventTicketService, { IEventTicket } from "../../services/EventTicketService"
import Utils from "../../utils/Utils"
import moment from "moment"
import Configs from "../../Configs"
import QRCodeModal from "../../components/QRCodeModal"
import QRCodeService from "../../services/QRCodeService"
import PaymentStatusBadge from "../../components/PaymentStatusBadge"
import { useTranslation } from "react-i18next"

interface IEventTicketDetailsModalProps {
    providerId: number | null
    eventTicketId: number | null
    show: boolean
    onClose: () => void
}

function EventTicketDetailsModal(props: IEventTicketDetailsModalProps) {
    const { t } = useTranslation("global")
    const [eventTicket, setEventTicket] = useState<IEventTicket>()

    const GetEventTicket = async () => {
        if (props.providerId && props.eventTicketId && props.show) {
            const res = await EventTicketService.get(props.providerId, props.eventTicketId)

            if (res?.isSuccess) {
                const data: IEventTicket = res.data

                setEventTicket(data)
            } else {
                console.log(res?.message)
                props.onClose()
            }
        } else {
            props.onClose()
        }
    }

    useEffect(() => {
        GetEventTicket()
    }, [props.providerId, props.show])

    // QRCode
    const [selectedText, setSelectedText] = useState("")
    const [qrCode, setQRCode] = useState<{ image: string, code: string, caption?: Array<string> }>({ image: "", code: "", caption: [] })
    const [showQRCodeModal, setShowQRCodeModal] = useState(false)
    const handleCloseQRCodeModal = () => {
        setShowQRCodeModal(false)
        setQRCode({ image: "", code: "", caption: [] })
        setSelectedText("")
    }

    const handleShowQRCodeModal = (text: string) => {
        if (text) {
            const res = QRCodeService.generate(text)

            res.then(data => {
                if (data?.isSuccess) {
                    setQRCode({ image: data.data.image, code: text, caption: [] })
                    setSelectedText(text)
                    setShowQRCodeModal(true)
                } else {
                    console.log(data?.message)
                }
            })
        }
    }

    const handleDownloadQRCode = () => {
        if (selectedText) {
            QRCodeService.download(selectedText)
        }
    }

    return (<>
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t("eventTicketDetailsModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {eventTicket && <>
                    <fieldset>
                        <legend>{t("eventTicketDetailsModal.ticketDetails")}</legend>
                        <dl className="ticket-details">
                            <dt>{t("eventTicketsPage.event")}:</dt><dd>{eventTicket.event?.name || ""}</dd>
                            <dt>{t("eventTicketsPage.date")}:</dt><dd>{eventTicket.eventDate ? moment(eventTicket.eventDate).format(Configs.DATE_FORMAT) : ""}</dd>
                            <dt>{t("eventTicketsPage.time")}:</dt><dd>{eventTicket.eventSchedule?.startTime.substring(0, 5)} - {eventTicket.eventSchedule?.endTime.substring(0, 5)}</dd>
                            <dt>{t("eventTicketsPage.amount")}:</dt><dd>{Utils.formatNumber(eventTicket.totalPrice || 0)} {eventTicket.currency}</dd>
                        </dl>
                        <Table responsive bordered size="sm">
                            <thead>
                                <tr>
                                    <th>{t("eventTicketsPage.code")}</th>
                                    <th>{t("eventTicketsPage.ticketClass")}/{t("eventTicketsPage.seat")}</th>
                                    <th>{t("eventTicketsPage.price")}</th>
                                    <th>{t("eventTicketsPage.status")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {eventTicket.details?.map(x => <tr key={x.id}>
                                    <td>
                                        <Button variant="default" size="sm" onClick={() => handleShowQRCodeModal(x.code)}>
                                            <i className="fas fa-qrcode text-info"></i>
                                        </Button>
                                        {x.code || ""}
                                    </td>
                                    <td>{x.ticketClass?.name}{x.seat ? "/" : ""}{x.seat?.letter || ""}{x.seat?.number || ""}</td>
                                    <td>{Utils.formatNumber(x.price || 0)}</td>
                                    <td className="text-primary">
                                        {x.usedOn && <><span className="text-muted">{t("eventTicketsPage.usedOn")}:</span> {Utils.dateToString(x.usedOn, Configs.DATE_TIME_FORMAT)}</>}
                                        {x.checker && <><br /><span className="text-muted">{t("eventTicketsPage.checkedBy")}:</span> {x.checker.userName}</>}
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </fieldset>
                    <fieldset>
                        <legend>{t("eventTicketDetailsModal.paymentDetails")}</legend>
                        <dl className="ticket-details dt-200">
                            <dt>{t("eventTicketsPage.paymentService")}:</dt><dd>{eventTicket.paymentService && t(`paymentService.${eventTicket.paymentService}`)}</dd>
                            <dt>{t("eventTicketsPage.paymentStatus")}:</dt><dd><PaymentStatusBadge value={eventTicket.paymentStatus || ""}></PaymentStatusBadge></dd>
                            <dt>{t("eventTicketsPage.note")}:</dt><dd>{eventTicket.note || ""}</dd>
                        </dl>
                    </fieldset>
                    <fieldset>
                        <legend>{t("eventTicketDetailsModal.customerDetails")}</legend>
                        <dl className="ticket-details">
                            <dt>{t("eventTicketsPage.name")}:</dt><dd>{eventTicket.customer?.name || ""}</dd>
                            <dt>{t("eventTicketsPage.tel")}:</dt><dd>{eventTicket.customer?.formattedTel || ""}</dd>
                            <dt>Email:</dt><dd>{eventTicket.customer?.email || ""}</dd>
                            <dt>{t("eventTicketsPage.address")}:</dt><dd>{eventTicket.customer?.address || ""}</dd>
                            <dt>{t("eventTicketsPage.personalId")}:</dt><dd>{eventTicket.customer?.personalId || ""}</dd>
                        </dl>
                    </fieldset>
                    <small className="text-muted mt-1" style={{ display: "block", textAlign: "right", fontStyle: "italic" }}>{t("eventTicketsPage.createdOn")}: {Utils.dateToString(eventTicket.createdOn, Configs.DATE_TIME_FORMAT)}
                        {eventTicket.creator && <><br />{t("eventTicketsPage.createdBy")}: {eventTicket.creator?.userName || ""}</>}</small>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> {t("common.close")}</Button>
            </Modal.Footer>
        </Modal>
        <QRCodeModal image={qrCode.image} code={qrCode.code} caption={qrCode.caption} show={showQRCodeModal} onClose={handleCloseQRCodeModal} onDownload={handleDownloadQRCode}></QRCodeModal>
    </>)
}

export default EventTicketDetailsModal