import { Form } from "react-bootstrap"

interface IDaysOfWeekCheckboxesProps {
    index: number,
    value: Array<string> | null
    onChange: (e: any, index: number) => void
}

export const DayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
export const DayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
export const MonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
export const MonthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

function DaysOfWeekCheckboxes(props: IDaysOfWeekCheckboxesProps) {
    return (
        <>
            {DayNames.map((name, index) => {
                const checked = (props.value && props.value.includes(name)) || false

                return <Form.Check
                    key={index}
                    id={`day_${index}_${props.index}`}
                    type="checkbox"
                    label={name}
                    checked={checked}
                    value={name}
                    className="text-dark"
                    onChange={e => props.onChange(e, props.index)}
                />
            })}
        </>
    )
}

export default DaysOfWeekCheckboxes