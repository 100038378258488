import { useTranslation } from "react-i18next"
import ContentHeader from "../components/ContentHeader"
import ContentBody from "../components/ContentBody"
import { Card, Col, Container, Row, Table } from "react-bootstrap"

function Download() {
    const { t, i18n } = useTranslation("global")

    return (
        <>
            <ContentHeader title={t("userNavbar.download")} />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    <Table striped bordered hover responsive>
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th style={{ width: "100px", textAlign: "center" }}>OS</th>
                                                <th style={{ width: "100px" }}>File Size</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Download</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Ticket Checker App</td>
                                                <td className="text-center">Desktop</td>
                                                <td>29.3 MB</td>
                                                <td className="text-center">
                                                    <a href="/files/TicketChecker-Desktop.zip" target="_blank"><i className="fas fa-cloud-download-alt text-success"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ticket Checker App</td>
                                                <td className="text-center">Android</td>
                                                <td>51.8 MB</td>
                                                <td className="text-center">
                                                    <a href="/files/TicketChecker-Android.apk" target="_blank"><i className="fas fa-cloud-download-alt text-success"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ticket Checker App (TestFlight)</td>
                                                <td className="text-center">iOS</td>
                                                <td></td>
                                                <td className="text-center">
                                                    <a href="https://testflight.apple.com/join/Vzq1iAMh" target="_blank"><i className="fas fa-cloud-download-alt text-success"></i></a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Ticket Checker User Manual (PDF)</td>
                                                <td className="text-center">All</td>
                                                <td>531 KB</td>
                                                <td className="text-center">
                                                    <a href="/files/TicketChecker-User-Manual.pdf" target="_blank"><i className="fas fa-cloud-download-alt text-success"></i></a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default Download