import { Chart, ArcElement, CategoryScale, BarElement, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js"
import { CSSProperties } from "react"
import { Chart as Chart2, Bar, Line, Pie } from "react-chartjs-2"

const ChartColors = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0"]

const chartStyle: CSSProperties = {
    maxHeight: "400px"
}

const getChartColor = (index: number) => {
    if (index >= ChartColors.length) {
        index = 0
    }

    return ChartColors[index]
}

export type DatasetChart = {
    label: string
    data: number[]
}

export type ChartProps = {
    title?: string
    labels: string[]
    datasets: DatasetChart[]
}

export function PieChart(props: ChartProps) {
    Chart.register(ArcElement, Tooltip, Legend)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: "top" as const
            },
            title: {
                display: props.title ? true : false,
                text: props.title || ""
            },
            datalabels: {
                formatter: (val: number, context: any) => `${((val * 100) / context.chart.data.datasets[context.datasetIndex].data.reduce((a: number, b: number) => a + b, 0)).toFixed(2)}%`
            },
            tooltip: {
                callbacks: {
                    label: (ttItem: any) => `${((ttItem.parsed * 100) / ttItem.dataset.data.reduce((a: number, b: number) => a + b, 0)).toFixed(2)}%`
                }
            }
        }
    }

    const data = {
        labels: props.labels,
        datasets: props.datasets.map(ds => {
            const backgroundColor = ChartColors.slice(0, ds.data.length)

            return { ...ds, backgroundColor: backgroundColor, borderWidth: 1 }
        })
    }

    return (
        <Pie options={options} data={data} className="chartjs-box" style={chartStyle} />
    )
}

export function LineChart(props: ChartProps) {
    Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: props.datasets.length > 1 ? true : false,
                position: "top" as const
            },
            title: {
                display: props.title ? true : false,
                text: props.title || ""
            }
        },
    }

    const data = {
        labels: props.labels,
        datasets: props.datasets.map((ds, index) => {
            const backgroundColor = getChartColor(index)
            const borderColor = getChartColor(index)

            return { ...ds, backgroundColor: backgroundColor, borderColor: borderColor }
        }),
    }

    return (
        <Line options={options} data={data} className="chartjs-box" style={chartStyle} />
    )
}

export function BarChart(props: ChartProps) {
    Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: props.datasets.length > 1 ? true : false,
                position: "top" as const
            },
            title: {
                display: props.title ? true : false,
                text: props.title || ""
            }
        }
    }

    const data = {
        labels: props.labels,
        datasets: props.datasets.map((ds, index) => {
            const backgroundColor = getChartColor(index)
            const borderColor = getChartColor(index)

            return { ...ds, backgroundColor: backgroundColor, borderColor: borderColor, barThickness: 30 }
        }),
    }

    return (
        <Bar options={options} data={data} className="chartjs-box" style={chartStyle} />
    )
}

export function BarWithTotalLineChart(props: ChartProps) {
    Chart.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: props.datasets.length > 1 ? true : false,
                position: "top" as const
            },
            title: {
                display: props.title ? true : false,
                text: props.title || ""
            }
        },
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }

    var datasets = props.datasets.map(x => x)
    var total: number[] = []
    datasets.forEach(ds => {
        ds.data.forEach((val, i) => {
            if (total[i] === undefined) {
                total.push(val)
            } else {
                total[i] += val
            }
        })
    })

    datasets.push({
        label: "Total",
        data: total
    })

    const data = {
        labels: props.labels,
        datasets: datasets.map((ds, index) => {
            const backgroundColor = getChartColor(index)
            const borderColor = getChartColor(index)

            if (index === datasets.length - 1) {
                return {
                    ...ds, backgroundColor: backgroundColor, borderColor: borderColor, barThickness: 30, type: "line" as const,
                    steppedLine: true
                }
            } else {
                return { ...ds, backgroundColor: backgroundColor, borderColor: borderColor, barThickness: 30, type: "bar" as const }
            }
        }),
    }

    return (
        <Chart2 type="bar" data={data} className="chartjs-box" options={options} style={chartStyle} />
    )
}