import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Alert, Button, Card, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import Configs from "../../Configs"
import RoutePath from "../../constants/RoutePath"
import useAppContext from "../../hooks/useAppContext"
import ProviderService, { IProviderProfile } from "../../services/ProviderService"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"
import ProviderPhotos from "./ProviderPhotos"

function ProviderInfo() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()
    const navigate = useNavigate()

    const initFormData: IProviderProfile = {
        id: null,
        name: "",
        address: "",
        email: "",
        description: "",
        about: "",
        logo: "",
        banner: ""
    }

    const [logoFile, setLogoFile] = useState(null as any)
    const [bannerFile, setBannerFile] = useState(null as any)
    const [message, setMessage] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)

    useEffect(() => {
        if (state.currentProviderId) {
            loadProvider(state.currentProviderId)
        }
        setLogoFile(null)
        setBannerFile(null)
    }, [state.currentProviderId])

    const loadProvider = async (id: number) => {
        const res = await ProviderService.getByUser(id)

        if (res?.isSuccess && res.data) {
            setFormData(res.data)
        } else {
            setFormData(initFormData)
            setMessage(res?.message)
            navigate(RoutePath.UNAUTHORIZED, { replace: true })
        }
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleLogoFileControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setLogoFile(e.target.files[0])
        } else {
            setLogoFile(null)
        }
    }

    const handleBannerFileControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setBannerFile(e.target.files[0])
        } else {
            setBannerFile(null)
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formData.id) {
            var frmData = new FormData()
            if (logoFile !== null) {
                frmData.append("Logo", logoFile)
            }

            if (bannerFile !== null) {
                frmData.append("Banner", bannerFile)
            }

            frmData.append("Name", formData.name || "")
            frmData.append("Address", formData.address || "")
            frmData.append("Email", formData.email || "")
            frmData.append("Description", formData.description || "")
            frmData.append("About", formData.about || "")

            if (formRef.current?.checkValidity()) {
                try {
                    const res = await ProviderService.updateByUser(formData.id, frmData)
                    if (res?.isSuccess && res.data) {
                        setFormData(res.data)
                    }

                    setMessage(res?.message)
                } catch (error) {
                    console.log(error)
                }
            } else {
                setValidatedForm(true)
            }
        }
    }

    return (
        <>
            <ContentHeader title="Provider Info" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                        <Row>
                                            <Col lg={6}>
                                                <Tabs defaultActiveKey="logo">
                                                    <Tab eventKey="logo" title="Logo">
                                                        <Form.Group controlId="logoFile">
                                                            <Card className="m-0 border-0 shadow-0">
                                                                {formData.logo && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.logo}`} style={{ maxWidth: "200px", margin: "auto" }} />}
                                                                <Card.Body className="p-2">
                                                                    <Form.Control type="file" name="logo" onChange={handleLogoFileControlChange} accept={Configs.IMAGE_EXTENSION} />
                                                                </Card.Body>
                                                            </Card>
                                                        </Form.Group>
                                                    </Tab>
                                                    <Tab eventKey="banner" title="Banner">
                                                        <Form.Group controlId="banner">
                                                            <Card className="m-0 border-0 shadow-0">
                                                                {formData.banner && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.banner}`} style={{ maxWidth: "500px", margin: "auto" }} />}
                                                                <Card.Body className="p-2">
                                                                    <Form.Control type="file" name="banner" onChange={handleBannerFileControlChange} accept={Configs.IMAGE_EXTENSION} />
                                                                </Card.Body>
                                                            </Card>
                                                        </Form.Group>
                                                    </Tab>
                                                    {hasPermission(Permissions.ProviderPhotos.View) &&
                                                        <Tab eventKey="photos" title="Photos">
                                                            <ProviderPhotos providerId={state.currentProviderId} />
                                                        </Tab>
                                                    }
                                                </Tabs>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group className="mb-3" controlId="name">
                                                    <Form.Label>Name</Form.Label>
                                                    <Form.Control type="text" name="name" required value={formData.name || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                    <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="address">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control type="text" name="address" value={formData.address || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" name="email" value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="description">
                                                    <Form.Label>Description</Form.Label>
                                                    <Form.Control type="text" name="description" value={formData.description || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="about">
                                                    <Form.Label>About</Form.Label>
                                                    <Form.Control as="textarea" rows={7} name="about" value={formData.about || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    {hasPermission(Permissions.Providers.EditProfile) &&
                                        <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                                    }
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default ProviderInfo