import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AuthProvider } from '../context/AuthContext'
import TopNavLayout from '../layouts/TopNavLayout'
import SidebarLayout from '../layouts/SidebarLayout'
import ChangePassword from '../pages/ChangePassword'
import ForgotPassword from '../pages/ForgotPassword'
import Home from '../pages/Home'
import Login from '../pages/Login'
import Page404 from '../pages/Page404'
import Profile from '../pages/Profile'
import RecoverPassword from '../pages/RecoverPassword'
import Register from '../pages/Register'
import RequireAuth from './RequireAuth'
import RoutePath from '../constants/RoutePath'
import Users from '../pages/admin/Users'
import Roles from '../pages/admin/Roles'
import Regions from '../pages/admin/Regions'
import Unauthorized from '../pages/Unauthorized'
import Languages from '../pages/admin/Languages'
import Providers from '../pages/admin/Providers'
import Welcome from '../pages/Welcome'
import ProviderInfo from '../pages/provider/ProviderInfo'
import Places from '../pages/provider/Places'
import ApiKeys from '../pages/provider/ApiKeys'
import Vouchers from '../pages/provider/Vouchers'
import Localizations from '../pages/admin/Localizations'
import ErrorLogs from '../pages/admin/ErrorLogs'
import ProviderUsers from '../pages/provider/ProviderUsers'
import ProviderRoles from '../pages/provider/ProviderRoles'
import TicketTemplates from '../pages/provider/TicketTemplates'
import MyVouchers from '../pages/provider/MyVouchers'
import PlaceTypes from '../pages/admin/PlaceTypes'
import ApiLogs from '../pages/admin/ApiLogs'
import DailyVoucherDashboard from '../pages/provider/DailyVoucherDashboard'
import Combos from '../pages/provider/Combos'
import Permissions from '../constants/Permissions'
import AppLogs from '../pages/provider/AppLogs'
import Agencies from '../pages/admin/Agencies'
import AgencyVouchers from '../pages/agency/AgencyVouchers'
import AgencyPartners from '../pages/provider/AgencyPartners'
import ProviderPartners from '../pages/agency/ProviderPartners'
import AgencyVoucherDashboard from '../pages/agency/AgencyVoucherDashboard'
import AgencyDashboard from '../pages/provider/AgencyDashboard'
import AgencyInfo from '../pages/agency/AgencyInfo'
import AuditLogs from '../pages/admin/AuditLogs'
import Events from '../pages/provider/Events'
import EventTickets from '../pages/provider/EventTickets'
import VoucherOverTimeDashboard from '../pages/provider/VoucherOverTimeDashboard'
import MonthlyVoucherDashboard from '../pages/provider/MonthlyVoucherDashboard'
import MyEventTickets from '../pages/provider/MyEventTickets'
import DailyEventTicketDashboard from '../pages/provider/DailyEventTicketDashboard'
import MonthlyEventTicketDashboard from '../pages/provider/MonthlyEventTicketDashboard'
import Credentials from '../pages/admin/Credentials'
import FileUpload from '../pages/FileUpload'
import PaymentTransactionLogs from '../pages/provider/PaymentTransactionLogs'
import EventCategories from '../pages/admin/EventCategories'
import CheckOut from '../pages/provider/CheckOut'
import TicketSellersDashboard from '../pages/provider/TicketSellersDashboard'
import TicketClassesDashboard from '../pages/provider/TicketClassesDashboard'
import IssueInvoiceLogs from '../pages/provider/IssueInvoiceLogs'
import MyEventTicketsSold from '../pages/provider/MyEventTicketsSold'
import EventTicketsSold from '../pages/provider/EventTicketsSold'
import InvitationCodesDashboard from '../pages/provider/InvitationCodesDashboard'
import Download from '../pages/Download'
import TicketSellersDetailsDashboard from '../pages/provider/TicketSellersDetailsDashboard'
import VoucherSellersDashboard from '../pages/provider/VoucherSellersDashboard'
import VoucherProvidersDashboard from '../pages/provider/VoucherProvidersDashboard'
import MonthlyVoucherProvidersDashboard from '../pages/provider/MonthlyVoucherProvidersDashboard'

function AppRoutes() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route element={<TopNavLayout />}>
                        <Route path={RoutePath.AUTH.LOGIN} element={<Login />} />
                        <Route path={RoutePath.AUTH.REGISTER} element={<Register />} />
                        <Route path={RoutePath.AUTH.FORGOT_PASSWORD} element={<ForgotPassword />} />
                        <Route path={RoutePath.AUTH.RECOVER_PASSWORD} element={<RecoverPassword />} />

                        <Route element={<RequireAuth allowedPermissions={[]} />}>
                            <Route path={RoutePath.HOME} element={<Home />} />
                            <Route path={RoutePath.AUTH.PROFILE} element={<Profile />} />
                            <Route path={RoutePath.AUTH.CHANGE_PASSWORD} element={<ChangePassword />} />
                        </Route>

                        <Route path={RoutePath.UNAUTHORIZED} element={<Unauthorized />} />
                        <Route path="/" element={<Home />} />
                        <Route path={RoutePath.PAGE404} element={<Page404 />} />
                        <Route path="*" element={<Page404 />} />
                        <Route path={RoutePath.DOWNLOAD} element={<Download />} />
                    </Route>

                    <Route element={<SidebarLayout />}>
                        <Route element={<RequireAuth allowedPermissions={[]} />}>
                            <Route path={RoutePath.WELCOME} element={<Welcome />} />
                            <Route path={RoutePath.FILEUPLOAD} element={<FileUpload />} />
                        </Route>

                        {/* Administror */}
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Users.View]} />}>
                            <Route path={RoutePath.ADMIN.USERS} element={<Users />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Roles.View]} />}>
                            <Route path={RoutePath.ADMIN.ROLES} element={<Roles />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Regions.View]} />}>
                            <Route path={RoutePath.ADMIN.REGIONS} element={<Regions />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Languages.View]} />}>
                            <Route path={RoutePath.ADMIN.LANGUAGES} element={<Languages />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Providers.View]} />}>
                            <Route path={RoutePath.ADMIN.PROVIDERS} element={<Providers />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Agencies.View]} />}>
                            <Route path={RoutePath.ADMIN.AGENCIES} element={<Agencies />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Localizations.View]} />}>
                            <Route path={RoutePath.ADMIN.LOCALIZATIONS} element={<Localizations />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.PlaceTypes.View]} />}>
                            <Route path={RoutePath.ADMIN.PLACE_TYPES} element={<PlaceTypes />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.EventCategories.View]} />}>
                            <Route path={RoutePath.ADMIN.EVENT_CATEGORIES} element={<EventCategories />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.ErrorLogs.View]} />}>
                            <Route path={RoutePath.ADMIN.ERROR_LOGS} element={<ErrorLogs />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.ApiLogs.View]} />}>
                            <Route path={RoutePath.ADMIN.API_LOGS} element={<ApiLogs />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AuditLogs.View]} />}>
                            <Route path={RoutePath.ADMIN.AUDIT_LOGS} element={<AuditLogs />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Credentials.View]} />}>
                            <Route path={RoutePath.ADMIN.CREDENTIALS} element={<Credentials />} />
                        </Route>

                        {/* Provider */}
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Places.View]} />}>
                            <Route path={RoutePath.PROVIDER.PLACES} element={<Places />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllVouchers.View]} />}>
                            <Route path={RoutePath.PROVIDER.VOUCHERS} element={<Vouchers />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Combos.View]} />}>
                            <Route path={RoutePath.PROVIDER.COMBOS} element={<Combos />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.ApiKeys.View]} />}>
                            <Route path={RoutePath.PROVIDER.API_KEYS} element={<ApiKeys />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Providers.ViewProfile]} />}>
                            <Route path={RoutePath.PROVIDER.PROVIDER_INFO} element={<ProviderInfo />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.ProviderUsers.View]} />}>
                            <Route path={RoutePath.PROVIDER.USERS} element={<ProviderUsers />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.ProviderRoles.View]} />}>
                            <Route path={RoutePath.PROVIDER.ROLES} element={<ProviderRoles />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.TicketTemplates.View]} />}>
                            <Route path={RoutePath.PROVIDER.TICKET_TEMPLATES} element={<TicketTemplates />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.MyVouchers.View]} />}>
                            <Route path={RoutePath.PROVIDER.MY_VOUCHERS} element={<MyVouchers />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllVouchers.DailyVouchersReport]} />}>
                            <Route path={RoutePath.PROVIDER.DAILY_VOUCHER_DASHBOARD} element={<DailyVoucherDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllVouchers.MonthlyVouchersReport]} />}>
                            <Route path={RoutePath.PROVIDER.MONTHLY_VOUCHER_DASHBOARD} element={<MonthlyVoucherDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllVouchers.VoucherSellersReport]} />}>
                            <Route path={RoutePath.PROVIDER.VOUCHER_SELLERS_DASHBOARD} element={<VoucherSellersDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllVouchers.VoucherProvidersReport]} />}>
                            <Route path={RoutePath.PROVIDER.VOUCHER_PROVIDERS_DASHBOARD} element={<VoucherProvidersDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllVouchers.MonthlyVoucherProvidersReport]} />}>
                            <Route path={RoutePath.PROVIDER.MONTHLY_VOUCHER_PROVIDERS_DASHBOARD} element={<MonthlyVoucherProvidersDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllVouchers.VoucherOverTimeReport]} />}>
                            <Route path={RoutePath.PROVIDER.VOUCHER_OVER_TIME_DASHBOARD} element={<VoucherOverTimeDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AgencyPartners.Report]} />}>
                            <Route path={RoutePath.PROVIDER.AGENCY_DASHBOARD} element={<AgencyDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AppLogs.View]} />}>
                            <Route path={RoutePath.PROVIDER.APP_LOGS} element={<AppLogs />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AgencyPartners.View]} />}>
                            <Route path={RoutePath.PROVIDER.AGENCY_PARTNERS} element={<AgencyPartners />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Events.View]} />}>
                            <Route path={RoutePath.PROVIDER.EVENTS} element={<Events />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllEventTickets.View]} />}>
                            <Route path={RoutePath.PROVIDER.EVENT_TICKETS} element={<EventTickets />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.MyEventTickets.View]} />}>
                            <Route path={RoutePath.PROVIDER.MY_EVENT_TICKETS} element={<MyEventTickets />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllEventTickets.View]} />}>
                            <Route path={RoutePath.PROVIDER.EVENT_TICKETS_SOLD} element={<EventTicketsSold />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.MyEventTickets.View]} />}>
                            <Route path={RoutePath.PROVIDER.MY_EVENT_TICKETS_SOLD} element={<MyEventTicketsSold />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllEventTickets.DailyEventTicketsReport]} />}>
                            <Route path={RoutePath.PROVIDER.DAILY_EVENT_TICKET_DASHBOARD} element={<DailyEventTicketDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllEventTickets.MonthlyEventTicketsReport]} />}>
                            <Route path={RoutePath.PROVIDER.MONTHLY_EVENT_TICKET_DASHBOARD} element={<MonthlyEventTicketDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllEventTickets.TicketSellersReport]} />}>
                            <Route path={RoutePath.PROVIDER.TICKET_SELLERS_DASHBOARD} element={<TicketSellersDashboard />} />
                            <Route path={RoutePath.PROVIDER.TICKET_SELLERS_DETAILS_DASHBOARD} element={<TicketSellersDetailsDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllEventTickets.TicketClassesReport]} />}>
                            <Route path={RoutePath.PROVIDER.TICKET_CLASSES_DASHBOARD} element={<TicketClassesDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AllEventTickets.InvitationCodesReport]} />}>
                            <Route path={RoutePath.PROVIDER.INVITATION_CODES_DASHBOARD} element={<InvitationCodesDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.PaymentTransactionLogs.View]} />}>
                            <Route path={RoutePath.PROVIDER.PAYMENT_TRANSACTION_LOGS} element={<PaymentTransactionLogs />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.IssueInvoiceLogs.View]} />}>
                            <Route path={RoutePath.PROVIDER.ISSUE_INVOICE_LOGS} element={<IssueInvoiceLogs />} />
                        </Route>

                        {/* Agency */}
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AgencyVouchers.Report]} />}>
                            <Route path={RoutePath.AGENCY.VOUCHER_DASHBOARD} element={<AgencyVoucherDashboard />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.AgencyVouchers.View]} />}>
                            <Route path={RoutePath.AGENCY.VOUCHERS} element={<AgencyVouchers />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.ProviderPartners.View]} />}>
                            <Route path={RoutePath.AGENCY.PROVIDER_PARTNERS} element={<ProviderPartners />} />
                        </Route>
                        <Route element={<RequireAuth allowedPermissions={[Permissions.Agencies.ViewProfile]} />}>
                            <Route path={RoutePath.AGENCY.AGENCY_INFO} element={<AgencyInfo />} />
                        </Route>
                    </Route>

                    <Route path={RoutePath.PROVIDER.CHECKOUT} element={<CheckOut />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter >
    )
}

export default AppRoutes