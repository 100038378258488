import jwt_decode from "jwt-decode"
import Cookies from "universal-cookie"
import { UserTypes } from "../constants/UserTypes"

export type TToken = {
    accessToken: string
    refreshToken: string
}

export type TUserInfo = {
    id: number
    username: string
    fullname: string
    email: string
    avatar: string
    type: string
}

export type TUser = {
    token: TToken,
    permissions: string[],
    userInfo: TUserInfo
}

//cookies
const cookies = new Cookies()
const ACCESS_TOKEN_COOKIE: string = "access_token"
const cookieOtions = {
    path: "/"
}

class TokenService {
    static setUser(token: TToken | null) {
        if (token) {
            let payload = jwt_decode<any>(token.accessToken)

            if (payload) {
                let user = JSON.parse(payload.user)

                let userAuth: TUser = {
                    token: token,
                    permissions: payload.permissions,
                    userInfo: {
                        id: user.Id,
                        username: user.UserName,
                        fullname: user.FullName,
                        email: user.Email,
                        avatar: user.Avatar,
                        type: user.Type
                    }
                }

                localStorage.setItem("user", JSON.stringify(userAuth))

                //cookies
                cookies.set(ACCESS_TOKEN_COOKIE, token, cookieOtions)
            }
        }
    }

    static getTokenCookie(): TToken | null {
        let token = cookies.get(ACCESS_TOKEN_COOKIE, { doNotParse: false })
        return token ? token as TToken : null
    }

    static removeUser() {
        localStorage.removeItem("user")
        localStorage.removeItem("appState")

        //cookies
        cookies.remove(ACCESS_TOKEN_COOKIE)
    }

    static getUser() {
        const user = localStorage.getItem("user")
        return user ? JSON.parse(user) as TUser : null
    }

    static getToken() {
        return this.getUser()?.token || null
    }

    static getPermissions() {
        return this.getUser()?.permissions || []
    }

    static getUserInfo() {
        return this.getUser()?.userInfo || null
    }

    static isAdministrator() {
        return this.getUser()?.userInfo.type === UserTypes.ADMINISTRATOR
    }

    static isProvider() {
        return this.getUser()?.userInfo.type === UserTypes.PROVIDER
    }

    static isAgency() {
        return this.getUser()?.userInfo.type === UserTypes.AGENCY
    }
}

export default TokenService