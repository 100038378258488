import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import useAppContext from "../../hooks/useAppContext"
import DateRangePicker from "../../components/DateRangePicker"
import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import EventTicketService from "../../services/EventTicketService"
import { DatasetChart, LineChart, PieChart } from "../../components/ChartJS"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import { getReportItemText } from "../../constants/ReportItems"

function DailyEventTicketDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetDailyEventTicketsReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [reportData, setReportData] = useState<{
        date: Date,
        quantity: number,
        amount: number,
        paymentService: string,
        eventId: number,
        eventName: string
    }[] | null>(null)

    const GetDailyEventTicketsReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await EventTicketService.dailyEventTicketsReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    const [currentEventId, setCurrentEventId] = useState<number | null>(null)
    const [events, setEvents] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentEventId(null)
            generateEventTicketQuantityChartData(null)
            generateEventTicketAmountChartData(null)

            setEvents(
                reportData
                    .map((x) => { return { id: x.eventId, name: x.eventName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )
        } else {
            setCurrentEventId(null)
            setEvents(null)
            setEventTicketQuantityChartData({ ...eventTicketQuantityChartData, labels: [], datasets: [] })
            setEventTicketAmountChartData({ ...eventTicketAmountChartData, labels: [], datasets: [] })
        }
    }, [reportData])

    const handleEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentEventId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateEventTicketQuantityChartData(currentEventId)
        generateEventTicketAmountChartData(currentEventId)
    }, [currentEventId])

    const [eventTicketQuantityChartData, setEventTicketQuantityChartData] = useState({
        title: "SALES VOLUME",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateEventTicketQuantityChartData = (eventId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(date => {
                //labels
                labels.push(Utils.dateToString(date, Configs.DATE_FORMAT))
            })

            //paymentServices
            var paymentServices = data.map((x) => x.paymentService).filter((value, index, self) => self.indexOf(value) === index).sort()
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.paymentService === paymentService && x.date === date)

                    var quantity = 0
                    if (items) {
                        quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(quantity)
                })

                datasets.push(dataset)
            })

            //All
            const dataset = {
                label: "All",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var quantity = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(quantity)
            })

            datasets.push(dataset)

            setEventTicketQuantityChartData({ ...eventTicketQuantityChartData, labels: labels, datasets: datasets })
        } else {
            setEventTicketQuantityChartData({ ...eventTicketQuantityChartData, labels: [], datasets: [] })
        }
    }

    const [eventTicketAmountChartData, setEventTicketAmountChartData] = useState({
        title: "SALES REVENUE",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateEventTicketAmountChartData = (eventId: number | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(d => {
                //labels
                labels.push(Utils.dateToString(d, Configs.DATE_FORMAT))
            })

            //paymentServices
            var paymentServices = data.map((x) => x.paymentService).filter((value, index, self) => self.indexOf(value) === index).sort()
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.paymentService === paymentService && x.date === date)

                    var amount = 0
                    if (items) {
                        amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(amount)
                })

                datasets.push(dataset)
            })

            //All
            const dataset = {
                label: "All",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var amount = 0
                if (items) {
                    amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(amount)
            })

            datasets.push(dataset)

            setEventTicketAmountChartData({ ...eventTicketAmountChartData, labels: labels, datasets: datasets })
        } else {
            setEventTicketAmountChartData({ ...eventTicketAmountChartData, labels: [], datasets: [] })
        }
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>{getReportItemText(Permissions.AllEventTickets.DailyEventTicketsReport)}</h1>
                            <DashboardDropdown reportName={Permissions.AllEventTickets.DailyEventTicketsReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentEventId || ""} onChange={handleEventChange}>
                                        <option value="">All events</option>
                                        {events && events.map(event => {
                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                        })}
                                    </Form.Select>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <LineChart title={eventTicketAmountChartData.title} labels={eventTicketAmountChartData.labels} datasets={eventTicketAmountChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <PieChart
                                                title="PERCENTAGE OF SALES REVENUE"
                                                labels={eventTicketAmountChartData.datasets.filter(x => x.label !== "All").map(ds => ds.label)}
                                                datasets={[{
                                                    label: "Sales",
                                                    data: eventTicketAmountChartData.datasets.filter(x => x.label !== "All").map(ds => ds.data.reduce((a, b) => a + b, 0))
                                                }]}
                                            ></PieChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Payment</th>
                                                        <th style={{ textAlign: "right" }}>Revenue</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {eventTicketAmountChartData.datasets.filter(x => x.label !== "All").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>All</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(eventTicketAmountChartData.datasets.filter(x => x.label !== "All").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} md={12}>
                                            <LineChart title={eventTicketQuantityChartData.title} labels={eventTicketQuantityChartData.labels} datasets={eventTicketQuantityChartData.datasets}></LineChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <PieChart
                                                title="PERCENTAGE OF SALES VOLUME"
                                                labels={eventTicketQuantityChartData.datasets.filter(x => x.label !== "All").map(ds => ds.label)}
                                                datasets={[{
                                                    label: "Volume",
                                                    data: eventTicketQuantityChartData.datasets.filter(x => x.label !== "All").map(ds => ds.data.reduce((a, b) => a + b, 0))
                                                }]}
                                            ></PieChart>
                                        </Col>
                                        <Col lg={3} md={6}>
                                            <Table responsive className="mt-2" size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>Payment</th>
                                                        <th style={{ textAlign: "right" }}>Volume</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {eventTicketQuantityChartData.datasets.filter(x => x.label !== "All").map((ds, index) => {
                                                        return <tr key={index}>
                                                            <td>{ds.label}</td>
                                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(ds.data.reduce((a, b) => a + b, 0))}</td>
                                                        </tr>
                                                    })}
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th>All</th>
                                                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(eventTicketQuantityChartData.datasets.filter(x => x.label !== "All").map(ds => ds.data).flat().reduce((a, b) => a + b, 0))}</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default DailyEventTicketDashboard