import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { IApiKey } from "./ApiKeyService"

export interface IAppLog {
    id: number
    className: string
    methodName: string
    text: string
    exception: string
    logType: string
    logLevel: string
    createdOn: Date
    clientInfo: string
    apiKey: IApiKey
}

export default class AppLogService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AppLogs/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AppLogs/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AppLogs/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async clear(providerId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AppLogs/${providerId}/Clear`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}