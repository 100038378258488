import { KeyboardEvent, RefObject, useEffect, useRef, useState } from "react"
import ErrorLogService, { IErrorLog } from "../../services/ErrorLogService"
import Configs from "../../Configs"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import ContentHeader from "../../components/ContentHeader"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import Pagination from "../../components/Pagination"
import Utils from "../../utils/Utils"
import Confirmation from "../../components/Confirmation"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

function ErrorLogs() {
    const { hasPermission } = useAuthContext()

    const [tableData, setTableData] = useState({
        data: new Array<IErrorLog>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const [message, setMessage] = useState("")
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const FilterFn = async (pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE
        let keyword = keywordRef.current?.value || ""

        const res = await ErrorLogService.filter(pageIndex, pageSize, keyword)

        if (res?.isSuccess) {
            setTableData(res.data)
        } else {
            console.log(res?.message)
        }

        setCurrentPageIndex(pageIndex)
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    //Delete
    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setDeletedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (deletedItem.id > 0) {
            const res = await ErrorLogService.delete(deletedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    //Clear
    const [displayClearConfirmationModal, setDisplayClearConfirmationModal] = useState(false)

    const hideClearConfirmationModal = () => {
        setDisplayClearConfirmationModal(false)
    }

    const showClearConfirmation = () => {
        setDisplayClearConfirmationModal(true)
        setMessage("")
    }

    const handleClear = async () => {
        const res = await ErrorLogService.clear()

        if (res?.isSuccess) {
            FilterFn(1)
        }

        hideClearConfirmationModal()
        setMessage(res.message)
    }

    //View
    const [formData, setFormData] = useState<IErrorLog | null>(null)

    const handleView = async (id: number) => {
        var item = tableData.data.find(x => x.id === id) || null

        if (item) {
            setFormData(item)
            handleShowModal()
        }
    }

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => {
        setShowModal(false)
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = "Log Details"

    return (
        <>
            <ContentHeader title="Error Logs" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.ErrorLogs.Clear) &&
                                        <Button variant="default" size="sm" onClick={showClearConfirmation}>
                                            <i className="fas fa-trash-alt"></i> Clear logs
                                        </Button>
                                    }

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Log Level</th>
                                                <th>Event Name</th>
                                                <th>Event Id</th>
                                                <th>Thread Id</th>
                                                <th>Log Time</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{item.logLevel}</td>
                                                        <td>{item.eventName || ""}</td>
                                                        <td>{item.eventId}</td>
                                                        <td>{item.threadId}</td>
                                                        <td>{Utils.dateToString(item.createdOn, Configs.DATE_TIME_FORMAT)}</td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            <Button variant="warning" className="mr-1" size="sm" onClick={() => handleView(item.id)}><i className="fas fa-eye"></i> View</Button>
                                                            {hasPermission(Permissions.ErrorLogs.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.logLevel)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />

            <Confirmation show={displayClearConfirmationModal} message="Are you sure you want to delete all error logs" onHide={hideClearConfirmationModal} onConfirm={handleClear}></Confirmation>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {formData && formData.exceptionDetails && (
                        Utils.isJSON(formData.exceptionDetails) ?
                            <pre>{JSON.stringify(JSON.parse(formData.exceptionDetails), null, 2)}</pre> :
                            <p>{formData.exceptionDetails}</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ErrorLogs