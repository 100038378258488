import { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import BankService, { IBank } from "../services/BankService"

interface IBankSelectProps {
    value: string
    required?: boolean
    onChange: (e: any) => void
}

function BankSelect(props: IBankSelectProps) {
    const [bankOptions, setBankOptions] = useState([] as any[])

    const BankOptions = async () => {
        const res = await BankService.list()
        let options: any[] = []

        if (res?.isSuccess) {
            options = res.data.map((item: IBank) => <option key={item.short_name} value={item.short_name || 0}>{item.short_name} - {item.name}</option>)
        } else {
            console.log(res?.message)
        }

        return options
    }

    useEffect(() => {
        BankOptions().then(options => {
            setBankOptions(options)
        })
    }, [])

    return (
        <Form.Group className="mb-3" controlId="bankAccountName">
            <Form.Label className="mb-0">Bank Name</Form.Label>
            <Form.Select name="bankAccountName" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {bankOptions}
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Select a bank.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default memo(BankSelect)