import axios from "axios"
import Configs from "../Configs"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IApiLog {
    id: number
    clientIp: string
    requestDateTimeUtc: Date
    requestMethod: string
    requestPath: string
    responseDateTimeUtc: Date
    responseStatus: number
    details: string
}

export default class ApiLogService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ApiLogs?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ApiLogs/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ApiLogs/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async clear() {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ApiLogs/Clear`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getGeolocation(ipAddress: string) {
        try {
            const { data } = await axios.get(`${Configs.IP_DETAILS_BASE_URL}${ipAddress}`)

            return data
        } catch (error) {
            console.log(error)

            return null
        }
    }
}