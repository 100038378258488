import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface IAudioStoryPhotoTranslation {
    audioStoryPhotoId: number | null
    languageId: number
    caption: string
    language: ILanguage
}

export interface IAudioStoryPhoto {
    id: number | null
    caption: string
    photo: string
    orderNumber: number | null
    published: boolean
    audioStoryId: number
    translations: Array<IAudioStoryPhotoTranslation>
}

export default class AudioStoryPhotoService {
    static async listAll(audioStoryId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AudioStoryPhotos/${audioStoryId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(audioStoryId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AudioStoryPhotos/${audioStoryId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(audioStoryId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AudioStoryPhotos/${audioStoryId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(audioStoryId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AudioStoryPhotos/${audioStoryId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(audioStoryId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AudioStoryPhotos/${audioStoryId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(audioStoryId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/AudioStoryPhotos/${audioStoryId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePublished(audioStoryId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AudioStoryPhotos/${audioStoryId}/ChangePublished/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}