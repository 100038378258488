import { Badge } from "react-bootstrap"
import { PaymentStatusType } from "../constants/PaymentStatusType"
import { useTranslation } from "react-i18next"

interface IPaymentStatusBadgeProps {
    value: string
}

function PaymentStatusBadge(props: IPaymentStatusBadgeProps) {
    const { t } = useTranslation("global")

    const badge = () => {
        switch (props.value) {
            case PaymentStatusType.PENDING:
                return <Badge bg="secondary">{t(`paymentStatus.${PaymentStatusType.PENDING}`)}</Badge>
            case PaymentStatusType.PAID:
                return <Badge bg="success">{t(`paymentStatus.${PaymentStatusType.PAID}`)}</Badge>
            case PaymentStatusType.CANCELED:
                return <Badge bg="danger">{t(`paymentStatus.${PaymentStatusType.CANCELED}`)}</Badge>
            default:
                return <Badge bg="light">{props.value}</Badge>
        }
    }

    return (<small>{badge()}</small>)
}

export default PaymentStatusBadge