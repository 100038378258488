import { IAgency } from "./AgencyService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IAgencyPartner {
    providerId: number,
    agencyId: number,
    status: number,
    requestedTime: Date,
    agency: IAgency
}

export default class AgencyPartnerService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AgencyPartners/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, agencyId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/AgencyPartners/${providerId}/${agencyId}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async add(providerId: number, agencyId: number) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/AgencyPartners/${providerId}/Add/${agencyId}`, {})

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async cancel(providerId: number, agencyId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AgencyPartners/${providerId}/Cancel/${agencyId}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async remove(providerId: number, agencyId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AgencyPartners/${providerId}/Remove/${agencyId}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async accept(providerId: number, agencyId: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/AgencyPartners/${providerId}/Accept/${agencyId}`, {})

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async reject(providerId: number, agencyId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/AgencyPartners/${providerId}/Reject/${agencyId}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}