import { useEffect, useState } from "react"
import { Dropdown } from "react-bootstrap"
import { Link, Outlet, useNavigate } from "react-router-dom"
import { Footer } from "../components/Footer"
import Logo from "../components/Logo"
import UserNavbar from "../components/UserNavbar"
import RoutePath from "../constants/RoutePath"
import useAppContext from "../hooks/useAppContext"
import AppStateService from "../services/AppStateService"
import TokenService from "../services/TokenService"
import ProviderService, { IProvider } from "../services/ProviderService"
import { ActionTypes } from "../store/actions"
import ControlSidebar from "../components/ControlSidebar"
import useAuthContext from "../hooks/useAuthContext"
import Permissions from "../constants/Permissions"
import AgencyService, { IAgency } from "../services/AgencyService"
import { DashboardDropdownItems } from "../pages/provider/DashboardDropdown"
import { useTranslation } from "react-i18next"

function SidebarLayout() {
    const { t } = useTranslation("global")
    const { state, dispatch } = useAppContext()
    const navigate = useNavigate()
    const { hasPermission } = useAuthContext()
    const dashboardDropdownItems = DashboardDropdownItems()

    const [providers, setProviders] = useState([] as IProvider[])
    const [agencies, setAgencies] = useState([] as IAgency[])

    useEffect(() => {
        document.body.className = "hold-transition sidebar-mini layout-fixed"

        if (TokenService.isProvider()) {
            ListProviderByUser()
        }

        if (TokenService.isAgency()) {
            ListAgencyByUser()
        }
    }, [])

    const ListProviderByUser = async () => {
        const res = await ProviderService.listByUser()

        if (res?.isSuccess) {
            setProviders(res.data)
            if (res.data.length > 0) {
                let currentProviderId = AppStateService.get().currentProviderId
                if (res.data.find((item: IProvider) => item.id === currentProviderId) === undefined) {
                    handleProviderChange((res.data[0] as IProvider).id)
                }
            } else {
                AppStateService.remove()
                navigate(RoutePath.UNAUTHORIZED, { replace: true })
            }
        } else {
            console.log(res?.message)
        }
    }

    const handleProviderChange = (id: number | null) => {
        dispatch({
            type: ActionTypes.CHANGE_PROVIDER,
            payload: id
        })
    }

    const ListAgencyByUser = async () => {
        const res = await AgencyService.listByUser()

        if (res?.isSuccess) {
            setAgencies(res.data)
            if (res.data.length > 0) {
                let currentAgencyId = AppStateService.get().currentAgencyId
                if (res.data.find((item: IAgency) => item.id === currentAgencyId) === undefined) {
                    handleAgencyChange((res.data[0] as IAgency).id)
                }
            } else {
                AppStateService.remove()
                navigate(RoutePath.UNAUTHORIZED, { replace: true })
            }
        } else {
            console.log(res?.message)
        }
    }

    const handleAgencyChange = (id: number | null) => {
        dispatch({
            type: ActionTypes.CHANGE_AGENCY,
            payload: id
        })
    }

    const userSidebar = () => {
        if (TokenService.isAdministrator()) {
            return (
                <>
                    {hasPermission([Permissions.Localizations.View, Permissions.Languages.View, Permissions.Regions.View, Permissions.PlaceTypes.View]) &&
                        <li className="nav-header">{t("sidebar.administrator.categories")}</li>
                    }
                    {hasPermission(Permissions.Localizations.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.LOCALIZATIONS} className="nav-link">
                                <i className="nav-icon fas fa-cog text-muted"></i>
                                <p>{t("sidebar.administrator.localizations")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.Languages.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.LANGUAGES} className="nav-link">
                                <i className="nav-icon fas fa-language text-muted"></i>
                                <p>{t("sidebar.administrator.languages")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.Regions.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.REGIONS} className="nav-link">
                                <i className="nav-icon fas fa-globe text-muted"></i>
                                <p>{t("sidebar.administrator.regions")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.PlaceTypes.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.PLACE_TYPES} className="nav-link">
                                <i className="nav-icon fas fa-tags text-muted"></i>
                                <p>{t("sidebar.administrator.placeTypes")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.EventCategories.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.EVENT_CATEGORIES} className="nav-link">
                                <i className="nav-icon fas fa-tags text-muted"></i>
                                <p>{t("sidebar.administrator.eventCategories")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.Providers.View, Permissions.Agencies.View]) &&
                        <li className="nav-header">{t("sidebar.administrator.partners")}</li>
                    }
                    {hasPermission(Permissions.Providers.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.PROVIDERS} className="nav-link">
                                <i className="nav-icon fas fa-user-edit text-muted"></i>
                                <p>{t("sidebar.administrator.providers")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.Agencies.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.AGENCIES} className="nav-link">
                                <i className="nav-icon fas fa-user-tie text-muted"></i>
                                <p>{t("sidebar.administrator.agencies")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.Users.View, Permissions.Roles.View]) &&
                        <li className="nav-header">{t("sidebar.administrator.usersAndRoles")}</li>
                    }
                    {hasPermission(Permissions.Users.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.USERS} className="nav-link">
                                <i className="nav-icon fas fa-user text-muted"></i>
                                <p>{t("sidebar.administrator.users")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.Roles.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.ROLES} className="nav-link">
                                <i className="nav-icon fas fa-tasks text-muted"></i>
                                <p>{t("sidebar.administrator.roles")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.ErrorLogs.View, Permissions.ApiLogs.View, Permissions.AuditLogs.View, Permissions.Credentials.View]) &&
                        <li className="nav-header">{t("sidebar.administrator.system")}</li>
                    }
                    {hasPermission(Permissions.Credentials.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.CREDENTIALS} className="nav-link">
                                <i className="nav-icon fas fa-key text-muted"></i>
                                <p>{t("sidebar.administrator.credentials")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.ErrorLogs.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.ERROR_LOGS} className="nav-link">
                                <i className="nav-icon fas fa-exclamation-triangle text-muted"></i>
                                <p>{t("sidebar.administrator.errorLogs")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.ApiLogs.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.API_LOGS} className="nav-link">
                                <i className="nav-icon fas fa-exchange-alt text-muted"></i>
                                <p>{t("sidebar.administrator.apiLogs")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.AuditLogs.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.ADMIN.AUDIT_LOGS} className="nav-link">
                                <i className="nav-icon fas fa-database text-muted"></i>
                                <p>{t("sidebar.administrator.auditLogs")}</p>
                            </Link>
                        </li>
                    }
                </>
            )
        }

        if (TokenService.isProvider()) {
            return (
                <>
                    {dashboardDropdownItems.length > 0 &&
                        <li className="nav-item">
                            <Link to={dashboardDropdownItems[0].link} className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt text-muted"></i>
                                <p>{t("sidebar.provider.dashboard")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.MyVouchers.View, Permissions.MyEventTickets.View]) &&
                        <li className="nav-header">{t("sidebar.provider.ticketCounter")}</li>
                    }
                    {hasPermission(Permissions.MyVouchers.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.MY_VOUCHERS} className="nav-link">
                                <i className="nav-icon fas fa-ticket-alt text-muted"></i>
                                <p>{t("sidebar.provider.myVouchers")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.MyEventTickets.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.MY_EVENT_TICKETS} className="nav-link">
                                <i className="nav-icon fas fa-ticket-alt text-muted"></i>
                                <p>{t("sidebar.provider.myEventTickets")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.AllVouchers.View, Permissions.Combos.View, Permissions.TicketTemplates.View, Permissions.AgencyPartners.View]) &&
                        <li className="nav-header">{t("sidebar.provider.voucherApp")}</li>
                    }
                    {hasPermission(Permissions.AllVouchers.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.VOUCHERS} className="nav-link">
                                <i className="nav-icon fas fa-ticket-alt text-muted"></i>
                                <p>{t("sidebar.provider.allVouchers")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.Combos.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.COMBOS} className="nav-link">
                                <i className="nav-icon fas fa-ticket-alt text-muted"></i>
                                <p>{t("sidebar.provider.combos")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.TicketTemplates.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.TICKET_TEMPLATES} className="nav-link">
                                <i className="nav-icon fas fa-calculator text-muted"></i>
                                <p>{t("sidebar.provider.templates")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.AgencyPartners.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.AGENCY_PARTNERS} className="nav-link">
                                <i className="nav-icon far fa-handshake text-muted"></i>
                                <p>{t("sidebar.provider.agencies")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.Places.View, Permissions.ApiKeys.View]) &&
                        <li className="nav-header">{t("sidebar.provider.audioGuide")}</li>
                    }
                    {hasPermission(Permissions.Places.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.PLACES} className="nav-link">
                                <i className="nav-icon fas fa-map-marker-alt text-muted"></i>
                                <p>{t("sidebar.provider.places")}</p>
                            </Link>
                        </li>
                    }
                    {/* <li className="nav-header"><hr /></li> */}
                    {hasPermission(Permissions.ApiKeys.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.API_KEYS} className="nav-link">
                                <i className="nav-icon fas fa-key text-muted"></i>
                                <p>{t("sidebar.provider.apiKeys")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.Events.View, Permissions.AllEventTickets.View, Permissions.PaymentTransactionLogs.View]) &&
                        <li className="nav-header">{t("sidebar.provider.eventTickets")}</li>
                    }
                    {hasPermission(Permissions.AllEventTickets.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.EVENT_TICKETS} className="nav-link">
                                <i className="nav-icon fas fa-ticket-alt text-muted"></i>
                                <p>{t("sidebar.provider.allEventTickets")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.Events.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.EVENTS} className="nav-link">
                                <i className="nav-icon fas fa-calendar-alt text-muted"></i>
                                <p>{t("sidebar.provider.events")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.Providers.ViewProfile]) &&
                        <li className="nav-header">{t("sidebar.provider.settings")}</li>
                    }
                    {hasPermission(Permissions.Providers.ViewProfile) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.PROVIDER_INFO} className="nav-link">
                                <i className="nav-icon fas fa-info-circle text-muted"></i>
                                <p>{t("sidebar.provider.providerInfo")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.Providers.ViewProfile, Permissions.ProviderUsers.View, Permissions.ProviderRoles.View]) &&
                        <li className="nav-header">{t("sidebar.provider.usersAndRoles")}</li>
                    }
                    {hasPermission(Permissions.ProviderUsers.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.USERS} className="nav-link">
                                <i className="nav-icon fas fa-user text-muted"></i>
                                <p>{t("sidebar.provider.users")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.ProviderRoles.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.ROLES} className="nav-link">
                                <i className="nav-icon fas fa-tasks text-muted"></i>
                                <p>{t("sidebar.provider.roles")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission([Permissions.PaymentTransactionLogs.View, Permissions.IssueInvoiceLogs.View, Permissions.AppLogs.View]) &&
                        <li className="nav-header">{t("sidebar.provider.logs")}</li>
                    }
                    {hasPermission(Permissions.PaymentTransactionLogs.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.PAYMENT_TRANSACTION_LOGS} className="nav-link">
                                <i className="nav-icon fas fa-exchange-alt text-muted"></i>
                                <p style={{ fontSize: "14px" }}>{t("sidebar.provider.paymentTransactionLogs")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.IssueInvoiceLogs.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.ISSUE_INVOICE_LOGS} className="nav-link">
                                <i className="nav-icon fas fa-file-invoice-dollar text-muted"></i>
                                <p style={{ fontSize: "14px" }}>{t("sidebar.provider.issueInvoiceLogs")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.AppLogs.View) && <>
                        <li className="nav-item">
                            <Link to={RoutePath.PROVIDER.APP_LOGS} className="nav-link">
                                <i className="nav-icon fas fa-exclamation-triangle text-muted"></i>
                                <p style={{ fontSize: "14px" }}>{t("sidebar.provider.appLogs")}</p>
                            </Link>
                        </li>
                    </>
                    }
                </>
            )
        }

        if (TokenService.isAgency()) {
            return (
                <>
                    {hasPermission(Permissions.AgencyVouchers.Report) &&
                        <li className="nav-item">
                            <Link to={RoutePath.AGENCY.VOUCHER_DASHBOARD} className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt text-muted"></i>
                                <p>{t("sidebar.agency.dashboard")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.AgencyVouchers.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.AGENCY.VOUCHERS} className="nav-link">
                                <i className="nav-icon fas fa-ticket-alt text-muted"></i>
                                <p>{t("sidebar.agency.vouchers")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.ProviderPartners.View) &&
                        <li className="nav-item">
                            <Link to={RoutePath.AGENCY.PROVIDER_PARTNERS} className="nav-link">
                                <i className="nav-icon far fa-handshake text-muted"></i>
                                <p>{t("sidebar.agency.providers")}</p>
                            </Link>
                        </li>
                    }
                    {hasPermission(Permissions.Agencies.ViewProfile) &&
                        <li className="nav-item">
                            <Link to={RoutePath.AGENCY.AGENCY_INFO} className="nav-link">
                                <i className="nav-icon fas fa-info-circle text-muted"></i>
                                <p>{t("sidebar.agency.agencyInfo")}</p>
                            </Link>
                        </li>
                    }
                </>
            )
        }

        return <></>
    }

    return (
        <div className="wrapper">
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                    {TokenService.isProvider() && providers.length > 0 && (
                        <Dropdown as={"li"}>
                            <Dropdown.Toggle variant="default">
                                {providers.find(item => item.id === state.currentProviderId)?.name || ""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {providers.map((item: IProvider) => <Dropdown.Item onClick={() => handleProviderChange(item.id)} key={item.id} className={`${item.id === state.currentProviderId ? "active" : ""}`}>{item.name}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                    {TokenService.isAgency() && agencies.length > 0 && (
                        <Dropdown as={"li"}>
                            <Dropdown.Toggle variant="default">
                                {agencies.find(item => item.id === state.currentAgencyId)?.name || ""}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {agencies.map((item: IAgency) => <Dropdown.Item onClick={() => handleAgencyChange(item.id)} key={item.id}>{item.name}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    )}
                </ul>

                <UserNavbar showControlSidebarButtonToggle />
            </nav>

            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <Logo className="brand-link" />

                <div className="sidebar">
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {userSidebar()}
                        </ul>
                    </nav>
                </div>
            </aside>

            <div className="content-wrapper">
                <Outlet />
            </div>

            <Footer />

            <ControlSidebar />
        </div>
    )
}

export default SidebarLayout