import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Button, Modal, Form, Table, InputGroup, Card, Container, Row, Col, Alert, Breadcrumb, Spinner } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import ContentHeader from "../../components/ContentHeader"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import Pagination from "../../components/Pagination"
import Configs from "../../Configs"
import RegionService, { IRegion } from "../../services/RegionService"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

function Regions() {
    const { hasPermission } = useAuthContext()

    const [tableData, setTableData] = useState({
        data: new Array<IRegion>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData = {
        id: 0,
        name: "",
        code: "",
        orderNumber: null,
        parentId: null,
        isUsed: false
    } as IRegion

    const [parentId, setParentID] = useState(initFormData.parentId)
    const [message, setMessage] = useState("")
    const [searchInput, setSearchInput] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [validatedFormModal, setValidatedFormModal] = useState(false)
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        ten: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)

    const FilterFn = async (parentId: number | null, keyword: string, pageIndex: number) => {
        let pageSize = Configs.DEFAULT_PAGE_SIZE

        const res = await RegionService.filter(parentId, pageIndex, pageSize, keyword)

        if (res?.isSuccess) {
            setTableData(res.data)
        } else {
            console.log(res?.message)
        }

        setCurrentPageIndex(pageIndex)
        setParentID(parentId)
        setSearchInput(keyword)
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(null, "", 1)
    }, [])

    const handleSearch = () => {
        FilterFn(null, keywordRef.current?.value || "", 1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(null, keywordRef.current?.value || "", 1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(parentId, keywordRef.current?.value || "", pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleCheckBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.checked })
    }

    const handleAddNew = () => {
        setFormData({ ...initFormData, parentId: parentId })

        handleShowModal()
    }

    const handleEdit = async (id: number) => {
        const res = await RegionService.get(id)

        if (res?.isSuccess) {
            setFormData({
                ...initFormData,
                id: res.data.id,
                code: res.data.code,
                name: res.data.name,
                orderNumber: res.data.orderNumber,
                parentId: res.data.parentId,
                isUsed: res.data.isUsed
            })

            handleShowModal()
        } else {
            console.log(res?.message)
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formRef.current?.checkValidity()) {
            if (formData.id > 0) {
                const res = await RegionService.update(formData.id, formData)

                if (res?.isSuccess) {
                    FilterFn(parentId, keywordRef.current?.value || "", currentPageIndex)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            } else {
                const res = await RegionService.create(formData)

                if (res?.isSuccess) {
                    FilterFn(parentId, keywordRef.current?.value || "", 1)
                    handleCloseModal()
                    setMessage(res.message)
                } else {
                    setMessageFormModal(res?.message)
                }
            }
        } else {
            setValidatedFormModal(true)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedFormModal(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = (formData.id > 0 ? "Edit" : "New") + " Region"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            ten: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            ten: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        const res = await RegionService.delete(deletedItem.id)

        if (res?.isSuccess) {
            FilterFn(parentId, keywordRef.current?.value || "", currentPageIndex)
        }

        hideConfirmationModal()
        setMessage(res.message)
    }

    const handleIsUsedSwitch = async (id: number) => {
        const res = await RegionService.changeIsUsed(id)

        if (res?.isSuccess) {
            FilterFn(parentId, keywordRef.current?.value || "", currentPageIndex)
        } else {
            console.log(res?.message)
        }
    }

    const handleShowChildren = (parentId: number | null) => {
        FilterFn(parentId, "", 1)
    }

    const [breadcrumbItems, setBreadcrumbItems] = useState([] as Array<IRegion>)

    useEffect(() => {
        if (parentId === null) {
            setBreadcrumbItems([])
        } else {
            GetBreadcrumbItems(parentId)
        }
    }, [parentId])

    const GetBreadcrumbItems = async (parentId: number) => {
        const res = await RegionService.getPath(parentId)
        if (res?.isSuccess && res?.data) {
            setBreadcrumbItems(res.data)
        } else {
            console.log(res?.message)
            setBreadcrumbItems([])
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleRefreshCache = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            const res = await RegionService.refreshTreeDataCache()
            setMessage(res.isSuccess ? "Refresh successfully." : res.message)
            setProcessing(false)
        }
    }

    return (
        <>
            <ContentHeader title="Regions" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.Regions.Create) &&
                                        <Button variant="info" size="sm" className="mr-1" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Add new
                                        </Button>
                                    }
                                    {hasPermission(Permissions.Regions.Edit) &&
                                        <Button variant="secondary" size="sm" onClick={handleRefreshCache} disabled={processing}>
                                            {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-sync-alt"></i>} Refresh tree data cache
                                        </Button>
                                    }

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                                onChange={e => setSearchInput(e.target.value)}
                                                value={searchInput}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}

                                    <Breadcrumb>
                                        {breadcrumbItems.length > 0 &&
                                            <Breadcrumb.Item key={0} onClick={() => handleShowChildren(null)}><i className="fas fa-globe-asia"></i></Breadcrumb.Item>
                                        }
                                        {breadcrumbItems.map(item => {
                                            return <Breadcrumb.Item key={item.id} onClick={() => handleShowChildren(item.id)}>{item.name}</Breadcrumb.Item>
                                        })}
                                    </Breadcrumb>

                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "40px" }}></th>
                                                <th>Name</th>
                                                <th>Code</th>
                                                <th>Order Number</th>
                                                <th style={{ textAlign: "center", width: "100px" }}>Is Used</th>
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            <Button variant="default" size="sm" onClick={() => handleShowChildren(item.id)}>
                                                                <i className="far fa-caret-square-right"></i>
                                                            </Button>
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td>{item.code}</td>
                                                        <td>{item.orderNumber}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" id={"region_isused_" + item.id} checked={item.isUsed} onChange={(e) => handleIsUsedSwitch(item.id)} disabled={!hasPermission(Permissions.Regions.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.Regions.Edit) &&
                                                                <Button variant="warning" size="sm" className="mr-1" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.Regions.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Breadcrumb>
                        {breadcrumbItems.map(item => {
                            return <Breadcrumb.Item key={item.id} active>{item.name}</Breadcrumb.Item>
                        })}
                    </Breadcrumb>

                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" required value={formData.name} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>Code</Form.Label>
                            <Form.Control type="text" name="code" value={formData.code || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="isUsed">
                            <Form.Check type="checkbox" label="Is Used" name="isUsed" checked={formData.isUsed} onChange={(e) => handleCheckBoxChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.ten} />

        </>
    )
}

export default Regions