import { useEffect, useState } from "react"
import { ContentState, EditorState, convertToRaw } from "draft-js"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { Editor } from "react-draft-wysiwyg"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { Button, Form } from "react-bootstrap"
import UploadService from "../services/UploadService"

interface ITextEditorProps {
    contentHtml: string
    onChange: (contentHtml: string) => void
}

function TextEditor(props: ITextEditorProps) {
    const getEditorState = (contentHtml: string) => {
        const contentBlocks = htmlToDraft(contentHtml).contentBlocks
        const contentState = ContentState.createFromBlockArray(contentBlocks)
        return EditorState.createWithContent(contentState)
    }

    const getEditorContent = (editorState: EditorState) => draftToHtml(convertToRaw(editorState.getCurrentContent()))

    const [codeView, setCodeView] = useState(false)
    const [editorState, setEditorState] = useState<EditorState>(getEditorState(props.contentHtml))
    const [editorContent, setEditorContent] = useState(props.contentHtml)

    useEffect(() => {
        props.onChange(getEditorContent(editorState))
    }, [editorState])

    useEffect(() => {
        if (codeView) {
            setEditorContent(getEditorContent(editorState))
        } else {
            setEditorState(getEditorState(editorContent))
        }
    }, [codeView])

    const _uploadImageCallBack = async (file: File) => {
        const frmData = new FormData()
        frmData.append("file", file)

        const res = await UploadService.uploadImage(frmData)

        // const imageObject = {
        //     file: file,
        //     localSrc: URL.createObjectURL(file),
        // }

        return new Promise(
            (resolve, reject) => {
                if (res?.isSuccess) {
                    // resolve({ data: { link: imageObject.localSrc } })
                    resolve({ data: { link: res.data } })
                } else {
                    reject(res.message)
                }
            }
        )
    }

    return (
        <>
            {!codeView ?
                <Editor
                    editorState={editorState}
                    wrapperClassName="draft-wrapper"
                    toolbarClassName="draft-toolbar"
                    editorClassName="draft-editor"
                    onEditorStateChange={newEditorState => setEditorState(newEditorState)}
                    toolbar={{
                        image: {
                            uploadCallback: _uploadImageCallBack,
                            alt: {
                                present: true,
                                mandatory: false
                            },
                            previewImage: true,
                            defaultSize: {
                                height: "auto",
                                width: "100%"
                            },
                            title: ""
                        }
                    }}
                /> :
                <Form.Control as="textarea" rows={20} type="text" value={editorContent} onChange={(e) => setEditorContent(e.target.value)} />
            }
            <Button variant="default" size="sm" className={codeView ? "text-secondary" : "text-info"} onClick={() => setCodeView(value => !value)}><i className="fas fa-code"></i> Code View</Button>
        </>
    )
}

export default TextEditor