import { useState } from "react"
import { Form } from "react-bootstrap"
import Configs from "../Configs"
import TokenService from "../services/TokenService"

function ControlSidebar() {
    const [currentPrintMethod, setCurrentPrintMethod] = useState(Configs.PrintMethod.get())
    const [currentHideTestVouchers, setCurrentHideTestVouchers] = useState(Configs.Voucher.getHideTestVouchers())

    return (
        <aside className="control-sidebar control-sidebar-dark">
            <div className="p-3 control-sidebar-content">
                <h5>User Settings</h5>
                <hr></hr>
                <h6 className="mb-2">Print Vouchers As</h6>
                <Form>
                    {['HTML', 'PDF'].map((type) => (
                        <Form.Check
                            type="radio"
                            name="PrintMethod"
                            id={`print-method-${type}`}
                            label={type}
                            value={type}
                            checked={currentPrintMethod === type}
                            onChange={e => {
                                Configs.PrintMethod.set(e.target.value)
                                setCurrentPrintMethod(e.target.value)
                            }}
                            key={`print-method-${type}`}
                        />
                    ))}
                </Form>
                {TokenService.isProvider() && <>
                    <h6 className="mt-3">Other Options</h6>
                    <Form>
                        <Form.Check
                            type="checkbox"
                            name="HideTestVouchers"
                            id="HideTestVouchers"
                            label="Don't show test vouchers"
                            checked={currentHideTestVouchers}
                            onChange={e => {
                                Configs.Voucher.setHideTestVouchers(e.target.checked)
                                setCurrentHideTestVouchers(e.target.checked)
                            }}
                        />
                    </Form>
                </>}
            </div>
        </aside>
    )
}

export default ControlSidebar