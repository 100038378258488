import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import VoucherTypeService, { IVoucherType } from "../../services/VoucherTypeService"
import Configs from "../../Configs"
import { Alert, Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"
import Utils from "../../utils/Utils"

interface IVoucherTypesModalProps {
    placeId: number | null
    show: boolean
    title: string
    onClose: () => void
}

function VoucherTypesModal(props: IVoucherTypesModalProps) {
    const { hasPermission } = useAuthContext()
    const [tableData, setTableData] = useState({
        data: new Array<IVoucherType>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IVoucherType = {
        id: null,
        name: "",
        orderNumber: null,
        price: null,
        currency: "",
        timeLimit: null,
        isUsed: false,
        placeId: props.placeId || 0
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)

    const FilterFn = async (pageIndex: number) => {
        if (props.placeId && props.show) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await VoucherTypeService.filter(props.placeId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        } else {
            setTableData({
                data: new Array<IVoucherType>(),
                hasNext: false,
                hasPrevious: false,
                pageIndex: 1,
                pageSize: Configs.DEFAULT_PAGE_SIZE,
                totalPages: 0,
                totalRecords: 0
            })
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [props.placeId, props.show])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (e.target.name === "timeLimit" || e.target.name === "orderNumber") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else if (e.target.name === "price") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseFloat(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await VoucherTypeService.get(props.placeId, id)

            if (res?.isSuccess) {
                setFormData(res.data)

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (props.placeId) {
            if (formRef.current?.checkValidity()) {
                try {
                    if (formData.id && formData.id > 0) {
                        const res = await VoucherTypeService.update(props.placeId, formData.id, formData)

                        if (res?.isSuccess) {
                            FilterFn(currentPageIndex)
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    } else {
                        const res = await VoucherTypeService.create(props.placeId, formData)

                        if (res?.isSuccess) {
                            FilterFn(1)
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            } else {
                setValidatedForm(true)
            }
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Voucher Type" : "New Voucher Type"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setDeletedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (props.placeId && deletedItem.id > 0) {
            const res = await VoucherTypeService.delete(props.placeId, deletedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handleIsUsedSwitch = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await VoucherTypeService.changeIsUsed(props.placeId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton className="p-2">
                    <Modal.Title><i className="fas fa-ticket-alt"></i> Voucher Types <span>|</span> {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Card className="m-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Card.Header>
                            {hasPermission(Permissions.VoucherTypes.Create) &&
                                <Button variant="info" size="sm" onClick={handleAddNew}>
                                    <i className="fas fa-plus"></i> Add new
                                </Button>
                            }

                            <div className="card-tools">
                                <InputGroup size="sm">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        ref={keywordRef as RefObject<HTMLInputElement>}
                                        onKeyUp={handleSearchInputKeyPress}
                                    />
                                    <Button variant="info" size="sm" onClick={handleSearch}>
                                        <i className="fas fa-search"></i>
                                    </Button>
                                </InputGroup>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Currency</th>
                                        <th>Time Limit (Hours)</th>
                                        <th>Order Number</th>
                                        <th style={{ textAlign: "center", width: "100px" }}>Status</th>
                                        <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.data.map(item =>
                                            <tr key={item.id}>
                                                <td>{item.name || ""}</td>
                                                <td>{Utils.formatNumber(item.price)}</td>
                                                <td>{item.currency}</td>
                                                <td>{item.timeLimit}</td>
                                                <td>{item.orderNumber || ""}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Form.Check type="switch" id={"voucher_type_isused_" + item.id} checked={item.isUsed} onChange={() => handleIsUsedSwitch(item.id)} label="Active" disabled={!hasPermission(Permissions.VoucherTypes.Edit)} />
                                                </td>
                                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                    {hasPermission(Permissions.VoucherTypes.Edit) &&
                                                        <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                    }
                                                    {hasPermission(Permissions.VoucherTypes.Delete) &&
                                                        <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" name="name" required value={formData.name || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                        </Form.Group>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Price</InputGroup.Text>
                            <Form.Control type="number" min={0} step={0.1} name="price" required value={formData.price || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <InputGroup.Text>Currency</InputGroup.Text>
                            <Form.Select name="currency" required value={formData.currency || ""} onChange={(e) => handleFormControlChange(e as any)}>
                                <option value=""></option>
                                <option value="VND">VND</option>
                                <option value="USD">USD</option>
                            </Form.Select>
                            <InputGroup.Text>Time Limit</InputGroup.Text>
                            <Form.Control type="number" min={0} name="timeLimit" value={formData.timeLimit || ""} onChange={(e) => handleFormControlChange(e as any)} />
                            <InputGroup.Text style={{ borderTopRightRadius: "0.375rem", borderBottomRightRadius: "0.375rem" }}>Hour(s)</InputGroup.Text>
                            <Form.Control.Feedback type="invalid">Price and Currency is required.</Form.Control.Feedback>
                        </InputGroup>
                        <Form.Group controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" min={0} name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />

        </>
    )
}

export default VoucherTypesModal