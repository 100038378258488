import { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import AreaService, { IArea } from "../services/AreaService"

interface IAreaSelectProps {
    name: "parentId" | "areaId",
    placeId: number | null
    excludeBranchId: number | null
    value: number | null
    required?: boolean
    onChange: (e: any) => void
}

function AreaSelect(props: IAreaSelectProps) {
    const [placeOptions, setAreaOptions] = useState([] as any[])

    const AreaOptions = async () => {
        var options: any[] = []

        if (props.placeId) {
            const res = await AreaService.list(props.placeId, props.excludeBranchId)
            if (res?.isSuccess) {
                const areas: IArea[] = res.data
                var roots = areas.filter(x => x.parentId === null)
                var level: number = 1

                CreateTreeOption(areas, roots, level, options)
                // options = areas.map((item) => <option key={item.id} value={item.id || 0}>{item.name}</option>)
            } else {
                console.log(res?.message)
            }
        }

        return options
    }

    const CreateTreeOption = (areas: IArea[], roots: IArea[], level: number, options: any[]) => {
        roots.forEach(item => {
            options.push(<option key={item.id} value={item.id || 0}>{CreateIndentedString(level)} {item.name}</option>)

            var parents = areas.filter(x => x.parentId === item.id)
            CreateTreeOption(areas, parents, level + 1, options)
        })
    }

    const CreateIndentedString = (level: number) => {
        let str: string = ""
        for (let i = 1; i < level; i++) {
            str += "|----"
        }
        return str
    }

    useEffect(() => {
        AreaOptions().then(options => {
            setAreaOptions(options)
        })
    }, [props.excludeBranchId])

    return (
        <Form.Group className="mt-3" controlId={props.name}>
            <Form.Label>{props.name === "parentId" ? "Parent" : "Area"}</Form.Label>
            <Form.Select name={props.name} required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {placeOptions}
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Select a {props.name === "parentId" ? "Parent" : "Area"}.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default memo(AreaSelect)