import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IBank {
    name: string
    code: string
    bin: string
    short_name: string
}

class BankService {
    static async list() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Banks/List`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}

export default BankService