import { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import PlaceService, { IPlace } from "../services/PlaceService"
import Utils from "../utils/Utils"

interface IPlaceSelectProps {
    providerId: number | null
    placeId: number | null
    voucherTypeId?: number | null
    required?: boolean
    onChange: (e: any) => void
}

function PlaceSelect(props: IPlaceSelectProps) {
    const [placeOptions, setPlaceOptions] = useState([] as any[])
    const [places, setPlaces] = useState<Array<IPlace>>([])

    const PlaceOptions = async (providerId: number) => {
        const res = await PlaceService.listAllPublished(providerId)
        let options: any[] = []

        if (res?.isSuccess) {
            options = res.data.map((item: IPlace) => <option key={item.id} value={item.id || 0}>{item.name}</option>)
            setPlaces(res.data)
        } else {
            console.log(res?.message)
            setPlaces([])
        }

        return options
    }

    useEffect(() => {
        if (props.providerId) {
            PlaceOptions(props.providerId).then(options => {
                setPlaceOptions(options)
            })
        }
    }, [])

    const ShowPrice = () => {
        const item = places.find(x => x.id === props.placeId)
        if (item) {
            return (item.voucherTypes?.length || 0) === 0 && item.price && <Form.Text>
                <span className="text-danger">{Utils.formatNumber(item.price)} {item.currency}</span>
                {item.price && (item.timeLimit || 0) > 0 ? <span className="text-muted">/{item.timeLimit} hours</span> : <span className="text-muted">/Unlimited</span>}
            </Form.Text>
        }
    }

    const ShowVoucherTypes = () => {
        const item = places.find(x => x.id === props.placeId)
        if (item) {
            return (item.voucherTypes?.length || 0) > 0 && <Form.Group className="mb-3">
                <Form.Label>Voucher Types</Form.Label>
                <Form.Select name="voucherTypeId" required={props.required} value={props.voucherTypeId || ""} onChange={props.onChange}>
                    <option value=""></option>
                    {
                        item.voucherTypes?.map(voucherType => {
                            return <option key={voucherType.id} value={voucherType.id || 0}>
                                {voucherType.name} (
                                {Utils.formatNumber(voucherType.price)} {voucherType.currency}
                                {voucherType.price && (voucherType.timeLimit || 0) > 0 ? <>/{voucherType.timeLimit} hours</> : <>/Unlimited</>}
                                )
                            </option>
                        })
                    }
                </Form.Select>
                {props.required && <Form.Control.Feedback type="invalid">Select a voucher type.</Form.Control.Feedback>}
            </Form.Group>
        }
    }

    return <>
        <Form.Group className="mb-3" controlId="placeId">
            <Form.Label>Place</Form.Label>
            <Form.Select name="placeId" required={props.required} value={props.placeId || ""} onChange={props.onChange}>
                <option value=""></option>
                {placeOptions}
            </Form.Select>
            {ShowPrice()}
            {props.required && <Form.Control.Feedback type="invalid">Select a place.</Form.Control.Feedback>}
        </Form.Group>
        {ShowVoucherTypes()}
    </>
}

export default memo(PlaceSelect)