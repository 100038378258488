import { useLocation } from 'react-router-dom'
import useAppContext from '../../hooks/useAppContext'
import PayGateService from '../../services/PayGateService'
import { useEffect, useState } from 'react'

function CheckOut() {
    const location = useLocation()
    const { state } = useAppContext()

    const [message, setMessage] = useState("")

    const urlParams = new URLSearchParams(window.location.search)

    const checkPaymentResponse = async () => {
        var orderId = parseInt(urlParams.get('vpc_OrderInfo') || "") || null

        if (state.currentProviderId && orderId) {

            const res = await PayGateService.checkPaymentResponse(state.currentProviderId, orderId, location.search)

            setMessage(res.data.vi || res.message)
        }
    }

    useEffect(() => {
        checkPaymentResponse()
    }, [])

    return (<h1 style={{ textAlign: "center" }}>{message}</h1>)
}

export default CheckOut