import { Dropdown, DropdownButton } from "react-bootstrap"
import useAuthContext from "../../hooks/useAuthContext"
import { Link } from "react-router-dom"
import { ReportItems } from "../../constants/ReportItems"

export function DashboardDropdownItems() {
    const { hasPermission } = useAuthContext()

    return ReportItems.filter(x => hasPermission(x.name))
}

function DashboardDropdown(props: {
    reportName: string
}) {
    const dropdownItems = DashboardDropdownItems()

    return (
        <>
            {dropdownItems.length > 1 &&
                <DropdownButton title={""} variant="default" size="sm">
                    {dropdownItems.map((x, i) => <Dropdown.Item as={Link} to={x.link} key={i}>
                        <i className={`fas fa-chart-line ${props.reportName === x.name ? "text-warning" : "text-info"}`}></i> {x.text}
                    </Dropdown.Item>)
                    }
                </DropdownButton>}
        </>
    )
}

export default DashboardDropdown 