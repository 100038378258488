import AppStateService from "../services/AppStateService"
import { ActionMap, ActionTypes } from "./actions"

type AppStatePayload = {
    [ActionTypes.CHANGE_PROVIDER]: number | null
    [ActionTypes.CHANGE_AGENCY]: number | null
}

export type AppStateActions = ActionMap<AppStatePayload>[keyof ActionMap<AppStatePayload>]

export const appStateReducer = (state: number | null, action: AppStateActions) => {
    var appState = AppStateService.get()
    switch (action.type) {
        case ActionTypes.CHANGE_PROVIDER:
            appState.currentProviderId = action.payload
            AppStateService.save(appState)

            return state = action.payload
        case ActionTypes.CHANGE_AGENCY:
            appState.currentAgencyId = action.payload
            AppStateService.save(appState)

            return state = action.payload
        default:
            return state
    }
}