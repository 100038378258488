import { IEvent } from "./EventService"
import { IPlace } from "./PlaceService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IApiKey {
    id: string
    name: string
    company: string
    address: string
    email: string
    country: string
    accessKey: string
    isActive: boolean
    providerId: number
    places: Array<IPlace>
    events: Array<IEvent>
}

interface IApiKeyPlace {
    placeId: number
}

interface IApiKeyEvent {
    eventId: number
}

export interface IApiKeyUpdate {
    name: string
    company: string
    address: string
    email: string
    country: string
    places: Array<IApiKeyPlace>
    events: Array<IApiKeyEvent>
}

export default class ApiKeyService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ApiKeys/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ApiKeys/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ApiKeys/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: string) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ApiKeys/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: string, itemData: IApiKeyUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/ApiKeys/${providerId}/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, itemData: IApiKeyUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/ApiKeys/${providerId}/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsActive(providerId: number, id: string) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/ApiKeys/${providerId}/ChangeIsActive/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}