import moment from "moment"
import Configs from "../Configs"

class Utils {
    static getTimezoneOffset() {
        return moment().utcOffset()
    }

    static getCurrentDate() {
        return moment().format("YYYY-MM-DDTHH:mm")
    }

    static getCurrentMonth() {
        return moment().format("YYYY-MM")
    }

    static startOfMonth() {
        var date = new Date()
        return new Date(date.getFullYear(), date.getMonth(), 1)
    }

    static endOfMonth() {
        var date = new Date()
        return new Date(date.getFullYear(), date.getMonth() + 1, 0)
    }

    static startOfWeek() {
        return moment().startOf('week').toDate()
    }

    static endOfWeek() {
        return moment().endOf('week').toDate()
    }

    static dateToString(date?: Date | string | null, format?: string | null) {
        if (date) {
            return moment(date).format(format || Configs.DATE_FORMAT)
        } else {
            return ""
        }
    }

    static stringToDate(str?: string | null) {
        if (str && moment(str, "YYYY-MM-DD").isValid()) {
            return new Date(str)
        } else {
            return null
        }
    }

    static combineDateAndTime(date?: Date | null, time?: string | null) {
        var timeParsed = moment(time || "00:00", "HH:mm")
        if (date && timeParsed.isValid()) {
            date.setHours(timeParsed.hour())
            date.setMinutes(timeParsed.minute())

            return date
        } else {
            return null
        }
    }

    static combineBirthday(ngaySinh?: number | null, thangSinh?: number | null, namSinh?: number | null) {
        return [ngaySinh, thangSinh, namSinh].filter(x => x != null).join("/")
    }

    static isValidDateRange(start?: string | null, end?: string | null) {
        if (start && end) {
            return moment(end) >= moment(start)
        } else {
            return true
        }
    }

    static timeDuration(startDate?: Date | null, endDate?: Date | null) {
        if (startDate && endDate) {
            var diff = moment(endDate).diff(moment(startDate))
            var milliseconds = diff % 1000
            diff = (diff - milliseconds) / 1000
            var seconds = diff % 60
            diff = (diff - seconds) / 60
            var minutes = diff % 60
            diff = (diff - minutes) / 60
            var hours = diff % 24
            var days = (diff - hours) / 24

            var times = []
            if (days > 0) times.push(`${days}d`)
            if (hours > 0) times.push(`${hours}h`)
            if (minutes > 0) times.push(`${minutes}m`)
            if (seconds > 0) times.push(`${seconds}s`)
            if (milliseconds > 0) times.push(`${milliseconds}ms`)

            return times.join(':')

        } else {
            return null
        }
    }

    static formatNumber(num: number | null) {
        return num ? num.toLocaleString() : ""
    }

    static replaceNL2BR(str: string | null) {
        return str ? str.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ""
    }

    static splitNL2Array(str: string | null) {
        return str ? str.split(/(?:\r\n|\r|\n)/) : []
    }

    static getFileNameFromContentDisposition(contentDisposition: string) {
        var filename = ''
        var filenameRegex = /filename[^=\n]*=((['"]).*?\2|[^\n]*)/
        var matches = filenameRegex.exec(contentDisposition)
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
        }
        return filename
    }

    static getFileNameUTF8FromContentDisposition(disposition: string) {
        const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?: ?|$)/i
        const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?: ?|$)/i

        let fileName: string = ""
        if (utf8FilenameRegex.test(disposition)) {
            let result = utf8FilenameRegex.exec(disposition)
            if (result) {
                fileName = decodeURIComponent(result[1])
            }
        } else {
            // prevent ReDos attacks by anchoring the ascii regex to string start and slicing off everything before 'filename='
            const filenameStart = disposition.toLowerCase().indexOf('filename=')
            if (filenameStart >= 0) {
                const partialDisposition = disposition.slice(filenameStart)
                const matches = asciiFilenameRegex.exec(partialDisposition)
                if (matches != null && matches[2]) {
                    fileName = matches[2]
                }
            }
        }
        return fileName
    }

    static isJSON(stringJson: string) {
        try {
            return JSON.parse(stringJson) && !!stringJson
        } catch (e) {
            return false
        }
    }

    static downloadBase64File(base64Data: string, fileName: string) {
        const downloadLink = document.createElement("a")
        downloadLink.href = base64Data
        downloadLink.download = fileName
        downloadLink.click()
    }

    static getBaseUrl() {
        return window.location.origin
    }

    static formatXml(xml: string) {
        var formatted = ''
        var reg = /(>)(<)(\/*)/g
        xml = xml.replace(reg, '$1\r\n$2$3')
        var pad = 0
        xml.split('\r\n').forEach(node => {
            var indent = 0
            if (node.match(/.+<\/\w[^>]*>$/)) {
                indent = 0
            } else if (node.match(/^<\/\w/)) {
                if (pad !== 0) {
                    pad -= 1
                }
            } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
                indent = 1
            } else {
                indent = 0
            }

            var padding = ''
            for (var i = 0; i < pad; i++) {
                padding += '    '
            }

            formatted += padding + node + '\r\n'
            pad += indent
        })

        return formatted
    }

    static isEmpty(value?: string) {
        return (value === "" || value === null || value === undefined)
    }
}

export default Utils