import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import useAppContext from "../../hooks/useAppContext"
import DateRangePicker from "../../components/DateRangePicker"
import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import EventTicketService from "../../services/EventTicketService"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import { NavLink } from "react-router-dom"
import RoutePath from "../../constants/RoutePath"
import useAuthContext from "../../hooks/useAuthContext"
import DailySalesBySellerDashboardModal from "./DailySalesBySellerDashboardModal"
import { getReportItemText } from "../../constants/ReportItems"

function TicketSellersDashboard() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    const [reportData, setReportData] = useState<{
        createdBy: number,
        userName: string
        fullName: string,
        eventId: number,
        eventName: string,
        quantity: number,
        amount: number
    }[] | null>(null)

    const GetTicketSellersReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await EventTicketService.ticketSellersReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetTicketSellersReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [currentEventId, setCurrentEventId] = useState<number | null>(null)
    const [events, setEvents] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentEventId(null)
            setTicketSellersSummary([])

            setEvents(
                reportData
                    .map((x) => { return { id: x.eventId, name: x.eventName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )

            generateTicketSellersSummary(null)
        } else {
            setCurrentEventId(null)
            setEvents(null)
            setTicketSellersSummary([])
        }
    }, [reportData])

    const handleEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentEventId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateTicketSellersSummary(currentEventId)
    }, [currentEventId])

    type TSummaryItem = {
        createdBy: number | null
        userName: string
        fullName: string
        quantity: number
        amount: number
    }

    const [ticketSellersSummary, setTicketSellersSummary] = useState<TSummaryItem[]>([])

    const generateTicketSellersSummary = (eventId: number | null) => {
        const list = [] as TSummaryItem[]

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            var sellers = data
                .map((x) => { return { createdBy: x.createdBy, userName: x.userName, fullName: x.fullName } })
                .filter((value, index, self) => self.findIndex(x => x.createdBy === value.createdBy) === index)

            sellers.forEach(seller => {
                var items = data.filter(x => x.createdBy === seller.createdBy)

                var quantity = 0
                var amount = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                }

                var summaryItem: TSummaryItem = { ...seller, quantity: quantity, amount: amount }
                list.push(summaryItem)
            })

            //Total
            list.push({
                createdBy: null,
                userName: "",
                fullName: "Total",
                quantity: data.map(x => x.quantity).reduce((a, b) => a + b, 0),
                amount: data.map(x => x.amount).reduce((a, b) => a + b, 0)
            })

            setTicketSellersSummary(list)
        } else {
            setTicketSellersSummary([])
        }
    }

    //ShowDailySalesBySellerDashboardModal
    const [showDailySalesBySellerDashboardModal, setShowDailySalesBySellerDashboardModal] = useState(false)
    const [seller, setSeller] = useState<{
        sellerId: number | null
        sellerName: string
    }>({
        sellerId: null,
        sellerName: ""
    })

    const handleShowDailySalesBySellerDashboardModal = (sellerId: number | null, sellerName: string) => {
        if (state.currentProviderId && sellerId) {
            setSeller({
                sellerId: sellerId,
                sellerName: sellerName
            })
            setShowDailySalesBySellerDashboardModal(true)
        }
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>{getReportItemText(Permissions.AllEventTickets.TicketSellersReport)}</h1>
                            <DashboardDropdown reportName={Permissions.AllEventTickets.TicketSellersReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentEventId || ""} onChange={handleEventChange}>
                                        <option value="">All events</option>
                                        {events && events.map(event => {
                                            return <option value={event.id} key={event.id}>{event.name}</option>
                                        })}
                                    </Form.Select>

                                    <div className="card-tools">
                                        <NavLink to={RoutePath.PROVIDER.TICKET_SELLERS_DETAILS_DASHBOARD} style={{ whiteSpace: "nowrap" }} className="btn btn-light btn-sm"><i className="fas fa-list"></i> Details</NavLink>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Table responsive hover striped>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>#</th>
                                                <th>Seller</th>
                                                <th style={{ textAlign: "right" }}>Volume</th>
                                                <th style={{ textAlign: "right" }}>Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketSellersSummary.filter(x => x.createdBy !== null).map((item, index) => <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    {hasPermission(Permissions.AllEventTickets.DailySalesBySellerReport)
                                                        ? <a onClick={() => handleShowDailySalesBySellerDashboardModal(item.createdBy, item.fullName)} style={{ cursor: "pointer" }} className="text-primary">{item.fullName}</a>
                                                        : item.fullName}
                                                </td>
                                                <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.quantity)}</td>
                                                <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.amount)}</td>
                                            </tr>)}
                                        </tbody>
                                        <tfoot>
                                            {ticketSellersSummary.filter(x => x.createdBy === null).map((item, index) => <tr key={index}>
                                                <th></th>
                                                <th>{item.fullName}</th>
                                                <th style={{ textAlign: "right" }}>{Utils.formatNumber(item.quantity)}</th>
                                                <th style={{ textAlign: "right" }}>{Utils.formatNumber(item.amount)}</th>
                                            </tr>)}
                                        </tfoot>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody >

            <DailySalesBySellerDashboardModal
                show={showDailySalesBySellerDashboardModal}
                onClose={() => setShowDailySalesBySellerDashboardModal(false)}
                sellerId={seller.sellerId}
                title={seller.sellerName}
                startDate={dateReport.startDate}
                endDate={dateReport.endDate} />
        </>
    )
}

export default TicketSellersDashboard