import { AppProvider } from "./context/AppContext"
import AppRoutes from "./routes/AppRoutes"
import { GlobalProvider } from "./context/GlobalContext"
// import ErrorBoundary from "./components/ErrorBoundary"
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.css"

function App() {
  return (
    // <ErrorBoundary>
    <GlobalProvider>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </GlobalProvider>
    // </ErrorBoundary>
  )
}

export default App
