import { Card, Col, Container, Form, Row } from "react-bootstrap"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import { addMonths } from "date-fns/esm"
import Configs from "../../Configs"
import { ChangeEvent, useEffect, useState } from "react"
import ContentBody from "../../components/ContentBody"
import DatePicker from 'react-datepicker'
import VoucherService from "../../services/VoucherService"
import { DatasetChart, LineChart } from "../../components/ChartJS"
import Utils from "../../utils/Utils"
import useAuthContext from "../../hooks/useAuthContext"
import { NavLink } from "react-router-dom"
import RoutePath from "../../constants/RoutePath"
import { getReportItemText } from "../../constants/ReportItems"

function MonthlyVoucherProvidersDashboard() {
    const { hasPermission } = useAuthContext()

    const [dateReport, setDateReport] = useState<{
        startDate: Date | null,
        endDate: Date | null
    }>({
        startDate: addMonths(new Date(), -Configs.DEFAULT_LAST_MONTHS + 1),
        endDate: new Date()
    })

    const handleChange = ([startDate, endDate]: [Date | null, Date | null]) => {
        setDateReport({
            startDate: startDate,
            endDate: endDate
        })
    }

    const GetMonthlyActiveVoucherProvidersReport = async (startYear: number, startMonth: number, endYear: number, endMonth: number) => {
        const res = await VoucherService.monthlyActiveVoucherProvidersReport(startYear, startMonth, endYear, endMonth)

        if (res?.isSuccess) {
            setReportData(res.data)
        } else {
            setReportData(null)
            console.log(res.message);
        }
    }

    useEffect(() => {
        if (dateReport.startDate && dateReport.endDate) {
            var startYear = dateReport.startDate.getFullYear()
            var startMonth = dateReport.startDate.getMonth() + 1
            var endYear = dateReport.endDate.getFullYear()
            var endMonth = dateReport.endDate.getMonth() + 1

            GetMonthlyActiveVoucherProvidersReport(startYear, startMonth, endYear, endMonth)
        }
    }, [dateReport])

    const [reportData, setReportData] = useState<{
        date: Date,
        quantity: number,
        amount: number,
        paymentService: string,
        providerId: number,
        providerName: string
    }[] | null>(null)

    const [currentPaymentService, setCurrentPaymentService] = useState<string | null>(null)
    const [paymentServices, setPaymentServices] = useState<string[] | null>(null)

    const handlePaymentServiceChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentPaymentService(e.target.value ? e.target.value : null)
    }

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentPaymentService(null)
            setPaymentServices(
                reportData.map(x => x.paymentService)
                    .filter((value, index, self) => self.indexOf(value) === index)
                    .sort()
            )

            generateVoucherQuantityChartData(null)
            generateVoucherAmountChartData(null)
            generateSummaryBoxes()
        } else {
            setCurrentPaymentService(null)
            setPaymentServices(null)

            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
            setVoucherAmountChartData({ ...voucherAmountChartData, labels: [], datasets: [] })
        }
    }, [reportData])

    useEffect(() => {
        generateVoucherQuantityChartData(currentPaymentService)
        generateVoucherAmountChartData(currentPaymentService)
    }, [currentPaymentService])

    const [voucherQuantityChartData, setVoucherQuantityChartData] = useState({
        title: "SALES VOLUME",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherQuantityChartData = (paymentService: string | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.paymentService === (paymentService ? paymentService : x.paymentService))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(date => {
                //labels
                labels.push(Utils.dateToString(date, Configs.MONTH_FORMAT))
            })

            //providers
            var providers = data
                .map((x) => { return { id: x.providerId, name: x.providerName } })
                .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)

            providers.forEach(provider => {
                const dataset = {
                    label: provider.name,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.providerId === provider.id && x.date === date)

                    var quantity = 0
                    if (items) {
                        quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(quantity)
                })

                datasets.push(dataset)
            })

            //All
            const dataset = {
                label: "All",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var quantity = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(quantity)
            })

            datasets.push(dataset)

            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
        }
    }

    const [voucherAmountChartData, setVoucherAmountChartData] = useState({
        title: "SALES REVENUE",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherAmountChartData = (paymentService: string | null) => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var data = reportData.filter(x => x.paymentService === (paymentService ? paymentService : x.paymentService))

            //dates
            var dates = data.map((x) => x.date).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

            dates.forEach(d => {
                //labels
                labels.push(Utils.dateToString(d, Configs.MONTH_FORMAT))
            })

            //providers
            var providers = data
                .map((x) => { return { id: x.providerId, name: x.providerName } })
                .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)

            providers.forEach(provider => {
                const dataset = {
                    label: provider.name,
                    data: []
                } as DatasetChart

                dates.forEach(date => {
                    var items = data.filter(x => x.providerId === provider.id && x.date === date)

                    var amount = 0
                    if (items) {
                        amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(amount)
                })

                datasets.push(dataset)
            })

            //Total
            const dataset = {
                label: "All",
                data: []
            } as DatasetChart

            dates.forEach(date => {
                var items = data.filter(x => x.date === date)

                var amount = 0
                if (items) {
                    amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                }
                dataset.data.push(amount)
            })

            datasets.push(dataset)

            setVoucherAmountChartData({ ...voucherAmountChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherAmountChartData({ ...voucherAmountChartData, labels: [], datasets: [] })
        }
    }

    //Summary
    const [summaryBoxes, setSummaryBoxes] = useState<any>([])

    const generateSummaryBoxes = () => {
        if (reportData) {
            var boxes: any[] = []

            boxes.push(<Col lg="3" xs="6">
                <div className="summary-box">
                    <h6>Total</h6>
                    <h3 className="text-danger">{Utils.formatNumber(reportData.map(x => x.amount).reduce((a, b) => a + b, 0))}</h3>
                    <p className="text-muted">Volume: <span className="text-success text-bold" style={{ fontSize: "20px" }}>{Utils.formatNumber(reportData.map(x => x.quantity).reduce((a, b) => a + b, 0))}</span></p>
                </div>
            </Col>)

            var paymentServices = reportData.map((x) => x.paymentService)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort()

            paymentServices.forEach(paymentService => {
                var amount = reportData.filter(x => x.paymentService === paymentService).map(x => x.amount).reduce((a, b) => a + b, 0)
                var quantity = reportData.filter(x => x.paymentService === paymentService).map(x => x.quantity).reduce((a, b) => a + b, 0)

                boxes.push(<Col lg="3" xs="6">
                    <div className="summary-box">
                        <h6>{paymentService}</h6>
                        <h3 className="text-danger">{Utils.formatNumber(amount)}</h3>
                        <p className="text-muted">Volume: <span className="text-success text-bold" style={{ fontSize: "20px" }}>{Utils.formatNumber(quantity)}</span></p>
                    </div>
                </Col>)
            })

            setSummaryBoxes(boxes)
        } else {
            setSummaryBoxes([])
        }
    }

    return <>
        <section className="content-header">
            <Container fluid>
                <Row className="mb-2">
                    <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                        <h1>{getReportItemText(Permissions.AllVouchers.MonthlyVoucherProvidersReport)}</h1>
                        <DashboardDropdown reportName={Permissions.AllVouchers.MonthlyVoucherProvidersReport}></DashboardDropdown>
                    </Col>
                </Row>
            </Container>
        </section>

        <ContentBody>
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header>
                                <DatePicker
                                    onChange={handleChange}
                                    selectsRange
                                    startDate={dateReport.startDate}
                                    endDate={dateReport.endDate}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    className="form-control"
                                />
                                <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentPaymentService || ""} onChange={handlePaymentServiceChange}>
                                    <option value="">All payments</option>
                                    {paymentServices && paymentServices.map(paymentService => {
                                        return <option value={paymentService} key={paymentService}>{paymentService}</option>
                                    })}
                                </Form.Select>

                                {hasPermission(Permissions.AllVouchers.VoucherProvidersReport) &&
                                    <div className="card-tools" style={{ lineHeight: "38px", marginRight: 0 }}>
                                        <NavLink to={RoutePath.PROVIDER.VOUCHER_PROVIDERS_DASHBOARD} className="btn btn-sm btn-outline-info">
                                            <i className="fas fa-chart-line"></i> Summary Report
                                        </NavLink>
                                    </div>}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    {summaryBoxes}
                                </Row>
                                <Row>
                                    <Col>
                                        <LineChart title={voucherAmountChartData.title} labels={voucherAmountChartData.labels} datasets={voucherAmountChartData.datasets}></LineChart>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <LineChart title={voucherQuantityChartData.title} labels={voucherQuantityChartData.labels} datasets={voucherQuantityChartData.datasets}></LineChart>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </ContentBody>
    </>
}

export default MonthlyVoucherProvidersDashboard