import { Card, Col, Container, Row } from "react-bootstrap"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import ContentBody from "../../components/ContentBody"
import DateRangePicker from "../../components/DateRangePicker"
import { useEffect, useState } from "react"
import VoucherService from "../../services/VoucherService"
import { BarWithTotalLineChart, DatasetChart } from "../../components/ChartJS"
import Utils from "../../utils/Utils"
import { NavLink } from "react-router-dom"
import RoutePath from "../../constants/RoutePath"
import useAuthContext from "../../hooks/useAuthContext"
import { getReportItemText } from "../../constants/ReportItems"

function VoucherProvidersDashboard() {
    const { hasPermission } = useAuthContext()

    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: Utils.startOfMonth(),
        endDate: Utils.endOfMonth()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        GetActiveVoucherProvidersReport(dateReport.startDate, dateReport.endDate)
    }, [dateReport])

    const [reportData, setReportData] = useState<{
        paymentService: string,
        providerId: number,
        providerName: string,
        quantity: number,
        amount: number
    }[] | null>(null)

    const GetActiveVoucherProvidersReport = async (start: Date, end: Date) => {
        const res = await VoucherService.activeVoucherProvidersReport(start, end)

        if (res?.isSuccess) {
            setReportData(res.data)
        } else {
            setReportData(null)
            console.log(res.message);
        }
    }

    useEffect(() => {
        if (reportData && reportData.length) {
            generateVoucherQuantityChartData()
            generateVoucherAmountChartData()
            generateSummaryBoxes()
        } else {
            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
            setVoucherAmountChartData({ ...voucherAmountChartData, labels: [], datasets: [] })
            setSummaryBoxes([])
        }
    }, [reportData])

    const [voucherAmountChartData, setVoucherAmountChartData] = useState({
        title: "SALES REVENUE",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherAmountChartData = () => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var providers = reportData
                .map((x) => { return { id: x.providerId, name: x.providerName } })
                .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)

            var paymentServices = reportData.map((x) => x.paymentService)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort()

            providers.forEach(provider => {
                //labels
                labels.push(provider.name)
            })

            //paymentServices
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                providers.forEach(provider => {
                    var items = reportData.filter(x => x.paymentService === paymentService && x.providerId === provider.id)

                    var amount = 0
                    if (items) {
                        amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(amount)
                })

                datasets.push(dataset)
            })

            setVoucherAmountChartData({ ...voucherAmountChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherAmountChartData({ ...voucherAmountChartData, labels: [], datasets: [] })
        }
    }

    const [voucherQuantityChartData, setVoucherQuantityChartData] = useState({
        title: "SALES VOLUME",
        labels: [] as string[],
        datasets: [] as DatasetChart[]
    })

    const generateVoucherQuantityChartData = () => {
        const labels = [] as string[]
        const datasets: DatasetChart[] = []

        if (reportData) {
            var providers = reportData
                .map((x) => { return { id: x.providerId, name: x.providerName } })
                .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)

            var paymentServices = reportData.map((x) => x.paymentService)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort()

            providers.forEach(provider => {
                //labels
                labels.push(provider.name)
            })

            //paymentServices
            paymentServices.forEach(paymentService => {
                const dataset = {
                    label: paymentService,
                    data: []
                } as DatasetChart

                providers.forEach(provider => {
                    var items = reportData.filter(x => x.paymentService === paymentService && x.providerId === provider.id)

                    var quantity = 0
                    if (items) {
                        quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    }
                    dataset.data.push(quantity)
                })

                datasets.push(dataset)
            })

            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: labels, datasets: datasets })
        } else {
            setVoucherQuantityChartData({ ...voucherQuantityChartData, labels: [], datasets: [] })
        }
    }

    //Summary
    const [summaryBoxes, setSummaryBoxes] = useState<any>([])

    const generateSummaryBoxes = () => {
        if (reportData) {
            var boxes: any[] = []

            boxes.push(<Col lg="3" xs="6">
                <div className="summary-box">
                    <h6>Total</h6>
                    <h3 className="text-danger">{Utils.formatNumber(reportData.map(x => x.amount).reduce((a, b) => a + b, 0))}</h3>
                    <p className="text-muted">Volume: <span className="text-success text-bold" style={{ fontSize: "20px" }}>{Utils.formatNumber(reportData.map(x => x.quantity).reduce((a, b) => a + b, 0))}</span></p>
                </div>
            </Col>)

            var paymentServices = reportData.map((x) => x.paymentService)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort()

            paymentServices.forEach(paymentService => {
                var amount = reportData.filter(x => x.paymentService === paymentService).map(x => x.amount).reduce((a, b) => a + b, 0)
                var quantity = reportData.filter(x => x.paymentService === paymentService).map(x => x.quantity).reduce((a, b) => a + b, 0)

                boxes.push(<Col lg="3" xs="6">
                    <div className="summary-box">
                        <h6>{paymentService}</h6>
                        <h3 className="text-danger">{Utils.formatNumber(amount)}</h3>
                        <p className="text-muted">Volume: <span className="text-success text-bold" style={{ fontSize: "20px" }}>{Utils.formatNumber(quantity)}</span></p>
                    </div>
                </Col>)
            })

            setSummaryBoxes(boxes)
        } else {
            setSummaryBoxes([])
        }
    }

    return <>
        <section className="content-header">
            <Container fluid>
                <Row className="mb-2">
                    <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                        <h1>{getReportItemText(Permissions.AllVouchers.VoucherProvidersReport)}</h1>
                        <DashboardDropdown reportName={Permissions.AllVouchers.VoucherProvidersReport}></DashboardDropdown>
                    </Col>
                </Row>
            </Container>
        </section>

        <ContentBody>
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header>
                                <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>

                                {hasPermission(Permissions.AllVouchers.MonthlyVoucherProvidersReport) &&
                                    <div className="card-tools" style={{ lineHeight: "38px", marginRight: 0 }}>
                                        <NavLink to={RoutePath.PROVIDER.MONTHLY_VOUCHER_PROVIDERS_DASHBOARD} className="btn btn-sm btn-outline-info">
                                            <i className="fas fa-chart-line"></i> Monthly Report
                                        </NavLink>
                                    </div>}
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    {summaryBoxes}
                                </Row>
                                <Row>
                                    <Col>
                                        <BarWithTotalLineChart
                                            title={voucherAmountChartData.title}
                                            labels={voucherAmountChartData.labels}
                                            datasets={voucherAmountChartData.datasets}
                                        ></BarWithTotalLineChart>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <BarWithTotalLineChart
                                            title={voucherQuantityChartData.title}
                                            labels={voucherQuantityChartData.labels}
                                            datasets={voucherQuantityChartData.datasets}
                                        ></BarWithTotalLineChart>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </ContentBody>
    </>
}

export default VoucherProvidersDashboard