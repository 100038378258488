import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface IPlacePhotoTranslation {
    placePhotoId: number | null
    languageId: number
    title: string
    description: string
    language: ILanguage
}

export interface IPlacePhoto {
    id: number | null
    title: string
    photo: string
    orderNumber: number | null
    published: boolean
    placeId: number
    translations: Array<IPlacePhotoTranslation>
}

export default class PlacePhotoService {
    static async listAll(placeId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PlacePhotos/${placeId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(placeId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PlacePhotos/${placeId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PlacePhotos/${placeId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/PlacePhotos/${placeId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(placeId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/PlacePhotos/${placeId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(placeId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/PlacePhotos/${placeId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePublished(placeId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/PlacePhotos/${placeId}/ChangePublished/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}