type ContentBodyProps = {
    children?: JSX.Element | JSX.Element[] | string
}

function ContentBody({ children }: ContentBodyProps) {
    return (
        <section className="content">
            {children}
        </section>
    )
}

export default ContentBody