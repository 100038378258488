import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react";
import { Alert, Button, Form, InputGroup, Modal, Spinner } from "react-bootstrap";
import PlaceService, { IPlace, IPlaceTranslation, IWebPConfig } from "../../services/PlaceService";
import useAppContext from "../../hooks/useAppContext";
import Configs from "../../Configs";
import Utils from "../../utils/Utils";

interface IPlaceSettingsModalProps {
    placeId: number | null;
    show: boolean;
    title: string;
    onClose: () => void;
}

interface IDownloadFile {
    fileName: string;
    sizeInBytes: number | null;
}

interface IVersionInfo {
    lastModifiedTime: Date | null;
    downloadFiles: Array<IDownloadFile> | null;
}

interface IContentVersion {
    free: IVersionInfo;
    purchase: IVersionInfo;
    full: IVersionInfo;
    map: IVersionInfo;
}

function PlaceSettingsModal(props: IPlaceSettingsModalProps) {
    const { state } = useAppContext()
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [messageFormModal, setMessageFormModal] = useState("")

    const [webPConfig, setWebPConfig] = useState<IWebPConfig>({ quality: 30 })

    const initFormData: IPlace = {
        id: null,
        name: "",
        thumbnail: "",
        homeBGImage: "",
        introBGImage: "",
        orderNumber: null,
        price: null,
        currency: "",
        timeLimit: null,
        published: false,
        popular: false,
        useWebPImages: false,
        dynamicLink: "",
        providerId: state.currentProviderId || 0,
        locationId: null,
        lat: null,
        lng: null,
        zoom: null,
        placeTypeId: null,
        placeType: null,
        translations: [],
        regionId: null,
        regions: []
    }
    const [formData, setFormData] = useState(initFormData)

    const initContentVersion: IContentVersion = {
        free: {
            lastModifiedTime: null,
            downloadFiles: null
        },
        purchase: {
            lastModifiedTime: null,
            downloadFiles: null
        },
        full: {
            lastModifiedTime: null,
            downloadFiles: null
        },
        map: {
            lastModifiedTime: null,
            downloadFiles: null
        }
    }
    const [contentVersion, setContentVersion] = useState(initContentVersion)

    const loadFormData = async () => {
        if (state.currentProviderId) {
            if (props.placeId && props.placeId > 0 && props.show) {
                const res = await PlaceService.get(state.currentProviderId, props.placeId)

                if (res?.isSuccess) {
                    setFormData(res.data)
                } else {
                    console.log(res?.message)
                }
            }
        }
    }

    const loadContentVersion = async () => {
        if (state.currentProviderId) {
            if (props.placeId && props.placeId > 0 && props.show) {
                const res = await PlaceService.getContentVersion(state.currentProviderId, props.placeId)

                if (res?.isSuccess) {
                    setContentVersion(res.data)
                } else {
                    setContentVersion(initContentVersion)
                }
            }
        }
    }

    useEffect(() => {
        setMessageFormModal("")
        loadFormData()
        loadContentVersion()
    }, [props.placeId, props.show])

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "dynamicLink") {
            setFormData({ ...formData, dynamicLink: e.target.value })
        } else {
            setWebPConfig({ ...webPConfig, [e.target.name]: e.target.value ? parseFloat(e.target.value) : null })
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleConvertImageToWebP = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (state.currentProviderId) {
                if (formRef.current?.checkValidity()) {
                    if (props.placeId) {
                        const res = await PlaceService.convertImagesToWebP(state.currentProviderId, props.placeId, webPConfig)

                        setMessageFormModal(res?.message)
                    }
                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    //Use Web PImages Switch
    const handleUseWebPImagesSwitch = async () => {
        if (state.currentProviderId) {
            if (props.placeId && props.placeId > 0) {
                const res = await PlaceService.changeUseWebPImages(state.currentProviderId, props.placeId)
                if (res?.isSuccess) {
                    setFormData({ ...formData, useWebPImages: !formData.useWebPImages })
                } else {
                    console.log(res?.message)
                }
            }
        }
    }

    //Activating Languages
    const handleActiveLanguagesChange = (languageId: number | null) => {
        if (languageId) {
            setFormData({
                ...formData,
                translations: formData.translations.map((trans: IPlaceTranslation) => {
                    return trans.languageId !== languageId ? trans : { ...trans, isActive: !trans.isActive }
                })
            })
        }
    }

    const handleSaveActiveLanguages = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (state.currentProviderId) {
            if (props.placeId && props.placeId > 0) {
                var items = formData.translations.map((trans: IPlaceTranslation) => {
                    return { ...trans, isActive: trans.isActive || false }
                })

                const res = await PlaceService.changeActiveLanguages(state.currentProviderId, props.placeId, items)
                setMessageFormModal(res.message)
            }
        }
    }

    const handleSaveDynamicLink = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (state.currentProviderId) {
            if (props.placeId && props.placeId > 0) {
                const res = await PlaceService.updateDynamicLink(state.currentProviderId, props.placeId, formData.dynamicLink)
                setMessageFormModal(res.message)
            }
        }
    }

    const [isGettingLatestVersionsInfo, setIsGettingLatestVersionsInfo] = useState(false)

    const handleCreateContentVersion = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!isGettingLatestVersionsInfo) {
            setIsGettingLatestVersionsInfo(true)
            if (state.currentProviderId) {
                if (props.placeId && props.placeId > 0) {
                    const res = await PlaceService.createContentVersion(state.currentProviderId, props.placeId)
                    if (res.isSuccess) {
                        setContentVersion(res.data)
                    }
                    setMessageFormModal(res.message)
                }
            }
            setIsGettingLatestVersionsInfo(false)
        }
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-cog"></i> Place Settings <span>|</span> {props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0">
                {messageFormModal !== "" && <Alert variant="info" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                    <fieldset>
                        <legend>Convert Images to WebP</legend>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>Quality</InputGroup.Text>
                            <Form.Control type="number" min={1} max={100} name="quality" required value={webPConfig.quality} onChange={handleFormControlChange} />
                            <Button variant="info" onClick={handleConvertImageToWebP} disabled={processing} style={{ borderTopRightRadius: ".375rem", borderBottomRightRadius: ".375rem" }}>
                                {processing ? <><Spinner animation="border" size="sm" /> Converting...</> : <>Convert</>}
                            </Button>
                            <Form.Control.Feedback type="invalid">Quality must range from 1 to 100.</Form.Control.Feedback>
                        </InputGroup>
                        <Form.Check type="switch" id="useWebPImages" label="Use WebP Images" checked={formData.useWebPImages} onChange={() => handleUseWebPImagesSwitch()} />
                    </fieldset>
                    <fieldset>
                        <legend>Activating Languages</legend>
                        {formData.translations.map((trans: IPlaceTranslation) => {
                            const language = trans.language;
                            return <Form.Check
                                key={language.id}
                                type="checkbox"
                                id={`language-${language.id}`}
                                label={<><img src={`${Configs.API_BASE_URL}${language.flag}`} style={{ height: "20px" }} alt={language.name} /> {language.name}</>}
                                checked={trans.isActive || false}
                                className="text-dark"
                                onChange={() => handleActiveLanguagesChange(language.id)}
                            />
                        })}
                        <Button variant="default" onClick={handleSaveActiveLanguages} size="sm" className="text-info" style={{ float: "right", padding: 0 }}><i className="fas fa-check"></i> Apply</Button>
                    </fieldset>
                    <fieldset>
                        <legend>Dynamic Link</legend>
                        <InputGroup className="mb-3">
                            <InputGroup.Text>URL</InputGroup.Text>
                            <Form.Control type="text" name="dynamicLink" value={formData.dynamicLink || ""} onChange={handleFormControlChange} />
                            <Button variant="info" onClick={handleSaveDynamicLink}>Save</Button>
                        </InputGroup>
                        <Form.Text>
                            <a href="https://firebase.google.com/docs/dynamic-links/create-manually" target="_blank" rel="noreferrer" className="text-muted">
                                <i className="fas fa-question-circle"></i> Manually constructing a Dynamic Link URL
                            </a>
                        </Form.Text>
                    </fieldset>
                    <fieldset>
                        <legend>Content Versions</legend>
                        <ul>
                            <li>Free:
                                <ul>
                                    <li>Last Modified Time: <b>{Utils.dateToString(contentVersion.free?.lastModifiedTime, Configs.DATE_TIME_FORMAT)}</b></li>
                                    <li>Downloads:
                                        <ul>
                                            {contentVersion.free?.downloadFiles?.map((item: IDownloadFile) => {
                                                return <li key={item.fileName}>{item.fileName}: <b>{Utils.formatNumber((item.sizeInBytes || 0) / (1024 * 1024))}</b> MB</li>
                                            })}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>Purchase:
                                <ul>
                                    <li>Last Modified Time: <b>{Utils.dateToString(contentVersion.purchase?.lastModifiedTime, Configs.DATE_TIME_FORMAT)}</b></li>
                                    <li>Downloads:
                                        <ul>
                                            {contentVersion.purchase?.downloadFiles?.map((item: IDownloadFile) => {
                                                return <li key={item.fileName}>{item.fileName}: <b>{Utils.formatNumber((item.sizeInBytes || 0) / (1024 * 1024))}</b> MB</li>
                                            })}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>Full:
                                <ul>
                                    <li>Last Modified Time: <b>{Utils.dateToString(contentVersion.full?.lastModifiedTime, Configs.DATE_TIME_FORMAT)}</b></li>
                                    <li>Downloads:
                                        <ul>
                                            {contentVersion.full?.downloadFiles?.map((item: IDownloadFile) => {
                                                return <li key={item.fileName}>{item.fileName}: <b>{Utils.formatNumber((item.sizeInBytes || 0) / (1024 * 1024))}</b> MB</li>
                                            })}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>Map:
                                <ul>
                                    <li>Last Modified Time: <b>{Utils.dateToString(contentVersion.map?.lastModifiedTime, Configs.DATE_TIME_FORMAT)}</b></li>
                                    <li>Downloads:
                                        <ul>
                                            {contentVersion.map?.downloadFiles?.map((item: IDownloadFile) => {
                                                return <li key={item.fileName}>{item.fileName}: <b>{Utils.formatNumber((item.sizeInBytes || 0) / (1024 * 1024))}</b> MB</li>
                                            })}
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <Button variant="default" onClick={handleCreateContentVersion} size="sm" className="text-info" style={{ float: "right", padding: 0 }}>
                            {isGettingLatestVersionsInfo ? <><Spinner animation="border" size="sm" /> Creating...</> : <><i className="fas fa-file-medical"></i> Create New Content Versions</>}
                        </Button>
                    </fieldset>
                </Form>
            </Modal.Body>
        </Modal >
    )
}

export default PlaceSettingsModal