import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { IRoleOfUser, IUser, IUserSetPassword } from "../../services/UserService"
import Configs from "../../Configs"
import ProviderUserService from "../../services/ProviderUserService"
import ProviderRoleService from "../../services/ProviderRoleService"
import ContentHeader from "../../components/ContentHeader"
import ContentBody from "../../components/ContentBody"
import { Alert, Badge, Button, Card, Col, Container, Form, InputGroup, Modal, Row, Table } from "react-bootstrap"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import useAppContext from "../../hooks/useAppContext"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

function ProviderUsers() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()

    const [tableData, setTableData] = useState({
        data: new Array<IUser>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData = {
        id: 0,
        userName: "",
        password: "",
        confirmPassword: "",
        email: "",
        fullName: "",
        type: "",
        roles: new Array<IRoleOfUser>()
    } as IUser

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [validatedFormModal, setValidatedFormModal] = useState(false)
    const [formData, setFormData] = useState(initFormData)
    const [roles, setRoles] = useState(new Array<IRoleOfUser>())
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await ProviderUserService.filter(state.currentProviderId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        }
    }

    const GetRoles = async () => {
        if (state.currentProviderId) {
            const res = await ProviderRoleService.listAll(state.currentProviderId)

            if (res?.isSuccess) {
                setRoles(res.data.map((item: IRoleOfUser) => {
                    return { ...item, checked: false }
                }))
            } else {
                console.log(res?.message)
            }
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
        GetRoles()
    }, [state.currentProviderId])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const ClearCheckedRoles = () => {
        setRoles(roles.map(role => {
            return { ...role, checked: false }
        }))
    }

    const handleEdit = async (id: number) => {
        if (state.currentProviderId) {
            const res = await ProviderUserService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setFormData({
                    ...initFormData,
                    id: res.data.id,
                    avatar: res.data.avatar ? Configs.API_BASE_URL + res.data.avatar : Configs.DEFAULT_AVATAR,
                    userName: res.data.userName,
                    fullName: res.data.fullName,
                    email: res.data.email,
                    type: res.data.type,
                    roles: res.data.roles
                })

                setRoles(roles.map(role => {
                    let checked = (res.data.roles as IRoleOfUser[]).some(r => { return r.id === role.id })
                    return { ...role, checked: checked }
                }))

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleRolesChange = (roleId: number) => {
        setRoles(roles.map(role => {
            if (role.id === roleId) {
                return { ...role, checked: !role.checked }
            } else {
                return role
            }
        }))
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()
        if (state.currentProviderId) {
            if (formRef.current?.checkValidity()) {
                const data = {
                    ...formData,
                    roles: roles.filter(role => {
                        return role.checked === true
                    })
                }

                if (formData.id > 0) {
                    const res = await ProviderUserService.update(state.currentProviderId, formData.id, data)

                    if (res?.isSuccess) {
                        FilterFn(currentPageIndex)
                        handleCloseModal()
                        setMessage(res.message)
                    } else {
                        setMessageFormModal(res?.message)
                    }
                } else {
                    const res = await ProviderUserService.create(state.currentProviderId, data)

                    if (res?.isSuccess) {
                        FilterFn(1)
                        handleCloseModal()
                        setMessage(res.message)
                    } else {
                        setMessageFormModal(res?.message)
                    }
                }
            } else {
                setValidatedFormModal(true)
            }
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        ClearCheckedRoles()
        setValidatedFormModal(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setShowModal(true)
        setMessage("")
    }

    const modalTitle = formData.id > 0 ? (
        <div className="user-block">
            {formData.avatar && <img className="img-circle" src={formData.avatar} alt={formData.fullName} />}
            <span className="username">{formData.userName}</span>
            <span className="description">{formData.fullName}</span>
        </div>
    ) : "Create User"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number, name: string) => {
        setDeletedItem({
            id: id,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (state.currentProviderId) {
            const res = await ProviderUserService.delete(state.currentProviderId, deletedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const initUserSetPasswordData = {
        userId: 0,
        newPassword: "",
        confirmNewPassword: ""
    } as IUserSetPassword

    const formSetPasswordRef = useRef<HTMLFormElement>(null)
    const [messageSetPasswordModal, setMessageSetPasswordModal] = useState("")
    const [validatedSetPasswordForm, setValidatedSetPasswordForm] = useState(false)
    const [userSetPasswordData, setUserSetPasswordData] = useState(initUserSetPasswordData)
    const [showSetPasswordModal, setShowSetPasswordModal] = useState(false)
    const handleCloseSetPasswordModal = () => {
        setShowSetPasswordModal(false)
        setValidatedSetPasswordForm(false)
        setMessageSetPasswordModal("")
        setUserSetPasswordData(initUserSetPasswordData)
    }

    const handleShowSetPasswordModal = () => {
        setShowSetPasswordModal(true)
        setMessage("")
    }

    const handleNewPasswordItemChange = (e: ChangeEvent<HTMLInputElement>) => {
        setUserSetPasswordData({ ...userSetPasswordData, [e.target.name]: e.target.value })
    }

    const handleSetPassword = async (id: number) => {
        if (state.currentProviderId) {
            setValidatedSetPasswordForm(false)
            setMessage("")

            const res = await ProviderUserService.get(state.currentProviderId, id)

            if (res?.isSuccess) {
                setFormData({
                    ...initFormData,
                    id: res.data.id,
                    avatar: res.data.avatar ? Configs.API_BASE_URL + res.data.avatar : Configs.DEFAULT_AVATAR,
                    userName: res.data.userName,
                    fullName: res.data.fullName,
                    email: res.data.email,
                    type: res.data.type,
                    roles: res.data.roles
                })

                setUserSetPasswordData({ ...userSetPasswordData, userId: res.data.id })

                handleShowSetPasswordModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSaveNewPassword = async (e: SyntheticEvent) => {
        e.preventDefault()
        if (state.currentProviderId) {
            if (formSetPasswordRef.current?.checkValidity()) {
                const res = await ProviderUserService.setPassword(state.currentProviderId, userSetPasswordData.userId, userSetPasswordData)

                if (res?.isSuccess) {
                    handleCloseSetPasswordModal()
                    setMessage(res.message)
                } else {
                    setMessageSetPasswordModal(res?.message)
                }
            } else {
                setValidatedSetPasswordForm(true)
            }
        }
    }

    const handleLockout = async (userId: number) => {
        if (state.currentProviderId) {
            const res = await ProviderUserService.lockOrUnlock(state.currentProviderId, userId)

            if (res?.isSuccess) {
                //FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    return (
        <>
            <ContentHeader title="Users" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    {hasPermission(Permissions.ProviderUsers.Create) &&
                                        <Button variant="info" size="sm" onClick={handleAddNew}>
                                            <i className="fas fa-plus"></i> Create user
                                        </Button>
                                    }

                                    <div className="card-tools">
                                        <InputGroup size="sm">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search"
                                                ref={keywordRef as RefObject<HTMLInputElement>}
                                                onKeyUp={handleSearchInputKeyPress}
                                            />
                                            <Button variant="info" size="sm" onClick={handleSearch}>
                                                <i className="fas fa-search"></i>
                                            </Button>
                                        </InputGroup>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Table responsive striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Avatar</th>
                                                <th>Username</th>
                                                <th>Email</th>
                                                <th>Fullname</th>
                                                <th>User Type</th>
                                                <th>Roles</th>
                                                <th style={{ textAlign: "center" }}>Is Locked Out</th>
                                                {/* <th>Lockout End</th> */}
                                                <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tableData.data.map(item =>
                                                    <tr key={item.id}>
                                                        <td>{
                                                            item.avatar && <img src={Configs.API_BASE_URL + item.avatar} style={{ width: "100px" }} alt={item.userName} />
                                                        }</td>
                                                        <td>{item.userName}</td>
                                                        <td>{item.email}</td>
                                                        <td>{item.fullName}</td>
                                                        <td>{item.type}</td>
                                                        <td>{
                                                            item.roles.map((role) => {
                                                                return <Badge bg="light" key={role.id} className="mr-1">{role.name}</Badge>
                                                            })
                                                        }</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Form.Check type="switch" id={"provider_user_islockedout_" + item.id} defaultChecked={!item.isLockedOut} onChange={(e) => handleLockout(item.id)} disabled={!hasPermission(Permissions.ProviderUsers.Edit)} />
                                                        </td>
                                                        <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                            {hasPermission(Permissions.ProviderUsers.Edit) &&
                                                                <Button variant="secondary" size="sm" className="mr-1" onClick={() => handleSetPassword(item.id)}><i className="fas fa-key"></i> Set password</Button>
                                                            }
                                                            {hasPermission(Permissions.ProviderUsers.Edit) &&
                                                                <Button variant="warning" size="sm" className="mr-1" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                            }
                                                            {hasPermission(Permissions.ProviderUsers.Delete) &&
                                                                <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.userName)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </Card.Body>
                                <Card.Footer>
                                    <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedFormModal} ref={formRef as RefObject<HTMLFormElement>}>
                        {
                            formData.id === 0 && (
                                <>
                                    <Form.Group className="mb-3" controlId="userName">
                                        <Form.Label>Username</Form.Label>
                                        <Form.Control type="text" placeholder="Username" name="userName" required isInvalid={false} value={formData.userName} onChange={(e) => handleFormControlChange(e as any)} />
                                        <Form.Control.Feedback type="invalid">Please enter Username.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="password">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" name="password" required value={formData.password} onChange={(e) => handleFormControlChange(e as any)} />
                                        <Form.Control.Feedback type="invalid">Please enter Password.</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="confirmPassword">
                                        <Form.Label>Confirm Password</Form.Label>
                                        <Form.Control type="password" placeholder="Confirm Password" name="confirmPassword" required value={formData.confirmPassword} onChange={(e) => handleFormControlChange(e as any)} />
                                        <Form.Control.Feedback type="invalid">Please enter Confirm Password.</Form.Control.Feedback>
                                    </Form.Group>
                                </>
                            )
                        }
                        <Form.Group className="mb-3" controlId="fullName">
                            <Form.Label>Fullname</Form.Label>
                            <Form.Control type="text" placeholder="Fullname" name="fullName" required value={formData.fullName} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Please enter Fullname.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" name="email" required value={formData.email} onChange={(e) => handleFormControlChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Please provide a valid Email.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Roles</Form.Label>
                            {
                                roles.map(role => {
                                    return <Form.Check
                                        key={role.id}
                                        type="checkbox"
                                        id={`role-${role.id}`}
                                        label={role.name}
                                        checked={role.checked}
                                        onChange={() => handleRolesChange(role.id)}
                                        className="text-dark"
                                    />
                                })
                            }
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Close</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />

            <Modal show={showSetPasswordModal} onHide={handleCloseSetPasswordModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedSetPasswordForm} ref={formSetPasswordRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="newPassword">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="New Password" name="newPassword" required value={userSetPasswordData.newPassword} onChange={(e) => handleNewPasswordItemChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Please enter New Password.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="confirmNewPassword">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm New Password" name="confirmNewPassword" required value={userSetPasswordData.confirmNewPassword} onChange={(e) => handleNewPasswordItemChange(e as any)} />
                            <Form.Control.Feedback type="invalid">Please enter Confirm New Password.</Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                    {messageSetPasswordModal !== "" && <Alert variant="danger" onClose={() => setMessageSetPasswordModal("")} dismissible>{messageSetPasswordModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseSetPasswordModal}><i className="fas fa-times"></i> Close</Button>
                    <Button variant="info" onClick={handleSaveNewPassword}><i className="fas fa-save"></i> Save New Password</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProviderUsers