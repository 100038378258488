import { ChangeEvent, useEffect, useState } from "react"
import useAppContext from "../../hooks/useAppContext"
import Configs from "../../Configs"
import { addDays } from "date-fns/esm"
import EventTicketService from "../../services/EventTicketService"
import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import ContentBody from "../../components/ContentBody"
import DateRangePicker from "../../components/DateRangePicker"
import Utils from "../../utils/Utils"
import { getReportItemText } from "../../constants/ReportItems"

function InvitationCodesDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    const [reportData, setReportData] = useState<{
        invitationCode: string,
        eventId: number,
        eventName: string,
        quantity: number,
        amount: number
    }[] | null>(null)

    const GetInvitationCodesReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await EventTicketService.invitationCodesReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetInvitationCodesReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [currentEventId, setCurrentEventId] = useState<number | null>(null)
    const [events, setEvents] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentEventId(null)
            setInvitationCodesSummary([])

            setEvents(
                reportData
                    .map((x) => { return { id: x.eventId, name: x.eventName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )

            generateInvitationCodesSummary(null)
        } else {
            setCurrentEventId(null)
            setEvents(null)
            setInvitationCodesSummary([])
        }
    }, [reportData])

    const handleEventChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentEventId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateInvitationCodesSummary(currentEventId)
    }, [currentEventId])

    type TSummaryItem = {
        invitationCode: string
        quantity: number
        amount: number
    }

    const [invitationCodesSummary, setInvitationCodesSummary] = useState<TSummaryItem[]>([])

    const generateInvitationCodesSummary = (eventId: number | null) => {
        const list = [] as TSummaryItem[]

        if (reportData) {
            var data = reportData.filter(x => x.eventId === (eventId ? eventId : x.eventId))

            var invitataionCodes = data
                .map(x => x.invitationCode)
                .filter((value, index, self) => self.indexOf(value) === index)

            console.log(invitataionCodes)
            invitataionCodes.forEach(invitationCode => {
                var items = data.filter(x => x.invitationCode === invitationCode)

                var quantity = 0
                var amount = 0
                if (items) {
                    quantity = items.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    amount = items.map(x => x.amount).reduce((a, b) => a + b, 0)
                }

                var summaryItem: TSummaryItem = { invitationCode: invitationCode, quantity: quantity, amount: amount }
                list.push(summaryItem)
            })

            //Total
            list.push({
                invitationCode: "",
                quantity: data.map(x => x.quantity).reduce((a, b) => a + b, 0),
                amount: data.map(x => x.amount).reduce((a, b) => a + b, 0)
            })

            setInvitationCodesSummary(list)
        } else {
            setInvitationCodesSummary([])
        }
    }

    return (<>
        <section className="content-header">
            <Container fluid>
                <Row className="mb-2">
                    <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                        <h1>{getReportItemText(Permissions.AllEventTickets.InvitationCodesReport)}</h1>
                        <DashboardDropdown reportName={Permissions.AllEventTickets.InvitationCodesReport}></DashboardDropdown>
                    </Col>
                </Row>
            </Container>
        </section>

        <ContentBody>
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header>
                                <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentEventId || ""} onChange={handleEventChange}>
                                    <option value="">All events</option>
                                    {events && events.map(event => {
                                        return <option value={event.id} key={event.id}>{event.name}</option>
                                    })}
                                </Form.Select>
                            </Card.Header>
                            <Card.Body>
                                <Table responsive hover striped>
                                    <thead>
                                        <tr>
                                            <th style={{ width: "50px" }}>#</th>
                                            <th>Invitation Code</th>
                                            <th style={{ textAlign: "right" }}>Volume</th>
                                            <th style={{ textAlign: "right" }}>Revenue</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invitationCodesSummary.filter(x => x.invitationCode !== "").map((item, index) => <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.invitationCode}</td>
                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.quantity)}</td>
                                            <td style={{ textAlign: "right" }}>{Utils.formatNumber(item.amount)}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        {invitationCodesSummary.filter(x => x.invitationCode === "").map((item, index) => <tr key={index}>
                                            <th></th>
                                            <th>{item.invitationCode}</th>
                                            <th style={{ textAlign: "right" }}>{Utils.formatNumber(item.quantity)}</th>
                                            <th style={{ textAlign: "right" }}>{Utils.formatNumber(item.amount)}</th>
                                        </tr>)}
                                    </tfoot>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </ContentBody>
    </>)
}

export default InvitationCodesDashboard