import { createContext, useEffect, useState } from "react"
import LanguageService, { ILanguage } from "../services/LanguageService"

type GlobalContextType = {
    languages: Array<ILanguage>
}

const GlobalContext = createContext<GlobalContextType>({
    languages: []
})

function GlobalProvider({ children }: any) {
    const [global, setGlobal] = useState({
        languages: []
    } as GlobalContextType)

    useEffect(() => {
        const getLanguages = async () => {
            const res = await LanguageService.listAll()

            if (res?.isSuccess) {
                setGlobal({ ...global, languages: res.data })
            } else {
                console.log(res?.message)
            }
        }

        getLanguages().catch(console.error)
    }, [])

    return (
        <GlobalContext.Provider value={global}>
            {children}
        </GlobalContext.Provider>
    )
}

export { GlobalProvider, GlobalContext }