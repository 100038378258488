import { createContext, Dispatch, useReducer } from "react";
import AppStateService, { AppStateType } from "../services/AppStateService";
import { AppStateActions, appStateReducer } from "../store/reducers";

const initialAppState: AppStateType = AppStateService.get();

const AppContext = createContext<{
    state: AppStateType;
    dispatch: Dispatch<AppStateActions>;
}>({
    state: initialAppState,
    dispatch: () => null
});

const appReducer = (
    { currentProviderId, currentAgencyId }: AppStateType,
    action: AppStateActions
) => ({
    currentProviderId: appStateReducer(currentProviderId, action),
    currentAgencyId: appStateReducer(currentAgencyId, action)
});

function AppProvider({ children }: any) {
    const [state, dispatch] = useReducer(appReducer, initialAppState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };