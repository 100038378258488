import avatar from "./avatar.png"

namespace Configs {
    export const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL || ""
    export const RECOVER_PASSWORD_URL: string = process.env.REACT_APP_RECOVER_PASSWORD_URL || ""
    export const PRIVACY_POLICY_URL: string = process.env.REACT_APP_PRIVACY_POLICY_URL || ""
    export const AUDIO_PLAYER_URL: string = process.env.REACT_APP_AUDIO_PLAYER_URL || ""
    export const DEFAULT_LANGUAGE_CODE: string = "vi"
    export const CHUNK_SIZE: number = 5 * 1024 * 1024 //5MB (adjust based on your requirements)
    export const DEFAULT_PAGE_SIZE: number = 10
    export const DEFAULT_LAST_DAYS: number = 14
    export const DEFAULT_LAST_MONTHS: number = 12
    export const DEFAULT_AVATAR: string = avatar
    export const MONTH_FORMAT: string = "MM/YYYY"
    export const TIME_FORMAT: string = "HH:mm"
    export const DATE_FORMAT: string = "DD/MM/YYYY"
    export const DATE_TIME_FORMAT: string = "DD/MM/YYYY HH:mm (Z)"
    export const GOOGLE_MAPS_API_KEY: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
    export const IMAGE_EXTENSION: string = ".jpg,.jpeg,.png,.webp,.gif"
    export const AUDIO_EXTENSION: string = ".mp3"
    export const MAP_EXTENSION: string = ".mbtiles"
    export const IP_DETAILS_BASE_URL: string = process.env.REACT_APP_IP_DETAILS_BASE_URL || ""
    export class PrintMethod {
        static get = () => {
            return localStorage.getItem("PrintMethod") || "PDF"
        }

        static set = (printMethod: string) => {
            localStorage.setItem("PrintMethod", printMethod)
        }
    }
    export class Voucher {
        static getHideTestVouchers = () => {
            return (localStorage.getItem("HideTestVouchers") || "true") === "true"
        }

        static setHideTestVouchers = (val: boolean) => {
            localStorage.setItem("HideTestVouchers", val ? "true" : "false")
        }
    }
    export class Language {
        static get = () => {
            return localStorage.getItem("Language") || "en"
        }

        static set = (code: string) => {
            localStorage.setItem("Language", code)
        }
    }
}

export default Configs