import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IProviderPermission {
    id: number
    name: string
    checked: boolean
}

export interface IProviderRoleUpdate {
    name: string
    permissions: Array<IProviderPermission>
}

export interface IProviderRole extends IProviderRoleUpdate {
    id: number
}

export default class ProviderRoleService {
    static async listAll(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderRoles/${providerId}/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderRoles/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderRoles/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ProviderRoles/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, roleData: IProviderRoleUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/ProviderRoles/${providerId}/${id}`, roleData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, roleData: IProviderRoleUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/ProviderRoles/${providerId}/Create`, roleData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}