const RoutePath = {
    HOME: "/home",
    PAGE404: "/page-404",
    WELCOME: "/welcome",
    DOWNLOAD: "/download",
    FILEUPLOAD: "/file-upload",
    UNAUTHORIZED: "/unauthorized",
    AUTH: {
        LOGIN: "/login",
        REGISTER: "/register",
        FORGOT_PASSWORD: "/forgot-password",
        RECOVER_PASSWORD: "/recover-password",
        PROFILE: "/profile",
        CHANGE_PASSWORD: "/change-password"
    },
    ADMIN: {
        USERS: "/admin/users",
        ROLES: "/admin/roles",
        CATEGORY: "/admin/category/",
        REGIONS: "/admin/regions",
        LANGUAGES: "/admin/languages",
        PROVIDERS: "/admin/providers",
        AGENCIES: "/admin/agencies",
        LOCALIZATIONS: "/admin/localizations",
        PLACE_TYPES: "/admin/place-types",
        ERROR_LOGS: "/admin/error-logs",
        API_LOGS: "/admin/api-logs",
        AUDIT_LOGS: "/admin/audit-logs",
        CREDENTIALS: "/admin/credentials",
        EVENT_CATEGORIES: "/admin/event-categories",
    },
    PROVIDER: {
        PLACES: "/provider/places",
        API_KEYS: "/provider/api-keys",
        VOUCHERS: "/provider/vouchers",
        COMBOS: "/provider/combos",
        MY_VOUCHERS: "/provider/my-vouchers",
        DAILY_VOUCHER_DASHBOARD: "/provider/daily-voucher-dashboard",
        MONTHLY_VOUCHER_DASHBOARD: "/provider/monthly-voucher-dashboard",
        VOUCHER_SELLERS_DASHBOARD: "/provider/voucher-sellers-dashboard",
        VOUCHER_OVER_TIME_DASHBOARD: "/provider/voucher-over-time-dashboard",
        VOUCHER_PROVIDERS_DASHBOARD: "/provider/voucher-providers-dashboard",
        MONTHLY_VOUCHER_PROVIDERS_DASHBOARD: "/provider/monthly-voucher-providers-dashboard",
        AGENCY_DASHBOARD: "/provider/agency-dashboard",
        TICKET_TEMPLATES: "/provider/ticket-templates",
        PROVIDER_INFO: "/provider/info",
        USERS: "/provider/users",
        ROLES: "/provider/roles",
        APP_LOGS: "/provider/app-logs",
        AGENCY_PARTNERS: "/provider/agencies",
        EVENTS: "/provider/events",
        EVENT_TICKETS: "/provider/event-tickets",
        EVENT_TICKETS_SOLD: "/provider/event-tickets-sold",
        MY_EVENT_TICKETS: "/provider/my-event-tickets",
        MY_EVENT_TICKETS_SOLD: "/provider/my-event-tickets-sold",
        DAILY_EVENT_TICKET_DASHBOARD: "/provider/daily-event-ticket-dashboard",
        MONTHLY_EVENT_TICKET_DASHBOARD: "/provider/monthly-event-ticket-dashboard",
        TICKET_SELLERS_DASHBOARD: "/provider/ticket-sellers-dashboard",
        TICKET_SELLERS_DETAILS_DASHBOARD: "/provider/ticket-sellers-details-dashboard",
        TICKET_CLASSES_DASHBOARD: "/provider/ticket-classes-dashboard",
        INVITATION_CODES_DASHBOARD: "/provider/invitation-codes-dashboard",
        PAYMENT_TRANSACTION_LOGS: "/provider/payment-transaction-logs",
        ISSUE_INVOICE_LOGS: "/provider/issue-invoice-logs",
        CHECKOUT: "/provider/checkout"
    },
    AGENCY: {
        VOUCHERS: "/agency/vouchers",
        PROVIDER_PARTNERS: "/agency/providers",
        VOUCHER_DASHBOARD: "/agency/voucher-dashboard",
        AGENCY_INFO: "/agency/info",
    }
}

export default RoutePath;