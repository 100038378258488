import { Outlet } from "react-router-dom"
import { Footer } from "../components/Footer"
import UserNavbar from "../components/UserNavbar"
import { useEffect } from "react"
import Logo from "../components/Logo"

function TopNavLayout() {
    useEffect(() => {
        document.body.className = "hold-transition layout-top-nav"
    }, [])

    return (
        <div className="wrapper">
            <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
                <div className="container">

                    <Logo className="navbar-brand" />

                    <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                        <UserNavbar />
                    </div>
                </div>
            </nav>

            <div className="content-wrapper">
                <Outlet />
            </div>

            <Footer />
        </div>
    )
}

export default TopNavLayout