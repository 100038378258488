import { IRoleOfUser, IUserSetPassword } from "./UserService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IProviderUserUpdate {
    email: string
    fullName: string
    roles: Array<IRoleOfUser>
}

export interface IProviderUserCreate extends IProviderUserUpdate {
    userName: string
    password: string
    confirmPassword: string
}

export default class ProviderUserService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderUsers/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/ProviderUsers/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, userData: IProviderUserUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/ProviderUsers/${providerId}/${id}`, userData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, userData: IProviderUserCreate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/ProviderUsers/${providerId}/Create`, userData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async setPassword(providerId: number, id: number, userData: IUserSetPassword) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/ProviderUsers/${providerId}/SetPassword/${id}`, userData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async lockOrUnlock(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/ProviderUsers/${providerId}/LockOrUnlock/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/ProviderUsers/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}