import { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import PlaceTypeService, { IPlaceType } from "../services/PlaceTypeService"

interface IPlaceTypeSelectProps {
    value: number | null
    required?: boolean
    onChange: (e: any) => void
}

function PlaceTypeSelect(props: IPlaceTypeSelectProps) {
    const [placeTypeOptions, setPlaceTypeOptions] = useState([] as any[])

    const PlaceTypeOptions = async () => {
        const res = await PlaceTypeService.listAll()
        let options: any[] = []

        if (res?.isSuccess) {
            options = res.data.map((item: IPlaceType) => <option key={item.id} value={item.id || 0}>{item.name}</option>)
        } else {
            console.log(res?.message)
        }

        return options
    }

    useEffect(() => {
        PlaceTypeOptions().then(options => {
            setPlaceTypeOptions(options)
        })
    }, [])

    return (
        <Form.Group className="mb-3" controlId="placeTypeId">
            <Form.Label>Place Type</Form.Label>
            <Form.Select name="placeTypeId" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {placeTypeOptions}
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Select a Place Type.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default memo(PlaceTypeSelect)