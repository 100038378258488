import { ILocation } from "../constants/Common"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"
import { IPlaceType } from "./PlaceTypeService"
import { IRegion } from "./RegionService"
import { IVoucherType } from "./VoucherTypeService"

export interface IPlaceTranslation {
    placeId: number | null
    languageId: number
    name: string
    description: string
    audio: string
    audioType: string
    duration: number | null
    isActive: boolean
    language: ILanguage
}

export interface IPlace extends ILocation {
    id: number | null
    name: string
    thumbnail: string
    homeBGImage: string
    introBGImage: string
    price: number | null
    currency: string
    timeLimit: number | null
    orderNumber: number | null
    published: boolean
    popular: boolean
    useWebPImages: boolean
    dynamicLink: string
    providerId: number
    placeTypeId: number | null
    placeType: IPlaceType | null
    translations: Array<IPlaceTranslation>
    regionId: number | null
    regions: Array<IRegion>
    voucherTypes?: Array<IVoucherType>
}

export interface IWebPConfig {
    quality: number
}

export default class PlaceService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Places/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateDynamicLink(providerId: number, id: number, dynamicLink: string) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/UpdateDynamicLink/${id}`, { dynamicLink })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async createContentVersion(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/CreateContentVersion/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getContentVersion(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/${providerId}/GetContentVersion/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeActiveLanguages(providerId: number, id: number, items: Array<IPlaceTranslation>) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/ChangeActiveLanguages/${id}`, items)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Places/${providerId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePublished(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/ChangePublished/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePopular(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/ChangePopular/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeUseWebPImages(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/ChangeUseWebPImages/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async setLocation(providerId: number, id: number, location: ILocation) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/SetLocation/${id}`, location)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async search(providerId: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/${providerId}/Search?keyword=${keyword}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async searchForCredentials(keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/SearchForCredentials?keyword=${keyword}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getByIdForCredentials(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/GetByIdForCredentials/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listAllPublished(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/${providerId}/ListAllPublished`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listAllPublishedForAgency(agencyId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Places/${agencyId}/ListAllPublishedForAgency`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async convertImagesToWebP(providerId: number, id: number, itemData: IWebPConfig) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Places/${providerId}/ConvertImagesToWebP/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}