import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Button, Modal, Form, Table, InputGroup, Card, Alert, Tabs, Tab } from "react-bootstrap"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import AddOrRemoveLanguages, { LanguageActionTypes } from "../../components/AddOrRemoveLanguages"
import Pagination from "../../components/Pagination"
import Configs from "../../Configs"
import useGlobalContext from "../../hooks/useGlobalContext"
import CollectionService, { ICollection } from "../../services/CollectionService"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"

interface ICollectionsModalProps {
    placeId: number | null;
    show: boolean;
    title: string;
    onClose: () => void;
}

function CollectionsModal(props: ICollectionsModalProps) {
    const { hasPermission } = useAuthContext()
    const { languages } = useGlobalContext()

    const [tableData, setTableData] = useState({
        data: new Array<ICollection>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: ICollection = {
        id: null,
        name: "",
        thumbnail: "",
        orderNumber: null,
        isUsed: false,
        placeId: props.placeId || 0,
        translations: languages.filter(x => x.code === Configs.DEFAULT_LANGUAGE_CODE).map(x => {
            return {
                collectionId: null,
                languageId: x.id || 0,
                name: "",
                language: x
            }
        })
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const [activeTabKey, setActiveTabKey] = useState(languages.find(x => x.code === Configs.DEFAULT_LANGUAGE_CODE)?.id?.toString());

    const FilterFn = async (pageIndex: number) => {
        if (props.placeId && props.show) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await CollectionService.filter(props.placeId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        } else {
            setTableData({
                data: new Array<ICollection>(),
                hasNext: false,
                hasPrevious: false,
                pageIndex: 1,
                pageSize: Configs.DEFAULT_PAGE_SIZE,
                totalPages: 0,
                totalRecords: 0
            })
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [props.placeId, props.show])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "orderNumber") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleTranslateFormControlChange = (e: ChangeEvent<HTMLInputElement>, languageId: number | null) => {
        setFormData({ ...formData, translations: formData.translations.map(x => x.languageId === languageId ? { ...x, [e.target.name]: e.target.value } : x) })
    }

    const handleAddNew = () => {
        setFormData(initFormData)

        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await CollectionService.get(props.placeId, id)

            if (res?.isSuccess) {
                setFormData(res.data)
                setActiveTabKey(res.data.translations[0]?.languageId?.toString())

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (props.placeId) {
            if (formRef.current?.checkValidity()) {
                var frmData = new FormData();
                if (thumbnail !== null) {
                    frmData.append("Thumbnail", thumbnail);
                }
                frmData.append("OrderNumber", formData.orderNumber ? formData.orderNumber.toString() : "");
                frmData.append("PlaceId", formData.placeId ? formData.placeId.toString() : "");

                formData.translations.forEach((item, index) => {
                    if (item.collectionId) {
                        frmData.append(`Translations[${index}].CollectionId`, item.collectionId.toString());
                    }
                    frmData.append(`Translations[${index}].LanguageId`, item.languageId.toString());
                    frmData.append(`Translations[${index}].Name`, item.name.toString());
                });

                try {
                    if (formData.id && formData.id > 0) {
                        const res = await CollectionService.update(props.placeId, formData.id, frmData)

                        if (res?.isSuccess) {
                            FilterFn(currentPageIndex)
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    } else {
                        const res = await CollectionService.create(props.placeId, frmData)

                        if (res?.isSuccess) {
                            FilterFn(1)
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            } else {
                setValidatedForm(true)
            }
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setThumbnail(null)
        setMessage("")
        setShowModal(true)
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Collection" : "New Collection"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setDeletedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (props.placeId && deletedItem.id > 0) {
            const res = await CollectionService.delete(props.placeId, deletedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handleIsUsedSwitch = async (id: number | null) => {
        if (props.placeId && id) {
            const res = await CollectionService.changeIsUsed(props.placeId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    const handleLanguagesChange = async (languageId: number, action: LanguageActionTypes) => {
        if (action === "Add") {
            const newLang = languages.find(x => x.id === languageId)
            if (newLang) {
                if (formData.translations.filter(x => x.languageId === newLang.id).length === 0) {
                    const newFormData = { ...formData }

                    newFormData.translations.push({
                        collectionId: null,
                        languageId: newLang.id || 0,
                        name: "",
                        language: newLang
                    })

                    setFormData(newFormData)
                    setActiveTabKey(newLang.id?.toString())
                }
            }
        } else {
            const newTranslations = formData.translations.filter(x => x.languageId !== languageId)

            setFormData({ ...formData, translations: newTranslations })
            setActiveTabKey(newTranslations[0]?.languageId.toString())
        }
    }

    const [thumbnail, setThumbnail] = useState(null as any);

    const handleThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setThumbnail(e.target.files[0]);
        } else {
            setThumbnail(null);
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton className="p-2">
                    <Modal.Title><i className="fas fa-tags"></i> Collections <span>|</span> {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Card className="m-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Card.Header>
                            {hasPermission(Permissions.Collections.Create) &&
                                <Button variant="info" size="sm" onClick={handleAddNew}>
                                    <i className="fas fa-plus"></i> Add new
                                </Button>
                            }

                            <div className="card-tools">
                                <InputGroup size="sm">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        ref={keywordRef as RefObject<HTMLInputElement>}
                                        onKeyUp={handleSearchInputKeyPress}
                                    />
                                    <Button variant="info" size="sm" onClick={handleSearch}>
                                        <i className="fas fa-search"></i>
                                    </Button>
                                </InputGroup>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", width: "160px" }}>Thumbnail</th>
                                        <th>Name</th>
                                        <th>Order Number</th>
                                        <th style={{ textAlign: "center", width: "100px" }}>Status</th>
                                        <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.data.map(item =>
                                            <tr key={item.id}>
                                                <td>{item.thumbnail && <img src={`${Configs.API_BASE_URL}${item.thumbnail}`} style={{ maxWidth: "100%" }} alt={item.name} />}</td>
                                                <td style={{ whiteSpace: "pre-wrap" }}>{item.name}</td>
                                                <td>{item.orderNumber}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Form.Check type="switch" id={"collection_isused_" + item.id} checked={item.isUsed} onChange={() => handleIsUsedSwitch(item.id)} label="Published" disabled={!hasPermission(Permissions.Collections.Edit)} />
                                                </td>
                                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                    {hasPermission(Permissions.Collections.Edit) &&
                                                        <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                    }
                                                    {hasPermission(Permissions.Collections.Delete) &&
                                                        <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.name)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddOrRemoveLanguages selectedLanguages={formData.translations.map(x => x.language)} onChange={handleLanguagesChange}></AddOrRemoveLanguages>
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Thumbnail</Form.Label>
                            <Card className="shadow-0">
                                {formData.thumbnail && <Card.Img variant="top" src={`${Configs.API_BASE_URL}${formData.thumbnail}`} style={{ width: "inherit", maxWidth: "300px", margin: "auto" }} />}
                                <Card.Body className="p-2">
                                    <Form.Control type="file" onChange={handleThumbnailChange} accept={Configs.IMAGE_EXTENSION} />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                        <Tabs activeKey={activeTabKey} onSelect={(k) => setActiveTabKey(k || undefined)}>
                            {
                                formData.translations.sort((a, b) => { return (a.language.orderNumber || 0) - (b.language.orderNumber || 0) }).map(translate => {
                                    return (
                                        <Tab
                                            key={translate.languageId}
                                            eventKey={(translate.languageId).toString()}
                                            title={translate.language.name}
                                            className="p-3"
                                        >
                                            <Form.Group className="mb-3" controlId={"name_" + translate.languageId}>
                                                <Form.Label>Name</Form.Label>
                                                <Form.Control as="textarea" rows={2} name="name" required value={translate?.name || ""} onChange={(e) => handleTranslateFormControlChange(e as any, translate.languageId)} />
                                                <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Tab>
                                    )
                                })
                            }
                        </Tabs>

                        <Form.Group className="mt-3" controlId="orderNumber">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="number" name="orderNumber" value={formData.orderNumber || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />
        </>
    )
}

export default CollectionsModal