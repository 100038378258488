import { OverlayTrigger, Tooltip } from "react-bootstrap"

interface ITooltipInfoProps {
    title: string
}

function TooltipInfo(props: ITooltipInfoProps) {
    return (
        <>
            {
                props.title &&
                <OverlayTrigger overlay={<Tooltip>{props.title}</Tooltip>}>
                    <i className="fas fa-info-circle text-muted mx-1"></i>
                </OverlayTrigger>
            }
        </>
    )
}

export default TooltipInfo