import { Form } from "react-bootstrap"
import RegionService from "../services/RegionService"
import DropdownTreeSelect, { TreeNode } from "react-dropdown-tree-select"
import "react-dropdown-tree-select/dist/styles.css"
import { ITreeNode } from "../constants/Common"
import { useEffect, useState } from "react"

interface RegionDropdownTreeSelectProps {
    value: string[]
    onChange: (currentNode: TreeNode, selectedNodes: TreeNode[]) => void
}

function RegionDropdownTreeSelect(props: RegionDropdownTreeSelectProps) {
    const [treeRegions, setTreeRegions] = useState<Array<ITreeNode>>([])
    const GetTreeRegions = async () => {
        const res = await RegionService.getTreeData()

        if (res?.isSuccess) {
            updateTreeRegions(res.data, props.value)
            setTreeRegions(res.data)
        } else {
            setTreeRegions([])
            console.log(res?.message)
        }
    }

    useEffect(() => {
        GetTreeRegions()
    }, [])

    useEffect(() => {
        const data = [...treeRegions]

        updateTreeRegions(data, props.value)

        setTreeRegions(data)
    }, [props.value])

    const updateTreeRegions = (items: ITreeNode[], value: string[]) => {
        items.forEach(item => {
            if (value.includes(item.value)) {
                item.checked = true
            } else {
                item.checked = false
                if (item.children?.length) {
                    updateTreeRegions(item.children, value)
                }
            }
        })
    }

    return (
        <Form.Group className="mb-3">
            <Form.Label>Regions</Form.Label>
            <DropdownTreeSelect
                id="region"
                data={treeRegions}
                onChange={props.onChange}
                inlineSearchInput={true}
                mode="radioSelect"
                keepTreeOnSearch={true}
            />
        </Form.Group>
    )
}

export default RegionDropdownTreeSelect