import axios from "axios"
import Configs from "../Configs"
import AuthService from "./AuthService"
import TokenService from "./TokenService"
import RoutePath from "../constants/RoutePath"
import Utils from "../utils/Utils"

export type ApiResponse = {
    isSuccess: boolean
    message: string
    // errors: string[]
    data: any
    availableQuantity: number | null
}

export const axiosCustomError = (error: any): ApiResponse => {
    // console.log(error)
    let message = error as string

    if (axios.isAxiosError(error)) {
        message = error.message
    }

    return {
        isSuccess: false,
        message: message,
        // errors: [],
        data: null,
        availableQuantity: null
    } as ApiResponse
}

const axiosClient = axios.create({
    // withCredentials: true,
    baseURL: Configs.API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Timezone-Offset": Utils.getTimezoneOffset()
    }
})


axiosClient.interceptors.request.use(
    (config) => {
        let token = TokenService.getToken()
        if (token?.accessToken) {
            config.headers = {
                ...config.headers,
                "Accept-Language": Configs.Language.get(),
                "Authorization": `Bearer ${token.accessToken}`
            }
        }
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

axiosClient.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        const originalConfig = error.config

        if (error.response) {
            let apiResponse: ApiResponse = {
                isSuccess: false,
                message: '',
                // errors: [],
                data: {},
                availableQuantity: null
            }

            //Forbidden (403), Unauthorized (401), BadRequest (400) 
            // access token expired
            if (error.response.status === 401) {
                if (!originalConfig._retry) {
                    console.log('token expired')

                    // handle infinite loop
                    originalConfig._retry = true

                    try {
                        const token = TokenService.getToken()
                        if (token) {
                            const res = await AuthService.refreshToken(token)

                            if (res?.isSuccess) {
                                console.log('get new token')
                                TokenService.setUser(res.data)
                            }
                        }

                        return axiosClient(originalConfig)
                    } catch (err) {
                        console.log('refresh token error', err)
                        return Promise.reject(err)
                    }
                } else {
                    console.log('refresh token expired')
                    TokenService.removeUser()
                    window.location.href = RoutePath.AUTH.LOGIN
                }
            } else if (error.response.status === 400) {
                apiResponse.message = 'BadRequest'

                // for (const [key, value] of Object.entries(error.response.data.errors)) {
                //     apiResponse.errors = apiResponse.errors.concat(key, ': ', value as string[])
                // }

                return Promise.reject(apiResponse)
            } else if (error.response.status === 403) {
                window.location.href = RoutePath.UNAUTHORIZED
                apiResponse.message = 'Forbidden'
                return Promise.reject(apiResponse)
            } else {
                console.log(error.response)
            }
        }

        return Promise.reject(error)
    }
)

export default axiosClient