import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface ITimeZoneInfo {
    id: string
    displayName: string
    baseUtcOffset: string
}

export default class TimeZoneService {
    static async list() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TimeZones/List`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getCurrentDateTimeById(id: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/TimeZones/GetCurrentDateTimeById?id=${id}`)
            
            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}