import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { TToken } from "./TokenService"

class AuthService {
    static async login(username: string, password: string) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Auth/Login", {
                userName: username,
                password: password
            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async refreshToken(token: Partial<TToken>) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Auth/RefreshToken", token)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async logout() {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Auth/Logout")

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getAllUserTypes() {
        try {
            const { data } = await axiosClient.get<ApiResponse>("/api/Auth/GetAllUserTypes")

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}

export default AuthService