import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IPaymentTransactionLog {
    id: number
    orderId: number
    orderType: string
    message: string
    logDetails: string
    providerId: number
    createdOn: Date
}

export default class PaymentTransactionLogService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PaymentTransactionLogs/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PaymentTransactionLogs/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/PaymentTransactionLogs/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async clear(providerId: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/PaymentTransactionLogs/${providerId}/Clear`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}