import { useTranslation } from "react-i18next"
import useAuthContext from "../hooks/useAuthContext"

function Welcome() {
    const { t } = useTranslation("global")
    const { user } = useAuthContext()

    return (
        <div className="container text-center w3-animate-zoom" style={{ paddingTop: "20%" }}>
            <h1 className="welcome"><i className="far fa-hand-paper"></i> {t("welcomePage.title")} <b>{user?.userInfo.fullname}</b>!</h1>
            <h5 className="text-white">{t("welcomePage.subTitle")}</h5>
        </div>
    )
}

export default Welcome