import { createContext, useMemo } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import AuthService from "../services/AuthService"
import TokenService, { TToken, TUser } from "../services/TokenService"
import RoutePath from "../constants/RoutePath"

export interface IAuthContext {
    user: TUser | null,
    onLogin: (token: TToken | null) => void,
    onLogout: () => void,
    hasPermission: (permission: string | string[]) => boolean
}

const AuthContext = createContext<IAuthContext>({
    user: null,
    onLogin: () => { },
    onLogout: () => { },
    hasPermission: () => false
})

export function AuthProvider({ children }: any) {
    const user = TokenService.getUser()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams()
    const redirectTo = searchParams.get("redirectTo") || RoutePath.WELCOME

    const handleLogin = (token: TToken | null) => {
        if (token) {
            TokenService.setUser(token)
            navigate(redirectTo, { replace: true })
        }
    }

    const handleLogout = async () => {
        TokenService.removeUser()
        await AuthService.logout()
        navigate(RoutePath.AUTH.LOGIN, { replace: true })
    }

    const value = useMemo<IAuthContext>(() => ({
        user: user,
        onLogin: handleLogin,
        onLogout: handleLogout,
        hasPermission(permission) {
            if (!user?.permissions)
                return false
            if (typeof permission === "string")
                return user.permissions.includes(permission)

            return user.permissions.some(p => permission.includes(p))
        }
    }), [user])

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext