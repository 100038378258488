import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface IEventCategoryTranslation {
    id: number | null
    placeTypeId: number | null
    languageId: number
    name: string
    language: ILanguage
}

export interface IEventCategory {
    id: number | null
    name: string
    orderNumber: number | null
    isUsed: boolean
    translations: Array<IEventCategoryTranslation>
}

export default class EventCategoryService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventCategories/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventCategories?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/EventCategories/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/EventCategories/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: number, itemData: IEventCategory) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventCategories/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(itemData: IEventCategory) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/EventCategories/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/EventCategories/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}