import { ChangeEvent, KeyboardEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import Configs from "../../Configs"
import EventInvitationCodeService, { IEventInvitationCode } from "../../services/EventInvitationCodeService"
import { Alert, Button, Card, Col, Form, InputGroup, Modal, Row, Spinner, Table } from "react-bootstrap"
import Pagination from "../../components/Pagination"
import DeleteConfirmation from "../../components/DeleteConfirmation"
import useAuthContext from "../../hooks/useAuthContext"
import Permissions from "../../constants/Permissions"
import Utils from "../../utils/Utils"
import DatePicker from "../../components/DatePicker"

interface IEventInvitationCodesModalProps {
    eventId: number | null
    title: string
    show: boolean
    onClose: () => void
}

function EventInvitationCodesModal(props: IEventInvitationCodesModalProps) {
    const { hasPermission } = useAuthContext()

    const [tableData, setTableData] = useState({
        data: new Array<IEventInvitationCode>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })

    const initFormData: IEventInvitationCode = {
        id: null,
        code: "",
        description: "",
        maxQuantity: null,
        startDate: null,
        endDate: null,
        isActive: false,
        eventId: props.eventId || 0
    }

    const [message, setMessage] = useState("")
    const [messageFormModal, setMessageFormModal] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const [currentPageIndex, setCurrentPageIndex] = useState(1)
    const [deletedItem, setDeletedItem] = useState({
        id: 0,
        name: ""
    })

    const keywordRef = useRef<HTMLInputElement>(null)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)

    const FilterFn = async (pageIndex: number) => {
        if (props.eventId && props.show) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await EventInvitationCodeService.filter(props.eventId, pageIndex, pageSize, keyword)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }

            setCurrentPageIndex(pageIndex)
        } else {
            setTableData({
                data: new Array<IEventInvitationCode>(),
                hasNext: false,
                hasPrevious: false,
                pageIndex: 1,
                pageSize: Configs.DEFAULT_PAGE_SIZE,
                totalPages: 0,
                totalRecords: 0
            })
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [props.eventId, props.show])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "maxQuantity") {
            setFormData({ ...formData, [e.target.name]: e.target.value ? parseInt(e.target.value) : null })
        } else if (e.target.name === "isActive") {
            setFormData({ ...formData, [e.target.name]: e.target.checked })
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        }
    }

    const handleAddNew = () => {
        setFormData(initFormData)
        handleShowModal()
    }

    const handleEdit = async (id: number | null) => {
        if (props.eventId && id) {
            const res = await EventInvitationCodeService.get(props.eventId, id)

            if (res?.isSuccess) {
                setFormData(res.data)

                handleShowModal()
            } else {
                console.log(res?.message)
            }
        }
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.eventId) {
                if (formRef.current?.checkValidity()) {
                    if (formData.id && formData.id > 0) {
                        const res = await EventInvitationCodeService.update(props.eventId, formData.id, formData)

                        if (res?.isSuccess) {
                            FilterFn(currentPageIndex)
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    } else {
                        const res = await EventInvitationCodeService.create(props.eventId, formData)

                        if (res?.isSuccess) {
                            FilterFn(1)
                            handleCloseModal()
                            setMessage(res.message)
                        } else {
                            setMessageFormModal(res?.message)
                        }
                    }
                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    const [showModal, setShowModal] = useState(false)
    const handleCloseModal = () => {
        setShowModal(false)
        setValidatedForm(false)
        setMessageFormModal("")
    }
    const handleShowModal = () => {
        setMessage("")
        setShowModal(true)
    }

    const modalTitle = (formData.id || 0) > 0 ? "Edit Invitation Code" : "New Invitation Code"

    const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)

    const hideConfirmationModal = () => {
        setDeletedItem({
            id: 0,
            name: ""
        })

        setDisplayConfirmationModal(false)
    }

    const showDeleteConfirmation = (id: number | null, name: string) => {
        setDeletedItem({
            id: id || 0,
            name: name
        })

        setDisplayConfirmationModal(true)
        setMessage("")
    }

    const handleDelete = async () => {
        if (props.eventId && deletedItem.id > 0) {
            const res = await EventInvitationCodeService.delete(props.eventId, deletedItem.id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            }

            hideConfirmationModal()
            setMessage(res.message)
        }
    }

    const handlePublishedSwitch = async (id: number | null) => {
        if (props.eventId && id) {
            const res = await EventInvitationCodeService.changeIsActive(props.eventId, id)

            if (res?.isSuccess) {
                FilterFn(currentPageIndex)
            } else {
                console.log(res?.message)
            }
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="xl">
                <Modal.Header closeButton className="p-2">
                    <Modal.Title><i className="fas fa-envelope-open-text"></i> Invitation Code <span>|</span> {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <Card className="m-0" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Card.Header>
                            {hasPermission(Permissions.EventInvitationCodes.Create) &&
                                <Button variant="info" size="sm" onClick={handleAddNew}>
                                    <i className="fas fa-plus"></i> Add new
                                </Button>
                            }

                            <div className="card-tools">
                                <InputGroup size="sm">
                                    <Form.Control
                                        type="search"
                                        placeholder="Search"
                                        ref={keywordRef as RefObject<HTMLInputElement>}
                                        onKeyUp={handleSearchInputKeyPress}
                                    />
                                    <Button variant="info" size="sm" onClick={handleSearch}>
                                        <i className="fas fa-search"></i>
                                    </Button>
                                </InputGroup>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                            <Table responsive striped bordered hover>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", width: "160px" }}>Code</th>
                                        <th>Description</th>
                                        <th style={{ textAlign: "center", width: "140px" }}>Max Quantity</th>
                                        <th style={{ textAlign: "center", width: "100px" }}>Start Date</th>
                                        <th style={{ textAlign: "center", width: "100px" }}>End Date</th>
                                        <th style={{ textAlign: "center", width: "80px" }}>Active</th>
                                        <th style={{ textAlign: "center", width: "80px" }}>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        tableData.data.map(item =>
                                            <tr key={item.id}>
                                                <td>{item.code}</td>
                                                <td>{item.description}</td>
                                                <td>{Utils.formatNumber(item.maxQuantity)}</td>
                                                <td>{Utils.dateToString(item.startDate)}</td>
                                                <td>{Utils.dateToString(item.endDate)}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Form.Check type="switch" id={"event_invitation_code_isActive_" + item.id} checked={item.isActive} onChange={() => handlePublishedSwitch(item.id)} disabled={!hasPermission(Permissions.EventInvitationCodes.Edit)} />
                                                </td>
                                                <td style={{ textAlign: "center", whiteSpace: "nowrap" }}>
                                                    {hasPermission(Permissions.EventInvitationCodes.Edit) &&
                                                        <Button variant="warning" className="mr-1" size="sm" onClick={() => handleEdit(item.id)}><i className="fas fa-pencil-alt"></i> Edit</Button>
                                                    }
                                                    {hasPermission(Permissions.EventInvitationCodes.Delete) &&
                                                        <Button variant="danger" size="sm" onClick={() => showDeleteConfirmation(item.id, item.code)}><i className="fas fa-trash-alt"></i> Delete</Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                        <Card.Footer>
                            <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                        <Form.Group className="mb-3" controlId="code">
                            <Form.Label>Code</Form.Label>
                            <Form.Control type="text" name="code" value={formData.code || ""} onChange={(e) => handleFormControlChange(e as any)} readOnly={(formData.id || 0) > 0} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="description">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" name="description" value={formData.description || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="maxQuantity">
                            <Form.Label>Max Quantity</Form.Label>
                            <Form.Control type="number" min={1} name="maxQuantity" value={formData.maxQuantity || ""} onChange={(e) => handleFormControlChange(e as any)} />
                        </Form.Group>
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3 " controlId="startDate">
                                    <Form.Label>Start Date</Form.Label>
                                    <DatePicker name="startDate" value={formData.startDate} onChange={handleFormControlChange}></DatePicker>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="endDate">
                                    <Form.Label>End Date</Form.Label>
                                    <DatePicker name="endDate" value={formData.endDate} onChange={handleFormControlChange}></DatePicker>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    {messageFormModal !== "" && <Alert variant="danger" onClose={() => setMessageFormModal("")} dismissible>{messageFormModal}</Alert>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}><i className="fas fa-times"></i> Cancel</Button>
                    <Button variant="info" onClick={handleSave} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                </Modal.Footer>
            </Modal>

            <DeleteConfirmation show={displayConfirmationModal} onConfirm={handleDelete} onHide={hideConfirmationModal} id={deletedItem.id} name={deletedItem.name} />
        </>
    )
}

export default EventInvitationCodesModal