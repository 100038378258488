export type AppStateType = {
    currentProviderId: number | null
    currentAgencyId: number | null
}

class AppStateService {
    static save(state: AppStateType) {
        localStorage.setItem("appState", JSON.stringify(state))
    }

    static get() {
        const state = localStorage.getItem("appState")
        return state ? JSON.parse(state) as AppStateType : {
            currentProviderId: null,
            currentAgencyId: null
        } as AppStateType
    }

    static remove() {
        localStorage.removeItem("appState")
    }
}

export default AppStateService