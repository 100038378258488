import { RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Link, useSearchParams } from "react-router-dom"
import AccountService from "../services/AccountService"
import RoutePath from "../constants/RoutePath"
import { Alert, Button, Form, Spinner } from "react-bootstrap"
import { useTranslation } from "react-i18next"

function RecoverPassword() {
    const { t } = useTranslation("global")
    const [message, setMessage] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const [searchParams] = useSearchParams()
    const email = searchParams.get("email") || ""
    const token = searchParams.get("token") || ""

    const passwordRef = useRef<HTMLInputElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        passwordRef.current?.focus()
    }, [])

    const [isLoading, setIsLoading] = useState(false)
    const [isHideForm, setIsHideForm] = useState(false)
    const handleSubmit = async (e: SyntheticEvent) => {
        e.preventDefault()
        if (!isLoading) {
            setIsLoading(true)
            try {
                if (formRef.current?.checkValidity()) {
                    const res = await AccountService.resetPassword(password, confirmPassword, email, token)

                    if (res?.isSuccess) {
                        setMessage(res.message)
                        setIsHideForm(true)
                    } else {
                        setMessage(res?.message)
                    }
                } else {
                    setValidatedForm(true)
                }
            } catch (error) {
                console.log(error)
            }
            setIsLoading(false)
        }
    }

    return (
        <div className="page-center">
            <div className="card max-w-400">
                <div className="card-body">
                    <p className="login-box-msg">{t("recoverPasswordPage.title")}</p>
                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                    {!isHideForm &&
                        <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>} className="mb-4">
                            <Form.Group className="mb-3" controlId="newPassword">
                                <Form.Label>{t("recoverPasswordPage.newPassword")}</Form.Label>
                                <Form.Control type="password" name="newPassword" autoComplete="off" required ref={passwordRef} onChange={(e) => { setPassword(e.target.value) }} />
                                <Form.Control.Feedback type="invalid">{t("recoverPasswordPage.requiredNewPassword")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="confirmNewPassword">
                                <Form.Label>{t("recoverPasswordPage.confirmNewPassword")}</Form.Label>
                                <Form.Control type="password" name="confirmNewPassword" autoComplete="off" onChange={(e) => { setConfirmPassword(e.target.value) }} />
                                <Form.Control.Feedback type="invalid">{t("recoverPasswordPage.requiredConfirmNewPassword")}</Form.Control.Feedback>
                            </Form.Group>
                            <div className="text-center">
                                <Button variant="primary" onClick={handleSubmit}>
                                    {isLoading ? <><Spinner animation="border" size="sm" /> {t("recoverPasswordPage.changePassword")}</> : <>{t("recoverPasswordPage.changePassword")}</>}
                                </Button>
                            </div>
                        </Form>
                    }
                    <p className="mt-3 mb-1">
                        <Link to={RoutePath.AUTH.LOGIN}>{t("loginPage.signIn")}</Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default RecoverPassword