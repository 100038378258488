import { Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import ContentBody from "../../components/ContentBody"
import useAppContext from "../../hooks/useAppContext"
import DateRangePicker from "../../components/DateRangePicker"
import { ChangeEvent, useEffect, useState } from "react"
import { addDays } from "date-fns/esm"
import VoucherService from "../../services/VoucherService"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"
import DashboardDropdown from "./DashboardDropdown"
import Permissions from "../../constants/Permissions"
import uuid from "react-uuid"
import { getReportItemText } from "../../constants/ReportItems"

function VoucherSellersDashboard() {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: addDays(new Date(), -Configs.DEFAULT_LAST_DAYS + 1),
        endDate: new Date()
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    const [reportData, setReportData] = useState<{
        createdBy: number,
        userName: string
        fullName: string,
        placeId: number,
        placeName: string,
        voucherTypeName: string,
        quantity: number,
        amount: number
    }[] | null>(null)

    const GetActiveVoucherSellersReport = async (start: Date, end: Date) => {
        if (state.currentProviderId) {
            const res = await VoucherService.activeVoucherSellersReport(state.currentProviderId, start, end)

            if (res?.isSuccess) {
                setReportData(res.data)
            } else {
                setReportData(null)
                console.log(res.message);
            }
        }
    }

    useEffect(() => {
        if (state.currentProviderId) {
            GetActiveVoucherSellersReport(dateReport.startDate, dateReport.endDate)
        }
    }, [state.currentProviderId, dateReport])

    const [currentPlaceId, setCurrentPlaceId] = useState<number | null>(null)
    const [places, setPlaces] = useState<{
        id: number,
        name: string
    }[] | null>(null)

    useEffect(() => {
        if (reportData && reportData.length) {
            setCurrentPlaceId(null)
            setActiveVoucherSellersSummary([])

            setPlaces(
                reportData
                    .map((x) => { return { id: x.placeId, name: x.placeName } })
                    .filter((value, index, self) => self.findIndex(x => x.id === value.id) === index)
            )

            generateActiveVoucherSellersSummary(null)
        } else {
            setCurrentPlaceId(null)
            setPlaces(null)
            setActiveVoucherSellersSummary([])
        }
    }, [reportData])

    const handlePlaceChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setCurrentPlaceId(e.target.value ? parseInt(e.target.value) : null)
    }

    useEffect(() => {
        generateActiveVoucherSellersSummary(currentPlaceId)
    }, [currentPlaceId])

    const [ticketSellersSummary, setActiveVoucherSellersSummary] = useState<any[]>([])

    const generateActiveVoucherSellersSummary = (placeId: number | null) => {
        const list = [] as any[]

        if (reportData) {
            var data = reportData.filter(x => x.placeId === (placeId ? placeId : x.placeId))

            var sellers = data
                .map(x => { return { createdBy: x.createdBy, userName: x.userName, fullName: x.fullName } })
                .filter((value, index, self) => self.findIndex(x => x.createdBy === value.createdBy) === index)

            // sellers
            var sellerIndex = 0
            sellers.forEach(seller => {
                var showSeller = true
                var sellerItems = data.filter(x => x.createdBy === seller.createdBy)

                var places = sellerItems
                    .map(x => { return { placeId: x.placeId, placeName: x.placeName } })
                    .filter((value, index, self) => self.findIndex(x => x.placeId === value.placeId) === index)

                // places
                places.forEach(place => {
                    var showPlace = true
                    var placeItems = sellerItems.filter(x => x.placeId === place.placeId)

                    var voucherTypes = placeItems
                        .map(x => x.voucherTypeName)
                        .filter((value, index, self) => self.indexOf(value) === index)
                        .sort()

                    // voucherTypes
                    voucherTypes.forEach(voucherTypeName => {
                        var showVoucherType = true
                        var voucherTypeItems = placeItems.filter(x => x.voucherTypeName === voucherTypeName)

                        var quantity = voucherTypeItems.map(x => x.quantity).reduce((a, b) => a + b, 0)
                        var amount = voucherTypeItems.map(x => x.amount).reduce((a, b) => a + b, 0)

                        list.push(<tr key={uuid()} style={{ backgroundColor: sellerIndex % 2 === 0 ? "rgba(0,0,0,.05)" : "#fff" }}>
                            <td className={showSeller ? "border-top" : ""}>{showSeller && sellerIndex + 1}</td>
                            <td className={showSeller ? "border-top" : ""}>{showSeller && seller.fullName}</td>
                            <td className={showPlace ? "border-top" : ""}>{showPlace && place.placeName}</td>
                            <td className={showVoucherType ? "border-top" : ""} style={{ textAlign: "center" }}>{showVoucherType && voucherTypeName}</td>
                            <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(quantity)}</td>
                            <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(amount)}</td>
                        </tr>)

                        showVoucherType = false
                        showPlace = false
                        showSeller = false
                    })

                    //place total
                    var placeQuantity = placeItems.map(x => x.quantity).reduce((a, b) => a + b, 0)
                    var placeAmount = placeItems.map(x => x.amount).reduce((a, b) => a + b, 0)

                    list.push(<tr key={uuid()} style={{ fontStyle: "italic", fontWeight: "bold", backgroundColor: sellerIndex % 2 === 0 ? "rgba(0,0,0,.05)" : "#fff" }}>
                        <td className={showSeller ? "border-top" : ""}>{showSeller && sellerIndex + 1}</td>
                        <td className={showSeller ? "border-top" : ""}>{showSeller && seller.fullName}</td>
                        <td className={"border-top"}>Total for Place</td>
                        <td className={"border-top"} style={{ textAlign: "center" }}></td>
                        <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(placeQuantity)}</td>
                        <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(placeAmount)}</td>
                    </tr>)
                })

                //seller total
                var sellerQuantity = sellerItems.map(x => x.quantity).reduce((a, b) => a + b, 0)
                var sellerAmount = sellerItems.map(x => x.amount).reduce((a, b) => a + b, 0)

                list.push(<tr key={uuid()} style={{ fontStyle: "italic", fontWeight: "bold", backgroundColor: sellerIndex % 2 === 0 ? "rgba(0,0,0,.05)" : "#fff" }}>
                    <td className={showSeller ? "border-top" : ""}>{showSeller && sellerIndex + 1}</td>
                    <td className={"border-top"}>Total for Seller</td>
                    <td className={"border-top"}></td>
                    <td className={"border-top"} style={{ textAlign: "center" }}></td>
                    <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(sellerQuantity)}</td>
                    <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(sellerAmount)}</td>
                </tr>)

                sellerIndex++
            })

            //Grand Total
            var grandQuantity = data.map(x => x.quantity).reduce((a, b) => a + b, 0)
            var grandAmount = data.map(x => x.amount).reduce((a, b) => a + b, 0)

            list.push(<tr key={uuid()} style={{ fontWeight: "bold", backgroundColor: sellerIndex % 2 === 0 ? "rgba(0,0,0,.05)" : "#fff" }}>
                <td className={"border-top"}></td>
                <td className={"border-top"}>Total</td>
                <td className={"border-top"}></td>
                <td className={"border-top"} style={{ textAlign: "center" }}></td>
                <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(grandQuantity)}</td>
                <td className="border-top" style={{ textAlign: "right" }}>{Utils.formatNumber(grandAmount)}</td>
            </tr>)

            setActiveVoucherSellersSummary(list)
        } else {
            setActiveVoucherSellersSummary([])
        }
    }

    return (
        <>
            <section className="content-header">
                <Container fluid>
                    <Row className="mb-2">
                        <Col sm={12} style={{ display: "flex", alignItems: "center" }}>
                            <h1>{getReportItemText(Permissions.AllVouchers.VoucherSellersReport)}</h1>
                            <DashboardDropdown reportName={Permissions.AllVouchers.VoucherSellersReport}></DashboardDropdown>
                        </Col>
                    </Row>
                </Container>
            </section>

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Header>
                                    <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
                                    <Form.Select className="ml-3" style={{ width: "auto", display: "inline-block" }} value={currentPlaceId || ""} onChange={handlePlaceChange}>
                                        <option value="">All places</option>
                                        {places && places.map(place => {
                                            return <option value={place.id} key={place.id}>{place.name}</option>
                                        })}
                                    </Form.Select>
                                </Card.Header>
                                <Card.Body>
                                    <Table responsive hover className="table-ticket-sellers-details">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "50px" }}>#</th>
                                                <th>Seller</th>
                                                <th>Place</th>
                                                <th style={{ textAlign: "center" }}>Voucher Type</th>
                                                <th style={{ textAlign: "right" }}>Volume</th>
                                                <th style={{ textAlign: "right" }}>Revenue</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {ticketSellersSummary}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default VoucherSellersDashboard