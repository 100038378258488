import { Button, Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

interface DeleteConfirmationProps {
    id: number | string | null
    name: string
    show: boolean
    onHide: () => void
    onConfirm: (id: number | string | null) => void
}

function DeleteConfirmation(props: DeleteConfirmationProps) {
    const { t } = useTranslation("global")

    return (
        <Modal show={props.show} onHide={props.onHide} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{t("deleteConfirmation.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t("deleteConfirmation.message")} <b>"{props.name}"</b>?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onHide}>{t("deleteConfirmation.no")}</Button>
                {props.id && <Button variant="danger" onClick={() => props.onConfirm(props.id)}>{t("deleteConfirmation.yes")}</Button>}
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteConfirmation