import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import { Alert, Button, Col, Form, Modal, Row, Spinner, Table } from "react-bootstrap"
import EventTicketService, { IEventTicket, IEventTicketUpdate } from "../../services/EventTicketService"
import Utils from "../../utils/Utils"
import moment from "moment"
import Configs from "../../Configs"
import PaymentStatusSelect from "../../components/PaymentStatusSelect"
import PaymentServiceSelect from "../../components/PaymentServiceSelect"
import QRCodeService from "../../services/QRCodeService"
import QRCodeModal from "../../components/QRCodeModal"
import { useTranslation } from "react-i18next"
import PhoneInput, { CountryData } from "react-phone-input-2"

interface IMyEventTicketEditModalProps {
    providerId: number | null
    eventTicketId: number | null
    show: boolean
    onClose: () => void
    onSuccess: () => void
}

function MyEventTicketEditModal(props: IMyEventTicketEditModalProps) {
    const { t } = useTranslation("global")
    const [message, setMessage] = useState("")
    const [processing, setProcessing] = useState<boolean>(false)
    const [validatedForm, setValidatedForm] = useState(false)
    const formRef = useRef<HTMLFormElement>(null)

    const [eventTicketUpdate, setEventTicketUpdate] = useState<IEventTicketUpdate>()
    const [eventTicket, setEventTicket] = useState<IEventTicket>()

    const GetEventTicket = async () => {
        if (props.providerId && props.eventTicketId && props.show) {
            const res = await EventTicketService.getByUser(props.providerId, props.eventTicketId)

            if (res?.isSuccess) {
                const data: IEventTicket = res.data

                setEventTicket(data)

                setEventTicketUpdate({
                    note: data.note,
                    paymentService: data.paymentService,
                    paymentStatus: data.paymentStatus,
                    customer: {
                        id: data.customer.id,
                        name: data.customer.name,
                        tel: data.customer.tel,
                        formattedTel: data.customer.formattedTel,
                        email: data.customer.email,
                        address: data.customer.address,
                        personalId: data.customer.personalId
                    }
                })
            } else {
                console.log(res?.message)
                props.onClose()
            }
        } else {
            props.onClose()
        }
    }

    useEffect(() => {
        setMessage("")
        GetEventTicket()
    }, [props.providerId, props.show])

    const handleCustomerChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (eventTicketUpdate) {
            setEventTicketUpdate({ ...eventTicketUpdate, customer: { ...eventTicketUpdate.customer, [e.target.name]: e.target.value } })
        }
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.providerId && props.eventTicketId && eventTicketUpdate) {
                if (formRef.current?.checkValidity()) {
                    let updateData = { ...eventTicketUpdate }
                    updateData.customer.tel = (dialCode === updateData.customer.tel) ? "" : updateData.customer.tel

                    const res = await EventTicketService.updateByUser(props.providerId, props.eventTicketId, updateData)

                    if (res?.isSuccess) {
                        props.onSuccess()
                        props.onClose()
                        setMessage(res.message)
                    } else {
                        setMessage(res?.message)
                    }

                } else {
                    setValidatedForm(true)
                }
            }
            setProcessing(false)
        }
    }

    // QRCode
    const [selectedText, setSelectedText] = useState("")
    const [qrCode, setQRCode] = useState<{ image: string, code: string, caption?: Array<string> }>({ image: "", code: "", caption: [] })
    const [showQRCodeModal, setShowQRCodeModal] = useState(false)
    const handleCloseQRCodeModal = () => {
        setShowQRCodeModal(false)
        setQRCode({ image: "", code: "", caption: [] })
        setSelectedText("")
    }

    const handleShowQRCodeModal = (text: string) => {
        if (text) {
            const res = QRCodeService.generate(text)

            res.then(data => {
                if (data?.isSuccess) {
                    setQRCode({ image: data.data.image, code: text, caption: [] })
                    setSelectedText(text)
                    setShowQRCodeModal(true)
                } else {
                    console.log(data?.message)
                }
            })
        }
    }

    const handleDownloadQRCode = () => {
        if (selectedText) {
            QRCodeService.download(selectedText)
        }
    }

    //PhoneInput
    const [dialCode, setDialCode] = useState("")
    const handlePhoneInputChange = (value: string, data: {} | CountryData) => {
        if (eventTicketUpdate) {
            let dialCode = (data as CountryData).dialCode
            setDialCode(dialCode)
            setEventTicketUpdate({ ...eventTicketUpdate, customer: { ...eventTicketUpdate.customer, tel: value } })
        }
    }

    return (<>
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t("eventTicketEditModal.title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                    {eventTicket &&
                        <fieldset>
                            <legend>{t("eventTicketDetailsModal.ticketDetails")}</legend>
                            <dl className="ticket-details">
                                <dt>{t("eventTicketsPage.event")}:</dt><dd>{eventTicket?.event?.name || ""}</dd>
                                <dt>{t("eventTicketsPage.date")}:</dt><dd>{eventTicket?.eventDate ? moment(eventTicket?.eventDate).format(Configs.DATE_FORMAT) : ""}</dd>
                                <dt>{t("eventTicketsPage.time")}:</dt><dd>{eventTicket?.eventSchedule?.startTime.substring(0, 5)} - {eventTicket?.eventSchedule?.endTime.substring(0, 5)}</dd>
                                <dt>{t("eventTicketsPage.amount")}:</dt><dd>{Utils.formatNumber(eventTicket?.totalPrice || 0)} {eventTicket?.currency}</dd>
                            </dl>
                            <Table responsive bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>{t("eventTicketsPage.code")}</th>
                                        <th>{t("eventTicketsPage.ticketClass")}/{t("eventTicketsPage.seat")}</th>
                                        <th>{t("eventTicketsPage.price")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {eventTicket.details?.map(x => <tr key={x.id}>
                                        <td>
                                            <Button variant="default" size="sm" onClick={() => handleShowQRCodeModal(x.code)}>
                                                <i className="fas fa-qrcode text-info"></i>
                                            </Button>
                                            {x.code || ""}
                                        </td>
                                        <td>{x.ticketClass?.name}{x.seat ? "/" : ""}{x.seat?.letter || ""}{x.seat?.number || ""}</td>
                                        <td>{Utils.formatNumber(x.price || 0)}</td>
                                    </tr>)}
                                </tbody>
                            </Table>
                        </fieldset>}
                    {eventTicketUpdate && <>
                        <fieldset>
                            <legend>{t("eventTicketDetailsModal.paymentDetails")}</legend>
                            <Row>
                                <Col md={6}>
                                    <PaymentServiceSelect required value={eventTicketUpdate.paymentService} onChange={(e) => setEventTicketUpdate({ ...eventTicketUpdate, paymentService: e.target.value })}></PaymentServiceSelect>
                                </Col>
                                <Col md={6}>
                                    <PaymentStatusSelect required value={eventTicketUpdate.paymentStatus} onChange={(e) => setEventTicketUpdate({ ...eventTicketUpdate, paymentStatus: e.target.value })}></PaymentStatusSelect>
                                </Col>
                            </Row>
                            <Form.Group controlId="note">
                                <Form.Label className="mb-0">{t("eventTicketsPage.note")}</Form.Label>
                                <Form.Control as="textarea" rows={2} type="text" name="note" value={eventTicketUpdate?.note || ""} onChange={(e) => setEventTicketUpdate({ ...eventTicketUpdate, note: e.target.value })} />
                            </Form.Group>
                        </fieldset>
                        <fieldset>
                            <legend>{t("eventTicketDetailsModal.customerDetails")}</legend>
                            <Form.Group className="mb-1" controlId="name">
                                <Form.Label className="mb-0">{t("eventTicketsPage.name")}</Form.Label>
                                <Form.Control type="text" name="name" required={false} value={eventTicketUpdate?.customer.name || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredName")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="tel">
                                <Form.Label className="mb-0">{t("eventTicketsPage.tel")}</Form.Label>
                                <PhoneInput
                                    country={'vn'}
                                    countryCodeEditable={false}
                                    placeholder=""
                                    searchPlaceholder={t("phoneInput.searchPlaceholder")}
                                    searchNotFound={t("phoneInput.searchNotFound")}
                                    enableSearch={true}
                                    value={eventTicketUpdate.customer.tel}
                                    onChange={handlePhoneInputChange}
                                />
                                {/* <Form.Control type="text" name="tel" required={false} value={eventTicketUpdate?.customer.tel || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredTel")}</Form.Control.Feedback> */}
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="email">
                                <Form.Label className="mb-0">Email</Form.Label>
                                <Form.Control type="email" name="email" required={false} value={eventTicketUpdate?.customer.email || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                <Form.Control.Feedback type="invalid">{t("eventTicketsPage.requiredEmail")}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="address">
                                <Form.Label className="mb-0">{t("eventTicketsPage.address")}</Form.Label>
                                <Form.Control type="text" name="address" value={eventTicketUpdate?.customer.address || ""} onChange={(e) => handleCustomerChange(e as any)} />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="personalId">
                                <Form.Label className="mb-0">{t("eventTicketsPage.personalId")}</Form.Label>
                                <Form.Control type="text" name="personalId" value={eventTicketUpdate?.customer.personalId || ""} onChange={(e) => handleCustomerChange(e as any)} />
                                {/* <Form.Text>Priority custormer</Form.Text> */}
                            </Form.Group>
                        </fieldset>
                    </>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {message !== "" && <Alert variant="danger" onClose={() => setMessage("")} dismissible style={{ flex: "1" }}>{message}</Alert>}
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> {t("common.cancel")}</Button>
                <Button variant="info" onClick={handleSave} disabled={processing}>
                    {processing ? <Spinner animation="border" size="sm" /> : <i className="fas fa-save"></i>} {t("common.save")}
                </Button>
            </Modal.Footer>
        </Modal>
        <QRCodeModal image={qrCode.image} code={qrCode.code} caption={qrCode.caption} show={showQRCodeModal} onClose={handleCloseQRCodeModal} onDownload={handleDownloadQRCode}></QRCodeModal>
    </>)
}

export default MyEventTicketEditModal