import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

class UploadService {
    static async uploadImage(formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Upload/Image", formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async uploadChunk(formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>("/api/Upload/Chunk", formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}

export default UploadService