import { KeyboardEvent, RefObject, useCallback, useEffect, useRef, useState } from "react"
import EventTicketService, { GetEventTicketDetailsSortFields, IEventTicketDetails, IEventTicketPrint } from "../../services/EventTicketService"
import Configs from "../../Configs"
import useAppContext from "../../hooks/useAppContext"
import ContentHeader from "../../components/ContentHeader"
import ContentBody from "../../components/ContentBody"
import { Button, Card, Col, Container, Form, InputGroup, Row, Table } from "react-bootstrap"
import Pagination from "../../components/Pagination"
import SortBy from "../../components/SortBy"
import Utils from "../../utils/Utils"
import RoutePath from "../../constants/RoutePath"
import { NavLink } from "react-router-dom"
import Permissions from "../../constants/Permissions"
import MyEventTicketCreateModal from "./MyEventTicketCreateModal"
import HtmlToPrint from "../../components/HtmlToPrint"
import useAuthContext from "../../hooks/useAuthContext"
import TicketTemplateService, { ITicketTemplate } from "../../services/TicketTemplateService"
import { useReactToPrint } from "react-to-print"
import MyEventTicketDashboardModal from "./MyEventTicketDashboardModal"
import { useTranslation } from "react-i18next"

function MyEventTicketsSold() {
    const { t } = useTranslation("global")
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()

    const [tableData, setTableData] = useState({
        data: new Array<IEventTicketDetails>(),
        hasNext: false,
        hasPrevious: false,
        pageIndex: 1,
        pageSize: Configs.DEFAULT_PAGE_SIZE,
        totalPages: 0,
        totalRecords: 0
    })
    const [sortBy, setSortBy] = useState("createdOn")
    const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC")

    const keywordRef = useRef<HTMLInputElement>(null)

    const FilterFn = async (pageIndex: number) => {
        if (state.currentProviderId) {
            let pageSize = Configs.DEFAULT_PAGE_SIZE
            let keyword = keywordRef.current?.value || ""

            const res = await EventTicketService.listOfSoldByUser(state.currentProviderId, pageIndex, pageSize, keyword, sortBy, sortDirection)

            if (res?.isSuccess) {
                setTableData(res.data)
            } else {
                console.log(res?.message)
            }
        }
    }

    useEffect(() => {
        keywordRef.current?.focus()

        FilterFn(1)
    }, [state.currentProviderId, sortBy, sortDirection])

    const handleSearch = () => {
        FilterFn(1)
    }

    const handleSearchInputKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            FilterFn(1)
        }
    }

    const handlePageChange = (pageNumber: number) => {
        FilterFn(pageNumber)
    }

    //AddNew
    const [showCreateModal, setShowCreateModal] = useState(false)

    const handleCreateSuccess = (id: number) => {
        handlePrint(id)
        FilterFn(1)
    }

    //Print
    const [isPrinting, setIsPrinting] = useState(false)
    const printRef = useRef(null)
    const onBeforeGetContentResolve = useRef<(() => void) | null>(null)
    const [eventTicketId, setEventTicketId] = useState<number | null>(null)
    const [ticketContentToPrint, setTicketContentToPrint] = useState<ITicketTemplate | null>(null)

    const handlePrint = (id: number | null) => {
        if (state.currentProviderId && id) {
            if (Configs.PrintMethod.get() === "HTML") {
                setEventTicketId(id)
            } else {
                EventTicketService.generatePDFByUser(state.currentProviderId, id)
            }
        }
    }

    useEffect(() => {
        loadTicketContent()
    }, [eventTicketId])

    const loadTicketContent = async () => {
        if (state.currentProviderId && eventTicketId) {
            const template = await TicketTemplateService.getEventTicketHTMLTemplate(state.currentProviderId)

            if (template?.isSuccess && template.data) {
                const ticketTemplate: ITicketTemplate = template.data

                const res = await EventTicketService.getForPrintByUser(state.currentProviderId, eventTicketId)

                if (res?.isSuccess) {
                    var html = ""
                    res.data.forEach((ticket: IEventTicketPrint, index: number) => {
                        var contentTemplate = ticketTemplate.htmlContent
                        var logo = (ticketTemplate.logo || "") !== "" ? `${Configs.API_BASE_URL}${ticketTemplate.logo}` : ""
                        var bgImage = (ticketTemplate.bgImage || "") !== "" ? `${Configs.API_BASE_URL}${ticketTemplate.bgImage}` : ""

                        if (index > 0) {
                            html += '<div class="page-break" />'
                        }

                        html += contentTemplate
                            .replaceAll("{eventId}", ticket.eventId.toString())
                            .replaceAll("{eventName}", ticket.eventName || "")
                            .replaceAll("{thumbnail}", ticket.thumbnail || "")
                            .replaceAll("{eventDate}", Utils.dateToString(ticket.eventDate, Configs.DATE_FORMAT))
                            .replaceAll("{eventStartTime}", ticket.eventStartTime?.substring(0, 5) || "")
                            .replaceAll("{eventEndTime}", ticket.eventEndTime?.substring(0, 5) || "")
                            .replaceAll("{tel}", ticket.tel || "")
                            .replaceAll("{email}", ticket.email || "")
                            .replaceAll("{website}", ticket.website || "")
                            .replaceAll("{websiteQRCode}", ticket.websiteQRCode || "")
                            .replaceAll("{address}", ticket.address || "")
                            .replaceAll("{code}", ticket.code || "")
                            .replaceAll("{qrcode}", ticket.qrcodeImage)
                            .replaceAll("{price}", Utils.formatNumber(ticket.price))
                            .replaceAll("{currency}", ticket.currency)
                            .replaceAll("{seat}", ticket.seat)
                            .replaceAll("{ticketClass}", ticket.ticketClass)
                            .replaceAll("{eventTicketId}", ticket.eventTicketId.toString())
                            .replaceAll("{logo}", logo)
                            .replaceAll("{bgImage}", bgImage)
                            .replaceAll("{createdOn}", Utils.dateToString(ticket.createdOn, `${Configs.TIME_FORMAT} ${Configs.DATE_FORMAT}`))
                    })

                    setTicketContentToPrint({ ...ticketTemplate, htmlContent: html })
                } else {
                    setTicketContentToPrint(null)
                }
            } else {
                setTicketContentToPrint(null)
            }
        } else {
            setTicketContentToPrint(null)
        }
    }

    const handleOnBeforeGetContent = useCallback(() => {
        setIsPrinting(true)

        return new Promise<void>((resolve) => {
            onBeforeGetContentResolve.current = resolve
            setIsPrinting(false)
            setEventTicketId(null)
            resolve()
        })
    }, [isPrinting, ticketContentToPrint])

    const handlePrintHTML = useReactToPrint({
        content: () => printRef?.current,
        onBeforeGetContent: handleOnBeforeGetContent,
        onAfterPrint: () => {
            onBeforeGetContentResolve.current = null
            setTicketContentToPrint(null)
        },
        removeAfterPrint: true
    })

    useEffect(() => {
        if (ticketContentToPrint) {
            handlePrintHTML()
        }
    }, [ticketContentToPrint])

    useEffect(() => {
        if (ticketContentToPrint && typeof onBeforeGetContentResolve.current === "function") {
            onBeforeGetContentResolve.current()
        }
    }, [onBeforeGetContentResolve.current, ticketContentToPrint])

    const [showMyEventTicketDashboardModal, setShowMyEventTicketDashboardModal] = useState(false)

    return (<>
        <ContentHeader title={t("myEventTicketsPage.title")} />

        <ContentBody>
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Header>
                                {hasPermission(Permissions.MyEventTickets.Create) &&
                                    <Button variant="info" size="sm" onClick={() => setShowCreateModal(true)}>
                                        <i className="fas fa-plus"></i> {t("eventTicketsPage.createNewEventTicket")}
                                    </Button>
                                }
                                {hasPermission(Permissions.MyEventTickets.Report) &&
                                    <Button variant="secondary" size="sm" className="ml-1" onClick={() => setShowMyEventTicketDashboardModal(true)}>
                                        <i className="fas fa-chart-line"></i> {t("myEventTicketsPage.dashboard")}
                                    </Button>
                                }

                                <div className="card-tools" style={{ display: "flex" }}>
                                    <NavLink to={RoutePath.PROVIDER.MY_EVENT_TICKETS} style={{ whiteSpace: "nowrap" }} className="btn btn-light btn-sm mr-1"><i className="fas fa-square"></i> {t("eventTicketsPage.overview")}</NavLink>
                                    <NavLink to={RoutePath.PROVIDER.MY_EVENT_TICKETS_SOLD} style={{ whiteSpace: "nowrap" }} className="btn btn-light btn-sm mr-1"><i className="fas fa-list"></i> {t("eventTicketsPage.details")}</NavLink>
                                    <SortBy fields={GetEventTicketDetailsSortFields()} sortBy={sortBy} sortDirection={sortDirection} onFieldChange={name => setSortBy(name)} onDirectionChange={name => setSortDirection(name)}></SortBy>

                                    <InputGroup size="sm">
                                        <Form.Control
                                            type="search"
                                            placeholder={t("common.search")}
                                            ref={keywordRef as RefObject<HTMLInputElement>}
                                            onKeyUp={handleSearchInputKeyPress}
                                        />
                                        <Button variant="info" size="sm" onClick={handleSearch}>
                                            <i className="fas fa-search"></i>
                                        </Button>
                                    </InputGroup>
                                </div>
                            </Card.Header>
                            <Card.Body className="pb-0">
                                <Table responsive striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>{t("eventTicketsPage.code")}</th>
                                            <th>{t("eventTicketsPage.createdOn")}</th>
                                            <th>{t("eventTicketsPage.createdBy")}</th>
                                            <th>{t("eventTicketsPage.customer")}</th>
                                            <th>{t("eventTicketsPage.ticket")}</th>
                                            <th style={{ textAlign: "center" }}>{t("eventTicketsPage.amount")}</th>
                                            <th style={{ textAlign: "center" }}>{t("eventTicketsPage.paymentService")}</th>
                                            <th>{t("eventTicketsPage.status")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.data.map(item => <tr key={item.id}>
                                            <td>{item.code}</td>
                                            <td>
                                                {Utils.dateToString(item.createdOn, Configs.DATE_FORMAT)}<br />
                                                {Utils.dateToString(item.createdOn, Configs.TIME_FORMAT)}
                                            </td>
                                            <td>
                                                {item.creator && <>
                                                    {item.creator.fullName}
                                                    <span className="d-block text-muted text-nowrap"><i className="far fa-user"></i> {item.creator.userName}</span>
                                                </>}
                                            </td>
                                            <td>{item.eventTicket.customer.name || ""}</td>
                                            <td>
                                                {item.eventTicket.event?.name || ""} ({item.ticketClass?.name || ""})<br />
                                                <i className="far fa-calendar text-black-50"></i> <span className="text-primary">{Utils.dateToString(item.eventTicket.eventDate, Configs.DATE_FORMAT)}</span> <i className="far fa-clock text-black-50"></i> {item.eventTicket.eventSchedule?.startTime.substring(0, 5)}
                                            </td>
                                            <td className="text-danger" style={{ textAlign: "right" }}>{Utils.formatNumber(item.price)}</td>
                                            <td style={{ textAlign: "center" }}>{item.eventTicket.paymentService && t(`paymentService.${item.eventTicket.paymentService}`)}</td>
                                            <td className="text-primary">
                                                {item.usedOn ?
                                                    <><span className="text-muted">{t("eventTicketsPage.usedOn")}:</span> {Utils.dateToString(item.usedOn, Configs.DATE_TIME_FORMAT)}</> :
                                                    <span className="text-muted" style={{ fontStyle: "italic" }}>{t("eventTicketsPage.unused")}</span>}
                                                {item.checker && <><br /><span className="text-muted">{t("eventTicketsPage.checkedBy")}:</span> {item.checker.userName}</>}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </Card.Body>
                            <Card.Footer>
                                <Pagination hasNext={tableData.hasNext} hasPrevious={tableData.hasPrevious} pageIndex={tableData.pageIndex} pageSize={tableData.pageSize} totalPages={tableData.totalPages} totalRecords={tableData.totalRecords} handlePageChange={handlePageChange} />
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </ContentBody>

        <MyEventTicketCreateModal show={showCreateModal} providerId={state.currentProviderId} onClose={() => setShowCreateModal(false)} onSuccess={handleCreateSuccess} />

        <HtmlToPrint
            htmlContent={ticketContentToPrint?.htmlContent || ""}
            styleSheet={ticketContentToPrint?.styleSheet || ""}
            paperSizeWidth={ticketContentToPrint?.paperSizeWidth || ""}
            paperSizeHeight={ticketContentToPrint?.paperSizeHeight || ""}
            ref={printRef}
        />

        <MyEventTicketDashboardModal show={showMyEventTicketDashboardModal} onClose={() => setShowMyEventTicketDashboardModal(false)} />
    </>)
}

export default MyEventTicketsSold