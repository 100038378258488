import { Alert, Button, Form, Modal, Spinner, Table } from "react-bootstrap"
import AppService, { IApp } from "../../services/AppService"
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react"
import Permissions from "../../constants/Permissions"
import useAuthContext from "../../hooks/useAuthContext"

interface IAppsModalProps {
    providerId: number
    apiKeyId: string
    title: string
    show: boolean
    onClose: () => void
}

function AppsModal(props: IAppsModalProps) {
    const { hasPermission } = useAuthContext()
    const [apps, setApps] = useState<Array<IApp>>([])
    const [message, setMessage] = useState("")

    const loadData = async () => {
        if (props.providerId && props.apiKeyId) {
            const res = await AppService.listByApiKeyId(props.providerId, props.apiKeyId)

            if (res?.isSuccess) {
                setApps(res.data)
            } else {
                console.log(res?.message)
            }
        } else {
            setApps([])
        }
    }

    useEffect(() => {
        if (props.show) {
            loadData()
            setMessage("")
        }
    }, [props.providerId, props.apiKeyId, props.show])

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>, name: string) => {
        setApps(apps.map(x => x.name !== name ? x : { ...x, url: e.target.value }))
    }

    const [processing, setProcessing] = useState<boolean>(false)
    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (!processing) {
            setProcessing(true)
            if (props.providerId && props.apiKeyId) {
                try {
                    const res = await AppService.updateByApiKeyId(props.providerId, props.apiKeyId, apps)

                    if (res?.isSuccess) {
                        setMessage(res.message)
                    } else {
                        setMessage(res?.message)
                    }
                } catch (error) {
                    console.log(error)
                }
            }
            setProcessing(false)
        }
    }

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton className="p-2">
                <Modal.Title><i className="fab fa-app-store-ios"></i> Apps <span>|</span> {props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{ width: "10%" }}>Name</th>
                                <th>Url</th>
                            </tr>
                        </thead>
                        <tbody>
                            {apps.map(app => {
                                return (
                                    <tr key={app.name}>
                                        <td>
                                            <h6>{app.name}</h6>
                                        </td>
                                        <td>
                                            <Form.Group controlId={app.name}>
                                                <Form.Control type="text" name="url" value={app?.url || ""} onChange={(e) => handleFormControlChange(e as any, app.name)} readOnly={!hasPermission(Permissions.Apps.Edit)} />
                                            </Form.Group>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Form>
                {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={props.onClose}><i className="fas fa-times"></i> Cancel</Button>
                {hasPermission(Permissions.Apps.Edit) &&
                    <Button variant="info" onClick={handleSave} disabled={processing}>
                        {processing ? <><Spinner animation="border" size="sm" /> Saving...</> : <><i className="fas fa-save"></i> Save</>}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default AppsModal