import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface IApp {
    id: number | null
    name: string
    url: string
}

export default class AppService {
    static async listByApiKeyId(providerId: number, apiKeyId: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Apps/${providerId}/ListByApiKeyId/${apiKeyId}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateByApiKeyId(providerId: number, apiKeyId: string, listData: Array<IApp>) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Apps/${providerId}/UpdateByApiKeyId/${apiKeyId}`, listData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}