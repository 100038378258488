import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"
import { ISeat } from "./SeatService"
import { ITicketClass } from "./TicketClassService"
import { ITimeZoneInfo } from "./TimeZoneService"

export interface IEventTranslation {
    eventId: number | null
    languageId: number
    name: string
    description: string
    organizer: string
    venue: string
    address: string
    isActive: boolean
    language: ILanguage
}

export interface IEventSchedule {
    id: number | null
    startTime: string
    endTime: string
    byDays: Array<string>
}

export interface IEvent {
    id: number | null
    name: string
    thumbnail: string
    floorPlan: string
    tel: string
    email: string
    website: string
    orderNumber: number | null
    timeZoneId: string
    startDate: string | null
    endDate: string | null
    openingTime: number | null
    closingTime: number | null
    published: boolean
    featured: boolean
    translations: Array<IEventTranslation>
    schedules: Array<IEventSchedule>
    ticketClasses: Array<ITicketClass>
    seats: Array<ISeat>
    timeZoneInfo: ITimeZoneInfo | null
    categoryIds: Array<number>
    bankAccountEnabled?: boolean
    onlinePaymentEnabled?: boolean
    eInvoiceEnabled?: boolean
}

export interface ITicketEmailTemplate {
    ticketEmailSubject: string
    ticketEmailBody: string
}

export interface IOnlinePaymentSettings {
    onlinePaymentProviderName: string
    onlinePaymentConfigurations: string
    onlinePaymentEnabled: boolean
}

export interface IBankAccountSettings {
    bankAccountName: string
    bankAccountNumber: string
    bankAccountEnabled: boolean
}

export interface IEInvoiceSettings {
    eInvoiceProviderName: string
    eInvoiceConfigurations: string
    eInvoiceAutoPublish: boolean
    eInvoiceEnabled: boolean
}

export default class EventService {
    static async filter(providerId: number, pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async listAllPublished(providerId: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/ListAllPublished`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getEventDates(providerId: number, id: number, pageId?: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/${id}/GetEventDates?pageId=${pageId || 1}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async getEventSchedules(providerId: number, id: number, date: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/${id}/${date}/GetEventSchedules`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Events/${providerId}/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(providerId: number, id: number, formData: FormData) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/${id}`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(providerId: number, formData: FormData) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Events/${providerId}/Create`, formData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateTicketEmailTemplate(providerId: number, id: number, itemData: ITicketEmailTemplate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/UpdateTicketEmailTemplate/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateOnlinePaymentSettings(providerId: number, id: number, itemData: IOnlinePaymentSettings) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/UpdateOnlinePaymentSettings/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateBankAccountSettings(providerId: number, id: number, itemData: IBankAccountSettings) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/UpdateBankAccountSettings/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async updateEInvoiceSettings(providerId: number, id: number, itemData: IEInvoiceSettings) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/UpdateEInvoiceSettings/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changePublished(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/ChangePublished/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeFeatured(providerId: number, id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Events/${providerId}/ChangeFeatured/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async search(providerId: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Events/${providerId}/Search?keyword=${keyword}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}