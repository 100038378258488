import { Form } from "react-bootstrap"
import { OnlinePaymentProvider } from "../constants/OnlinePaymentProvider"

interface IOnlinePaymentProviderSelectProps {
    value: string
    required?: boolean
    onChange: (e: any) => void
}

function OnlinePaymentProviderSelect(props: IOnlinePaymentProviderSelectProps) {
    return (
        <Form.Group className="mb-3" controlId="onlinePaymentProviderName">
            <Form.Label className="mb-0">Provider</Form.Label>
            <Form.Select name="onlinePaymentProviderName" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                <option value={OnlinePaymentProvider.ONEPAY}>{OnlinePaymentProvider.ONEPAY}</option>
            </Form.Select>
            {props.required && <Form.Control.Feedback type="invalid">Select a Provider.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default OnlinePaymentProviderSelect