import { memo, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import PlaceService, { IPlace } from "../services/PlaceService"
import Utils from "../utils/Utils"

interface IPlaceSelectForAgencyProps {
    agencyId: number | null
    value: number | null
    required?: boolean
    onChange: (e: any) => void
}

function PlaceSelectForAgency(props: IPlaceSelectForAgencyProps) {
    const [placeOptions, setPlaceOptions] = useState([] as any[])
    const [places, setPlaces] = useState<Array<IPlace>>([])

    const PlaceOptions = async (agencyId: number) => {
        const res = await PlaceService.listAllPublishedForAgency(agencyId)
        let options: any[] = []

        if (res?.isSuccess) {
            options = res.data.map((item: IPlace) => <option key={item.id} value={item.id || 0}>{item.name}</option>)
            setPlaces(res.data)
        } else {
            console.log(res?.message)
            setPlaces([])
        }

        return options
    }

    useEffect(() => {
        if (props.agencyId) {
            PlaceOptions(props.agencyId).then(options => {
                setPlaceOptions(options)
            })
        }
    }, [])

    const ShowPrice = () => {
        const item = places.find(x => x.id === props.value)
        if (item) {
            return item.price && <>
                <span className="text-danger">{Utils.formatNumber(item.price)} {item.currency}</span>
                {item.price && (item.timeLimit || 0) > 0 ? <span className="text-muted">/{item.timeLimit} hours</span> : <span className="text-muted">/Unlimited</span>}
            </>
        }
    }

    return (
        <Form.Group className="mb-3" controlId="placeId">
            <Form.Label>Place</Form.Label>
            <Form.Select name="placeId" required={props.required} value={props.value || ""} onChange={props.onChange}>
                <option value=""></option>
                {placeOptions}
            </Form.Select>
            <Form.Text>{ShowPrice()}</Form.Text>
            {props.required && <Form.Control.Feedback type="invalid">Select a place.</Form.Control.Feedback>}
        </Form.Group>
    )
}

export default memo(PlaceSelectForAgency)