import { IPlace } from "./PlaceService"
import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"

export interface ICredential {
    id: string
    name: string
    accessKey: string
    isActive: boolean
    places: Array<IPlace>
}

interface ICredentialPlace {
    placeId: number
}

export interface ICredentialUpdate {
    name: string
    places: Array<ICredentialPlace>
}

export default class CredentialService {
    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Credentials?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/Credentials/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: string) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/Credentials/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: string, itemData: ICredentialUpdate) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Credentials/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(itemData: ICredentialUpdate) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/Credentials/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsActive(id: string) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/Credentials/ChangeIsActive/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}