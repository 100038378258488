import { useNavigate } from "react-router-dom"
import useAppContext from "../../hooks/useAppContext"
import useAuthContext from "../../hooks/useAuthContext"
import AgencyService, { IAgencyProfile } from "../../services/AgencyService"
import { ChangeEvent, RefObject, SyntheticEvent, useEffect, useRef, useState } from "react"
import RoutePath from "../../constants/RoutePath"
import ContentHeader from "../../components/ContentHeader"
import ContentBody from "../../components/ContentBody"
import { Alert, Button, Card, Col, Container, Form, Row } from "react-bootstrap"
import Permissions from "../../constants/Permissions"

function AgencyInfo() {
    const { hasPermission } = useAuthContext()
    const { state } = useAppContext()
    const navigate = useNavigate()

    const initFormData: IAgencyProfile = {
        id: null,
        name: "",
        address: "",
        tel: "",
        email: ""
    }

    const [message, setMessage] = useState("")
    const [formData, setFormData] = useState(initFormData)
    const formRef = useRef<HTMLFormElement>(null)
    const [validatedForm, setValidatedForm] = useState(false)

    const loadAgency = async (id: number) => {
        const res = await AgencyService.getByUser(id)

        if (res?.isSuccess && res.data) {
            setFormData(res.data)
        } else {
            setFormData(initFormData)
            setMessage(res?.message)
            navigate(RoutePath.UNAUTHORIZED, { replace: true })
        }
    }

    useEffect(() => {
        if (state.currentAgencyId) {
            loadAgency(state.currentAgencyId)
        }
    }, [state.currentAgencyId])

    const handleFormControlChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSave = async (e: SyntheticEvent) => {
        e.preventDefault()

        if (formData.id) {
            if (formRef.current?.checkValidity()) {
                try {
                    const res = await AgencyService.updateByUser(formData.id, formData)
                    if (res?.isSuccess && res.data) {
                        setFormData(res.data)
                    }

                    setMessage(res?.message)
                } catch (error) {
                    console.log(error)
                }
            } else {
                setValidatedForm(true)
            }
        }
    }

    return (
        <>
            <ContentHeader title="Agency Info" />

            <ContentBody>
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <Card.Body>
                                    {message !== "" && <Alert variant="info" onClose={() => setMessage("")} dismissible>{message}</Alert>}
                                    <Form noValidate validated={validatedForm} ref={formRef as RefObject<HTMLFormElement>}>
                                        <Form.Group className="mb-3" controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control type="text" name="name" required value={formData.name || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                            <Form.Control.Feedback type="invalid">Name is required.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="address">
                                            <Form.Label>Address</Form.Label>
                                            <Form.Control type="text" name="address" value={formData.address || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="tel">
                                            <Form.Label>Tel</Form.Label>
                                            <Form.Control type="text" name="description" value={formData.tel || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" name="email" value={formData.email || ""} onChange={(e) => handleFormControlChange(e as any)} />
                                        </Form.Group>
                                    </Form>
                                </Card.Body>
                                <Card.Footer className="text-center">
                                    {hasPermission(Permissions.Agencies.EditProfile) &&
                                        <Button variant="info" onClick={handleSave}><i className="fas fa-save"></i> Save</Button>
                                    }
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </ContentBody>
        </>
    )
}

export default AgencyInfo