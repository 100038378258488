import axiosClient, { ApiResponse, axiosCustomError } from "./axiosClient"
import { ILanguage } from "./LanguageService"

export interface IPlaceTypeTranslation {
    id: number | null
    placeTypeId: number | null
    languageId: number
    name: string
    language: ILanguage
}

export interface IPlaceType {
    id: number | null
    name: string
    orderNumber: number | null
    isUsed: boolean
    translations: Array<IPlaceTypeTranslation>
}

export default class PlaceTypeService {
    static async listAll() {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PlaceTypes/All`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async filter(pageIndex: number, pageSize: number, keyword: string) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PlaceTypes?keyword=${keyword}&pageSize=${pageSize}&pageIndex=${pageIndex}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async get(id: number) {
        try {
            const { data } = await axiosClient.get<ApiResponse>(`/api/PlaceTypes/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async delete(id: number) {
        try {
            const { data } = await axiosClient.delete<ApiResponse>(`/api/PlaceTypes/${id}`, {

            })

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async update(id: number, itemData: IPlaceType) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/PlaceTypes/${id}`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async create(itemData: IPlaceType) {
        try {
            const { data } = await axiosClient.post<ApiResponse>(`/api/PlaceTypes/Create`, itemData)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }

    static async changeIsUsed(id: number) {
        try {
            const { data } = await axiosClient.put<ApiResponse>(`/api/PlaceTypes/ChangeIsUsed/${id}`)

            return data
        } catch (error) {
            return axiosCustomError(error)
        }
    }
}