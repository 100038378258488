import { Link } from "react-router-dom"
import RoutePath from "../constants/RoutePath"

export function Footer() {
    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                Version 1.0.2
            </div>
            Copyright © 2023 <Link to={RoutePath.HOME}>BonBonGuide</Link>. All rights reserved.
        </footer>
    )
}