import { Modal, Table } from "react-bootstrap"
import DateRangePicker from "../../components/DateRangePicker"
import { useEffect, useState } from "react"
import EventTicketService from "../../services/EventTicketService"
import useAppContext from "../../hooks/useAppContext"
import Utils from "../../utils/Utils"
import Configs from "../../Configs"

interface IDailySalesBySellerDashboardModalProps {
    sellerId: number | null
    startDate: Date
    endDate: Date
    title: string
    show: boolean
    onClose: () => void
}

function DailySalesBySellerDashboardModal(props: IDailySalesBySellerDashboardModalProps) {
    const { state } = useAppContext()
    const [dateReport, setDateReport] = useState<{
        startDate: Date,
        endDate: Date
    }>({
        startDate: props.startDate,
        endDate: props.endDate
    })

    const handleDateChange = (startDate?: Date, endDate?: Date) => {
        if (startDate && endDate) {
            setDateReport({ startDate, endDate })
        }
    }

    useEffect(() => {
        setDateReport({
            startDate: props.startDate,
            endDate: props.endDate
        })
    }, [props.show])

    useEffect(() => {
        if (props.show) {
            DailySalesBySellerReport(dateReport.startDate, dateReport.endDate)
        }
    }, [dateReport])

    type TReportItem = {
        eventName: string
        eventDate: Date
        eventStartTime: string
        eventEndTime: string
        customerName: string
        customerTel: string
        customerEmail: string
        invitationCode: string
        paymentStatus: string
        paymentService: string
        createdOn: Date
        totalPrice: number
        quantity: number
        bookedSeats: string
        note: string
    }

    const [reportItems, setReportItems] = useState<TReportItem[]>([])

    const DailySalesBySellerReport = async (start: Date, end: Date) => {
        if (state.currentProviderId && props.sellerId) {
            const res = await EventTicketService.dailySalesBySellerReport(state.currentProviderId, props.sellerId, start, end)

            if (res?.isSuccess) {
                setReportItems(res.data)
            } else {
                console.log(res.message)
            }
        }
    }

    return <Modal show={props.show} onHide={props.onClose} backdrop="static" keyboard={false} size="xl">
        <Modal.Header closeButton className="p-2">
            <Modal.Title>Daily Sales By <span className="text-primary">{props.title}</span></Modal.Title>
        </Modal.Header>
        <Modal.Header className="p-2">
            <DateRangePicker startDate={dateReport.startDate} endDate={dateReport.endDate} onChange={handleDateChange} closeOnSelected={true}></DateRangePicker>
        </Modal.Header>
        <Modal.Body className="p-0">
            <Table responsive hover striped className="mb-1" size="sm">
                <thead>
                    <tr>
                        <th style={{ width: "30px" }}>#</th>
                        <th>Created on</th>
                        <th>Customer</th>
                        <th>Ticket</th>
                        <th>Quantity</th>
                        <th style={{ textAlign: "right" }}>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {reportItems.map((item, index) => <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{Utils.dateToString(item.createdOn, Configs.DATE_FORMAT)} {Utils.dateToString(item.createdOn, Configs.TIME_FORMAT)}</td>
                        <td>
                            <ul className="list-in-table">
                                {[item.customerName, item.customerTel, item.customerEmail, item.note].filter(x => x !== "").map((x, i) => <li key={i}>{x}</li>)}
                            </ul>
                        </td>
                        <td>
                            <ul className="list-in-table">
                                {[item.eventName, Utils.dateToString(item.eventDate, Configs.DATE_FORMAT), item.eventStartTime.substring(0, 5)].filter(x => x !== "").map((x, i) => <li key={i}>{x}</li>)}
                            </ul>
                        </td>
                        <td>
                            <ul className="list-in-table">
                                {[Utils.formatNumber(item.quantity), item.bookedSeats].filter(x => x !== "").map((x, i) => <li key={i}>{x}</li>)}
                            </ul>
                        </td>
                        <td style={{ textAlign: "right" }} className="text-danger">
                            {Utils.formatNumber(item.totalPrice)}
                            <small className="text-muted d-block">{item.paymentService}</small>
                        </td>
                    </tr>)}
                </tbody>
                <tfoot>
                    <tr>
                        <th style={{ textAlign: "right" }} colSpan={4}>Total</th>
                        <th>{Utils.formatNumber(reportItems.map(x => x.quantity).reduce((a, b) => a + b, 0))}</th>
                        <th style={{ textAlign: "right" }}>{Utils.formatNumber(reportItems.map(x => x.totalPrice).reduce((a, b) => a + b, 0))}</th>
                    </tr>
                </tfoot>
            </Table>
        </Modal.Body>
    </Modal>
}

export default DailySalesBySellerDashboardModal