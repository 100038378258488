import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'

import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'

import resourceEn from './resources/en.json'
import resourceVi from './resources/vi.json'
import Configs from './Configs'

i18next.init({
  interpolation: { escapeValue: false },
  lng: Configs.Language.get(),
  fallbackLng: 'en',
  resources: {
    en: {
      global: resourceEn,
    },
    vi: {
      global: resourceVi,
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
